import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Body, Button } from "desisystem";
import { Delete } from "zoozoo";
import { useDropzone } from "react-dropzone";

import alert from "../../../../assets/empty-alert.svg";
import PreviewPredictCard from "../../../global/PreviewPredictCard";
import ButtonWithLoading from "../../../ui/buttons/ButtonWithLoading";
import Global from "../../../layouts/Global";

// redux
import { storePredictData } from "../../../../redux/ducks/storage";

import {
  createPrediction,
  predictionSelector,
} from "../../../../redux/slices/prediction";
import { predictionsSelector } from "../../../../redux/slices/predictions";

import { bytesToSize } from "../../../../utils/helperFunctions";
import isEmpty from "../../../../utils/helperFunctions";
import { resizeMe } from "../../../../utils/imageHelpers";
import { errorToast } from "../../../../utils/helperFunctions";
import { globalActionsSelector } from "../../../../redux/slices/globalActions";
import { subscriptionSelector } from "../../../../redux/slices/subs";

const Create = styled.div`
  background: ${(props) => props.theme.colors.globalBg};
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-top: 140px;

  h2 {
    text-align: center;
    margin-bottom: 40px;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
  }
`;

const DropAreBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;

  p {
    font-size: 20px;
    color: #212121;
  }

  span {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #bdbdbd;
    margin-top: 16px;

    strong {
      color: #212121;
    }
  }

  p:last-child {
    margin-top: 24px;
    font-weight: normal;
    font-size: 14px;
    color: #3e21de;
    cursor: pointer;
  }
`;

const DropArea = styled.div`
  min-width: 678px;
  width: 100%;
  height: 256px;
  background: #f1effd;
  font-size: ${(props) => props.theme.fontSize[14]};
  text-align: left;
  display: flex;
  background: #fafafa;
  border: 2px dashed #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;

  & > div {
    width: 100%;
    height: 100%;
  }
`;

const Preview = styled.div`
  min-width: 315px;
`;

const BtnActions = styled.div`
  margin-top: 40px;
  text-align: center;

  button {
    height: 40px;
    min-width: 200px;
    padding: 0 2rem;
    font-size: ${(props) => props.theme.fontSize[14]};
  }

  p {
    color: #3e21de;
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSize[14]};
  }
`;

const CreditMsg = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  p {
    margin-left: 6px;
    font-size: 12px;
    color: #9e9e9e;
  }

  img {
    width: 20px;
  }
`;

const CloseLink = styled(Link)`
  & > svg {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 40px;
  }
`;

const UploadPrediction = (props) => {
  const [files, setFile] = useState([]);
  const [image, setImage] = useState();
  const [maxHeight, setMaxHeight] = useState(true);

  const { projectIdForGlobal } = useSelector(globalActionsSelector);
  const predictData = useSelector((state) => state.storage.predictData);
  const { subs: { tier } = {} } = useSelector(subscriptionSelector);

  const { predictions } = useSelector(predictionsSelector);
  const { loading } = useSelector(predictionSelector);

  const [originalPredictName, setOriginalPredictName] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEmpty(predictData)) {
      props.history.push("/");
    }
  }, [predictData, props.history]);

  useEffect(() => {
    if (!maxHeight) {
      errorToast("Image exceeded maximum dimensions!");
    }
  }, [maxHeight]);

  const { getRootProps, getInputProps } = useDropzone({
    minSize: 0,
    maxSize: 20971520,
    accept: ["image/jpeg", "image/png"],
    onDrop: (acceptedFiles) => {
      const file = acceptedFiles[0];

      let reader = new FileReader();
      reader.readAsArrayBuffer(file);

      reader.onload = function(event) {
        let blob = new Blob([event.target.result]);
        window.URL = window.URL || window.webkitURL;
        let blobURL = window.URL.createObjectURL(blob);

        let image = new Image();
        image.src = blobURL;

        image.onload = function() {
          let resized = resizeMe(image);
          setImage(resized);
          setOriginalPredictName(file.name);

          let img = new Image();
          img.src = file.preview;
          img.onload = function() {
            setMaxHeight(img.height > 25000 ? false : true);
          };

          const imageData = {
            image: resized,
            name: predictData.name,
            model: predictData.model,
            projectId: predictData.projectId,
            pathname: predictData.pathname,
          };

          dispatch(storePredictData(imageData));
        };
      };
      reader.onerror = function(error) {
        console.log("Error: ", error);
      };

      setFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });
  // console.log(maxHeight);
  // const thumbs = files.map((file, i) => (
  //   <React.Fragment key={i}>
  //     {/* <span>{console.log(file.preview)}</span> */}
  //     <PreviewPredictCard
  //       backgroundImage={predictData.image || file.preview}
  //       fileName={predictData.name || file.name}
  //       size={bytesToSize(file.size)}
  //     />
  //   </React.Fragment>
  // ));

  const removeImage = () => {
    setFile([]);
  };

  const startPredict = () => {
    const predictInfo = new FormData();
    predictInfo.append("image", image);
    predictInfo.append(
      "name",
      predictData.name !== "" ? predictData.name : originalPredictName
    );
    predictInfo.append("model", predictData.model);
    if (projectIdForGlobal !== null || predictData.projectId !== null) {
      predictInfo.append("project_id", predictData.projectId);
    }

    dispatch(createPrediction(predictInfo, predictions.length));
  };

  const hasFiles = files.length > 0;

  const hasFilesAndLoading = files.length > 0 && loading;

  return (
    <React.Fragment>
      <Global />
      <CloseLink to={predictData.pathname}>
        <Delete width={40} height={40} />
      </CloseLink>
      <Create>
        <div>
          <h2>Upload image</h2>
          {files.length > 0 && maxHeight ? (
            <React.Fragment>
              <Preview>
                <React.Fragment>
                  <PreviewPredictCard
                    backgroundImage={files[0].preview}
                    fileName={predictData.name || files[0].name}
                    size={bytesToSize(files[0].size)}
                  />
                </React.Fragment>
              </Preview>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <DropArea>
                <div {...getRootProps({ className: "dropzone" })}>
                  <DropAreBody>
                    <input {...getInputProps()} />
                    <Body>Drag and Drop your image here.</Body>
                    <span>
                      We support PNG, JPEG, WEBP formats. <br />
                      Max size: <strong>20MB</strong>, Max height:{" "}
                      <strong>25000px</strong>
                    </span>
                    <Body>or browse your computer</Body>
                  </DropAreBody>
                </div>
              </DropArea>
            </React.Fragment>
          )}

          <BtnActions>
            {hasFiles && maxHeight ? (
              <ButtonWithLoading
                loading={loading}
                title="Start Prediction"
                onClick={startPredict}
              />
            ) : (
              <Button disabled={true}>Start Prediction</Button>
            )}
            {!hasFilesAndLoading && (
              <p onClick={removeImage}>
                or remove this image and upload another
              </p>
            )}
          </BtnActions>

          {tier === "free" && (
            <CreditMsg>
              <img src={alert} alt="alert" />
              <p>
                Upon starting a prediction, one (1) Credit will be deducted from
                your account!
              </p>
            </CreditMsg>
          )}
        </div>
      </Create>
    </React.Fragment>
  );
};

export default UploadPrediction;
