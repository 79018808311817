import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import useForm from "react-hook-form";
import { Link } from "react-router-dom";
import SocialProofLayout from "../../layouts/SocialProofLayout";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";
import InlineErrorMessage from "../../ui/globals/InlineErrorMessage";

// assets
import { dummy } from "../../icons/dummy";

// ds
import { Input, PasswordInput } from "desisystem";

// actions
import { createUser } from "../../../redux/ducks/auth";
import { plugin } from "react-ga";

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${props => props.theme.weight.bold};
    font-size: ${props => props.theme.fontSize[26]};
    color: ${props => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${props => props.theme.fontSize[14]};
    color: ${props => props.theme.colors.subHeading};
  }
`;

const NotMember = styled.div`
  margin-top: 16px;
  font-size: ${props => props.theme.fontSize[12]};
  color: ${props => props.theme.colors.black};

  a {
    color: ${props => props.theme.colors.brandPurple};
    text-decoration: none;
  }
`;

const TwoInputRow = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

const FormBody = styled.div`
  margin-top: 40px;

  & > form button {
    margin-top: 30px;
  }
`;

const AddName = ({ location: { state = "" }, history }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [direct, setDirect] = useState(null);
  const [pluginName, setPluginName] = useState("");

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const { errorMsg } = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.loadings);
  const dispatch = useDispatch();

  // console.log(state);

  useEffect(() => {
    if (state === "") {
      history.push("/auth/create");
    } else {
      const { email, directData, pluginNewUser } = state.state;
      setUserEmail(email);
      setDirect(directData);
      setPluginName(pluginNewUser);
    }
  }, [state, history]);

  const handleCreateUser = e => {
    const userData = new FormData();
    userData.append("first_name", firstName);
    userData.append("last_name", lastName);
    userData.append("email", userEmail);
    userData.append("password", password);
    userData.append("avatar", dummy);
    userData.append("plugin", pluginName);

    dispatch(createUser(userData, direct));
  };

  const isDisabled = firstName === "" || lastName === "" || password === "";

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Thanks for signing up</h1>
          <p>We just need a few more things and you'll be ready to go.</p>
        </Heading>

        <FormBody>
          <form onSubmit={handleSubmit(handleCreateUser)}>
            <TwoInputRow>
              <div>
                <Input
                  label="FIRST NAME"
                  type="text"
                  size="10px"
                  onChange={e => setFirstName(e.target.value)}
                  name="createFirstName"
                  validate={register({
                    minLength: 3,
                    required: true
                  })}
                />
                {errors.createFirstName && (
                  <span className="error-msg">Field must not be empty</span>
                )}
              </div>
              <div>
                <Input
                  label="LAST NAME"
                  type="text"
                  size="10px"
                  onChange={e => setLastName(e.target.value)}
                  name="createLastName"
                  validate={register({
                    minLength: 2,
                    required: true
                  })}
                />
                {errors.createLastName && (
                  <span className="error-msg">Field must not be empty</span>
                )}
              </div>
            </TwoInputRow>

            <PasswordInput
              handleChange={setPassword}
              label="PASSWORD"
              name="createPassword"
              size="10px"
              hasError={errors["createPassword"]}
              register={register}
            />

            {errorMsg && <InlineErrorMessage errorMsg={errorMsg} />}

            <ButtonWithLoading
              loading={loading}
              title="Continue"
              type="submit"
              width="100"
              disabled={isDisabled}
            />
          </form>
          <NotMember>
            Already have an account?{" "}
            <Link
              to={{
                pathname: "/auth/login",
                state: {
                  fromSiteData: state !== "" ? direct : null
                }
              }}
            >
              Log in.
            </Link>
          </NotMember>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default AddName;
