import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SvgLogo from "../../../../icons/SvgLogo";
import AvatarActions from "./AvatarActions";
import MenuLinks from "./MenuLinks";
import NavbarInfo from "./NavbarInfo";
import { fbTrack } from "../../../../../utils/analytics";
import { BigKey, ThunderCredits } from "zoozoo";
import { config } from "../../../../../constants/endpoints";
import NeuronsBanner from "../../../banners/NeuronsBanners";

const Fixed = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 125px;
  margin: 0 auto;
  display: flex;
  flex-direction: column
  align-items: center;
`;

const Nav = styled.nav`
  background: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  padding: 10px 40px;
  display: flex;
  align-items: center;
  width: 100%;
`;

const NavGrid = styled.div`
  display: grid;
  grid-template-columns: 8% 41% 50%;
  width: 100%;
`;

const Logo = styled(Link)`
  display: inline-block;
  pointer-events: ${(props) => (props.isroot ? "none" : "auto")};

  img {
    width: 100%;
    height: 100%;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  & > div {
    padding: 0 10px;
  }
  & > div:last-child {
    padding: 0;
  }
`;

const Divider = styled.span`
  max-width: 1px;
  min-width: 1px;
  background-color: #e2e0ec;
  height: 1.5rem;
  margin: 0 1rem;

  @media (max-width: 1090px) {
    margin: 0;
  }
`;

const StyledLink = styled(Link)`
  padding: 0.75rem 1.5rem;
  background: ${(props) => props.theme.colors.brandPurple};
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  margin-left: 2rem;
  text-decoration: none;
  transition: all 0.3s;
  font-weight: bold;

  @media (max-width: 1090px) {
    margin-left: 0;
  }

  &:hover {
    color: #fff;
    transition: all 0.3s;
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

// const msg = (
//   <span style={{ textAlign: "center", display: "block" }}>
//     Unlock 15 more free credits per
//     <br />
//     month by verifying your email address.
//   </span>
// );

const msg = (
  <span style={{ textAlign: "center", display: "block" }}>
    Verify your email address to unlock all available credits
  </span>
);

const noCreditsMsg = (
  <span style={{ textAlign: "center", display: "block" }}>
    You 've run out of credits
    <br />
    Upgrade to Pro to get more.
  </span>
);

const Navbar = ({
  avatar,
  requests,
  apiKey,
  verified,
  tier,
  custom,
  pathname,
  uid,
}) => {
  return (
    <Fixed>
      <NeuronsBanner margin={0} />
      <Nav>
        <NavGrid>
          <LogoContainer>
            <Logo to="/" isroot={pathname === "/" ? 1 : 0}>
              {!config.WHITE_LABEL.map((user) => user.id).includes(uid) ? (
                <SvgLogo width={40} height={40} fill="#3e21de" />
              ) : (
                <img
                  src={config.WHITE_LABEL.find((user) => user.id === uid).logo}
                  alt="logo"
                />
              )}
            </Logo>
          </LogoContainer>
          <div>
            <MenuLinks pathname={pathname} />
          </div>
          <div>
            <Box>
              <NavbarInfo
                hasWarning={!verified || (verified && requests === 0)}
                icon={<ThunderCredits stroke="#3E21DE" />}
                label="Credits"
                value={requests}
                hasCopy={false}
                tooltipMessage={
                  !verified || (tier === "basic" && custom)
                    ? msg
                    : verified && requests === 0
                    ? noCreditsMsg
                    : ""
                }
              />
              <Divider />
              <NavbarInfo
                icon={<BigKey fill="#3E21DE" />}
                label="API Key"
                value={apiKey}
                hasCopy={true}
                copyText={apiKey}
              />

              <AvatarActions avatar={avatar} />

              {/* {tier === "free" || tier === "basic" ? (
                <span onClick={() => fbTrack("InitiateCheckout")}>
                  <StyledLink
                    to={{
                      pathname: "/upgrade/choose-plan",
                      state: {
                        pathname,
                      },
                    }}
                  >
                    Upgrade
                  </StyledLink>
                </span>
              ) : null} */}
            </Box>
          </div>
        </NavGrid>
      </Nav>
    </Fixed>
  );
};

export default Navbar;
