import React from "react";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import { Attention, Clarity, OriginalImage, Aoi } from "zoozoo";

const ResultsDetails = styled.div`
  & > div:first-child {
    display: flex;
    ${(props) =>
      props.forPredict &&
      `
     width: 350px;
    
    `}

    & > div:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & > div:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

const EachDetail = styled.div`
  padding: 12px 16px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  background: ${(props) => (props.active ? props.bgColor : "#eeeeee")};
  ${(props) =>
    props.forPredict &&
    props.active &&
    `
    width: 200px;    
    transition: all 0.6s; 
    `}

    ${(props) =>
      props.forPredict &&
      !props.active &&
      `
    width:50px;   
    transition: all 0.6s;  
    `}

  ${(props) =>
    !props.active &&
    `
  &:hover {
    background: #2d226b42;
  }
`}

  h3 {
    font-weight: normal;
    font-size: 14px;
    color: #1d0f68;
    margin: 0 0 0 16px;
  ${(props) =>
    props.forPredict &&
    props.active &&
    `
      display: block;
    `}

  ${(props) =>
    props.forPredict &&
    !props.active &&
    `
      display: none;
    `}
  }

`;

const DEEP_BLUE = "#1D0F68";
const WHITE = "#FFF";

const TypeNavBar = ({
  handleChange,
  checkType,
  bgColor = DEEP_BLUE,
  forPredict = false,
}) => {
  return (
    <ResultsDetails forPredict={forPredict}>
      <div>
        <EachDetail
          onClick={() => handleChange("original")}
          active={checkType === "original"}
          bgColor={bgColor}
          forPredict={forPredict}
          data-for="original"
          data-tip
        >
          <OriginalImage
            width={20}
            height={20}
            stroke={checkType === "original" ? WHITE : DEEP_BLUE}
          />

          <h3 style={{ color: checkType === "original" ? WHITE : DEEP_BLUE }}>
            Original
          </h3>
        </EachDetail>
        <EachDetail
          onClick={() => handleChange("attention")}
          active={checkType === "attention"}
          bgColor={bgColor}
          forPredict={forPredict}
          data-for="attention"
          data-tip
        >
          <Attention
            width={20}
            height={20}
            fill={checkType === "attention" ? WHITE : DEEP_BLUE}
          />

          <h3 style={{ color: checkType === "attention" ? WHITE : DEEP_BLUE }}>
            Attention
          </h3>
        </EachDetail>
        <EachDetail
          onClick={() => handleChange("aoi")}
          active={checkType === "aoi"}
          bgColor={bgColor}
          forPredict={forPredict}
          data-for="aoi"
          data-tip
        >
          <Aoi
            stroke={checkType === "aoi" ? WHITE : DEEP_BLUE}
            width={20}
            height={20}
          />

          <h3 style={{ color: checkType === "aoi" ? WHITE : DEEP_BLUE }}>
            AOI
          </h3>
        </EachDetail>

        <EachDetail
          onClick={() => handleChange("clarity")}
          active={checkType === "clarity"}
          bgColor={bgColor}
          forPredict={forPredict}
          data-for="clarity"
          data-tip
        >
          <Clarity
            width={20}
            height={20}
            fill={checkType === "clarity" ? WHITE : DEEP_BLUE}
          />

          <h3 style={{ color: checkType === "clarity" ? WHITE : DEEP_BLUE }}>
            Clarity
          </h3>
        </EachDetail>
      </div>

      <ReactTooltip id="original" effect="solid" place={"bottom"}>
        <p style={{ margin: 0 }}>Original Image</p>
      </ReactTooltip>
      <ReactTooltip id="attention" effect="solid" place={"bottom"}>
        <p style={{ margin: 0 }}>Attention</p>
      </ReactTooltip>
      <ReactTooltip id="aoi" effect="solid" place={"bottom"}>
        <p style={{ margin: 0 }}>Area of Interest</p>
      </ReactTooltip>
      <ReactTooltip id="clarity" effect="solid" place={"bottom"}>
        <p style={{ margin: 0 }}>Clarity</p>
      </ReactTooltip>
    </ResultsDetails>
  );
};

export default TypeNavBar;
