import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ArrowLeft, TiltAttachment } from "zoozoo";

// assets
import BenchmarkIcon from "../../../../icons/BenchmarkIcon";

// ui
import PredictCard from "../../../../ui/cards/PredictCard";

// constants
import { DELETE_TYPES } from "../../../../../constants/deleteTypes";

// utils
import { isEmpty } from "../../../../../utils/helperFunctions";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 24px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectsSidebar = styled.div`
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
  background: #fff;
  padding: 0 0 50px 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    height: 100%;
    width: calc(50vh);
    left: -50vh;
  }

  @media (max-width: 991px) {
    height: auto;

    &::before {
      display: none;
    }
  }

  h1 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[12]};
    margin: 0 0 8px 0;
    width: 90%;
  }
`;

const PredictsGrid = styled.div`
  padding: 94px 40px 48px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 991px) {
    padding: 94px 40px 40px 40px;
  }
`;

const GoBack = styled.div`
  position: relative;
  margin: 72px 0 16px 0;
`;

const GoToPreviousPage = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]};
  cursor: pointer;

  svg {
    margin-right: 6px;
    transition: all 0.3s;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};

    svg {
      margin-right: 6px;
      transform: translateX(-2px);
      transition: all 0.3s;
    }
  }
`;

const SidebarHeader = styled.div`
  position: relative;
  margin-right: 24px;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 0 16px 0;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[20]};
    color: #212121;
    margin-bottom: 8px;
  }

  span {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
  }
`;

const SidebarContent = styled.div`
  padding-right: 24px;
  padding-top: 16px;

  p {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
  }
`;

const SidebarDescription = styled.div`
  margin-right: 24px;
  border-bottom: 1px solid #f5f5f5;
  padding: 16px 0;

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
    margin: 0;
    line-height: 1.4;
  }
`;

const CaseStudies = styled.div`
  margin-right: 24px;
  border-bottom: 1px solid #f5f5f5;
  padding: 16px 0;
  display: flex;

  svg {
    margin-right: 12px;
  }

  a {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #3e21de;
  }
`;

const ProjectSidebarActions = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    span {
      margin-left: 6px;
    }
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: ${(props) => props.theme.fontSize[14]};
  min-height: 40px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 0 2rem;
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  height: 40px;

  span {
    margin-left: 6px;
  }
`;

const SingleExampleProject = ({ project = null, pathOrigin, parentPath }) => {
  const { name, description, case_study_link, category } = project;

  return (
    <Grid>
      <ProjectsSidebar>
        <GoBack>
          <GoToPreviousPage to={parentPath}>
            <ArrowLeft width={20} height={20} stroke="#3E21DE" />
            Go Back
          </GoToPreviousPage>
        </GoBack>

        <SidebarHeader>
          <h2>{name}</h2>
          <span>{category}</span>
        </SidebarHeader>

        {description !== "" && (
          <SidebarDescription>
            <p>{description}</p>
          </SidebarDescription>
        )}

        {case_study_link !== "" && (
          <CaseStudies>
            <TiltAttachment stroke="#212121" />
            <a href={case_study_link} target="_blank" rel="noopener noreferrer">
              Go to case study
            </a>
          </CaseStudies>
        )}

        <SidebarContent>
          <ProjectSidebarActions>
            <StyledLink to={`/compare/project/${project.id}`}>
              <BenchmarkIcon stroke="#fff" width={20} height={20} />
              <span>View Compare Mode</span>
            </StyledLink>
          </ProjectSidebarActions>
        </SidebarContent>
      </ProjectsSidebar>

      {!isEmpty(project) && (
        <React.Fragment>
          {project.predictions.length > 0 && (
            <div>
              <PredictsGrid>
                {project.predictions.map((prediction, i) => {
                  return (
                    <PredictCard
                      key={i}
                      prediction={prediction}
                      pathOrigin={pathOrigin}
                      deleteType={DELETE_TYPES.PREDICT_IN_PROJECT}
                      isExample={true}
                    />
                  );
                })}
              </PredictsGrid>
            </div>
          )}
        </React.Fragment>
      )}
    </Grid>
  );
};

export default SingleExampleProject;
