import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import { Rnd } from "react-rnd";
import Modal from "react-awesome-modal";
import { useDispatch } from "react-redux";
import ReactTooltip from "react-tooltip";
import Dialog from "@material-ui/core/Dialog";
import { Button } from "desisystem";
import { Bin, AddAoi } from "zoozoo";

// redux
import { sendAoi } from "../../../redux/slices/prediction";

// assets
import dots from "../../../assets/dark-dots.svg";
// import dots from "../../../assets/back.svg";

const FullPage = styled.div`
  background: #fff;

  && {
    .MuiDialog-paperFullScreen {
      border-radius: 0 !important;
    }
  }
`;

const AoiLayout = styled.div`
  overflow: auto;

  .MuiSnackbar-root {
    top: 135px;
    height: 33px;
  }

  ${(props) =>
    props.active &&
    `
  #learnMore {
    visibility: visible !important;
    opacity: 1 !important;
    top: calc(100% - 139px) !important;
    left: calc(100% - 139px) !important;
  }
`}
`;

const TopBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  height: 130px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;

  div {
    h1 {
      margin: 0 0 8px 0;
      font-weight: bold;
      font-size: 26px;
      color: ${(props) => props.theme.colors.black};
      text-align: center;
    }

    h1 + p {
      margin: 0;
      font-weight: normal;
      font-size: 14px;
      color: ${(props) => props.theme.colors.subHeading};
      text-align: center;
      max-width: 380px;
    }
  }
`;

const DeleteIcon = styled.div`
  display: none;
  background: ${(props) => props.theme.colors.brandPurple};
  position: absolute;
  height: 32px;
  width: 110px;
  top: -34px;
  right: -2px;
  cursor: pointer;
  color: #fff;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize[12]};
`;

const Container = styled.div`
  min-height: calc(100vh - 130px);
  background-image: url(${(props) => props.url});
  background-attachment: fixed;
  background-size: cover;
  padding: 40px;

  & > div {
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;

    .react-draggable:hover {
      background: rgba(62, 33, 222, 0.17) !important;
      border: 2px solid #3e21de !important;

      ${DeleteIcon} {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    img {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }
`;

const AddAoiButton = styled.div`
  position: fixed;
  bottom: 40px;
  right: 48px
  z-index: 7;

  button {
    border-radius: 50px;
    border: 2px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    padding: 0 0.8rem;
    font-weight: bold;
    color: #fff;
    font-size: ${(props) => props.theme.fontSize[14]};
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    }
  }
`;

const ModalBody = styled.div`
  padding: 40px;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    text-align: center;
    margin: 0 0 16px 0;
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;

  button {
    padding: 0;
    max-width: 140px;
    min-width: 140px;
    width: 100%;
    margin: 0 8px;
  }
`;

const newBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "2px solid #3E21DE",
  background: "rgba(62, 33, 222, 0.17)",
};

const EditAoiModal = ({
  open,
  closeEditAoiModal,
  originalImage,
  id,
  areas,
}) => {
  const [innerModal, setInnerModal] = useState(false);
  const [addAoiLength, setAddAoiLength] = useState([]);
  const [aoiDimensions, setAoiDimensions] = useState(areas);
  const [imgDimensions, setImgDimensions] = useState(null);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [activeHover, setActiveHover] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (addAoiLength.length > 0) {
      setAoiDimensions((aoiDimensions) => [
        ...aoiDimensions,
        {
          x: 0.4,
          y: 0.05 + scrollHeight / imgDimensions.canvasHeight,
          width: 0.2,
          height: 0.05,
          uid: uuidv4(),
        },
      ]);
    }
  }, [addAoiLength]);

  let canvasHeight;
  let canvasWidth;

  const calcDimensions = () => {
    const ori = document.getElementById("original-edit");
    canvasHeight = ori.offsetHeight;
    canvasWidth = ori.offsetWidth;

    let bothDimensions = {
      canvasHeight,
      canvasWidth,
    };

    setImgDimensions(bothDimensions);
  };

  const openInnerModal = () => {
    setInnerModal(true);
  };

  const closeInnerModal = () => {
    setInnerModal(false);
  };

  const closeAllModals = () => {
    setInnerModal(false);
    closeEditAoiModal();
    setActiveHover(true);
  };

  const submitCoors = () => {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("areas", JSON.stringify(aoiDimensions));
    dispatch(sendAoi(formData, id));
    closeEditAoiModal();
  };

  const deleteAoi = (id) => {
    let newAoiState = aoiDimensions.filter((r) => r.uid !== id);
    setAoiDimensions(newAoiState);
  };

  const calcHeightOffset = () => {
    let element = document.getElementById("page-offset");
    element.style.height = imgDimensions.canvasHeight;

    setScrollHeight(element.scrollTop);
  };

  const initialHoverState = () => {
    setActiveHover(false);
  };

  return (
    <FullPage>
      <Dialog fullScreen open={open}>
        <AoiLayout
          id="page-offset"
          onScroll={() => calcHeightOffset()}
          active={activeHover}
        >
          <TopBar>
            <Button appearance="secondary" onClick={() => openInnerModal()}>
              Exit edit mode
            </Button>
            <div>
              <h1>Draw Areas of Interest (AOI)</h1>
              <p>
                Mark up areas of your design frame to quantify their visibility.
                Then save your changes and view the results.
              </p>
            </div>

            <Button onClick={() => submitCoors()}>Save changes</Button>
          </TopBar>
          <Container id="fullPage" url={dots}>
            <div>
              <img
                onLoad={() => calcDimensions()}
                src={originalImage}
                alt="original"
                id="original-edit"
              />
              {aoiDimensions.map((b, i) => (
                <React.Fragment key={i}>
                  {imgDimensions !== null && (
                    <Rnd
                      style={newBoxStyle}
                      bounds="parent"
                      size={{
                        width:
                          aoiDimensions[i].width * imgDimensions.canvasWidth,
                        height:
                          aoiDimensions[i].height * imgDimensions.canvasHeight,
                      }}
                      position={{
                        x: aoiDimensions[i].x * imgDimensions.canvasWidth,
                        y: aoiDimensions[i].y * imgDimensions.canvasHeight,
                      }}
                      onDragStop={(e, d) => {
                        const dim = [...aoiDimensions];
                        dim[i] = {
                          ...dim[i],
                          x: d.x / imgDimensions.canvasWidth,
                          y: d.y / imgDimensions.canvasHeight,
                        };
                        setAoiDimensions(dim);
                      }}
                      onResizeStop={(e, direction, ref, delta, position) => {
                        const dim = [...aoiDimensions];
                        dim[i] = {
                          ...dim[i],
                          width: ref.offsetWidth / imgDimensions.canvasWidth,
                          height: ref.offsetHeight / imgDimensions.canvasHeight,
                          x: position.x / imgDimensions.canvasWidth,
                          y: position.y / imgDimensions.canvasHeight,
                        };
                        setAoiDimensions(dim);
                      }}
                    >
                      <DeleteIcon
                        onClick={() => deleteAoi(aoiDimensions[i].uid)}
                      >
                        <Bin stroke="#fff" width={18} height={18} />
                        Delete AOI
                      </DeleteIcon>
                    </Rnd>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Container>

          <Modal
            visible={innerModal}
            effect="fadeInUp"
            onClickAway={() => closeInnerModal()}
          >
            <ModalBody>
              <h2> Are you sure you want to exit?</h2>
              <p>
                All changes will not be saved and AOI results will not be
                updated.
              </p>
              <ModalActions>
                <Button
                  appearance="secondary"
                  onClick={() => closeInnerModal()}
                >
                  I want to stay
                </Button>

                <Button onClick={() => closeAllModals()}>Yes, exit</Button>
              </ModalActions>
            </ModalBody>
          </Modal>

          <ReactTooltip
            id="learnMore"
            className="eve"
            effect="solid"
            place={"top"}
          >
            <span>Add new AOI</span>
          </ReactTooltip>

          <AddAoiButton>
            <a
              data-tip
              data-for="learnMore"
              href="#0"
              onMouseEnter={() => initialHoverState()}
            >
              <Button onClick={() => setAddAoiLength([...addAoiLength, 1])}>
                <AddAoi stroke="#fff" width={30} height={30} />
              </Button>
            </a>
          </AddAoiButton>
        </AoiLayout>
      </Dialog>
    </FullPage>
  );
};

export default EditAoiModal;
