import React, { useState } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import { Body, Button, Input, H2, Info } from "desisystem";
import { useDispatch } from "react-redux";
import Dialog from "./Dialog";
import Tooltip from "@material-ui/core/Tooltip";
// actions
import { updateUser } from "../../../../redux/slices/user";

const ProfileCon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  input[type="email"] {
    color: #e2e0ec;
  }

  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const UploadAvatar = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;

  p {
    font-size: 10px;
    letter-spacing: 0.2rem;
    color: #616161;
    margin-bottom: 2px;
  }
`;

const Upload = styled.div`
  display: flex;
  align-items: center;
  img {
    border: 4px solid rgb(241, 239, 253);
    width: 52px;
    height: 52px;
    border-radius: 50px;
    object-fit: cover;
  }

  & div:last-child {
    margin-left: 20px;
  }
  span {
    color: ${(props) => props.theme.colors.brandPurple};
    margin-left: 10px;
    font-size: 1rem;
  }
`;

const TwoInRow = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;

  input[value] {
    color: #151515;
  }
  input[value]:focus {
    color: #151515;
  }

  .is-valid {
    input {
      border-color: ${(props) => props.theme.colors.red};
      outline-color: ${(props) => props.theme.colors.red};
      &:focus,
      &:active {
        outline-color: ${(props) => props.theme.colors.red};
        border-color: ${(props) => props.theme.colors.red};
      }
    }
  }
`;

const StyledBtn = styled(Button)`
  margin-top: 80px;
`;

const ProfileSettings = ({ user }) => {
  const dispatch = useDispatch();
  const { id, first_name, last_name, email, avatar } = user;

  const [firstName, setFirstName] = useState(first_name);
  const [lastName, setLastName] = useState(last_name);

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit",
  });

  const changeProfileSettings = () => {
    const userData = new FormData();
    userData.set("first_name", firstName);
    userData.set("last_name", lastName);

    dispatch(updateUser(userData));
  };

  const isDisabled = firstName === first_name && lastName === last_name;

  return (
    <ProfileCon>
      <div>
        <H2>Profile Settings</H2>
        <UploadAvatar>
          <Body type="caption">AVATAR</Body>
          <Upload>
            <div>
              <img src={avatar} alt="avatar" />
            </div>
            <Dialog />
          </Upload>
        </UploadAvatar>
      </div>
      <form onSubmit={handleSubmit(changeProfileSettings)}>
        <div>
          <TwoInRow>
            <div className={errors.change_first ? "is-valid" : ""}>
              <Input
                label="FIRST NAME"
                type="text"
                size="10px"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                name="change_first"
                validate={register({
                  required: true,
                })}
              />
              {errors.change_first && (
                <span className="error-msg">First name is required</span>
              )}
            </div>
            <div className={errors.change_last ? "is-valid" : ""}>
              <Input
                label="LAST NAME"
                type="text"
                size="10px"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                name="change_last"
                validate={register({
                  required: true,
                })}
              />
              {errors.change_last && (
                <span className="error-msg">Last name is required</span>
              )}
            </div>
          </TwoInRow>

          <Input
            label="E-MAIL"
            type="email"
            size="10px"
            value={email}
            disabled={true}
            icon={
              <Tooltip
                disableFocusListener
                disableTouchListener
                title="Contact us to change your email"
                placement="top"
              >
                <span>
                  <Info width={16} height={16} fill={"#1D0F68" + 55} />
                </span>
              </Tooltip>
            }
          />
        </div>
        <StyledBtn
          type="submit"
          disabled={isDisabled}
          appearance="primary"
          width="100"
        >
          Save changes
        </StyledBtn>
      </form>
    </ProfileCon>
  );
};

export default ProfileSettings;
