import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { CardMenu } from "desisystem";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";

// redux
import {
  openDeleteModal,
  globalActionsSelector,
} from "../../../../redux/slices/globalActions";
import { removeFromProject } from "../../../../redux/slices/prediction";
import { subscriptionSelector } from "../../../../redux/slices/subs";

const ITEM_HEIGHT = 48;

const OptionButton = styled.span`
  padding: 14px 16px;
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
`;

const MenuOption = styled(MenuItem)`
  && {
    color: ${(props) =>
      props.disabled ? "#d0d0d0" : props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSize[12]};
    padding: 0;

    &:hover {
      background: #f5f5f5;
    }
  }
`;

const PredictionOptions = ({
  predictName,
  predictId,
  projectId,
  pathOrigin,
  deleteType,
  projectName,
  paginationIds,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const prevPath = useLocation();
  const { deleteModalOpen } = useSelector(globalActionsSelector);
  const {
    subs: { tier },
  } = useSelector(subscriptionSelector);

  let onlyPro = tier === "basic" || tier === "free";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (deleteModalOpen) {
      setAnchorEl(null);
    }
  }, [deleteModalOpen]);

  const handleClickOpen = (data) => {
    dispatch(openDeleteModal(data, deleteType, pathOrigin));
  };

  const movePredict = () => {
    props.history.push("/predict/move", {
      hasPredictId: predictId,
      predictName,
      pathname: prevPath,
      hasProjectId: projectId,
    });
  };

  const handelRemove = () => {
    const removeInfo = new FormData();
    removeInfo.append("prediction", predictId);
    removeInfo.append("project_id", projectId);

    dispatch(removeFromProject(removeInfo, projectId));
  };

  const sharePrediction = () => {
    props.history.push(`/predictions/${predictId}`, {
      directShare: true,
      paginationIds,
    });
  };

  return (
    <div>
      <CardMenu
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        fill="#C9C9C9"
      >
        <MoreVertIcon />
      </CardMenu>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 140,
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
          },
        }}
      >
        <MenuOption>
          <OptionButton onClick={() => movePredict()}>
            Move to Project
          </OptionButton>
        </MenuOption>
        {projectName !== null && (
          <MenuOption onClick={() => handelRemove()}>
            <OptionButton>Remove from Project</OptionButton>
          </MenuOption>
        )}
        <MenuOption disabled={onlyPro}>
          <OptionButton onClick={() => sharePrediction()}>Share</OptionButton>
        </MenuOption>
        <MenuOption onClick={() => handleClickOpen(predictId)}>
          <OptionButton style={{ color: "#D53C3C" }}>Delete</OptionButton>
        </MenuOption>
      </Menu>
    </div>
  );
};

export default withRouter(PredictionOptions);
