import React from "react";
import styled from "styled-components";
import Global from "../layouts/Global";

const StyledLayout = styled.div`
  padding: 40px 0 0 0;
  background: ${props => props.theme.colors.globalBg};
  min-height: 100vh;
  position: relative;
  overflow: hidden;
`;

const Layout = props => {
  return (
    <div>
      <Global />
      <StyledLayout>{props.children}</StyledLayout>
    </div>
  );
};

export default Layout;
