import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CardMenu } from "desisystem";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import FullScreenModal from "../../../modals/FullScreenModal";
import { DELETE_FULLSCREEN_CONTENT } from "../../../../constants/GenericContent";
import { useSelector, useDispatch } from "react-redux";
import {
  openDeleteModal,
  globalActionsSelector,
} from "../../../../redux/slices/globalActions";
import { removeFromProject } from "../../../../redux/slices/prediction";

const { PREDICT_CONTENT } = DELETE_FULLSCREEN_CONTENT;

const ITEM_HEIGHT = 48;

const OptionButton = styled.span`
  padding: 14px 16px;
  width: 100%;
`;

const MenuOption = styled(MenuItem)`
  && {
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSize[12]};
    padding: 0;

    &:hover {
      background: #f5f5f5;
    }
  }
`;

const LongMenu = ({
  predictId,
  predictName,
  projectName,
  projectId,
  history,
  pathOrigin = "/",
  deleteType,
  ...props
}) => {
  const { deleteModalOpen } = useSelector(globalActionsSelector);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (deleteModalOpen) {
      setAnchorEl(null);
    }
  }, [deleteModalOpen]);

  const handleClickOpen = (id) => {
    dispatch(openDeleteModal(id, deleteType, pathOrigin));
  };

  const handelRemove = () => {
    const removeInfo = new FormData();
    removeInfo.append("prediction", predictId);
    removeInfo.append("project_id", projectId);

    dispatch(removeFromProject(removeInfo, projectId));
  };

  const movePredict = () => {
    history.push("/predict/move", {
      hasPredictId: predictId,
      predictName,
      pathname: props.location.pathname,
      hasProjectId: projectId,
    });
  };

  return (
    <div>
      <CardMenu
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        fill="#C9C9C9"
      >
        <MoreVertIcon />
      </CardMenu>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 170,
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
          },
        }}
      >
        <MenuOption>
          <OptionButton onClick={movePredict}>Move to Project</OptionButton>
        </MenuOption>
        {projectName !== null && (
          <MenuOption onClick={() => handelRemove()}>
            <OptionButton>Remove from Project</OptionButton>
          </MenuOption>
        )}

        <MenuOption onClick={() => handleClickOpen(predictId)}>
          <OptionButton style={{ color: "#D53C3C" }}>Delete</OptionButton>
        </MenuOption>
      </Menu>
      <FullScreenModal isOpen={deleteModalOpen} content={PREDICT_CONTENT} />
    </div>
  );
};

export default withRouter(LongMenu);
