import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import Global from "../../../../layouts/Global";
import { Link } from "react-router-dom";
import { Input, Close, Button } from "desisystem";
import { Search } from "zoozoo";
import { moveΤοProject } from "../../../../../redux/slices/prediction";

import ProjectLoading from "../../../../loadings/ProjectLoading";
import SearchResults from "./SearchResults";
import {
  MOVE_PREDICTION_STATE,
  MOVE_BUTTON_STATE,
} from "../../../../../constants/renderStates";

const CreationLayout = styled.div`
  background: #f9f9f9;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 140px;
  justify-content: center;

  & > div {
    max-width: 600px;
  }

  h2 {
    text-align: center;
    font-weight: bold;
    font-size: 26px;
  }

  input {
    border: 1px solid #c3baf5;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 600px;
    height: 40px;
    padding-left: 56px;
  }

  input:focus {
    border: 1px solid #3e21de;
  }
`;

const CloseLink = styled(Link)`
  & > svg {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 40px;
  }
`;

const ProjectsSearchGrid = styled.div`
  max-width: 360px;
  margin: 0 auto;
  margin-top: 40px;

  & > div {
    margin-bottom: 16px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #3e21de;
  margin-top: 16px;
  display: inline-block;
`;

const LinkBox = styled.div`
  margin: 40px auto 0 auto;
  text-align: center;

  button {
    padding: 0;
    height: 40px;
    width: 200px;
    transition: all 0.3s;
  }
`;

const MovePrediction = ({
  searchResults,
  onChange,
  predictName,
  hasPredictId,
  pathname,
  movePredictionsState,
  limitedProjects,
  freeLimitedProjects,
  projects,
}) => {
  const [projectId, setProjectId] = useState("");
  const [predictId, setPredictId] = useState([]);
  const [buttonState, setButtonState] = useState("");
  const dispatch = useDispatch();

  const prepareToAdd = (id) => {
    setPredictId([hasPredictId]);
    setProjectId(id);
  };

  const addToProject = () => {
    const movePredictInfo = new FormData();
    movePredictInfo.append("predictions", JSON.stringify(predictId));
    movePredictInfo.append("project_id", projectId);

    dispatch(moveΤοProject(movePredictInfo, projectId));
  };

  function renderMovePredictionContent(state) {
    switch (state) {
      case MOVE_PREDICTION_STATE.READY:
        return (
          <SearchResults
            searchResults={searchResults}
            prepareToAdd={prepareToAdd}
            projectId={projectId}
          />
        );
      case MOVE_PREDICTION_STATE.LOADING:
        return (
          <React.Fragment>
            <ProjectLoading />
            <ProjectLoading />
            <ProjectLoading />
          </React.Fragment>
        );

      default:
        return;
    }
  }

  const findButtonState = (
    projectSearchResults,
    idOfProject,
    limited,
    freeLimited
  ) => {
    if (idOfProject !== "") {
      return MOVE_BUTTON_STATE.SELECTED;
    } else if (idOfProject === "" && projectSearchResults.length === 0) {
      return MOVE_BUTTON_STATE.EMPTY;
    } else if (idOfProject.length === 0) {
      return MOVE_BUTTON_STATE.EMPTY_SEARCH;
    } else if (limited) {
      return MOVE_BUTTON_STATE.LIMITED_PROJECTS;
    } else if (freeLimited) {
      return MOVE_BUTTON_STATE.FREE_LIMITED_PROJECTS;
    }
  };

  useEffect(() => {
    setButtonState(
      findButtonState(
        searchResults,
        projectId,
        limitedProjects,
        freeLimitedProjects
      )
    );
  }, [searchResults, projectId, limitedProjects, freeLimitedProjects]);

  function renderSubmitContent(state) {
    switch (state) {
      case MOVE_BUTTON_STATE.SELECTED:
        return <Button onClick={addToProject}>Add to Project</Button>;

      case MOVE_BUTTON_STATE.EMPTY:
        return (
          <React.Fragment>
            {projects.length > 0 && (
              <Button disabled={true}>Add to Project</Button>
            )}
            {!limitedProjects && (
              <StyledLink
                to={{
                  pathname: "/create/projects",
                  state: {
                    hasPredictId,
                    pathname: "/projects",
                  },
                }}
              >
                Add Prediction to a new project
              </StyledLink>
            )}
          </React.Fragment>
        );
      case MOVE_BUTTON_STATE.EMPTY_SEARCH:
        return <Button disabled={true}>Add to Project</Button>;
      case MOVE_BUTTON_STATE.LIMITED_PROJECTS:
        return;
      case MOVE_BUTTON_STATE.FREE_LIMITED_PROJECTS:
        return;
      default:
        return;
    }
  }

  return (
    <React.Fragment>
      <Global />
      <CloseLink to={pathname}>
        <Close width={30} height={30} />
      </CloseLink>
      <CreationLayout>
        <div>
          <h2>Add {predictName} to project</h2>
          <form onSubmit={(e) => e.preventDefault()}>
            <Input
              placeholder="Search projects"
              onChange={onChange}
              leftIcon={<Search width={24} height={24} />}
            />
          </form>
          <ProjectsSearchGrid>
            {renderMovePredictionContent(movePredictionsState)}
          </ProjectsSearchGrid>
          <LinkBox>{renderSubmitContent(buttonState)}</LinkBox>
        </div>
      </CreationLayout>
    </React.Fragment>
  );
};

export default MovePrediction;
