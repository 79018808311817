import React from "react";
import PropTypes from "prop-types";

const BenchmarkIcon = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12 4.00098V6.43998"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21 13H19"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 13H5"
      stroke={props.currentColor}
      stroke-idth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3603 6.64001L13.4043 11.596"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.9814 11C13.0864 11 13.9814 11.895 13.9814 13C13.9814 14.105 13.0864 15 11.9814 15C10.8764 15 9.98145 14.105 9.98145 13C9.98145 11.895 10.8764 11 11.9814 11Z"
      stroke={props.currentColor}
      strokeWidth="1.5"
      stroke-inecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.63965 6.64001L7.75965 8.76002"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2402 17.24L18.3603 19.36L18.3643 19.364C21.8793 15.849 21.8793 10.151 18.3643 6.63601C14.8493 3.12101 9.15125 3.12101 5.63625 6.63601C2.12125 10.151 2.12125 15.849 5.63625 19.364L5.64025 19.36L7.64025 17.36"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

BenchmarkIcon.propTypes = {
  currentColor: PropTypes.string,
};

BenchmarkIcon.defaultProps = {
  stroke: "#323232",
};

export default BenchmarkIcon;
