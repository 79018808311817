import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

import { sendImagePathBack } from "../../../../redux/slices/prediction";
import { errorToast } from "../../../../utils/helperFunctions";
import mergeImages from "../../../../utils/mergeImages";

// utils
import { downloadImageFromUrl } from "../../../../utils/helperFunctions";
import { PREDICTION_TITLES } from "../../../../constants/GenericContent";
import {
  attentionOptions,
  clarityOptions,
  aoiOptions,
} from "../../../../constants/PredictionContent";

import DoubleButton from "../../../ui/elements/prediction/DoubleButton";
import CoreActions from "../../../ui/elements/prediction/CoreActions";
import Pagination from "../../../ui/navigation/Pagination";
import PredictionHeading from "../../../ui/elements/prediction/PredictionHeading";
import DateInfo from "../../../ui/elements/prediction/DateInfo";
import TypeNavBar from "../../../ui/navigation/reportNavigation/TypeNavBar";
import { gaDownloadImage } from "../../../../utils/analytics";
import { updatePredictionName } from "../../../../redux/slices/prediction";

const Nav = styled.nav`
  background: #fff;
  position: sticky;
  top: 0;
  padding: 0 40px;
  z-index: 10;
`;

const IntraNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #e0e0e0;

  & > div:first-child {
    display: flex;
    align-items: center;
  }

  & > div:last-child {
    display: flex;
    align-items: center;
  }
`;

const TypeOfBar = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  position: relative;

  & > div:nth-child(2) {
    margin-left: 40px;
  }

  & > div:last-child {
    margin-left: auto;
  }
`;

const SinglePredictionNav = ({
  handleChange,
  checkType,
  prediction,
  getCreationDate,
  oldDate,
  pagination,
  paginationIds,
  openSurveyModal,
  downloadAoi,
  tier,
  type,
  pathname,
  parentPath,
  setOpenSharing,
  downloadModalCount,
}) => {
  const {
    id,
    name,
    project_name,
    project_id,
    model,
    platform,
    attention_serve_path,
    clarity_serve_path,
    original_serve_path,
  } = prediction;

  const { ORIGINAL, ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;
  const [loadingDownload, setLoadingDownload] = useState(false);
  const dispatch = useDispatch();

  const renderDropdownContent = (type) => {
    switch (type) {
      case ATTENTION:
        return attentionOptions;
      case CLARITY:
        return clarityOptions;
      case AOI:
        return aoiOptions;
      default:
        return;
    }
  };

  let dropdownContent;
  dropdownContent = renderDropdownContent(type);

  const isActive = () => {
    switch (type) {
      case ATTENTION:
        return attention_serve_path;
      case CLARITY:
        return clarity_serve_path;
      default:
        return original_serve_path;
    }
  };
  let downloadUrl = isActive(type);

  const downloadImage = () => {
    const overlayUrl =
      type !== AOI
        ? type === ATTENTION
          ? attention_serve_path
          : clarity_serve_path
        : downloadAoi;
    setLoadingDownload(true);

    if (downloadModalCount <= 1) {
      openSurveyModal();
    }

    mergeImages(original_serve_path, overlayUrl)
      .then((base64) => {
        downloadImageFromUrl(downloadUrl, name, type, base64);
        setLoadingDownload(false);
        gaDownloadImage();

        const downloadData = new FormData();
        downloadData.append("url", downloadUrl);
        dispatch(sendImagePathBack(downloadData));
      })
      .catch((err) => {
        errorToast(
          "Something went wrong when downloading. Please try again later."
        );
        setLoadingDownload(false);
      });
  };

  const updateInfo = (newName, id) => {
    const predictionName = new FormData();
    predictionName.append("id", id);
    predictionName.append("name", newName);

    dispatch(updatePredictionName(predictionName, id, newName));
  };

  return (
    <Nav>
      <IntraNav>
        <div>
          <PredictionHeading
            name={name}
            id={id}
            projectId={project_id}
            projectName={project_name}
            updateInfo={updateInfo}
          />
          <CoreActions
            tier={tier}
            pathname={pathname}
            projectName={project_name}
            id={id}
            setOpenSharing={setOpenSharing}
          />
        </div>

        <div>
          <DateInfo
            getCreationDate={getCreationDate}
            oldDate={oldDate}
            projectName={project_name}
            model={model}
            platform={platform}
          />
          <Pagination
            projectId={project_id}
            pagination={pagination}
            paginationIds={paginationIds}
            parentPath={parentPath}
          />
        </div>
      </IntraNav>
      <TypeOfBar>
        <TypeNavBar
          handleChange={handleChange}
          checkType={checkType}
          bgColor="#3E21DE"
          forPredict="true"
        />

        <div>
          {type !== ORIGINAL && (
            <DoubleButton
              tier={tier}
              dropdownContent={dropdownContent}
              type={type}
              loadingDownload={loadingDownload}
              downloadImage={downloadImage}
            />
          )}
        </div>
      </TypeOfBar>
    </Nav>
  );
};

export default SinglePredictionNav;
