import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import useForm from "react-hook-form";
import { Link } from "react-router-dom";
import GoogleLogin from "react-google-login";

// ds
import { Input, Checkbox } from "desisystem";

// actions
import { handleError } from "../../../redux/ducks/errors";
import { checkEmail, socialAuth } from "../../../redux/ducks/auth";

// layout
import SocialProofLayout from "../../layouts/SocialProofLayout";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";
import { GoogleButton } from "../../layouts/Global";
import InlineErrorMessage from "../../ui/globals/InlineErrorMessage";

import { config } from "../../../constants/endpoints";

//  Google auth key
let googleAuthKey = config.GOOGLE_AUTH;

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.subHeading};
  }
`;

const CheckboxBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  label > span:last-child {
    font-size: 12px;
  }
  a {
    color: #3e21de;
    text-decoration: none;
    padding: 0 4px;
  }
`;

const StyledGoogleLogin = styled(GoogleLogin)`
  ${GoogleButton}

  background-color: ${(props) => props.theme.colors.globalBg} !important;

  & > div {
    background: transparent !important;
  }
`;

const HorizontalLine = styled.div`
  position: relative;
  padding: 24px;
  margin: 0 1.5rem;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    z-index: 1;
  }

  p {
    color: #c9c9c9;
    font-size: 12px;
    background: ${(props) => props.theme.colors.globalBg};
    margin: 16px auto;
    display: inline-block;
    padding: 0 24px;
    z-index: 2;
    font-weight: bold;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const FormBody = styled.div`
  margin-top: 40px;
`;

const NotMember = styled.div`
  margin-top: 16px;
  font-size: ${(props) => props.theme.fontSize[12]};
  color: ${(props) => props.theme.colors.black};

  a {
    color: ${(props) => props.theme.colors.brandPurple};
    text-decoration: none;
  }
`;

const AddEmail = ({ location: { state = null } }) => {
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(true);
  const [futurePlan, setFuturePlan] = useState("");
  const [futureDuration, setFutureDuration] = useState("");
  const [pluginNewUser, setPluginNewUser] = useState("");

  // console.log(state);
  // console.log(fromPlugin);
  useEffect(() => {
    window.location.href = "https://go.neuronsinc.com/visualeyes-predict";
  }, []);

  useEffect(() => {
    if (state !== null) {
      if (state.fromSiteData) {
        const { plan, duration } = state.fromSiteData;
        setFuturePlan(plan);
        setFutureDuration(duration);
      }
    }
  }, [futurePlan, futureDuration, state]);

  useEffect(() => {
    if (state !== null) {
      if (state.fromPlugin) {
        setPluginNewUser(state.fromPlugin);
      }
    }
  }, [pluginNewUser, state]);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const dispatch = useDispatch();
  const { errorMsg } = useSelector((state) => state.errors);
  const { loading } = useSelector((state) => state.loadings);

  useEffect(() => {
    dispatch(handleError(null));
  }, [dispatch]);

  const handleCreateUser = (e) => {
    const directData = {
      plan: futurePlan,
      duration: futureDuration,
    };
    const userData = new FormData();
    userData.append("email", email);

    dispatch(checkEmail(userData, email, directData, pluginNewUser));
  };

  const responseGoogle = (response) => {
    if (!response.error) {
      const directData = {
        plan: futurePlan,
        duration: futureDuration,
      };
      const { accessToken } = response;
      const userData = new FormData();
      userData.append("access_token", accessToken);

      dispatch(socialAuth(userData, directData));
    }
  };

  const isDisabled = email === "" || checked === false;

  const handleChange = (inputEmail) => {
    setEmail(inputEmail);
    if (errorMsg) {
      dispatch(handleError(null));
    }
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Create your account now</h1>
          <p>Get started in 3 simple steps.</p>
        </Heading>

        <FormBody>
          <StyledGoogleLogin
            clientId={googleAuthKey}
            buttonText="Sign up with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
          <HorizontalLine>
            <p>OR USE YOUR EMAIL</p>
          </HorizontalLine>
          <form onSubmit={handleSubmit(handleCreateUser)}>
            <div className={errors.createEmail || errorMsg ? "is-valid" : ""}>
              <Input
                label="E-MAIL"
                type="email"
                size="10px"
                onChange={(e) => handleChange(e.target.value)}
                name="createEmail"
                validate={register({
                  // pattern: /^([a-z\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
                  required: true,
                })}
              />
              {errors.createEmail && (
                <span className="error-msg">Field must be a valid email</span>
              )}
            </div>

            <CheckboxBox>
              <Checkbox
                checked={checked}
                msg={
                  <span>
                    I have read and accepted the
                    <a
                      href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Terms-of-Services-Provision.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms
                    </a>
                    and
                    <a
                      href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Data-Protection-Policy.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Data
                    </a>
                    Policy.
                  </span>
                }
                handleCheckboxChange={handleCheckboxChange}
              />
            </CheckboxBox>

            {errorMsg && (
              <InlineErrorMessage errorMsg="Email already exists." />
            )}

            <ButtonWithLoading
              loading={loading}
              title="Continue"
              type="submit"
              width="100"
              disabled={isDisabled}
            />
          </form>
          <NotMember>
            Already have an account?{" "}
            <Link
              to={{
                pathname: "/auth/login",
                state: {
                  fromSiteData: state !== null ? state.fromSiteData : null,
                },
              }}
            >
              Log in.
            </Link>
          </NotMember>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default AddEmail;
