import React from "react";
import PropTypes from "prop-types";

const SvgLogo = props => (
  <svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
    <path
      d="M38.82 19.16L20.83 1.17C20.1 0.45 19.1 0 18 0H4C1.79 0 0 1.79 0 4V18C0 19.11 0.45 20.11 1.18 20.83L19.18 38.83C19.9 39.55 20.9 40 22 40C23.1 40 24.11 39.55 24.83 38.83L38.83 24.83C39.55 24.1 40 23.1 40 22C40 20.89 39.55 19.89 38.82 19.16ZM7 10C5.34 10 4 8.66 4 7C4 5.34 5.34 4 7 4C8.66 4 10 5.34 10 7C10 8.66 8.66 10 7 10Z"
      fill={props.currentColor}
    />
  </svg>
);

SvgLogo.propTypes = {
  currentColor: PropTypes.string
};

SvgLogo.defaultProps = {
  fill: "#5940e3"
};

export default SvgLogo;
