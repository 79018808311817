import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";

// base url
let url = config.URL;

export const initialState = {
  loading: true,
  hasErrors: false,
  projectReport: {},
  predictionReport: {},
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    loadReport: (state) => {
      state.loading = true;
    },

    projectReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.projectReport = payload;
    },

    predictionsReportSuccess: (state, { payload }) => {
      state.loading = false;
      state.hasErrors = false;
      state.predictionReport = payload;
    },

    reportFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  loadReport,
  projectReportSuccess,
  predictionsReportSuccess,
  reportFailure,
} = reportsSlice.actions;
export const reportsSelector = (state) => state.reports;
export default reportsSlice.reducer;

export const getProjectReport = (id) => async (dispatch) => {
  // dispatch(loadReport());

  let apiUrl = `${url}/projects/${id}/report`;

  try {
    const res = await axios.get(apiUrl, config);

    dispatch(projectReportSuccess(res.data));
  } catch (error) {
    if (error) {
      if (error.response.status === 400) {
        dispatch(reportFailure());
        dispatch(push("/unavailable/reporting"));
      } else {
        dispatch(reportFailure());
      }
    }
  }
};

export const getPredictionReport = (id) => async (dispatch) => {
  // dispatch(loadReport());

  let apiUrl = `${url}/predictions/${id}/report`;

  try {
    const res = await axios.get(apiUrl, config);

    dispatch(predictionsReportSuccess(res.data));
  } catch (error) {
    if (error) {
      if (error.response.status === 400) {
        dispatch(reportFailure());
        dispatch(push("/unavailable/reporting"));
      } else {
        dispatch(reportFailure());
      }
    }
  }
};
