import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ArrowBack } from "desisystem";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 24px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectsSidebar = styled.div`
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
  background: #fff;
  padding: 0 0 50px 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    height: 100%;
    width: calc(50vh);
    left: -50vh;
  }

  @media (max-width: 991px) {
    height: auto;

    &::before {
      display: none;
    }
  }

  h1 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[34]};
    margin: 0 0 8px 0;
  }

  span {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #9e9e9e;
  }
`;

const ProjectsGrid = styled.div`
  padding: 94px 40px 40px 0;

  @media (max-width: 991px) {
    padding: 94px 40px 40px 40px;
  }
`;

const GoBack = styled.div`
  position: relative;
  margin: 72px 0 16px 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]}
  display: flex;
  align-items: center;

  > svg {
    margin-right: 6px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const SidebarContent = styled.div`
  padding-right: 24px;

  p {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
    line-height: 1.4;
  }

  a {
    font-weight: normal;
    font-size: 14px;
    color: #3e21de;
  }
`;

const ExampleProjects = ({
  pathname,
  limitedModal,
  projectIdForGlobal,
  deleteModalOpen,
  proration,
  proPrice,
  tier,
  custom,
  children,
  ...props
}) => {
  return (
    <React.Fragment>
      <Grid>
        <ProjectsSidebar>
          <GoBack>
            <StyledLink to="/">
              <ArrowBack width={20} height={20} fill="#3E21DE" />
              Go Back
            </StyledLink>
          </GoBack>

          <h1>Guides</h1>

          <SidebarContent>
            <p>
              A list with our curated guides. Discover how to use VisualEyes’
              insights, producing great design solutions.
            </p>

            <a
              href="https://www.visualeyes.design/tools/use-cases/?ref=visualeyes-platform"
              target="_blank"
              rel="noopener noreferrer"
            >
              Explore our case studies
            </a>
          </SidebarContent>
        </ProjectsSidebar>

        <React.Fragment>
          {children.length > 0 && <ProjectsGrid>{children}</ProjectsGrid>}
        </React.Fragment>
      </Grid>
    </React.Fragment>
  );
};

export default ExampleProjects;
