import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";
import { refreshToken, authorizedToken, getRefreshToken } from "./auth";
import { closeDeleteModal } from "./globalActions";
import { hasSeenModal, hasSeenFreeModal } from "../ducks/storage";
import { successToast, errorToast } from "../../utils/helperFunctions";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../constants/ToastMessages";

// base url
let url = config.URL;

export const initialState = {
  loading: false,
  activeRoute: false,
  projects: [],
  project: {},
  latestProjects: [],
  exampleProjects: [],
  limitedModal: false,
  comparisons: [],
};

const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    loadProjects: (state) => {
      state.loading = true;
    },

    getProjectsSuccess: (state, { payload }) => {
      state.projects = payload;
      state.project = {};
      state.comparisons = [];
      state.loading = false;
    },

    getSingleProjectsSuccess: (state, { payload }) => {
      state.project = payload;
      state.comparisons = [];
      state.loading = false;
    },

    updateProjectSuccess: (state, { payload }) => {
      state.project.name = payload;
      state.loading = false;
    },

    sharingSuccess: (state, { payload }) => {
      state.project.shareable = payload;
    },

    sharingCompareSuccess: (state, { payload }) => {
      state.project.shared_compare = payload;
    },

    showLimitedProject: (state) => {
      state.limitedModal = true;
    },

    hideLimitedProject: (state) => {
      state.limitedModal = false;
    },
    addActiveRoute: (state) => {
      state.activeRoute = true;
    },

    removeActiveRoute: (state) => {
      state.activeRoute = false;
    },

    deleteLatestProject: (state, { payload }) => {
      state.latestProjects.splice(payload, 1);
      state.loading = false;
    },

    deleteCurrentProject: (state, { payload }) => {
      // state.projects.splice(payload, 1);
      state.projects = state.projects.filter((p) => p.id !== payload);
      state.loading = false;
    },

    deletePredictionFromProjectSuccess: (state, { payload }) => {
      state.project.predictions = state.project.predictions.filter(
        (p) => p.id !== payload
      );
      state.loading = false;
    },

    comparePredictionsSuccess: (state, { payload }) => {
      state.loading = false;
      state.comparisons = payload;
    },

    exampleProjectsSuccess: (state, { payload }) => {
      state.loading = false;
      state.exampleProjects = payload;
    },

    getLatestSuccess: (state, { payload }) => {
      state.latestProjects = payload;
      state.loading = false;
      state.hasErrors = false;
    },

    getProjectsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    emptyProjects: (state) => {
      state.projects = [];
      state.project = {};
      state.latestProjects = [];
    },

    emptySingleProjects: (state) => {
      state.project = {};
    },
  },
});

export const {
  loadProjects,
  getProjectsSuccess,
  getSingleProjectsSuccess,
  getProjectsFailure,
  getLatestSuccess,
  updateProjectSuccess,
  sharingSuccess,
  sharingCompareSuccess,
  addActiveRoute,
  removeActiveRoute,
  showLimitedProject,
  hideLimitedProject,
  deleteLatestProject,
  deleteCurrentProject,
  deletePredictionFromProjectSuccess,
  comparePredictionsSuccess,
  exampleProjectsSuccess,
  emptySingleProjects,
  emptyProjects,
} = projectsSlice.actions;
export const projectsSelector = (state) => state.projects;
export default projectsSlice.reducer;

export const fetchProjects = (filter = "") => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = filter ? `${url}/projects${filter}` : `${url}/projects`;

  try {
    const res = await axios.get(apiUrl, config);
    dispatch(getProjectsSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchProjects, filter));
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export function fetchLatestProjects() {
  return async (dispatch) => {
    dispatch(loadProjects());

    const userData = await dispatch(getRefreshToken());
    const config = await dispatch(authorizedToken());

    let apiUrl = `${url}/projects?last=true`;

    try {
      const res = await axios.get(apiUrl, config);
      dispatch(getLatestSuccess(res.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(refreshToken(userData, fetchLatestProjects));
        } else {
          dispatch(getProjectsFailure());
        }
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    }
  };
}

export function fetchSingleProjects(id) {
  return async (dispatch) => {
    dispatch(loadProjects());

    const userData = await dispatch(getRefreshToken());
    const config = await dispatch(authorizedToken());

    let apiUrl = `${url}/projects/${id}`;

    try {
      const res = await axios.get(apiUrl, config);
      dispatch(getSingleProjectsSuccess(res.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(refreshToken(userData, fetchSingleProjects, id));
        } else {
          dispatch(push("/"));
          dispatch(getProjectsFailure());
          errorToast(ERROR_TOAST.GENERIC);
        }
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    }
  };
}

export function updateProjectInfo(projectData, id, newName) {
  return async (dispatch) => {
    dispatch(loadProjects());

    const userData = await dispatch(getRefreshToken());
    const config = await dispatch(authorizedToken());

    let apiUrl = `${url}/projects/${id}`;

    try {
      await axios.patch(apiUrl, projectData, config);
      dispatch(updateProjectSuccess(newName));
      dispatch(push(`/projects/${id}`));
      successToast(SUCCESS_TOAST.UPDATE_PROJECT);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(
            refreshToken(userData, updateProjectInfo, projectData, id, newName)
          );
        } else {
          dispatch(push(`/projects/${id}`));
          dispatch(getProjectsFailure());
          errorToast(ERROR_TOAST.GENERIC);
        }
      }
      //  else {
      //   dispatch(push("/"));
      //   dispatch(getProjectsFailure());
      //   errorToast(ERROR_TOAST.GENERIC);
      // }
    }
  };
}

export const toggleSharing = (reportData, id, isShareable) => async (
  dispatch
) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects/${id}`;

  try {
    await axios.patch(apiUrl, reportData, config);
    dispatch(sharingSuccess(isShareable));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, toggleSharing, reportData, id, isShareable)
        );
      } else {
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.UPDATE_PROJECT);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const toggleCompareAvailability = (
  reportData,
  id,
  compareAvailable
) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects/${id}`;

  try {
    await axios.patch(apiUrl, reportData, config);
    dispatch(sharingCompareSuccess(compareAvailable));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(
            userData,
            toggleCompareAvailability,
            reportData,
            id,
            compareAvailable
          )
        );
      } else {
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.UPDATE_PROJECT);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const createProject = (projectData, userTier) => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects`;

  try {
    const res = await axios.post(apiUrl, projectData, config);
    const { id } = res.data;
    dispatch(push(`/projects/${id}`));
    successToast(SUCCESS_TOAST.CREATE_PROJECT);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, createProject, projectData, userTier));
      } else if (error.response.status === 402) {
        userTier === "free"
          ? dispatch(showFreeLimitedModal())
          : dispatch(showLimitedModal());

        dispatch(getProjectsFailure());
      } else if (error.response.status === 400) {
        errorToast(error.response.data.reason);
        dispatch(getProjectsFailure());
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const createPredictInProject = (
  projectName,
  predictId,
  userTier
) => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects`;

  try {
    const res = await axios.post(apiUrl, projectName, config);
    const { data } = res;
    dispatch(createAndMove(predictId, data.id, userTier));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(
            userData,
            createPredictInProject,
            projectName,
            predictId,
            userTier
          )
        );
      } else if (error.response.status === 402) {
        userTier === "free"
          ? dispatch(showFreeLimitedModal())
          : dispatch(showLimitedModal());

        dispatch(getProjectsFailure());
      } else if (error.response.status === 400) {
        errorToast(error.response.data.reason);
        dispatch(getProjectsFailure());
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const createAndMove = (predictId, projectId, userTier) => async (
  dispatch
) => {
  const movePredictInfo = new FormData();
  movePredictInfo.append("predictions", JSON.stringify(predictId));
  movePredictInfo.append("project_id", projectId);
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/move`;

  try {
    await axios.post(apiUrl, movePredictInfo, config);

    dispatch(push(`/projects/${projectId}`));
    successToast("Predict moved to new project successfully");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, createAndMove, predictId, projectId, userTier)
        );
      } else if (error.response.status === 402) {
        userTier === "free"
          ? dispatch(showFreeLimitedModal())
          : dispatch(showLimitedModal());

        dispatch(getProjectsFailure());
      } else if (error.response.status === 400) {
        errorToast(error.response.data.reason);
        dispatch(getProjectsFailure());
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const deleteProject = (id, pathOrigin, isLatest) => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects/${id}`;

  try {
    await axios.delete(apiUrl, config);

    isLatest
      ? dispatch(deleteLatestProject(id))
      : dispatch(deleteCurrentProject(id));
    isLatest && dispatch(fetchLatestProjects());

    dispatch(closeDeleteModal());
    successToast(SUCCESS_TOAST.DELETE_PROJECT);
    dispatch(push(pathOrigin));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, deleteProject, id, pathOrigin, isLatest)
        );
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const deletePredictionFromProject = (id, pathOrigin) => async (
  dispatch
) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/${id}`;

  try {
    await axios.delete(apiUrl, config);

    dispatch(deletePredictionFromProjectSuccess(id));

    dispatch(closeDeleteModal());
    successToast(SUCCESS_TOAST.DELETE_PREDICTION);
    dispatch(push(pathOrigin));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, deletePredictionFromProject, id, pathOrigin)
        );
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const comparePredictions = (id) => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects/${id}/compare`;

  try {
    let res = await axios.get(apiUrl, config);
    dispatch(comparePredictionsSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, comparePredictions, id));
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(error.response.statusText);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const fetchExampleProjects = () => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects/examples`;

  try {
    let res = await axios.get(apiUrl, config);
    dispatch(exampleProjectsSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchExampleProjects));
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(error.response.statusText);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const fetchFirstExamples = () => async (dispatch) => {
  dispatch(loadProjects());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/projects/examples?basic=true`;

  try {
    let res = await axios.get(apiUrl, config);
    dispatch(exampleProjectsSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchFirstExamples));
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(error.response.statusText);
      }
    } else {
      dispatch(push("/"));
      dispatch(getProjectsFailure());
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export function fetchSingleExampleProject(id) {
  return async (dispatch) => {
    dispatch(loadProjects());

    const userData = await dispatch(getRefreshToken());
    const config = await dispatch(authorizedToken());

    let apiUrl = `${url}/projects/${id}`;

    try {
      const res = await axios.get(apiUrl, config);
      dispatch(getSingleProjectsSuccess(res.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(refreshToken(userData, fetchSingleExampleProject, id));
        } else {
          dispatch(push("/"));
          dispatch(getProjectsFailure());
          errorToast(ERROR_TOAST.GENERIC);
        }
      } else {
        dispatch(push("/"));
        dispatch(getProjectsFailure());
        errorToast(ERROR_TOAST.GENERIC);
      }
    }
  };
}

export const dynamicClass = () => (dispatch) => {
  dispatch(addActiveRoute());
};

export const removeDynamicClass = () => (dispatch) => {
  dispatch(removeActiveRoute());
};

export const showLimitedModal = () => (dispatch) => {
  dispatch(showLimitedProject());
  dispatch(hasSeenModal());
  dispatch(push("/projects"));
};

export const showFreeLimitedModal = () => (dispatch) => {
  dispatch(showLimitedProject());
  dispatch(hasSeenFreeModal());
  dispatch(push("/projects"));
};

export const hideLimitedModal = () => (dispatch) => {
  dispatch(hideLimitedProject());
};

export const projectsLogout = () => (dispatch) => {
  dispatch(emptyProjects());
};

export const projectLogout = () => (dispatch) => {
  dispatch(emptySingleProjects());
};
