import React from "react";
import styled, { css } from "styled-components";
import StepsLayout from "../../layouts/StepsLayout";
import stepFour from "../../icons/onboarding/stepFour.jpg";
import { Link } from "react-router-dom";
import { ChevronLeft } from "zoozoo";
import { IMAGE_CONTAINER_HEIGHT } from "../../../constants/onboardingContent";
import { gaSkipTour } from "../../../utils/analytics";

const StepContainer = styled.div`
  max-width: 660px;
  margin: 0 auto;
  position: relative;
  max-height: ${IMAGE_CONTAINER_HEIGHT};
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  max-width: 660px;
  height: 80%;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #fff;
  outline: 0;
  background: rgb(62, 33, 222);
  border-radius: ${(props) => props.theme.radius.soft};
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;

  &:hover {
    transition: all 0.3s;
    box-shadow: ${(props) => props.theme.shadows.darkShadowHover};
  }
`;

const StepActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a {
    min-width: 280px;
  }
`;

const ArrowStyles = css`
  transform: translate(0, -50%);
  z-index: 2;
  position: absolute;
  top: 45%;
  background: #eeeeee;
  border-radius: ${(props) => props.theme.radius.round};
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.brandPurple};
    transition: all 0.3s;

    svg {
      transition: all 0.3s;
      stroke: ${(props) => props.theme.colors.white};
    }
  }
`;

const ArrowsLeft = styled(Link)`
  ${ArrowStyles}
  left: 0;
`;

const StepFour = () => {
  return (
    <StepsLayout>
      <ArrowsLeft to="/onboarding/step-three">
        <ChevronLeft width={66} height={66} stroke="#BDBDBD" />
      </ArrowsLeft>
      <StepContainer>
        <div>
          <ImageContainer>
            <img src={stepFour} alt="step images" />
          </ImageContainer>
          <StepActions>
            <StyledLink to="/" onClick={() => gaSkipTour(4)}>
              Jump right in!
            </StyledLink>
          </StepActions>
        </div>
      </StepContainer>
    </StepsLayout>
  );
};

export default StepFour;
