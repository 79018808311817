const prod = {
  URL: process.env.REACT_APP_DB_PROD,
  SENTRY: process.env.REACT_APP_SENTRY,
  GOOGLE_AUTH: process.env.REACT_APP_GOOGLE_AUTH,
  WHITE_LABEL: [
    {
      id: "03355d35-187a-4114-b518-385d863233bf",
      name: "MindMaster.io",
      logo: require("../assets/GuestLogoWhatIf.png"),
      favicon: require("../assets/GuestLogoWhatIf.ico"),
    },
    {
      id: "38b5764f-a124-474c-ba06-e3dac13a7e94",
      name: "De Retailversneller",
      logo: require("../assets/GuestLogoRetailversneller.png"),
      favicon: require("../assets/GuestLogoRetailversneller.ico"),
    },
  ],
};
const dev = {
  URL: process.env.REACT_APP_DB_DEV,
  GOOGLE_AUTH: process.env.REACT_APP_GOOGLE_AUTH_DEV,
  WHITE_LABEL: [
    {
      id: "03355d35-187a-4114-b518-385d863233bf",
      name: "Whatif Robert",
      logo: require("../assets/GuestLogoWhatIf.svg"),
      favicon: require("../assets/GuestLogoWhatIf.ico"),
    },
    {
      id: "38b5764f-a124-474c-ba06-e3dac13a7e94",
      // id: "0e1428e5-0091-482c-a6cc-c66ce6d710c2",
      name: "De Retailversneller",
      logo: require("../assets/GuestLogoRetailversneller.png"),
      favicon: require("../assets/GuestLogoRetailversneller.ico"),
    },
  ],
};

export const config = process.env.NODE_ENV === "development" ? dev : prod;
