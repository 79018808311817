import dayjs from "dayjs";
import { PREDICTION_TITLES } from "../constants/GenericContent";
import { toastr } from "react-redux-toastr";
import { errorOptions, successOptions } from "../constants/ToastMessages";

//
//
// Arrays
//
//

export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue, randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};

export const getLastArrayItem = (array) => {
  return array.concat().slice(-1);
};

export const getArrayExceptLastItem = (arr) => {
  let t = arr.concat();
  t.pop();
  return t;
};

//
//
// Objects
//
//

// determines if object is empty
export const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

export default isEmpty;

//
//
// Miscellaneous
//
//

// calcs bytes to it's corresponding size
export const bytesToSize = (bytes) => {
  var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
};

// calcs the time of creation (day, hours, minutes)
const oneHour = 1000 * 60 * 60;
const oneDay = 1000 * 60 * 60 * 24;
const oneWeek = 1000 * 60 * 60 * 24 * 7;

export const calcCreationDate = (creationDate) => {
  let dateOfCreation = new Date(creationDate);

  let dateNow = new Date();
  let diff = dateNow.getTime() - dateOfCreation.getTime();

  const minAgo = Math.round(diff / 1000 / 60);
  const hoursAgo = Math.round(diff / 1000 / 60 / 60);

  if (diff < oneHour) {
    return `Created  ${minAgo} minutes ago`;
  } else if (diff < oneDay) {
    return `Created ${hoursAgo} hour ago`;
  } else if (diff < oneDay * 2 && diff > oneDay) {
    return `Created Υesterday`;
  } else if (diff < oneWeek) {
    return `Created on ${dayjs(dateOfCreation).format("DD MMMM YYYY")}`;
  }
};

// download image
const { ATTENTION, AOI } = PREDICTION_TITLES;
export const downloadImageFromUrl = (
  imageURL,
  imageName,
  predictType,
  base64
) => {
  const img = new Image();
  img.crossOrigin = "Anonymous";

  img.onload = function() {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.height = img.naturalHeight;
    canvas.width = img.naturalWidth;
    ctx.drawImage(img, 0, 0);

    const anchor = document.createElement("a");
    anchor.download =
      predictType !== AOI
        ? predictType === ATTENTION
          ? `VisualEyes Attention Map - ${imageName}.jpg`
          : `VisualEyes Clarity - ${imageName}.jpg`
        : `VisualEyes AOI - ${imageName}.jpg`;
    anchor.href = base64;
    anchor.click();
  };

  img.src = imageURL;
};

// toast messages
export const successToast = (msg) => {
  return toastr.success(msg, successOptions);
};

export const errorToast = (msg) => {
  return toastr.error(msg, errorOptions);
};

// debounce
export const debounceAction = (fn, ms) => {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this);
    }, ms);
  };
};

// export const trunk = (num) => {
//   return num.toString().match(/^\d{1,2}(\.\d{1,2})?$/);
// };

// export const trunk2 = (num) => {
//   return num.toString();
//   // return num.toString().match(/^\d{1,2}(\.\d{1,2})?$/);
// };

export function toFix(num, fixed) {
  var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re)[0];
}

export const drawAoiCoords = (
  canvasHeight,
  canvasWidth,
  areas,
  canvasRef = null,
  percentageAoi
) => {
  let canvas;
  canvasRef
    ? (canvas = canvasRef.current)
    : (canvas = document.createElement("canvas"));

  // console.log("hohohoho");
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  var ctx = canvas.getContext("2d");

  areas.forEach((m) => {
    if (percentageAoi) {
      if (m.score_absolute < 0) {
        ctx.strokeStyle = "#FFFB94";
      } else {
        ctx.strokeStyle = "#FFFB94";
      }

      ctx.lineWidth = 2;
      ctx.strokeRect(
        m.x * canvasWidth,
        m.y * canvasHeight,
        m.width * canvasWidth,
        m.height * canvasHeight
      );
      ctx.fillStyle = "rgba(#255, 251, 148, 0.1)";

      m.score_relative < 0
        ? (ctx.fillStyle = "rgba(255, 251, 148, 0.1)")
        : (ctx.fillStyle = "rgba(255, 251, 148, 0.1)");

      ctx.fillRect(
        m.x * canvasWidth,
        m.y * canvasHeight,
        m.width * canvasWidth,
        m.height * canvasHeight
      );
      ctx.fillStyle = "#58B149";

      m.score_relative < 0
        ? (ctx.fillStyle = "#FFFB94")
        : (ctx.fillStyle = "#FFFB94");

      ctx.fillRect(
        m.x * canvasWidth - 1,
        m.y * canvasHeight > 60
          ? m.y * canvasHeight - 30
          : m.y * canvasHeight + m.height * canvasHeight,
        80,
        30
      );

      ctx.fillStyle = "#1D0F68";
      ctx.font = "700 14px Roboto";
      let textWidth = ctx.measureText(`${m.score_absolute}%`).width;
      ctx.fillText(
        `${m.score_absolute}%`,
        m.x * canvasWidth + (80 - textWidth) / 2,
        m.y * canvasHeight > 60
          ? m.y * canvasHeight - 10
          : m.y * canvasHeight + 20 + m.height * canvasHeight
      );
    } else {
      if (m.score_relative < 0) {
        ctx.strokeStyle = "#EB4B4B";
      } else {
        ctx.strokeStyle = "#58B149";
      }

      ctx.lineWidth = 2;
      ctx.strokeRect(
        m.x * canvasWidth,
        m.y * canvasHeight,
        m.width * canvasWidth,
        m.height * canvasHeight
      );
      ctx.fillStyle = "rgba(111, 207, 151, 0.1)";

      m.score_relative < 0
        ? (ctx.fillStyle = "rgba(235, 75, 75, 0.1)")
        : (ctx.fillStyle = "rgba(111, 207, 151, 0.1)");

      ctx.fillRect(
        m.x * canvasWidth,
        m.y * canvasHeight,
        m.width * canvasWidth,
        m.height * canvasHeight
      );
      ctx.fillStyle = "#58B149";

      m.score_relative < 0
        ? (ctx.fillStyle = "#EB4B4B")
        : (ctx.fillStyle = "#58B149");

      ctx.fillRect(
        m.x * canvasWidth - 1,
        m.y * canvasHeight > 60
          ? m.y * canvasHeight - 30
          : m.y * canvasHeight + m.height * canvasHeight,
        80,
        30
      );

      ctx.fillStyle = "#fff";
      ctx.font = "700 14px Roboto";
      let textWidth = ctx.measureText(
        `x${toFix(0.01 * m.score_relative + 1, 2)}`
      ).width;
      ctx.fillText(
        `x${toFix(0.01 * m.score_relative + 1, 2)}`,
        m.x * canvasWidth + (80 - textWidth) / 2,
        m.y * canvasHeight > 60
          ? m.y * canvasHeight - 10
          : m.y * canvasHeight + 20 + m.height * canvasHeight
      );
    }

    // ctx.lineWidth = 2;
    // ctx.strokeRect(
    //   m.x * canvasWidth,
    //   m.y * canvasHeight,
    //   m.width * canvasWidth,
    //   m.height * canvasHeight
    // );
    // ctx.fillStyle = "rgba(111, 207, 151, 0.1)";

    // m.score_relative < 0
    //   ? (ctx.fillStyle = "rgba(235, 75, 75, 0.1)")
    //   : (ctx.fillStyle = "rgba(111, 207, 151, 0.1)");

    // ctx.fillRect(
    //   m.x * canvasWidth,
    //   m.y * canvasHeight,
    //   m.width * canvasWidth,
    //   m.height * canvasHeight
    // );
    // ctx.fillStyle = "#58B149";

    // m.score_relative < 0
    //   ? (ctx.fillStyle = "#EB4B4B")
    //   : (ctx.fillStyle = "#58B149");

    // ctx.fillRect(
    //   m.x * canvasWidth - 1,
    //   m.y * canvasHeight > 60
    //     ? m.y * canvasHeight - 30
    //     : m.y * canvasHeight + m.height * canvasHeight,
    //   80,
    //   30
    // );

    // ctx.fillStyle = "#fff";
    // ctx.font = "700 14px Roboto";
    // let textWidth = ctx.measureText(
    //   `${m.score_relative > 0 ? "+" : ""}${m.score_relative}%`
    // ).width;
    // ctx.fillText(
    //   `${m.score_relative > 0 ? "+" : ""}${m.score_relative}%`,
    //   m.x * canvasWidth + (80 - textWidth) / 2,
    //   m.y * canvasHeight > 60
    //     ? m.y * canvasHeight - 10
    //     : m.y * canvasHeight + 20 + m.height * canvasHeight
    // );
  });

  let dataURL = canvas.toDataURL();
  return dataURL;
};
