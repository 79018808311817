import React, { useState } from "react";
import styled from "styled-components";
import { Button, Input } from "desisystem";
import { useDispatch } from "react-redux";

// redux
import { applyCoupon } from "../../../../redux/slices/subs";

const CouponBox = styled.form`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: end;
  grid-gap: 16px;

  p {
    margin: 0;
  }

  & > div:first-child {
    grid-column: 1 / 3;

    & > div {
      margin-bottom: 0;
    }
  }

  input {
    border-radius: 4px;
  }

  button {
    height: 40px;
    width: 100%;
    padding: 0;
  }
`;

const Coupon = ({ error, couponError, planTier }) => {
  const dispatch = useDispatch();
  const [coupon, setCoupon] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    let couponCode = coupon;

    const couponData = new FormData();
    couponData.append("coupon", coupon);
    couponData.append("tier", planTier);

    dispatch(applyCoupon(couponData, couponCode));
  };

  return (
    <CouponBox onSubmit={handleSubmit}>
      <Input
        label="COUPON"
        type="text"
        size="10px"
        placeholder="Enter your coupon"
        onChange={(e) => setCoupon(e.target.value)}
      />
      <Button appearance="secondary" type="submit">
        Apply
      </Button>
    </CouponBox>
  );
};

export default Coupon;
