import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Global from "../../../layouts/Global";
import { Link } from "react-router-dom";
import { Input, Close, Button } from "desisystem";
import { useSelector, useDispatch } from "react-redux";

import {
  createProject,
  createPredictInProject,
  projectsSelector,
} from "../../../../redux/slices/projects";

import ButtonWithLoading from "../../../ui/buttons/ButtonWithLoading";
import { subscriptionSelector } from "../../../../redux/slices/subs";
import { closeFeatureForAll } from "../../../../redux/ducks/storage";

const Create = styled.div`
  background: ${(props) => props.theme.colors.globalBg};
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
  }

  input {
    border: 1px solid #c3baf5;
    box-sizing: border-box;
    border-radius: 4px;
    min-width: 600px;
    height: 40px;
  }

  input:focus {
    border: 1px solid #3e21de;
  }
`;

const LinkBox = styled.div`
  margin: 40px auto 0 auto;
  text-align: center;

  button {
    height: 40px;
    min-width: 200px;
    padding: 0 2rem;
    font-size: 14px;
    transition: all 0.3s;
  }
`;

const CloseLink = styled(Link)`
  & > svg {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 40px;
  }
`;

const MakeProject = (props) => {
  const [projectName, setProjectName] = useState("");
  const { loading } = useSelector(projectsSelector);
  const {
    subs: { tier },
  } = useSelector(subscriptionSelector);

  const { hasPredictId = null } = props.location.state || {
    hasPredictId: null,
  };

  const { pathname = "/" } = props.location.state || {
    pathname: "/",
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeFeatureForAll());
  }, [dispatch]);

  const handleProjectName = (e) => {
    e.preventDefault();

    if (hasPredictId !== null) {
      const userData = new FormData();
      userData.append("name", projectName);
      dispatch(createPredictInProject(userData, [hasPredictId], tier));
    } else {
      const userData = new FormData();
      userData.append("name", projectName);

      dispatch(createProject(userData, tier));
    }
  };

  return (
    <React.Fragment>
      <Global />
      <CloseLink to={pathname}>
        <Close width={30} height={30} />
      </CloseLink>
      <Create>
        <div>
          <h2>Create your Project</h2>
          <form onSubmit={handleProjectName}>
            <Input
              type="text"
              size="10px"
              placeholder="Add a name for your project..."
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
            <LinkBox>
              <ButtonWithLoading
                loading={loading}
                title="Create Project"
                disabled={projectName === ""}
              />
            </LinkBox>
          </form>
        </div>
      </Create>
    </React.Fragment>
  );
};

export default MakeProject;
