import React, { useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { useSelector, useDispatch } from "react-redux";
import AllPredictions from "./screens/AllPredictions";
import GlobalLoader from "../../ui/loadings/GlobalLoader";
// import InfiniteScroll from "react-infinite-scroller";
import InfiniteScroll from "react-infinite-scroll-component";

// import { useInfiniteScroll } from "react-infinite-scroll-hook";
import PredictCard from "../../ui/cards/PredictCard";
import styled from "styled-components";
import {
  fetchPredictions,
  predictionsSelector,
  fetchPaginatedPredictions,
  // fetchPaginatedPredictions,
} from "../../../redux/slices/predictions";
import { closeFeatureForAll } from "../../../redux/ducks/storage";
import {
  globalActionsSelector,
  globalCreationMenu,
} from "../../../redux/slices/globalActions";
import { emptyPrediction } from "../../../redux/slices/prediction";
import { fetchUser, userSelector } from "../../../redux/slices/user";

const PredictionsGrid = styled.div`
  padding: 94px 40px 48px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

let page = 1;
const PredictionsContainer = (props) => {
  const { loading, predictions, hasMore } = useSelector(predictionsSelector);
  const { projectIdForGlobal, deleteModalOpen } = useSelector(
    globalActionsSelector
  );
  const [loadingPredictions, setLoadingPredictions] = useState(true);
  const {
    user: { total_predictions },
  } = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUser());
    // dispatch(fetchPaginatedPredictions())
    // fetchPaginatedPredictions
    dispatch(fetchPredictions());
    dispatch(globalCreationMenu(null));
    dispatch(emptyPrediction());
    dispatch(closeFeatureForAll());
  }, [dispatch]);

  const { pathname } = props.location;

  // const loadFunc = () => {
  //   dispatch(fetchPaginatedPredictions(page));
  //   page = page + 1;
  //   // setLoadingPredictions(false);
  // };

  const renderPredictions = () => {
    if (loading) return <GlobalLoader />;

    return (
      <AllPredictions
        allPredictions={predictions}
        projectIdForGlobal={projectIdForGlobal}
        deleteModalOpen={deleteModalOpen}
        loadingPredictions={loadingPredictions}
        totalPredictions={total_predictions}
      >
        {/* <div>
          <InfiniteScroll
            dataLength={page}
            next={loadFunc}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
          > */}
        {/* <InfiniteScroll
          loadMore={loadFunc}
          hasMore={hasMore}
          loader={
            <div className="loader" key={0}>
              loading...
            </div>
          }
          threshold={10}
        > */}

        {/* <PredictionsGrid> */}
        {predictions.map((prediction, i) => {
          return (
            <PredictCard
              key={i}
              prediction={prediction}
              pathOrigin={pathname}
              deleteType="predict"
            />
          );
        })}
        {/* </PredictionsGrid> */}
        {/* </InfiniteScroll>
        </div> */}
      </AllPredictions>
    );
  };

  return <Layout>{renderPredictions()}</Layout>;
};

export default PredictionsContainer;

//
//  </PredictionsGrid>
