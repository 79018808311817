import React from "react";
import styled from "styled-components";
import zombe from "../../../assets/illustrations/learn-more.svg";
// import { Link } from "react-router-dom";
import { gaViewChangelog, gaNotionLink } from "../../../utils/analytics";

const DiveDeeper = styled.div`
  margin-top: 72px;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 8px 0;
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.softGrey};
    margin: 8px 0 26px 0;
  }
`;

const DiveDeeperBody = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  grid-gap: 24px;

  & > div {
    justify-self: center;
    height: 100%;
    box-sizing: border-box;
    min-height: 260px;
    background: #fff;
    width: 100%;
  }

  img {
    width: 200px;
  }

  h2 {
    font-weight: normal;
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSize[20]};
    margin: 0;
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.softGrey};
    max-width: 256px;
  }
`;

const InlineContent = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);

  & > div:first-child {
    padding: 32px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;

  a {
    margin-top: auto;
  }
`;

const StyledLink = styled.a`
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: ${(props) => props.theme.primary[5]};
`;

const DashInfo = () => {
  return (
    <DiveDeeper>
      <h2>Dive Deeper</h2>
      <p>Keep track with VisualEyes.</p>
      <DiveDeeperBody>
        <Content>
          <h2>What’s New</h2>
          <p>
            We love that you love VisualEyes, so we’ll keep updating it. Every
            new addition will be documented in our changelog. Have a look!
          </p>
          <StyledLink
            onClick={() => gaViewChangelog()}
            target="_blank"
            href="https://www.notion.so/Changelog-5c6188d8774a4711af4d55030102aea4"
          >
            View our changelog
          </StyledLink>
        </Content>
        <InlineContent>
          <div>
            <img src={zombe} alt="al" />
          </div>
          <Content>
            <h2>Learn more</h2>
            <p>
              Get a glimpse of VisualEyes’ magic in Notion. How-to guides, white
              papers, and case studies are available to you.
            </p>
            <StyledLink
              onClick={() => gaNotionLink()}
              target="_blank"
              href="https://www.notion.so/Knowledge-Base-497fcfe4c05c4408aacf828b3c82a314"
            >
              Visit our learning section in Notion
            </StyledLink>
          </Content>
        </InlineContent>
      </DiveDeeperBody>
    </DiveDeeper>
  );
};

export default DashInfo;
