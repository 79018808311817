import React from "react";
import ProjectIcon from "../components/icons/ProjectIcon";
// import BenchmarkIcon from "../components/icons/BenchmarkIcon";
import PredictionIcon from "../components/icons/PredictionIcon";
import PredictionIconPlus from "../components/icons/PredictionIconPlus";

export const creationButtonContent = [
  {
    icon: <PredictionIcon width={30} height={30} />,
    title: "Make a Prediction",
    description:
      "Upload your image file and get instant feedback on your design.",
    link: "/predict/create/device",
    disabled: false,
  },
  {
    icon: <ProjectIcon width={30} height={30} />,
    title: "Create a Project",
    description: "Keep your work organized and easily accessible.",
    link: "/create/projects",
    disabled: false,
  },
  // {
  //   icon: <BenchmarkIcon stroke="#E0E0E0" width={30} height={30} />,
  //   title: "Compare & Benchmark",
  //   description:
  //     "Test how your designs are scoring against those of your competitors.",
  //   link: "/",
  //   disabled: true
  // }
];

export const singleProjectButtonContent = [
  {
    icon: <PredictionIcon width={30} height={30} />,
    title: "Add a New Prediction",
    description:
      "Upload a new image file and import it directly into you project.",
    link: "/predict/create/device",
    disabled: false,
  },
  {
    icon: <PredictionIconPlus width={30} height={30} />,
    title: "Add an existing Prediction",
    description:
      "Choose a prediction you already have created and add it into your project.",
    link: "/add/predictions",
    disabled: false,
  },
  // {
  //   icon: <BenchmarkIcon stroke="#E0E0E0" width={30} height={30} />,
  //   title: "Compare & Benchmark",
  //   description:
  //     "Test how your designs are scoring against those of your competitors.",
  //   link: "/",
  //   disabled: true
  // }
];
