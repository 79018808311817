import React from "react";

const GreenCheck = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20.25V20.25C7.44325 20.25 3.75 16.5568 3.75 12V12C3.75 7.44325 7.44325 3.75 12 3.75V3.75C16.5568 3.75 20.25 7.44325 20.25 12V12C20.25 16.5568 16.5568 20.25 12 20.25Z"
      stroke="#58B149"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6667 10.1667L11.0833 14.75L8.33333 12"
      stroke="#58B149"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default GreenCheck;
