import React from "react";
import styled from "styled-components";
import DeviceType from "./DeviceType";
import PlatformTool from "./PlatformTool";

const Header = styled.div`
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 4px 6px;

  p {
    a {
      color: ${(props) => props.theme.colors.brandPurple};
    }
  }
`;

const SecondaryInfo = styled.p`
  margin: 0 0;
  font-size: ${(props) => props.theme.fontSize[12]};
  color: #9e9e9e;

  ${(props) =>
    props.exist &&
    `  
  &::first-letter {
    text-transform: uppercase;
  }
`}

  span {
    margin-right: 4px;
  }
`;

const Divider = styled.span`
  max-width: 1px;
  min-width: 1px;
  background-color: #e2e0ec;
  height: 0.9rem;
  margin: 0 0.5rem;
`;

const DateInfo = ({
  getCreationDate,
  oldDate,
  projectName,
  model,
  platform,
}) => {
  return (
    <Header>
      <SecondaryInfo exist={projectName === null}>
        {getCreationDate !== undefined
          ? getCreationDate
          : `Created on ${oldDate}`}
      </SecondaryInfo>

      <Divider />

      <DeviceType model={model} />

      {platform !== "dashboard" && (
        <React.Fragment>
          <Divider />
          <PlatformTool platform={platform} />
        </React.Fragment>
      )}
    </Header>
  );
};

export default DateInfo;
