import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

const GOOGLE_ANALYTICS_ID = "UA-99671636-3";
const FACEBOOK_PIXEL_ID = "174933950276277";

function gaInit(apiKey) {
  // ReactGA.initialize(GOOGLE_ANALYTICS_ID);

  ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
    gaOptions: {
      userId: apiKey,
      clientId: `${apiKey}-platform`,
    },
  });
  // console.log("gaInit");
}

function fbInit() {
  ReactPixel.init(FACEBOOK_PIXEL_ID);
}

function fbTrack(event, data = {}) {
  // console.log(event, data);
  ReactPixel.track(event, data);
}

function gaConvert(experimentName, variantName) {
  // console.log("gaConvert");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "conversion",
      action: `Registration`,
    });
  } else {
    setTimeout(gaConvert, 100);
  }
}

function gaPurchase() {
  // console.log(`Convert from category "Conversion" with action "Purchase"`);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (typeof window !== "undefined" && window.ga) {
    ReactGA.event({
      category: "conversion",
      action: "Purchase",
    });
  } else {
    setTimeout(gaPurchase, 100);
  }
}

function gaFirstPrediction() {
  // console.log("first prediction");
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Product",
      action: `Activated`,
    });
  } else {
    setTimeout(gaFirstPrediction, 100);
  }
}

function gaEveryPrediction() {
  // console.log("every prediction");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Product",
      action: `Prediction`,
    });
  } else {
    setTimeout(gaEveryPrediction, 100);
  }
}

function gaFeatureAdoption(action, label) {
  // console.log('adoption triggered');

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Feature Adoption",
      action: action,
      label: label,
    });
  } else {
    setTimeout(gaFeatureAdoption, 100);
  }
}

//                         //
// //                   // //
// // //             // // //
// // // //       // // // //
// Second analytic session //
// // // //       // // // //
// // //             // // //
// //                   // //
//                         //

// init unique user for analytics
function gaInitUserId(apiKey) {
  // console.log("init user with api key");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.set({
      userId: apiKey,
      clientId: `${apiKey}-platform`,
    });
  } else {
    setTimeout(gaInitUserId, 100);
  }
}

// how many users take the tour
function gaRetakeTour() {
  // console.log("retake tour");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Onboarding",
      label: "Retake Τour",
    });
  } else {
    setTimeout(gaRetakeTour, 100);
  }
}

// In which step users skip tour
function gaSkipTour(step) {
  let steps = step === 4 ? "Completed Tour" : `Completed Step-${step}`;
  // console.log(steps);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Onboarding",
      label: steps,
    });
  } else {
    setTimeout(gaRetakeTour, 100);
  }
}

// when user downloads a plugin
function gaDownloadPlugin() {
  // console.log("download plugin");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Homepage Activities",
      label: "Plugin Downloads",
    });
  } else {
    setTimeout(gaDownloadPlugin, 100);
  }
}

// when user click the changelog
function gaViewChangelog() {
  // console.log("view changelog");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Homepage Activities",
      label: "Changelog",
    });
  } else {
    setTimeout(gaViewChangelog, 100);
  }
}

// when user visits learning section in Notion
function gaNotionLink() {
  // console.log("view notion");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Homepage Activities",
      label: "Notion",
    });
  } else {
    setTimeout(gaNotionLink, 100);
  }
}

// when user makes a prediction
function gaMakePrediction() {
  // console.log("prediction made");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Homepage Activities",
      label: "Prediction from BoC",
    });
  } else {
    setTimeout(gaMakePrediction, 100);
  }
}

// view all predictions
function gaAllPredictions() {
  // console.log("view predictions");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Homepage Activities",
      label: "View All Predictions",
    });
  } else {
    setTimeout(gaAllPredictions, 100);
  }
}

// when user views compare page
function gaViewCompareMode() {
  // console.log("view compare");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Sharing",
      label: "Compare self",
    });
  } else {
    setTimeout(gaViewCompareMode, 100);
  }
}

// when user shares prediction url
function gaPredictionSharedUrl() {
  // console.log("prediction shared url");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Sharing",
      label: "Access to Shared Predictions",
    });
  } else {
    setTimeout(gaPredictionSharedUrl, 100);
  }
}

// when user shares project url
function gaProjectSharedUrl() {
  // console.log("project shared url");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Sharing",
      label: "Access to Shared Projects",
    });
  } else {
    setTimeout(gaProjectSharedUrl, 100);
  }
}

// when no auth user view the grid mode
function gaViewGridMode() {
  // console.log("view compare");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Sharing",
      label: "View Grid Mode",
    });
  } else {
    setTimeout(gaViewGridMode, 100);
  }
}

// delete prediction from anywhere
function gaDeletePrediction() {
  // console.log("delete prediction");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Prediction View",
      label: "Prediction Deletion",
    });
  } else {
    setTimeout(gaDeletePrediction, 100);
  }
}

// learn more overlay
function gaLearnMoreOverlay() {
  // console.log("learn more overlay");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Prediction View - Generic",
      label: "View Learn More overlay",
    });
  } else {
    setTimeout(gaLearnMoreOverlay, 100);
  }
}

// download image
function gaDownloadImage() {
  // console.log("download image");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Prediction View - Generic",
      label: "Image Result Downloads",
    });
  } else {
    setTimeout(gaDownloadImage, 100);
  }
}

// learn more about clarity ranges
function gaLearnMoreClarityRange() {
  // console.log("learn more clarity ranges");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Prediction View - Clarity",
      label: "Learn More about clarity range",
    });
  } else {
    setTimeout(gaLearnMoreClarityRange, 100);
  }
}

// attention switch on AOI
function gaAttentionInAoiSwitch() {
  // console.log("attention switch on AOI");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Prediction View - AOI",
      label: "Attention map overlay usage when in AOI",
    });
  } else {
    setTimeout(gaAttentionInAoiSwitch, 100);
  }
}

// learn more in main navigation
function gaLearnMainNavigation() {
  // console.log("learn in main navigation");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Navigation",
      label: "Learn",
    });
  } else {
    setTimeout(gaLearnMainNavigation, 100);
  }
}

// learn option in dropdown
function gaLearnOptionInDropdown() {
  // console.log("learn option in dropdown ");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Navigation",
      label: "Learn from Dropdown",
    });
  } else {
    setTimeout(gaLearnOptionInDropdown, 100);
  }
}

// project link in main navigation
function gaProjectLinkInNavigation() {
  // console.log("project link in navigation");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Navigation",
      label: "Projects",
    });
  } else {
    setTimeout(gaProjectLinkInNavigation, 100);
  }
}

// add new prediction to project
function gaNewPredictionToProject() {
  // console.log("add new prediction to project");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "General Use",
      label: "Added new prediction to project",
    });
  } else {
    setTimeout(gaNewPredictionToProject, 100);
  }
}

// add existing prediction to project
function gaExistingPredictionToProject() {
  // console.log("add existing prediction to project");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "General Use",
      label: "Added an existing prediction to project",
    });
  } else {
    setTimeout(gaExistingPredictionToProject, 100);
  }
}

function gaSubscriptionNavLink() {
  // console.log("subscription nav link");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Navigation",
      label: "Subscription",
    });
  } else {
    setTimeout(gaSubscriptionNavLink, 100);
  }
}

//                         //
// //                   // //
// // //             // // //
// // // //       // // // //
//         Guides          //
// // // //       // // // //
// // //             // // //
// //                   // //
//                         //

// View all guides
function gaViewAllGuides() {
  // console.log("View all guides");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "View all guides",
    });
  } else {
    setTimeout(gaViewAllGuides, 100);
  }
}

// View all guide from nav
function gaNavViewAllGuides() {
  // console.log("View all guides from nav");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Navigation",
      label: "Guides in Header Nav",
    });
  } else {
    setTimeout(gaNavViewAllGuides, 100);
  }
}

// Unlock Attention Maps
function gaAttentionGuide() {
  // console.log("Unlock Attention Maps");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Guide 1 - Unlock Attention Maps",
    });
  } else {
    setTimeout(gaAttentionGuide, 100);
  }
}

// Unlock Attention Maps from dashboard
function gaDashAttentionGuide() {
  // console.log("DAsh Unlock Attention Maps");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Dash Guide 1 - Unlock Attention Maps",
    });
  } else {
    setTimeout(gaDashAttentionGuide, 100);
  }
}

// Understand Visual Clarity
function gaClarityGuide() {
  // console.log("Understand Visual Clarity");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Guide 2 - Understand Visual Clarity",
    });
  } else {
    setTimeout(gaClarityGuide, 100);
  }
}

function gaDashClarityGuide() {
  // console.log("Dash Understand Visual Clarity");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Dash Guide 2 - Understand Visual Clarity",
    });
  } else {
    setTimeout(gaDashClarityGuide, 100);
  }
}

// Optimize Feature Images
function gaOptimizeFeatureGuide() {
  // console.log("Optimize Feature Images");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Guide 3 - Optimize Feature Images",
    });
  } else {
    setTimeout(gaOptimizeFeatureGuide, 100);
  }
}

// Competitors Analysis
function gaCompetitorsAnalysisGuide() {
  // console.log("Competitors Analysis");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Guide 4 - Competitors Analysis",
    });
  } else {
    setTimeout(gaCompetitorsAnalysisGuide, 100);
  }
}

// Uber Case Study
function gaUberCaseStudyGuide() {
  // console.log("Uber Case Study");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Guide 5 - Uber Case Study",
    });
  } else {
    setTimeout(gaUberCaseStudyGuide, 100);
  }
}

// Quick Visual A/B Tests
function gaQuickVisualTestsGuide() {
  // console.log("Quick Visual A/B Tests");

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") return;

  if (window.ga) {
    ReactGA.event({
      category: "Platform",
      action: "Guides",
      label: "Guide 4 - Competitors Analysis",
    });
  } else {
    setTimeout(gaQuickVisualTestsGuide, 100);
  }
}

export {
  gaConvert,
  gaInit,
  fbInit,
  fbTrack,
  gaPurchase,
  gaFirstPrediction,
  gaEveryPrediction,
  gaFeatureAdoption,
  gaInitUserId,
  gaRetakeTour,
  gaSkipTour,
  gaDownloadPlugin,
  gaViewChangelog,
  gaNotionLink,
  gaMakePrediction,
  gaAllPredictions,
  gaViewCompareMode,
  gaPredictionSharedUrl,
  gaProjectSharedUrl,
  gaViewGridMode,
  gaDeletePrediction,
  gaLearnMoreOverlay,
  gaDownloadImage,
  gaLearnMoreClarityRange,
  gaAttentionInAoiSwitch,
  gaLearnMainNavigation,
  gaLearnOptionInDropdown,
  gaProjectLinkInNavigation,
  gaNewPredictionToProject,
  gaExistingPredictionToProject,
  gaSubscriptionNavLink,
  gaViewAllGuides,
  gaNavViewAllGuides,
  gaAttentionGuide,
  gaDashAttentionGuide,
  gaClarityGuide,
  gaDashClarityGuide,
  gaOptimizeFeatureGuide,
  gaCompetitorsAnalysisGuide,
  gaUberCaseStudyGuide,
  gaQuickVisualTestsGuide,
};
