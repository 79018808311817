import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "desisystem";
import { Sharing, Lock } from "zoozoo";
import ReactTooltip from "react-tooltip";

const PredictionActions = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    display: flex;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 8px;
    min-height: 32px;

    span {
      margin-left: 6px;
    }
  }
`;

const MoveToProject = styled(Link)`
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: ${(props) => props.theme.colors.brandPurple};
  border: 2px solid ${(props) => props.theme.colors.brandPurple};
  outline: 0;
  background: #fff;
  border-radius: ${(props) => props.theme.radius.soft};
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;
  padding: 0 0.7rem;
`;

// const LockIcon = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0 10px;
//   margin: 0 0 0 5px;
// `;

const CoreActions = ({ tier, pathname, projectName, id, setOpenSharing }) => {
  // let onlyPro = tier === "basic" || tier === "free";

  // let onlyPro = tier === "pro";

  // console.log(onlyPro);
  return (
    <React.Fragment>
      <PredictionActions>
        <div>
          {!projectName && (
            <MoveToProject
              to={{
                pathname: "/predict/move",
                state: { hasPredictId: id, pathname },
              }}
            >
              Move to Project
            </MoveToProject>
          )}
          {/* <div
            data-for={onlyPro ? `lock` : ""}
            data-tip
            style={{ display: "flex" }}
          > */}
          <Button
            width="100"
            // disabled={onlyPro}
            onClick={() => setOpenSharing(true)}
          >
            <Sharing width={20} height={20} />
            <span>Share</span>
          </Button>
          {/* <LockIcon data-for="lock" data-tip>
            {tier !== "pro" && <Lock stroke="#212121" width={18} height={20} />}
          </LockIcon> */}
        </div>
        {/* </div> */}

        <ReactTooltip id="lock" effect="solid" place={"bottom"}>
          <p style={{ margin: 0 }}>Upgrade to unlock</p>
        </ReactTooltip>
      </PredictionActions>
    </React.Fragment>
  );
};

export default CoreActions;
