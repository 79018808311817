import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { PREDICTION_TITLES } from "../../../../../constants/GenericContent";
import PreviewReportsModal from "../../../../ui/modals/PreviewReportsModal";
import { Preview } from "zoozoo";
import CircleScore from "../../../../ui/globals/CircleScore";
import { drawAoiCoords } from "../../../../../utils/helperFunctions";

import watermarkDark from "../../../../../assets/image.png";
import watermarkLight from "../../../../../assets/watermark-light.svg";

const ImageResult = styled.div`
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: 100%;
  }

  && {
    .MuiDialog-paperWidthSm {
      max-width: initial !important;
    }
  }
`;

const WatermarkImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: url(${(props) => props.watermark});
  background-size: 25% auto;
`;

const HoverIcon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: none;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
    top: 54%;
    z-index: 3;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(0, 0, 0, 0.8);
  }
`;

const RootImage = styled.div`
  display: flex;
  position: relative;
  cursor: pointer;

  &:hover {
    ${HoverIcon} {
      display: block;
    }
  }
`;

const SingleImageResult = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    ${HoverIcon} {
      display: block;
    }
  }
`;

const AoiSingleImageResult = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:hover {
    ${HoverIcon} {
      display: block;
    }
  }

  z-index: ${(props) => (!props.active ? -1 : 1)};
`;

const Header = styled.div`
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  padding: 16px;

  h2 {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    text-align: center;
    color: #212121;
  }
`;

const ScoreCircle = styled.div`
  position: absolute;
  top: 25px;
  background: #fff;
  display: inline-block;
  width: 70px;
  height: 70px;
  right: 19px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 1;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const EachComparison = ({
  compare,
  openPreviewModal,
  closeModal,
  type,
  typeUrl,
  tier,
}) => {
  const { ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;
  const [aoiUrl, setAoiUrl] = useState("");
  const { use_percentage: percentageAoi } = compare;

  const calcDimensions = (e, areas, id) => {
    let w = e.target.offsetWidth;
    let h = e.target.offsetHeight;

    let aoiPoints = drawAoiCoords(h, w, areas, null, percentageAoi);

    setAoiUrl(aoiPoints);
  };

  return (
    <ImageResult onClick={() => openPreviewModal(compare.id)}>
      <Header>
        <h2>{compare.name}</h2>
      </Header>

      <React.Fragment>
        <RootImage className="root-image">
          <img
            src={compare.original_serve_path}
            alt="original-result"
            onLoad={(e) => calcDimensions(e, compare.areas, compare.id)}
          />

          <HoverIcon>
            <Preview stroke="#fff" width={34} height={34} />
            <p>Expand</p>
          </HoverIcon>

          {type === ATTENTION && (
            <SingleImageResult>
              <img src={compare.attention_serve_path} alt="attention-result" />

              {tier === "free" && (
                <WatermarkImage watermark={watermarkDark}></WatermarkImage>
              )}

              <HoverIcon>
                <Preview stroke="#fff" width={34} height={34} />
                <p>Expand</p>
              </HoverIcon>
            </SingleImageResult>
          )}

          {type === CLARITY && (
            <SingleImageResult>
              <img src={compare.clarity_serve_path} alt="clarity-result" />

              {tier === "free" && (
                <WatermarkImage watermark={watermarkLight}></WatermarkImage>
              )}

              <HoverIcon>
                <Preview stroke="#fff" width={34} height={34} />
                <p>Expand</p>
              </HoverIcon>

              <ScoreCircle>
                <CircleScore score={compare.clarity_score} size={50} />
              </ScoreCircle>
            </SingleImageResult>
          )}

          <AoiSingleImageResult active={type === AOI}>
            <img src={aoiUrl} alt="aoi-url" />

            <HoverIcon>
              <Preview stroke="#fff" width={34} height={34} />
              <p>Expand</p>
            </HoverIcon>
          </AoiSingleImageResult>
        </RootImage>
      </React.Fragment>

      <PreviewReportsModal
        open={compare.open_modal}
        closeModal={closeModal}
        src={typeUrl}
        id={compare.id}
        type={type}
        compare={compare}
        tier={tier}
      />
    </ImageResult>
  );
};

export default EachComparison;
