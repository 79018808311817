import React from "react";
import styled from "styled-components";
import { Warning } from "zoozoo";

const Message = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fontSize[14]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InlineErrorMessage = ({ errorMsg }) => {
  return (
    <Message>
      {" "}
      <Warning
        stroke="#D53C3C"
        width={17}
        height={17}
        style={{ marginRight: "2px" }}
      />
      {errorMsg}
    </Message>
  );
};

export default InlineErrorMessage;
