import React from "react";
import styled from "styled-components";
import ProjectCard from "../../../ui/cards/ProjectCard";
import freeImage from "../../../../assets/woman.png";
import { Link } from "react-router-dom";
import {
  PREDICTION_OPENING_CONTENT,
  PROJECT_OPENING_CONTENT,
  SECOND_PREDICTION_OPENING_CONTENT,
} from "../../../../constants/GenericContent";
import FirstOpening from "../../openings/FirstOpening";
import DASHBOARD_STATES from "../../../../constants/DashboardState";
import ProjectLoading from "../../../loadings/ProjectLoading";
import useDimensions from "react-use-dimensions";
import { DELETE_TYPES } from "../../../../constants/deleteTypes";

const Projects = styled.div`
  & > h2 {
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 8px 0;
  }
`;

const ProjectsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  & > p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.softGrey};
    margin: 0;
  }
`;

const ProjectsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: ${(props) => props.theme.colors.brandPurple};
`;

const DashboardProjects = ({ latestProjects, dashboardState, pathOrigin }) => {
  const [ref, { width }] = useDimensions();

  // for loading cards
  let customWidth;
  let customViewport;
  if (width) {
    customWidth = width;
    customViewport = `0 0 ${width} 80`;
  }

  function renderDashboardContent(state) {
    switch (state) {
      case DASHBOARD_STATES.NONE:
        return <FirstOpening openingContent={PREDICTION_OPENING_CONTENT} />;
      case DASHBOARD_STATES.ONLY_PREDICTION:
        return <FirstOpening openingContent={PROJECT_OPENING_CONTENT} />;
      case DASHBOARD_STATES.BOTH:
      case DASHBOARD_STATES.ONLY_PROJECTS:
        return (
          <Projects>
            <h2>Projects</h2>
            <ProjectsHeader>
              <p>Your latest projects</p>
              <StyledLink to="/projects">View all projects</StyledLink>
            </ProjectsHeader>
            <ProjectsGrid>
              {latestProjects.map((l, i) => {
                return (
                  <div ref={ref} key={i}>
                    <ProjectCard
                      info={l}
                      pathOrigin={pathOrigin}
                      deleteType={DELETE_TYPES.PROJECT}
                    />
                  </div>
                );
              })}
            </ProjectsGrid>
          </Projects>
        );
      case DASHBOARD_STATES.LOADING:
        return (
          <Projects>
            <h2>Projects</h2>
            <ProjectsHeader>
              <p>Your latest projects</p>
              <StyledLink to="/projects">View all projects</StyledLink>
            </ProjectsHeader>
            <ProjectsGrid>
              <ProjectLoading
                customWidth={customWidth}
                customViewport={customViewport}
              />
              <ProjectLoading
                customWidth={customWidth}
                customViewport={customViewport}
              />
              <ProjectLoading
                customWidth={customWidth}
                customViewport={customViewport}
              />
            </ProjectsGrid>
          </Projects>
        );
      default:
        return;
    }
  }

  return renderDashboardContent(dashboardState);
};

export default DashboardProjects;
