import React from "react";
import styled from "styled-components";
import { Button } from "desisystem";
import FullPageModal from "../../global/FullPageModal";
import { useSelector } from "react-redux";
import { proPlan } from "../../../constants/planFeatures";
import newPlan from "../../../assets/tags/new-plan.svg";
import { fbTrack } from "../../../utils/analytics";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import isEmpty from "../../../utils/helperFunctions";
import { subscriptionSelector } from "../../../redux/slices/subs";
import { userSelector } from "../../../redux/slices/user";

const ContentBox = styled.div`
  text-align: center;
  padding-top: 10vh;

  h2:first-letter {
    text-transform: uppercase;
  }

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[22]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 16px 0;

    p:first-letter {
      text-transform: uppercase;
    }

    p {
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSize[22]};
      color: ${(props) => props.theme.colors.black};
      display: inline-block;
    }
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 auto;
  }

  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const ActionButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding-bottom: 20px;

  button {
    max-width: 200px;
    min-width: 200px;
    height: 40px;
    padding: 0;
    margin: 0 12px
    font-size: ${(props) => props.theme.fontSize[14]};
    transition: all .3s;
  }
`;

const Card = styled.div`
  margin-top: 32px;
  display: flex;
  ${"" /* grid-template-columns: 1fr 0.8fr 1.2fr; */}
  height: 100%;
  position: relative;

  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  padding: 32px;
  max-width: 820px;
  border: 2px solid #3e21de;
  border-radius: 10px;

  .active {
    border: 2px solid #3e21de;
  }
`;

const FreeCardBody = styled.div`
  display: grid;
  align-self: center;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  grid-gap: 0 16px;
  grid-auto-rows: max-content;
  padding-right: 32px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
      font-size: 14px;
      color: #212121;
      font-weight: normal;
      white-space: nowrap;
    }
  }
`;

const EachOption = styled.div`
  svg {
    stroke: ${(props) => (props.disabled ? "#C9C9C9" : "#3E21DE")};
  }

  span {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      margin-right: 4px;
    }

    strong {
      margin-right: 4px;
    }
  }
`;

const CardTag = styled.div`
  align-items: center;
  display: flex;
  padding-right: 32px;
`;

const CardMiddle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 32px 0 0;
  width: 210px;

  h2::first-letter {
    text-transform: uppercase;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    color: #212121;
    text-align: left;
    margin: 0 0 8px 0;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
    text-align: left;
  }

  button {
    max-width: 160px;
    padding: 0;
  }
`;

const Divider = styled.div`
  display: flex;
  align-items: center;

  & > div {
    width: 1px;
    height: 56px;
    background: #c9c9c9;
  }
`;

const CardPrice = styled.div`
  padding-left: 24px;
  display: flex;
  align-items: center;

  h3 {
    font-weight: bold;
    font-size: 20px;
    color: #212121;
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
      font-size: 14px;
      color: #212121;
      font-weight: normal;
      white-space: nowrap;
    }
  }
`;

const InfoCard = styled.div`
  background: #424242;
  padding: 32px;
  max-width: 820px;
  margin-top: 16px;
  border-radius: 10px;
`;

const InfoCardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;

  h4 {
    font-weight: normal;
    font-size: 14px;
    color: #ffffff;
    margin: 0;
  }
`;

const InfoCardBody = styled.div`
  border-top: 1px solid #c9c9c9;

  h4 {
    font-weight: normal;
    font-size: 20px;
    color: #ffffff;
    text-align: left;
    margin: 16px 0 8px 0;
  }

  p {
    font-size: 14px;
    color: #ffffff;
    text-align: left;
    margin: 16px 0;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 14px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 0 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: all 0.4s;
  max-width: 200px;
  min-width: 200px;

  &:hover {
    transition: all 0.4s;
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

const ConfirmPaymentModal = ({ open, closeModal, proration, proPrice }) => {
  const {
    user: { first_name: firstName },
  } = useSelector(userSelector);

  const {
    subs: { period },
  } = useSelector(subscriptionSelector);

  const trunk = (num) => {
    return num.toString().match(/[^.]*/i)[0];
  };
  // console.log(proration);
  return (
    <FullPageModal open={open}>
      {proration !== "" && proration !== undefined && !isEmpty(proration) && (
        <React.Fragment>
          <ContentBox>
            <h2>You chose a new plan, {firstName}.</h2>

            <Card>
              <CardTag>
                <img src={newPlan} alt="tag" />
              </CardTag>
              <CardMiddle>
                <div>
                  <h2>Pro</h2>
                  <p>For medium-sized teams and digital agencies.</p>
                </div>
              </CardMiddle>
              <FreeCardBody>
                {proPlan.options.map((f, i) => {
                  return (
                    <EachOption key={i} disabled={f.disabled}>
                      {f.icon}
                      {f.option}
                    </EachOption>
                  );
                })}
              </FreeCardBody>
              <Divider>
                <div></div>
              </Divider>
              <CardPrice>
                <h3>
                  {" "}
                  $50.00 <span> / month</span>
                </h3>
              </CardPrice>
            </Card>
            <InfoCard>
              <InfoCardHeader>
                <h4>Prorated price due today</h4>
              </InfoCardHeader>
              {period !== "annual" && (
                <InfoCardBody>
                  <h4>
                    {" "}
                    For <strong> monthly</strong> subscription:{" "}
                    <strong>${trunk(proration.month)}</strong>{" "}
                  </h4>
                  <p>
                    As you are already subscribed in one of our paid plans,
                    you’ll pay the prorated price today and the full price of
                    the selected plan on the start of your next billing period.
                  </p>
                  <p>
                    Next payment due in{" "}
                    <strong>
                      {" "}
                      {dayjs(proration.renewal_month).format(
                        "DD MMM, YYYY"
                      )}{" "}
                    </strong>
                  </p>
                </InfoCardBody>
              )}
              <InfoCardBody>
                <h4>
                  For <strong> annual </strong> subscription:{" "}
                  <strong> ${trunk(proration.year)} </strong> ( $
                  {trunk(proPrice.year)} - $
                  {trunk(proPrice.year) - trunk(proration.year)})
                </h4>
                <p>
                  {" "}
                  As you are already subscribed in one of our paid plans, the
                  price you have already paid{" "}
                  <strong>
                    {" "}
                    will be deducted from the cost of the total annual plan cost
                  </strong>
                  .
                </p>
                <p>
                  Next payment due in{" "}
                  <strong>
                    {" "}
                    {dayjs(proration.renewal_year).format("DD MMM, YYYY")}{" "}
                  </strong>
                </p>
              </InfoCardBody>
            </InfoCard>
          </ContentBox>
          <ActionButtonBox>
            <span onClick={() => fbTrack("InitiateCheckout")}>
              <StyledLink
                to={{
                  pathname: "/upgrade",
                  state: {
                    plan: "pro",
                  },
                }}
              >
                Proceed to checkout
              </StyledLink>
            </span>
            <Button appearance="secondary" onClick={() => closeModal()}>
              Go Back
            </Button>
          </ActionButtonBox>
        </React.Fragment>
      )}
    </FullPageModal>
  );
};

export default ConfirmPaymentModal;
