import React from "react";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import styled, { createGlobalStyle } from "styled-components";
import { MagGlass, ChevronDown } from "zoozoo";

const GlobalStyle = createGlobalStyle`
	.MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0) !important;
      }

      .MuiPaper-elevation16 {   
        box-shadow: none !important;
      }

      .MuiDrawer-paperAnchorBottom {
          height: 64px;
      }
`;

const ClarityFooter = styled.footer`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  max-width: 1920px;
  padding: 16px 40px;
  border-bottom: 1px solid #e0e0e0;
  cursor: pointer;
`;

const ClarityBarTag = styled.div`
  display: flex;
  align-items: center;

  h4 {
    font-weight: normal;
    font-size: 14px;
    margin: 0 0 0 16px;
    color: #212121;
  }
`;

const ClarityBarIcon = styled.div`
  justify-self: end;

  svg {
    cursor: pointer;
  }
`;

export default function SwipeableTemporaryDrawer({
  clarityDrawer,
  closeDrawer,
  openDrawer,
  children,
}) {
  return (
    <div>
      <React.Fragment>
        <GlobalStyle />
        <SwipeableDrawer
          anchor={"bottom"}
          open={clarityDrawer}
          onClose={() => closeDrawer()}
          onOpen={() => openDrawer()}
        >
          <ClarityFooter onClick={() => closeDrawer()}>
            <ClarityBarTag>
              <MagGlass stroke="#3E21DE" width={26} height={26} />
              <h4>Clarity Range Recommendation</h4>
            </ClarityBarTag>

            <ClarityBarIcon>
              <ChevronDown width={30} height={30} />
            </ClarityBarIcon>
          </ClarityFooter>
          {children}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}
