import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";
import { Warning } from "zoozoo";
import dayjs from "dayjs";

const BREAKPOINT = "1300px";

const Card = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr 1.2fr;
  height: 100%;
  position: relative;

  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  padding: 48px;
  max-width: 1000px;
  border: 4px solid ${(props) => (props.active ? "#3e21de" : "transparent")};
  border-radius: 10px;

  @media (max-width: ${BREAKPOINT}) {
    padding: 32px;
  }

  .active {
    border: 2px solid #3e21de;
  }
`;

const FreeCardBody = styled.div`
  display: grid;
  align-self: center;
  grid-template-rows: repeat(4, 1fr);
  grid-auto-flow: column;
  grid-gap: 16px;
  grid-auto-rows: max-content;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(1, 1fr);
  }

  & > div {
    display: grid;
    grid-template-columns: 25px 1fr;

    span {
      margin-left: 6px;
      font-size: 14px;
      color: #212121;
      white-space: nowrap;
    }
  }
`;

const EachOption = styled.div`
  svg {
    stroke: ${(props) => (props.disabled ? "#C9C9C9" : "#3E21DE")};
  }

  span {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      margin-right: 4px;
    }

    strong {
      margin-right: 4px;
    }
  }
`;

const CardImage = styled.div`
  display: flex;

  img {
    width: 66%;
    min-width: 120px;
  }
`;

const CardMiddle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 32px 0 0;
  width: 300px;

  @media (max-width: ${BREAKPOINT}) {
    width: auto;
  }

  h2::first-letter {
    text-transform: uppercase;
  }

  h2 {
    font-weight: 500;
    font-size: 34px;
    color: #212121;
    margin: 0 0 8px 0;
  }

  h3 {
    font-weight: normal;
    font-size: 20px;
    color: #7864e8;
    margin: 0 0 8px 0;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
  }

  button {
    max-width: 160px;
    padding: 0;
  }
`;

const DowngradeDate = styled.div`
  display: flex;
  align-items: center;
  background: #f1effd;
  border-radius: 50px;
  padding: 0 12px;
  justify-content: space-evenly;
  margin-bottom: 16px;

  p {
    color: ${(props) => props.theme.colors.black};
    margin-left: 8px;
    font-size: 14px;

    span {
      font-weight: bold;
    }
  }
`;

const SubCard = ({ subscription, plan, active, buttonStates, modalStates }) => {
  const { tier, downgrade_to } = subscription;

  return (
    <Card active={active}>
      <CardImage>
        <img src={plan.image} alt="img" />
      </CardImage>

      <CardMiddle>
        <div>
          <h2>{plan.title}</h2>
          <h3>{plan.price} </h3>
          <p>{plan.description}</p>
          {tier === plan.title && downgrade_to !== "" && (
            <DowngradeDate>
              <Warning width={28} height={28} stroke="#3E21DE" />
              <p>
                Downgrading in{" "}
                <span>
                  {" "}
                  {dayjs(subscription.downgrade_at).format("DD MMM, YYYY")}
                </span>
              </p>
            </DowngradeDate>
          )}
        </div>
        {buttonStates}
      </CardMiddle>

      <FreeCardBody>
        {plan.options.map((f, i) => {
          return (
            <EachOption key={i} disabled={f.disabled}>
              {f.icon}
              <Tooltip title={f.tooltip} placement="top" arrow>
                {f.option}
              </Tooltip>
            </EachOption>
          );
        })}
      </FreeCardBody>
      {modalStates}
    </Card>
  );
};
export default SubCard;
