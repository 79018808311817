import React from "react";
import styled from "styled-components";
import Global from "./Global";
import SvgApple from "../icons/brands/SvgApple";
import SvgMailchimp from "../icons/brands/SvgMailchimp";
import SvgSpotify from "../icons/brands/SvgSpotify";
import SvgMonday from "../icons/brands/SvgMonday";
import SvgAsos from "../icons/brands/SvgAsos";
import SvgMicrosoft from "../icons/brands/SvgMicrosoft";
import SvgVerizon from "../icons/brands/SvgVerizon";
import SvgAirbnb from "../icons/brands/SvgAirbnb";
import SvgLogo from "../icons/SvgLogo";
import SvgBrand from "../icons/SvgBrand";
import appsumoLogo from "../../assets/appsumo-logo.svg";
import NeuronsBanner from "../ui/banners/NeuronsBanners";

const SocialProofGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.8fr;
  height: 100vh;
`;

const SocialProofColumn = styled.div`
  padding: 9vh 10vh 24px 10vw;
  height: 100%;

  & > div:first-child {
    display: flex;
    align-items: center;
  }
`;

const SocialProof = styled.div`
  padding: 25vh 24px 24px 24px;
  height: 100%;
  background: #f5f5f5;
  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
    margin: 0;
    font-weight: bold;
    font-size: 20px;
    color: #616161;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
    text-align: center;
  }
`;

const Companies = styled.div`
  max-width: 70%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 60px;

  @media (max-width: 1220px) {
    grid-template-columns: repeat(1, 1fr);
    margin-top: 0;
  }

  a {
    padding: 20px;
    border: 1px solid #ddd;
    margin: 20px;
    display: inline-block;
  }

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 70px;
    width: 200px;
    min-height: 60px;

    position: relative;

    svg {
      position: absolute;
      width: 100%;
      height: 50%;
    }
  }
`;

const brands = [
  {
    brand: <SvgApple />,
  },
  {
    brand: <SvgAirbnb />,
  },
  {
    brand: <SvgSpotify />,
  },
  {
    brand: <SvgAsos />,
  },
  {
    brand: <SvgMailchimp />,
  },
  {
    brand: <SvgVerizon />,
  },
  {
    brand: <SvgMonday />,
  },
  {
    brand: <SvgMicrosoft />,
  },
];

const SocialProofLayout = ({ children, isAppsumo = false }) => {
  return (
    <SocialProofGrid>
      <Global />
      <NeuronsBanner isFixed />
      <SocialProofColumn>
        <div>
          <SvgLogo width={48} height={48} />
          <SvgBrand fill="#1D0F68" width={110} height={28} />

          {isAppsumo && <div style={{ marginLeft: "1rem" }}>x</div>}
          {isAppsumo && (
            <img
              src={appsumoLogo}
              height="28px"
              style={{ marginLeft: "1rem" }}
            />
          )}
        </div>

        {children}
      </SocialProofColumn>
      <SocialProof>
        <h2>Join thousands of top notch professionals</h2>
        <p>from industry-leading teams across the world</p>
        <Companies>
          {brands.map((brand, i) => (
            <div key={i}>{brand.brand}</div>
          ))}
        </Companies>
      </SocialProof>
    </SocialProofGrid>
  );
};

export default SocialProofLayout;
