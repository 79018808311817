import React from "react";
import predict from "../assets/illustrations/predict-opening.svg";
import project from "../assets/illustrations/project-opening.svg";
import singleProject from "../assets/illustrations/empty-single-project.svg";

// titles of prediction types
export const PREDICTION_TITLES = {
  ORIGINAL: "Original Image",
  ATTENTION: "Attention Map",
  CLARITY: "Clarity",
  AOI: "Areas of Interest (AOI)",
  CLARITY_SCORE: "Clarity Score",
};

export const NUTSHELL_CONTENT = {
  ATTENTION:
    "Attention Maps help you understand which parts of your frame are the most engaging. Basically, it forecasts where your users will look into your design.",
  CLARITY:
    "Clarity predicts how clear and aesthetically pleasing your design is. A lower score means that your design is cluttered.",
  AOI:
    "AOI enable the quantification of attention in relation to the design frame as a whole. Percentage (%) values higher than zero (0) mean that the defined area is more prominent than average.",
};

//
// Content for welcome component
//
export const WELCOME_CONTENT = {
  FIRST_PARAGRAPH:
    "Get insightful predictions of your work and elevate your design process. You can also create a project folder to keep your files organized.",
  SECOND_PARAGRAPH:
    "Download the plugin for your favorite design tool and start getting awesome feedback on your work.",
};

//
// content for cards in subscription page
//
export const cardSubtitles = {
  PRO_SUBTITLE: "For sophisticated teams and professional designers.",
  FREE_SUBTITLE:
    "For hobbyists, early startups or rising professional freelancers.",
};

//
// hover plan features in subscription page
//
export const tooltipMessage = {
  ATTENTION_MAP:
    "Classic heatmap that highlights the attention grabbing areas.",
  CREDITS: "1 Credit equivalent to 1 image(prediction).",
  CLARITY_SCORE:
    "Benchmark how clear your design is by generating a score from  1 to 100.",
  SUPPORT: "Free to use any design dimensions you want.",
  CLARITY_MAPS:
    "Visual feedback on areas that influence positive and negative the design clarity score.",
  TRAINING:
    "Hands-on presentation on how to use VisualEyes and predictive design in general.",
};

//
// delete modal content
//
export const DELETE_FULLSCREEN_CONTENT = {
  PREDICT_CONTENT:
    "The prediction you are trying to delete will be lost and unretrievable forever.",
  PROJECT_CONTENT:
    "The project you are going to delete will be lost forever. Fortunately, its predictions will remain intact and accessible.",
};

//
// openings in dashboard
//
export const PREDICTION_OPENING_CONTENT = {
  HEADER_TITLE: "Make your first prediction",
  HEADER_PARAGRAPH:
    "Keep your files organized. Manage your work seamlessly and deliver better reports.",
  BUTTON: "Make Prediction",
  LINK: "/predict/create/device",
  SRC: predict,
};

export const PROJECT_OPENING_CONTENT = {
  HEADER_TITLE: " Create your first project",
  HEADER_PARAGRAPH:
    "Keep your files organized. Manage your work seamlessly and deliver better reports.",
  BUTTON: "Create Project",
  LINK: "/create/projects",
  SRC: project,
};

export const SECOND_PREDICTION_OPENING_CONTENT = {
  HEADER_TITLE: "Your projects are empty....",
  HEADER_PARAGRAPH: "Create your first prediction.",
  BUTTON: "Make Prediction",
  LINK: "/predict/create/device",
  SRC: predict,
};

//
// Empty history
//
export const EMPTY_HISTORY_PROJECT = {
  HEADER_TITLE: "It seems like you don’t have any projects.",
  HEADER_PARAGRAPH:
    "Keep your predictions organized. Manage your work seamlessly and deliver better reports.",
  BUTTON: "Create new Project",
  LINK: "/create/projects",
  SRC: project,
};

export const EMPTY_HISTORY_SINGLE_PROJECT = {
  HEADER_TITLE: "You have no predictions yet.",
  HEADER_PARAGRAPH:
    "Keep your predictions organized. Manage your work seamlessly and deliver better reports.",
  BUTTON: "Add Existing Predictions ",
  LINK: "/add/predictions",
  SRC: singleProject,
};

export const EMPTY_HISTORY_PREDICT = {
  HEADER_TITLE: "You have no predictions yet.",
  HEADER_PARAGRAPH:
    "Keep your predictions organized. Manage your work seamlessly and deliver better reports.",
  BUTTON: "Add new Prediction",
  LINK: "/predict/create/device",
  SRC: singleProject,
};

//
// reporting content
//
export const REPORT_CONTENT = {
  TEXTAREA_PLACEHOLDER:
    "(Optional) - This message will be displayed in the report. You can use it as a description or even as a welcome message for your recipients",
  ATTENTION_MAP:
    "Understand which parts of your screen are the most engaging. Basically, it forecasts where your users will look into your design.",
  CLARITY_SCORE:
    "Clarity predicts how clear and aesthetically pleasing is your design. A lower score means that your design is cluttered.",
  AOI_CONTENT:
    " The percentage value of the AOI shows you how visible an area is compared to the design's average: key elements should receive higher scores.",
};

//
// aoi metric type tooltips
//
export const AOI_METRIC_TOOLTIP = {
  ABSOLUTE: (
    <span>
      Attention concentration of the defined area, <br /> compared to the whole
      image.
    </span>
  ),
  RELATIVE: (
    <span>
      How many times more noticeable <br />
      your defined area is, compared to the rest of the image.
    </span>
  ),
};
