import React from "react";
import styled from "styled-components";
import ProjectOptions from "../ui/cards/screens/ProjectOptions";
import { Link } from "react-router-dom";
import ProjectIcon from "../icons/ProjectIcon";

const CardContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  border-radius: ${(props) => props.theme.radius.soft};
  min-height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  box-sizing: border-box;
  transition: all 0.3s;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.35);
    transition: all 0.3s;
  }

  & > div {
    h2 {
      margin: 0;
      font-size: ${(props) => props.theme.fontSize[14]};
      color: ${(props) => props.theme.colors.black};
      font-weight: normal;
      text-align: left;
    }

    span {
      color: ${(props) => props.theme.neutral[4]};
      font-size: ${(props) => props.theme.fontSize[10]};
    }
  }

  & > div:nth-child(2) {
    width: 100%;
    margin: 0 22px;
  }
`;

const ProjectCard = ({ info }) => {
  return (
    <CardContainer>
      <div>
        <ProjectIcon width={26} height={26} />
      </div>
      <div>
        <h2>{info.name}</h2>
        <span>Created on {info.created_at}</span>
      </div>
      <div></div>
    </CardContainer>
  );
};

export default ProjectCard;
