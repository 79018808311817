import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styled from "styled-components";
import { Link } from "react-router-dom";
import arrow from "../../../../../assets/arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { UserNew } from "desisystem";
import { logout, userSelector } from "../../../../../redux/slices/user";
import { Terms, SignOut, Learn, SubsIcon } from "zoozoo";
import { AiOutlineTeam } from "react-icons/ai";
import { gaLearnOptionInDropdown } from "../../../../../utils/analytics";
import { subscriptionSelector } from "../../../../../redux/slices/subs";

const AvatarContainer = styled.div`
  height: 100%;
  margin-left: 2rem;

  @media (max-width: 1090px) {
    margin-left: 0;
  }
`;

const StyledMenu = (props) => (
  <Menu
    elevation={20}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    style={{ marginTop: "1rem" }}
    {...props}
  />
);

const StyledDropdown = styled(StyledMenu)`
  && {
    border-radius: 1rem !important;
  }
  ul {
    padding: 0.75rem 0;
  }
`;

const StyledMenuItem = withStyles(() => ({
  root: {
    backgroundColor: "#fff",
    width: "180px",
    color: "#1D0F68",
    fontStyle: "normal",
    fontSize: "14px",
    padding: "0",
    "&:hover": {
      backgroundColor: "#F1EFFD",
    },
  },
}))(MenuItem);

const IconBox = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  cursor: pointer;

  & img:first-child {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    object-fit: cover;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: unset;
  width: 100%;
  padding: 0.75rem 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    margin-right: 1rem;
  }
`;

const OutsideStyledLink = styled.a`
  text-decoration: none;
  color: unset;
  width: 100%;
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    margin-right: 1rem;
  }
`;

let primaryLight = "#F1EFFD";
let primaryDark = "#1D0F68";

const CustomizedMenus = ({ avatar }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);
  const { subs: { tier } = {} } = useSelector(subscriptionSelector);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <AvatarContainer>
      <IconBox onClick={handleClick}>
        <img
          src={avatar}
          alt="img"
          style={{ border: `4px solid ${primaryLight}` }}
        />
        <img src={arrow} alt="img" />
      </IconBox>

      <StyledDropdown
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>
          <StyledLink to="/profile" style={{ color: primaryDark }}>
            <UserNew stroke={primaryDark} /> My Profile
          </StyledLink>
        </StyledMenuItem>

        {tier === "pro" && !user.is_team_member && (
          <StyledMenuItem>
            <StyledLink to="/team" style={{ color: primaryDark }}>
              <AiOutlineTeam color="rgb(29, 15, 104)" size={16} />
              Team
            </StyledLink>
          </StyledMenuItem>
        )}

        {!user.is_team_member && (
          <StyledMenuItem>
            <StyledLink to="/subscription" style={{ color: primaryDark }}>
              <SubsIcon stroke={primaryDark} /> Subscription
            </StyledLink>
          </StyledMenuItem>
        )}

        <StyledMenuItem>
          <OutsideStyledLink
            target="_blank"
            href="https://www.notion.so/VisualEyes-Cheatsheet-9c30a263a02b4402ac841396b03bd382"
            onClick={() => gaLearnOptionInDropdown()}
          >
            <Learn fill={primaryDark} /> Learn
          </OutsideStyledLink>
        </StyledMenuItem>

        <StyledMenuItem>
          <OutsideStyledLink
            target="_blank"
            href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Terms-of-Services-Provision.pdf"
          >
            <Terms fill={primaryDark} /> Terms of Use
          </OutsideStyledLink>
        </StyledMenuItem>

        <div
          style={{
            width: "calc(100% - 7rem)",
            maxHeight: "1px",
            minHeight: "1px",
            margin: "0.75rem 3.5rem",
            backgroundColor: primaryLight,
          }}
        />
        <StyledMenuItem>
          <StyledLink
            to="/"
            onClick={handleLogout}
            style={{ color: primaryDark + "88" }}
          >
            <SignOut fill={primaryDark + "88"} /> Logout
          </StyledLink>
        </StyledMenuItem>
      </StyledDropdown>
    </AvatarContainer>
  );
};

export default CustomizedMenus;
