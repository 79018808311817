import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Desktop, Mobile } from "zoozoo";

const CardContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  border-radius: ${(props) => props.theme.radius.soft};
  overflow: hidden;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.12);
    transition: all 0.3s;
  }
`;

const UpperCard = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  min-height: 158px;
  box-sizing: border-box;
  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }

  @media (min-width: 1600px) {
    min-height: 214px;
  }
`;

const BottomCard = styled.div`
  padding: 24px 16px 24px 16px;
  max-height: 82px;
  box-sizing: border-box;
  background: #fff;

  p {
    color: ${(props) => props.theme.neutral[4]};
    font-size: ${(props) => props.theme.fontSize[12]};
    margin: 0;
    display: flex;

    a {
      font-size: ${(props) => props.theme.fontSize[12]};
      color: ${(props) => props.theme.primary[5]};
      margin-right: 6px;
    }
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    width: 88%;
  }

  h2 {
    margin: 0 0 6px 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;

    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize[12]};
  color: ${(props) => props.theme.primary[5]};
  padding-right: 6px;
  margin-right: 6px;
  border-right: 1px solid #e0e0e0;

  span {
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const DeviceIcon = styled.span`
  padding: 0 10px 0 0;
  display: inline-flex;
  align-items: center;
`;

// let testLink = "reporting/ec0b6c6e-4b14-44e9-bb6d-4605127bebdc";

const Card = ({ report }) => {
  const { name, dimensions, report_url, model, thumb_serve_path } = report;
  return (
    <CardContainer>
      <Link
        to={{
          pathname: `/${report_url}`,
          state: {
            belongsToProject: true,
          },
        }}
      >
        <UpperCard url={thumb_serve_path}></UpperCard>
      </Link>

      <BottomCard>
        <CardTitle>
          <Link
            to={{
              pathname: `/${report_url}`,
              state: {
                belongsToProject: true,
              },
            }}
          >
            <h2>{name}</h2>
          </Link>
        </CardTitle>
        <p>
          <DeviceIcon>
            {model === "desktop" ? (
              <Desktop stroke="#212121" />
            ) : (
              <Mobile stroke="#212121" />
            )}
          </DeviceIcon>
          {dimensions}
        </p>
      </BottomCard>
    </CardContainer>
  );
};

export default Card;
