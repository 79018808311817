import React from "react";
import Modal from "react-awesome-modal";
import { Body, H2, Button, Input } from "desisystem";
import styled from "styled-components";
import Select from "react-select";
import "./store.css";

const ModalBody = styled.div`
  padding: 40px;
  border-radius: 10px;
  min-width: 500px;
  max-width: 500px;
  box-sizing: border-box;
  text-align: center;

  h2 {
    font-size: 22px;
    margin: 0 0 24px 0;
    font-weight: bold;
    color: #212121;

    p {
      font-size: 22px;
      color: #3e22de !important;
      font-weight: bold;
    }
    p::first-letter {
      text-transform: uppercase;
    }
  }
`;

const ModalButtons = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  background: #3e21de;

  p {
    color: #fff;
    font-size: 16px;
    padding: 0 0 0 26px;
    cursor: pointer;
    font-weight: bold;
    margin: 0 !important;
  }
`;

const BillingInfoModal = ({ open, close, setOptionalFields }) => {
  const [billingName, setBillingName] = React.useState("");
  const [billingEmail, setBillingEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");
  const [taxId, setTaxId] = React.useState("");
  const [vatType, setVatType] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");

  const formRef = React.useRef(null);
  const form = formRef.current;

  React.useEffect(() => {
    if (form) {
      console.log(form);
      form
        .querySelector("select[name=country]")
        .addEventListener("change", (event) => {
          event.preventDefault();
          selectCountry(event.target.value);
        });

      selectCountry("US");
    }
  }, [form]);

  const selectCountry = (country) => {
    const selector = document.getElementById("country");
    selector.querySelector(`option[value=${country}]`).selected = "selected";
    selector.className = `field ${country.toLowerCase()}`;

    // Trigger the methods to show relevant fields and payment methods on page load.
    showRelevantFormFields();
    // showRelevantPaymentMethods();
  };

  // Show only form fields that are relevant to the selected country.
  const showRelevantFormFields = (country) => {
    if (!country) {
      country = form.querySelector("select[name=country] option:checked").value;
      const countryFullName = form.querySelector(
        "select[name=country] option:checked"
      ).innerText;

      setCountry(country);
      console.log(countries2VatType[countryFullName]);
      if (
        countries2VatType[countryFullName].taxType &&
        countries2VatType[countryFullName].placeholder
      ) {
        // Update the Tax type value based on country
        setVatType(countries2VatType[countryFullName].taxType);

        // Update the 'Tax ID' placeholder based on country
        const taxIdInput = form.querySelector("label.taxId input");
        taxIdInput.placeholder = countries2VatType[countryFullName].placeholder;
      }
    }
    const zipLabel = form.querySelector("label.zip");
    // Only show the state input for the United States.
    zipLabel.parentElement.classList.toggle(
      "with-state",
      ["AU", "US"].includes(country)
    );
    // Update the ZIP label to make it more relevant for each country.
    const zipInput = form.querySelector("label.zip input");
    const zipSpan = form.querySelector("label.zip span");
    switch (country) {
      case "US":
        zipSpan.innerText = "ZIP";
        zipInput.placeholder = "94103";
        break;
      case "GB":
        zipSpan.innerText = "Postcode";
        zipInput.placeholder = "EC1V 9NR";
        break;
      case "AU":
        zipSpan.innerText = "Postcode";
        zipInput.placeholder = "3000";
        break;
      default:
        zipSpan.innerText = "Postal Code";
        zipInput.placeholder = "94103";
        break;
    }

    // Update the 'City' to appropriate name
    const cityInput = form.querySelector("label.city input");
    const citySpan = form.querySelector("label.city span");
    switch (country) {
      case "AU":
        citySpan.innerText = "City / Suburb";
        cityInput.placeholder = "Melbourne";
        break;
      default:
        citySpan.innerText = "City";
        cityInput.placeholder = "San Francisco";
        break;
    }
  };

  function submit(e) {
    e.preventDefault();

    setOptionalFields({
      billingName,
      billingEmail,
      address,
      taxId,
      vatType,
      city,
      country,
      postalCode,
    });
    close();
  }

  return (
    <Modal visible={open} effect="fadeInUp" onClickAway={() => close()}>
      <ModalBody>
        <H2>Set Your Billing Details</H2>
        <Body>
          Please, fill-in the fields you want us to use for your invoices. All
          the fields are optional.
        </Body>
        <form ref={formRef} id="payment-form" onSubmit={submit}>
          <fieldset class="with-state">
            <label>
              <span>Billing Name</span>
              <input
                name="name"
                class="field"
                placeholder="Jenny Rosen"
                required
                onChange={(e) => setBillingName(e.target.value)}
              />
            </label>
            <label>
              <span>Billing Email</span>
              <input
                name="email"
                type="email"
                class="field"
                placeholder="jenny@example.com"
                required
                onChange={(e) => setBillingEmail(e.target.value)}
              />
            </label>
            <label class="taxId">
              <span>Tax ID</span>
              <input
                name="taxId"
                class="field"
                placeholder="12345"
                required
                onChange={(e) => setTaxId(e.target.value)}
              />
            </label>
            <label>
              <span>Address</span>
              <input
                name="address"
                class="field"
                placeholder="185 Berry Street Suite 550"
                onChange={(e) => setAddress(e.target.value)}
              />
            </label>
            <label class="city">
              <span>City</span>
              <input
                name="city"
                class="field"
                placeholder="San Francisco"
                onChange={(e) => setCity(e.target.value)}
              />
            </label>
            <label class="state">
              <span>State</span>
              <input
                name="state"
                class="field"
                placeholder="CA"
                onChange={(e) => setState(e.target.value)}
              />
            </label>
            <label class="zip">
              <span>ZIP</span>
              <input
                name="postal_code"
                class="field"
                placeholder="94107"
                onChange={(e) => setPostalCode(e.target.value)}
              />
            </label>
            <label class="select">
              <span>Country</span>
              <div id="country" class="field US">
                <select
                  name="country"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="BE">Belgium</option>
                  <option value="BR">Brazil</option>
                  <option value="CA">Canada</option>
                  <option value="CN">China</option>
                  <option value="DK">Denmark</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="DE">Germany</option>
                  <option value="HK">Hong Kong</option>
                  <option value="IN">India</option>
                  <option value="IE">Ireland</option>
                  <option value="IT">Italy</option>
                  <option value="JP">Japan</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MY">Malaysia</option>
                  <option value="MX">Mexico</option>
                  <option value="NL">Netherlands</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NO">Norway</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="SG">Singapore</option>
                  <option value="ES">Spain</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US" selected="selected">
                    United States
                  </option>
                </select>
              </div>
            </label>
          </fieldset>
          {/* <p class="tip">
            Select another country to see different payment options.
          </p> */}
        </form>

        <Button width="100" style={{ marginTop: "16px" }} onClick={submit}>
          Save
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default BillingInfoModal;

const countries2VatType = {
  "United Arab Emirates": { taxType: "ae_trn", placeholder: "123456789012345" },
  Australia: { taxType: "au_abn", placeholder: "12345678912" },
  Brazil: { taxType: "br_cnpj", placeholder: "01.234.456/5432-10" },
  Brazil: { taxType: "br_cpf", placeholder: "123.456.789-87" },
  Canada: { taxType: "ca_bn", placeholder: "123456789" },
  Canada: { taxType: "ca_qst", placeholder: "1234567890TQ1234" },
  Switzerland: { taxType: "ch_vat", placeholder: "CHE-123.456.789 MWST" },
  Chile: { taxType: "cl_tin", placeholder: "12.345.678-K" },
  Spain: { taxType: "es_cif", placeholder: "A12345678" },
  Austria: { taxType: "eu_vat", placeholder: "ATU12345678" },
  Belgium: { taxType: "eu_vat", placeholder: "BE0123456789" },
  Bulgaria: { taxType: "eu_vat", placeholder: "BG0123456789" },
  Cyprus: { taxType: "eu_vat", placeholder: "CY12345678Z" },
  "Czech Republic": { taxType: "eu_vat", placeholder: "CZ1234567890" },
  Germany: { taxType: "eu_vat", placeholder: "DE123456789" },
  Denmark: { taxType: "eu_vat", placeholder: "DK12345678" },
  Estonia: { taxType: "eu_vat", placeholder: "EE123456789" },
  Spain: { taxType: "eu_vat", placeholder: "ESA1234567Z" },
  Finland: { taxType: "eu_vat", placeholder: "FI12345678" },
  France: { taxType: "eu_vat", placeholder: "FRAB123456789" },
  "United Kingdom": { taxType: "eu_vat", placeholder: "GB123456789" },
  Greece: { taxType: "eu_vat", placeholder: "EL123456789" },
  Croatia: { taxType: "eu_vat", placeholder: "HR12345678912" },
  Hungary: { taxType: "eu_vat", placeholder: "HU12345678912" },
  India: { taxType: "in_gst", placeholder: "12ABCDE3456FGZH" },
  Ireland: { taxType: "eu_vat", placeholder: "IE1234567AB" },
  Italy: { taxType: "eu_vat", placeholder: "IT12345678912" },
  Lithuania: { taxType: "eu_vat", placeholder: "LT123456789123" },
  Luxembourg: { taxType: "eu_vat", placeholder: "LU12345678" },
  Latvia: { taxType: "eu_vat", placeholder: "LV12345678912" },
  Malta: { taxType: "eu_vat", placeholder: "MT12345678" },
  Netherlands: { taxType: "eu_vat", placeholder: "NL123456789B12" },
  Poland: { taxType: "eu_vat", placeholder: "PL1234567890" },
  Portugal: { taxType: "eu_vat", placeholder: "PT123456789" },
  Romania: { taxType: "eu_vat", placeholder: "RO1234567891" },
  Sweden: { taxType: "eu_vat", placeholder: "SE123456789123" },
  Slovenia: { taxType: "eu_vat", placeholder: "SI12345678" },
  Slovakia: { taxType: "eu_vat", placeholder: "SK1234567891" },
  "Hong Kong": { taxType: "hk_br", placeholder: "2345678" },
  Indonesia: { taxType: "id_npwp", placeholder: "12.345.678.9-012.345" },
  India: { taxType: "in_gst", placeholder: "12ABCDE3456FGZH" },
  Japan: { taxType: "jp_cn", placeholder: "1234567891234" },
  "Korea, Republic of": { taxType: "kr_brn", placeholder: "123-45-67890" },
  Liechtenstein: { taxType: "li_uid", placeholder: "CHE123456789" },
  Mexico: { taxType: "mx_rfc", placeholder: "ABC010203AB9" },
  Malaysia: { taxType: "my_frp", placeholder: "12345678" },
  Malaysia: { taxType: "my_itn", placeholder: "1234567890" },
  Malaysia: { taxType: "my_sst", placeholder: "A12-3456-78912345" },
  Norway: { taxType: "no_vat", placeholder: "123456789MVA" },
  "New Zealand": { taxType: "nz_gst", placeholder: "123456789" },
  "Russian Federation": { taxType: "ru_inn", placeholder: "1234567891" },
  "Saudi Arabia": { taxType: "sa_vat", placeholder: "123456789012345" },
  Singapore: { taxType: "sg_gst", placeholder: "M12345678X" },
  Singapore: { taxType: "sg_uen", placeholder: "123456789F" },
  Thailand: { taxType: "th_vat", placeholder: "1234567891234" },
  Taiwan: { taxType: "tw_vat", placeholder: "12345678" },
  "United States": { taxType: "us_ein", placeholder: "12-3456789" },
  "South Africa": { taxType: "za_vat", placeholder: "4123456789" },
};
