import React, { useEffect } from "react";
import styled from "styled-components";
import CheckboxGroup from "react-checkbox-group";
import { Check, AddNew } from "zoozoo";
import { Trunk } from "../../../../layouts/Global";
import dayjs from "dayjs";

const SearchPredictsGrid = styled.div`
  max-width: 360px;
  margin: 40px auto 0 auto;
`;

const EachPredict = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 16px;
  position: relative;
  min-height: 48px;

  label > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const EachPredictBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;

  & > div:first-child {
    display: flex;
    align-items: center;
    width: 25px;
    justify-content: center;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 90%;

    & > div {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      h3 {
        font-weight: normal;
        font-size: 12px;
        color: #212121;
        margin: 0 0 6px 0;
        ${Trunk}
      }
      span {
        font-weight: normal;
        font-size: 12px;
        color: #bdbdbd;
      }
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
  }
`;

const SelectedPredicts = styled.div`
  margin: 40px 0;
  border-bottom: 2px solid #eeeeee;

  h4 {
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    color: #212121;
    padding-bottom: 8px;
  }
`;
const CustomCheckBox = styled.div`
  background: ${(props) => props.checked && "#3E21DE"};
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #3e21de;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SelectedResults = ({ predictList, setList, allPredictions }) => {
  useEffect(() => {
    // window.scrollTo(2000, 1000);
    window.scrollTo({ top: 2000, behavior: "smooth" });
  }, [setList]);

  return (
    <SearchPredictsGrid>
      {predictList.length > 0 && (
        <SelectedPredicts>
          <h4>Selected:</h4>
          {predictList.map((l, i) => {
            return (
              <div key={i}>
                {allPredictions.map((p, i) =>
                  p.id === l ? (
                    <CheckboxGroup
                      name="predictList"
                      value={predictList}
                      onChange={setList}
                      key={i}
                    >
                      {(Checkbox) => (
                        <EachPredict style={{ marginTop: "16px" }} key={i}>
                          <EachPredictBody>
                            <div>
                              <AddNew width={36} height={36} />
                            </div>
                            <div>
                              <div>
                                <h3>{p.name}</h3>
                                <span>
                                  Created on{" "}
                                  {dayjs(p.created_at).format("DD MMMM YYYY")}
                                </span>
                              </div>
                            </div>
                            <div>
                              <CustomCheckBox
                                checked={predictList.includes(p.id)}
                              >
                                <Check stroke="#fff" />
                              </CustomCheckBox>
                            </div>
                          </EachPredictBody>
                          <label>
                            <Checkbox value={p.id} />
                          </label>
                        </EachPredict>
                      )}
                    </CheckboxGroup>
                  ) : (
                    <div key={i}></div>
                  )
                )}
              </div>
            );
          })}
        </SelectedPredicts>
      )}
    </SearchPredictsGrid>
  );
};

export default SelectedResults;
