import React from "react";
import styled from "styled-components";
import { H1 } from "desisystem";
import { ArrowLeft } from "zoozoo";
import { Link } from "react-router-dom";

import SvgSketch from "../../../icons/plugin-logos/SvgSketch";
import SvgFigma from "../../../icons/plugin-logos/SvgFigma";
import SvgAdobe from "../../../icons/plugin-logos/SvgAdobe";
import SvgChrome from "../../../icons/plugin-logos/SvgChrome";
import SvgSlack from "../../../icons/brands/SvgSlack";
import Play from "../../../icons/Play";

import { gaRetakeTour, gaDownloadPlugin } from "../../../../utils/analytics";

const WelcomeContainer = styled.div`
  max-width: 400px;
  height: ${(props) =>
    props.isVerified ? "calc(100vh - 80px)" : "calc(100vh - 80px)"};
  position: sticky;
  top: 80px;
  background: #fff;
  display: flex;
  flex-direction: column;
  min-width: 300px;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    width: calc(50vw);
    left: -50vw;
  }

  @media (max-width: 991px) {
    position: static;
    height: auto;

    &::before {
      display: none;
    }
  }

  & > div {
    padding: 6rem 32px 24px 48px;
  }

  ${(props) =>
    props.isVerified &&
    `
& > div {
    padding: 48px 32px 24px 48px;
}

`}

  h1 {
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSize[26]};
    margin-bottom: 0;

    p {
      font-weight: 500;
      color: ${(props) => props.theme.colors.brandBlue};
      font-size: ${(props) => props.theme.fontSize[26]};
      display: inline-block;
      margin: 0 0 0 6px;
    }
  }

  p::first-letter {
    text-transform: uppercase;
  }

  span {
    display: inline-block;
    margin-left: 6px;
  }
`;

const Plugins = styled.div`
  margin-top: 48px;
`;

const Plugin = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
  font-size: ${(props) => props.theme.fontSize[14]};

  span {
    color: #c1c1c1;
  }
`;

const PluginIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: ${(props) => props.theme.shadows.darkShadow};
  border-radius: ${(props) => props.theme.radius.round};
  width: 32px;
  height: 32px;
  margin-right: 24px;
`;

const StyledLink = styled.a`
  color: ${(props) => props.theme.colors.black};
  text-decoration: none;
  display: flex;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.colors.brandBlue};
  }
`;

const RetakeLink = styled(Link)`
  border-radius: ${(props) => props.theme.radius.round};
  border: 2px solid ${(props) => props.theme.colors.brandBlue};
  padding: 2px 16px;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: ${(props) => props.theme.colors.brandBlue};
  transition: all 0.3s;
  display: inline-flex;
  align-items: center;
  margin-top: 40px;

  span {
    display: inline-block;
    font-weight: 500;
    margin-left: 8px;
    ${"" /* margin-left: 24px; */}
  }

  &:hover {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.brandBlue};
    transition: all 0.3s;

    svg {
      stroke: #fff;
      transition: all 0.3s;
    }
  }
`;

const New = styled.div`
  background: #3e21de;
  border-radius: 2px;
  color: #fff;
  padding: 4px;
  font-size: 10px;
  font-weight: bold;
  margin-left: 4px;
`;

const CommunityLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid ${(props) => props.theme.colors.brandPurple};
  border-radius: 4px;
  padding: 10px;
  transition: all 0.4s;

  p {
    margin: 0;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.brandPurple};
  }

  p + svg {
    fill: ${(props) => props.theme.colors.brandPurple};
    margin-left: 4px;
  }

  svg:last-child {
    stroke: ${(props) => props.theme.colors.brandPurple};
    transform: rotate(180deg);
  }

  &:hover {
    background: ${(props) => props.theme.colors.brandPurple};
    transition: all 0.4s;

    p {
      color: #fff;
    }

    p + svg {
      fill: #fff;
    }

    svg:last-child {
      stroke: #fff;
    }
  }
`;

const Welcome = ({ userName, isVerified }) => {
  return (
    <WelcomeContainer isVerified={isVerified}>
      <div>
        <H1>
          Welcome,
          <p>
            {userName}
            <span role="img" aria-label="thumbs-up">
              👋
            </span>
          </p>
        </H1>
        <RetakeLink to="/onboarding/step-one" onClick={() => gaRetakeTour()}>
          <Play stroke="#3E21DE" width={26} height={26} />
          <span>Retake tour</span>
        </RetakeLink>
        <Plugins>
          <Plugin>
            <StyledLink
              onClick={() => gaDownloadPlugin()}
              target="_blank"
              href="https://github.com/loceye/sketch-visualeyes/releases/latest/download/visualeyes.sketchplugin.zip"
            >
              <PluginIcon>
                <SvgSketch width={16} height={16} />
              </PluginIcon>
              Download Sketch Plugin
            </StyledLink>
          </Plugin>
          <Plugin>
            <StyledLink
              onClick={() => gaDownloadPlugin()}
              target="_blank"
              href="http://bit.ly/download-figma"
            >
              <PluginIcon>
                <SvgFigma width={16} height={16} />
              </PluginIcon>
              Install Figma Plugin
            </StyledLink>
          </Plugin>
          <Plugin>
            <StyledLink
              onClick={() => gaDownloadPlugin()}
              target="_blank"
              href="https://xd.adobelanding.com/en/xd-plugin-download/?pluginId=a85f3416"
            >
              <PluginIcon>
                <SvgAdobe width={16} height={16} />
              </PluginIcon>
              Download XD Plugin
            </StyledLink>
          </Plugin>
          <Plugin>
            <StyledLink
              onClick={() => gaDownloadPlugin()}
              target="_blank"
              href="https://chrome.google.com/webstore/detail/visualeyes-plugin/heephfjhabnjmmeiindjefllheeddogn"
            >
              <div>
                <PluginIcon>
                  <SvgChrome width={16} height={16} />
                </PluginIcon>
              </div>
              Install Chrome Extension <New>NEW</New>
            </StyledLink>
          </Plugin>
        </Plugins>

        {/* <CommunityLink
          href="https://join.slack.com/t/visualeyes-community/shared_invite/zt-f19we9ci-YnrO~ZbgwZd1oNXOgvbW8Q"
          target="_blank"
        >
          <p>Join our community in </p>
          <SvgSlack height={30} width={80} />
          <ArrowLeft width={20} height={20} />
        </CommunityLink> */}
      </div>
    </WelcomeContainer>
  );
};

export default Welcome;
