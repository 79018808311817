// types
export const STORE_PREDICT_DATA = "storage/STORE_PREDICT_DATA";
export const CLEAR_PREDICT_DATA = "storage/CLEAR_PREDICT_DATA";
export const SEEN_LIMITED_MODAL = "storage/SEEN_LIMITED_MODAL";
export const CANCEL_FREE_STORAGE = "storage/CANCEL_FREE_STORAGE";
export const SEEN_CLARITY_MODAL = "storage/SEEN_CLARITY_MODAL";
export const DOWNLOAD_MODAL_SURVEY = "storage/DOWNLOAD_MODAL_SURVEY";
export const SEEN_UPGRADE_MESSAGE = "storage/SEEN_UPGRADE_MESSAGE";
export const HIDE_AOI_ONBOARD = "storage/HIDE_AOI_ONBOARD";
export const HIDE_REPORT_POP = "storage/HIDE_REPORT_POP";
export const SEEN_FREE_LIMITED_MODAL = "storage/SEEN_FREE_LIMITED_MODAL";
export const CLOSE_FEATURE_FOR_ALL = "storage/CLOSE_FEATURE_FOR_ALL";

// state
const initialState = {
  seenLimitedModal: false,
  seenClarityModal: false,
  predictData: {},
  downloadModalCount: 0,
  seenUpgradeMessage: false,
  seenAoiOnBoard: false,
  seenReportPop: false,
  seenNewLimitedModal: false,
  seenFreeLimitedModal: false,
  seenFeatureForAll: false,
};

export const hideReportPop = () => (dispatch) => {
  dispatch({ type: HIDE_REPORT_POP });
};

export const hideAoiOnBoard = () => (dispatch) => {
  dispatch({
    type: HIDE_AOI_ONBOARD,
  });
};

export const closeFeatureForAll = () => (dispatch) => {
  dispatch({
    type: CLOSE_FEATURE_FOR_ALL,
  });
};

export const closeUpgradeMessage = () => (dispatch) => {
  dispatch({
    type: SEEN_UPGRADE_MESSAGE,
  });
};

export const increaseDownloadModalCount = (count) => (dispatch) => {
  dispatch({
    type: DOWNLOAD_MODAL_SURVEY,
    payload: { count },
  });
};

export const hasSeenModal = () => (dispatch) => {
  dispatch({
    type: SEEN_LIMITED_MODAL,
  });
};

export const hasSeenFreeModal = () => (dispatch) => {
  dispatch({
    type: SEEN_FREE_LIMITED_MODAL,
  });
};

export const hasSeenClarityModal = () => (dispatch) => {
  dispatch({
    type: SEEN_CLARITY_MODAL,
  });
};

export const cancelFreeStorage = () => (dispatch) => {
  dispatch({
    type: CANCEL_FREE_STORAGE,
  });
};

export const storePredictData = (data) => (dispatch) => {
  dispatch({
    type: STORE_PREDICT_DATA,
    payload: data,
  });
};

export const clearPredictData = () => (dispatch) => {
  dispatch({
    type: CLEAR_PREDICT_DATA,
    payload: {},
  });
};

// reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case HIDE_REPORT_POP:
      return {
        ...state,
        seenReportPop: true,
      };

    case DOWNLOAD_MODAL_SURVEY:
      return {
        ...state,
        downloadModalCount: action.payload.count,
      };

    case SEEN_UPGRADE_MESSAGE:
      return {
        ...state,
        seenUpgradeMessage: true,
      };

    case CLOSE_FEATURE_FOR_ALL:
      return {
        ...state,
        seenFeatureForAll: true,
      };

    case HIDE_AOI_ONBOARD:
      return {
        ...state,
        seenAoiOnBoard: true,
      };

    case SEEN_LIMITED_MODAL:
      return {
        ...state,
        seenNewLimitedModal: true,
      };

    case SEEN_FREE_LIMITED_MODAL:
      return {
        ...state,
        seenFreeLimitedModal: true,
      };

    case SEEN_CLARITY_MODAL:
      return {
        ...state,
        seenClarityModal: true,
      };

    case CANCEL_FREE_STORAGE:
      return {
        ...state,
        seenNewLimitedModal: false,
      };

    case STORE_PREDICT_DATA:
      return {
        ...state,
        predictData: action.payload,
      };

    case CLEAR_PREDICT_DATA:
      return {
        ...state,
        predictData: action.payload,
      };

    default:
      return state;
  }
}
