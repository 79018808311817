import React from "react";
import PropTypes from "prop-types";

const PredictionIconPlus = props => (
  <svg width="1em" height="1em" viewBox="0 0 21 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5209 18.6251H3.10418C1.94197 18.6251 1 17.6831 1 16.5209V8.10418C1 6.94197 1.94197 6 3.10418 6H11.5209C12.6831 6 13.6251 6.94197 13.6251 8.10418V16.5209C13.6251 17.6831 12.6831 18.6251 11.5209 18.6251Z"
      stroke={props.currentColor}
      strokeWidth="1.14773"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.90103 9.16517C6.38037 9.64452 6.38037 10.4217 5.90103 10.901C5.42168 11.3804 4.64451 11.3804 4.16517 10.901C3.68583 10.4217 3.68583 9.64451 4.16517 9.16517C4.64452 8.68583 5.42169 8.68583 5.90103 9.16517"
      stroke={props.currentColor}
      strokeWidth="1.14773"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.6251 10.7345C13.3095 10.6896 12.9889 10.6582 12.6607 10.6582C8.93207 10.6582 5.90979 13.9324 5.90979 17.661C5.90979 17.9892 5.94135 18.3098 5.98624 18.6254"
      stroke={props.currentColor}
      strokeWidth="1.14773"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 3.29167H19.5833"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.2917 5.58333V1"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

PredictionIconPlus.propTypes = {
  currentColor: PropTypes.string
};

PredictionIconPlus.defaultProps = {
  stroke: "#323232"
};

export default PredictionIconPlus;
