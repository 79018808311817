import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ArrowBack, Button } from "desisystem";
import { Link, useLocation } from "react-router-dom";
import { basicPlan, proPlan } from "../../../constants/planFeatures";
import ReportLayout from "../../layouts/ReportLayout";
import PlanCard from "./screens/PlanCard";
import popular from "../../../assets/popular.svg";
import ConfirmPaymentModal from "../../ui/modals/ConfirmPaymentModal";

import {
  getProPrice,
  getProration,
  fetchSubscription,
  subscriptionSelector,
} from "../../../redux/slices/subs";

const InnerContainer = styled.div`
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CardsGrid = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GoBack = styled.div`
  padding: 40px 0 0 78px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]};
  display: inline-flex;
  align-items: center;

  > svg {
    margin-right: 6px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const ButtonLink = styled(Link)`
  margin-top: 32px;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 14px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

const basicButtonState = (checkTier) => {
  if (checkTier === "basic") {
    return "BASIC_CURRENT";
  } else if (checkTier === "free") {
    return "FREE_CURRENT_TO_BASIC";
  } else {
    return;
  }
};

const proButtonState = (checkTier, checkCustomPayment) => {
  if (checkTier === "basic" && checkCustomPayment) {
    return "BASIC_CURRENT_CUSTOM";
  } else if (checkTier === "basic" && !checkCustomPayment) {
    return "BASIC_CURRENT_NO_CUSTOM";
  } else if (checkTier === "free") {
    return "FREE_CURRENT_TO_PRO";
  } else {
    return;
  }
};

const ChoosePaidPlan = (props) => {
  const [open, setOpen] = useState(false);
  const [basicState, setBasicButtonState] = useState("BASIC_CURRENT");
  const [proState, setProButtonState] = useState("BASIC_CURRENT_CUSTOM");

  const {
    loading,
    proration,
    proPrice,
    subs: { tier, custom },
  } = useSelector(subscriptionSelector);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { pathname: pathOrigin = "/" } = props.location.state || {
    pathOrigin: "/",
  };

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    setBasicButtonState(basicButtonState(tier));
    setProButtonState(proButtonState(tier, custom));
  }, [tier, custom]);

  useEffect(() => {
    dispatch(fetchSubscription());
    dispatch(getProration());
    dispatch(getProPrice());
  }, [dispatch]);

  function renderCardButton(state) {
    switch (state) {
      case "BASIC_CURRENT":
        return (
          <Button width="100" disabled={true}>
            Current plan
          </Button>
        );

      case "FREE_CURRENT_TO_BASIC":
        return (
          <ButtonLink
            to={{
              pathname: "/upgrade",
              state: {
                plan: "basic",
                pathname,
              },
            }}
          >
            Choose plan
          </ButtonLink>
        );

      case "BASIC_CURRENT_CUSTOM":
      case "FREE_CURRENT_TO_PRO":
        return (
          <ButtonLink
            to={{
              pathname: "/upgrade",
              state: {
                plan: "pro",
                pathname,
              },
            }}
          >
            Choose plan
          </ButtonLink>
        );

      case "BASIC_CURRENT_NO_CUSTOM":
        return (
          <Button
            width="100"
            // TODO: change that to
            onClick={() => openModal()}
            // to={{
            //   pathname: "/upgrade",
            //   state: {
            //     plan: "pro",
            //     pathname,
            //   },
            // }}
          >
            Choose plan
          </Button>
        );
      default:
        return;
    }
  }

  return (
    <ReportLayout>
      <GoBack>
        <StyledLink to={pathOrigin}>
          <ArrowBack width={20} height={20} fill="#3E21DE" />
          Go Back
        </StyledLink>
      </GoBack>
      <InnerContainer>
        {!loading && (
          <CardsGrid>
            <PlanCard
              plan={basicPlan}
              tier={tier}
              proration={proration}
              proPrice={proPrice}
              buttonCardStates={renderCardButton(basicState)}
            />
            <PlanCard
              plan={proPlan}
              tag={popular}
              tier={tier}
              custom={custom}
              proration={proration}
              proPrice={proPrice}
              buttonCardStates={renderCardButton(proState)}
            />
          </CardsGrid>
        )}
        <ConfirmPaymentModal
          open={open}
          closeModal={closeModal}
          proration={proration}
          proPrice={proPrice}
        />
      </InnerContainer>
    </ReportLayout>
  );
};

export default ChoosePaidPlan;
