import React from "react";
import styled from "styled-components";
import { Body } from "desisystem";

const DetailsBox = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Detail = styled.div`
  &:first-child {
    grid-column: 1 / -1;
  }

  span {
    display: block;
    margin-bottom: 10px;
    font-size: ${(props) => props.theme.fontSize[10]};
    font-weight: ${(props) => props.theme.weight.medium};
    color: ${(props) => props.theme.colors.overline}
    letter-spacing: 0.2rem;
  }

  p {
    font-weight: bold;
  }
`;

const MyPayments = ({ subscription }) => {
  const { payment_method: { number, type, expiration } = {} } = subscription;
  return (
    <div>
      {/* <H2>Payment Methods</H2> */}
      <DetailsBox>
        <Detail>
          <span>TYPE</span>
          <Body>{type}</Body>
        </Detail>
        <Detail>
          <span>CARD NUMBER</span>
          <Body>{number}</Body>
        </Detail>
        <Detail>
          <span>EXPIRATION</span>
          <Body>{expiration}</Body>
        </Detail>
      </DetailsBox>
    </div>
  );
};

export default MyPayments;
