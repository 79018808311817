import React, { Fragment } from "react";
import styled from "styled-components";
import SvgLogo from "../icons/SvgLogo";
import SvgBrand from "../icons/SvgBrand";
import mobileImage from "../../assets/illustrations/only-desktop.svg";
import Global from "../layouts/Global";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  overflow-x: auto;
  position: relative;

  div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    svg:first-child {
      margin-bottom: 10px;
    }
  }

  span {
    color: #212121;
    letter-spacing: 0.2rem;
    font-size: 10px;
  }

  h1 {
    color: #3e21de;
    text-align: center;
    font-size: 22px;
  }
`;

const LogoTop = styled.div`
  position: absolute;
  top: 4rem;
`;

const Middle = styled.div`
position: absolute;
top: 36%
z-index: 2;
`;

const ImageBox = styled.div`
  background-image: url(${mobileImage});
  background-position: center;
  background-size: auto;
  position: absolute;
  bottom: 0;
  z-index: 1;
  height: 50%;
  background-repeat: no-repeat;
`;

const MobileLayout = () => {
  return (
    <Fragment>
      <Global />
      <Container>
        <LogoTop>
          <SvgLogo width={48} height={48} />
          <SvgBrand width={148} height={48} fill="#1D0F68" />
        </LogoTop>
        <Middle>
          <span>OOPS! ACCESS IS DENIED.</span>
          <h1>Our platform is running only on Desktop mode.</h1>
        </Middle>
        <ImageBox>{/* <img src={mobileImage} alt="mobile" /> */}</ImageBox>
      </Container>
    </Fragment>
  );
};

export default MobileLayout;
