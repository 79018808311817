import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { ArrowBack } from "desisystem";

// ui
import GlobalCreationButton from "../../../ui/buttons/GlobalCreationButton";
import LimitedProjectsModal from "../../../modals/LimitedProjectsModal";
import LimitedProjects from "../../../global/LimitedProjects";
import ConfirmPaymentModal from "../../../ui/modals/ConfirmPaymentModal";
import EmptyHistory from "../../openings/EmptyHistory";
import FullScreenModal from "../../../modals/FullScreenModal";

// constants
import { EMPTY_HISTORY_PROJECT } from "../../../../constants/GenericContent";
import { DELETE_FULLSCREEN_CONTENT } from "../../../../constants/GenericContent";

// redux
import { hideLimitedModal } from "../../../../redux/slices/projects";

const { PREDICT_CONTENT } = DELETE_FULLSCREEN_CONTENT;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 24px;

  @media (max-width: 991px) {
    grid-template-columns: 1fr;
  }
`;

const ProjectsSidebar = styled.div`
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
  background: #fff;
  padding: 0 0 50px 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    height: 100%;
    width: calc(50vh);
    left: -50vh;
  }

  @media (max-width: 991px) {
    height: auto;

    &::before {
      display: none;
    }
  }

  h1 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[34]};
    margin: 0 0 8px 0;
  }

  span {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #9e9e9e;
  }
`;

const ProjectsGrid = styled.div`
  padding: 94px 40px 40px 0;

  @media (max-width: 991px) {
    padding: 94px 40px 40px 40px;
  }
`;

const GoBack = styled.div`
  position: relative;
  margin: 72px 0 16px 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]}
  display: flex;
  align-items: center;

  > svg {
    margin-right: 6px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const SidebarContent = styled.div`
  padding-right: 24px;

  p {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
  }
`;

const AllProjects = ({
  pathname,
  hasLimitedProjects,
  hasOneLimitedProject,
  limitedModal,
  projectIdForGlobal,
  deleteModalOpen,
  proration,
  proPrice,
  tier,
  custom,
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const closeLimitedModal = () => {
    dispatch(hideLimitedModal());
    if (tier === "basic" && !custom) {
      openModal();
    }
  };

  return (
    <React.Fragment>
      <Grid>
        <ProjectsSidebar>
          <GoBack>
            <StyledLink to="/">
              <ArrowBack width={20} height={20} fill="#3E21DE" />
              Go Back
            </StyledLink>
          </GoBack>
          <h1>All Projects</h1>
          <SidebarContent>
            <p>
              View all your projects. Manage the predictions inside each project
              while keeping your work organized.
            </p>
          </SidebarContent>
          {!hasLimitedProjects && tier === "basic" && (
            <StyledLink
              to={{
                pathname: "/create/projects",
                state: {
                  pathname,
                },
              }}
              style={{ marginTop: "auto" }}
            >
              Create new project
            </StyledLink>
          )}

          {!hasOneLimitedProject && tier === "free" && (
            <StyledLink
              to={{
                pathname: "/create/projects",
                state: {
                  pathname,
                },
              }}
              style={{ marginTop: "auto" }}
            >
              Create new project
            </StyledLink>
          )}
        </ProjectsSidebar>

        <React.Fragment>
          {children.length > 0 ? (
            <ProjectsGrid>
              {hasLimitedProjects && (
                <LimitedProjects
                  pathname={pathname}
                  projectLengthNum="5"
                  projectLengthWord="five"
                />
              )}
              {hasOneLimitedProject && (
                <LimitedProjects
                  pathname={pathname}
                  projectLengthNum="1"
                  projectLengthWord="one"
                />
              )}
              {children}
            </ProjectsGrid>
          ) : (
            <EmptyHistory openingContent={EMPTY_HISTORY_PROJECT} />
          )}
        </React.Fragment>

        <LimitedProjectsModal
          modal={limitedModal}
          closeModal={closeLimitedModal}
          tier={tier}
          custom={custom}
          pathname={pathname}
        />

        <ConfirmPaymentModal
          open={open}
          closeModal={closeModal}
          proration={proration}
          proPrice={proPrice}
        />

        {!deleteModalOpen && (
          <GlobalCreationButton projectIdForGlobal={projectIdForGlobal} />
        )}
      </Grid>

      {deleteModalOpen && (
        <FullScreenModal isOpen={deleteModalOpen} content={PREDICT_CONTENT} />
      )}
    </React.Fragment>
  );
};

export default AllProjects;
