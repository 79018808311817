import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Warning from "../icons/Warning";
import ConfirmPaymentModal from "../ui/modals/ConfirmPaymentModal";
import { useSelector } from "react-redux";
import { Button } from "desisystem";
import { subscriptionSelector } from "../../redux/slices/subs";

const CardContainer = styled.div`
  border-radius: ${(props) => props.theme.radius.soft};
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px 48px;
  box-sizing: border-box;
  ${"" /* margin-top: 48px; */}
  margin-bottom: 24px;
  border: 2.01575px solid #eeeeee;
`;

const CardContentBox = styled.div`
  display: flex;
  align-items: center;

  span {
    color: ${(props) => props.theme.neutral[4]};
    font-size: ${(props) => props.theme.fontSize[14]};
  }
`;

const LeftContent = styled.div`
  display: flex;

  & > div:first-child {
    display: flex;
    align-items: center;
  }

  & > div:last-child {
    margin: 0 0 0 40px;
  }

  h2 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize[20]};
    font-weight: bold;
    color: #757575;
    margin-bottom: 6px;
  }

  span {
    width: 70%;
    display: inline-block;
  }
`;

const UpgradeLink = styled(Link)`
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #fff;
  outline: 0;
  background: rgb(62, 33, 222);
  border-radius: ${(props) => props.theme.radius.soft};
  height: 40px;
  max-width: 200px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;

  &:hover {
    transition: all 0.3s;
    box-shadow: ${(props) => props.theme.shadows.darkShadowHover};
  }
`;

const LimitedProjects = ({ pathname, projectLengthNum, projectLengthWord }) => {
  const [open, setOpen] = useState(false);

  const {
    proration,
    proPrice,
    subs: { tier, custom },
  } = useSelector(subscriptionSelector);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CardContainer>
        <CardContentBox>
          <LeftContent>
            <div>
              <Warning stroke="#EB4B4B" width={38} height={38} />
            </div>
            <div>
              <h2>
                Limited to {projectLengthWord} ({projectLengthNum}){" "}
                {projectLengthNum === "1" ? "Project" : "Projects"}.
              </h2>
              <span>
                Your current plan is limited to{" "}
                <strong> {projectLengthWord} active </strong>
                {projectLengthNum === "1" ? "project" : "projects"}. Upgrade to
                pro to create unlimited projects.
              </span>
            </div>
          </LeftContent>
        </CardContentBox>

        <div>
          {tier === "basic" && !custom ? (
            <Button width="100" onClick={() => openModal()}>
              Upgrade
            </Button>
          ) : (
            <UpgradeLink
              to={{
                pathname: "/upgrade",
                state: {
                  plan: "pro",
                  pathname,
                },
              }}
            >
              Upgrade to Pro
            </UpgradeLink>
          )}
        </div>

        <ConfirmPaymentModal
          open={open}
          closeModal={closeModal}
          proration={proration}
          proPrice={proPrice}
        />
      </CardContainer>
    </React.Fragment>
  );
};

export default LimitedProjects;
