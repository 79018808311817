import React from "react";
import styled from "styled-components";
import nothing from "../../../../../assets/illustrations/nothing-found.svg";

const SearchPredictsGrid = styled.div`
  max-width: 360px;
  margin: 40px auto 0 auto;
`;

const NoProjects = styled.div`
  img {
    display: block
    margin: 32px auto 0 auto;
  }
  h4 {
    text-align: center;
  }
`;

const NoPredictions = () => {
  return (
    <SearchPredictsGrid>
      <NoProjects>
        <img src={nothing} alt="al" />
        <h4>Nothing found . . .</h4>
      </NoProjects>
    </SearchPredictsGrid>
  );
};

export default NoPredictions;
