import React from "react";
import styled from "styled-components";
import { PREDICTION_TITLES } from "../../../constants/GenericContent";

const Box = styled.div`
  border-radius: 4px;
  padding: 1px 8px;
  text-align: center;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  background-color: #2a2d43;

  span {
    font-weight: bold;
    font-size: 10px;
    color: #ffffff;
    margin: 6px 0;
    display: inline-block;
  }
`;

const Bar = styled.div`
  ${(props) =>
    props.type
      ? `
  background: linear-gradient(
    180deg,
    #9a2545 0%,
    #b33b4d 6.77%,
    #cb5152 14.06%,
    #e2724f 20.83%,
    #f1ab6a 29.17%,
    #f8e29b 38.02%,
    #edefa0 45.31%,
    #e2f0a3 53.65%,
    #b9dda9 61.46%,
    #80c1a6 69.27%,
    #448eba 81.77%,
    #2a2d43 98.44%
  ); 
`
      : `
  background: linear-gradient(360deg, #ff4949 0%, #ffffff 51.3%, #00904b 100%);
`}

  border: 2px solid #fff;
  height: 160px;
  width: 16px;
  margin: 0 auto;
  border-radius: 100px;
`;
const { ATTENTION } = PREDICTION_TITLES;
const GradientBox = ({ type }) => {
  return (
    <Box>
      <div>
        <span>{type === ATTENTION ? "HOT" : "CLEAR"}</span>
      </div>
      <Bar type={type === ATTENTION ? 1 : 0}></Bar>
      <div>
        <span>{type === ATTENTION ? "COLD" : "CLUTTER"}</span>
      </div>
    </Box>
  );
};

export default GradientBox;
