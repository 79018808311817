export const aboveTheFold = {
  pricing: {
    name: "Pricing",
    min: 53.0,
    max: 90.0,
    mean: 68.1,
    median: 67.0,
    standardDeviation: 7.0,
    range: 37,
    samplesNumber: 48,
  },
  // plans: {
  //   min: 49.0,
  //   max: 62.0,
  //   mean: 55.4,
  //   median: 55.0,
  //   standardDeviation: 4.5,
  //   range: 13,
  //   samplesNumber: 11
  // },
  // plansAndPricing: {
  //   min: 49.0,
  //   max: 90.0,
  //   mean: 65.7,
  //   median: 66.0,
  //   standardDeviation: 8.3,
  //   range: 41,
  //   samplesNumber: 59
  // },
  landing: {
    name: "Landing",
    min: 54.0,
    max: 95.0,
    mean: 74.3,
    median: 74.0,
    standardDeviation: 7.7,
    range: 41,
    samplesNumber: 57,
  },
  modal: {
    name: "Modals",
    min: 31.0,
    max: 80.0,
    mean: 58.1,
    median: 59.0,
    standardDeviation: 9.6,
    range: 49,
    samplesNumber: 36,
  },
  docs: {
    name: "Documentation",
    min: 32.0,
    max: 78.0,
    mean: 60.5,
    median: 65.0,
    standardDeviation: 14.9,
    range: 46,
    samplesNumber: 11,
  },
  terms: {
    name: "Term & Policy",
    min: 7.0,
    max: 93.0,
    mean: 54.0,
    median: 56.0,
    standardDeviation: 15.5,
    range: 86,
    samplesNumber: 32,
  },
  login: {
    name: "Log in",
    min: 48.0,
    max: 81.0,
    mean: 61.7,
    median: 63.0,
    standardDeviation: 6.6,
    range: 33,
    samplesNumber: 58,
  },
  comparison: {
    name: "Comparison articles",
    min: 67.0,
    max: 76.0,
    mean: 70.5,
    median: 70.0,
    standardDeviation: 2.8,
    range: 9,
    samplesNumber: 13,
  },
  search: {
    name: "Search results",
    min: 42.0,
    max: 69.0,
    mean: 56.0,
    median: 54.5,
    standardDeviation: 8.4,
    range: 27,
    samplesNumber: 10,
  },
  list: {
    name: "Lists",
    min: 28.0,
    max: 65.0,
    mean: 53.3,
    median: 54.0,
    standardDeviation: 9.7,
    range: 37,
    samplesNumber: 22,
  },
  forgot: {
    name: "Forgot Password",
    min: 48.0,
    max: 87.0,
    mean: 62.4,
    median: 63.0,
    standardDeviation: 8.8,
    range: 39,
    samplesNumber: 31,
  },
  invite: {
    name: "Invite/Share",
    min: 38.0,
    max: 72.0,
    mean: 57.5,
    median: 60.5,
    standardDeviation: 9.1,
    range: 34,
    samplesNumber: 22,
  },
  support: {
    name: "Support",
    min: 43.0,
    max: 81.0,
    mean: 66.9,
    median: 66.0,
    standardDeviation: 8.0,
    range: 38,
    samplesNumber: 35,
  },
  signup: {
    name: "Sign up",
    min: 46.0,
    max: 83.0,
    mean: 62.8,
    median: 62.0,
    standardDeviation: 7.6,
    range: 37,
    samplesNumber: 53,
  },
  status: {
    name: "Status page",
    min: 44.0,
    max: 75.0,
    mean: 61.5,
    median: 63.0,
    standardDeviation: 7.6,
    range: 31,
    samplesNumber: 21,
  },
  templates: {
    name: "List of Templates",
    min: 50.0,
    max: 82.0,
    mean: 63.4,
    median: 64.5,
    standardDeviation: 8.7,
    range: 32,
    samplesNumber: 12,
  },
  intro: {
    name: "Onboarding",
    min: 44.0,
    max: 84.0,
    mean: 61.9,
    median: 62.0,
    standardDeviation: 8.3,
    range: 40,
    samplesNumber: 64,
  },
  payment: {
    name: "payment",
    min: 54.0,
    max: 60.0,
    mean: 57.3,
    median: 57.5,
    standardDeviation: 2.3,
    range: 6,
    samplesNumber: 6,
  },
  settings: {
    name: "settings",
    min: 39.0,
    max: 72.0,
    mean: 53.3,
    median: 55.0,
    standardDeviation: 7.8,
    range: 33,
    samplesNumber: 64,
  },
  dashboard: {
    name: "dashboard",
    min: 43.0,
    max: 73.0,
    mean: 61.1,
    median: 60.0,
    standardDeviation: 7.6,
    range: 30,
    samplesNumber: 24,
  },
  record: {
    name: "record",
    min: 42.0,
    max: 59.0,
    mean: 52.9,
    median: 55.0,
    standardDeviation: 5.2,
    range: 17,
    samplesNumber: 7,
  },
  onboarding: {
    name: "onboarding",
    min: 32.0,
    max: 77.0,
    mean: 55.1,
    median: 55.0,
    standardDeviation: 10.2,
    range: 45,
    samplesNumber: 39,
  },

  referral: {
    name: "referral",
    min: 71.0,
    max: 71.0,
    mean: 71.0,
    median: 71.0,
    standardDeviation: 0.0,
    range: 0,
    samplesNumber: 1,
  },
  integrations: {
    name: "integrations",
    min: 28.0,
    max: 83.0,
    mean: 66.8,
    median: 69.0,
    standardDeviation: 11.4,
    range: 55,
    samplesNumber: 37,
  },
  addrecord: {
    name: "addrecord",
    min: 31.0,
    max: 74.0,
    mean: 58.8,
    median: 59.0,
    standardDeviation: 7.9,
    range: 43,
    samplesNumber: 24,
  },
  profile: {
    name: "profile",
    min: 42.0,
    max: 65.0,
    mean: 55.2,
    median: 55.0,
    standardDeviation: 6.5,
    range: 23,
    samplesNumber: 19,
  },
  emptyState: {
    name: "emptyState",
    min: 55.0,
    max: 77.0,
    mean: 63.5,
    median: 64.0,
    standardDeviation: 5.5,
    range: 22,
    samplesNumber: 12,
  },
  calendar: {
    name: "calendar",
    min: 47.0,
    max: 67.0,
    mean: 58.0,
    median: 58.0,
    standardDeviation: 5.2,
    range: 20,
    samplesNumber: 8,
  },
  blog: {
    name: "blog",
    min: 48.0,
    max: 96.0,
    mean: 67.2,
    median: 66.0,
    standardDeviation: 10.0,
    range: 48,
    samplesNumber: 28,
  },
  editor: {
    name: "editor",
    min: 43.0,
    max: 68.0,
    mean: 59.8,
    median: 61.5,
    standardDeviation: 6.8,
    range: 25,
    samplesNumber: 10,
  },
  productupdates: {
    name: "productupdates",
    min: 49.0,
    max: 77.0,
    mean: 65.3,
    median: 70.0,
    standardDeviation: 11.9,
    range: 28,
    samplesNumber: 3,
  },
  conversations: {
    name: "conversations",
    min: 51.0,
    max: 60.0,
    mean: 55.0,
    median: 56.0,
    standardDeviation: 3.5,
    range: 9,
    samplesNumber: 5,
  },
  faq: {
    name: "faq",
    min: 47.0,
    max: 69.0,
    mean: 58.0,
    median: 59.0,
    standardDeviation: 8.7,
    range: 22,
    samplesNumber: 6,
  },
  pressKit: {
    name: "pressKit",
    min: 57.0,
    max: 88.0,
    mean: 68.9,
    median: 64.5,
    standardDeviation: 9.6,
    range: 31,
    samplesNumber: 14,
  },
  roadmap: {
    name: "roadmap",
    min: 45.0,
    max: 56.0,
    mean: 50.5,
    median: 50.5,
    standardDeviation: 5.5,
    range: 11,
    samplesNumber: 2,
  },
  error: {
    name: "error",
    min: 52.0,
    max: 59.0,
    mean: 55.5,
    median: 55.5,
    standardDeviation: 3.5,
    range: 7,
    samplesNumber: 2,
  },
};

export const fullScroll = {
  pricing: {
    name: "Pricing",
    min: 24.0,
    max: 61.0,
    mean: 44.9,
    median: 45.5,
    standardDeviation: 7.8,
    range: 37,
    samplesNumber: 48,
  },
  // plans: {
  //   min: 30.0,
  //   max: 60.0,
  //   mean: 47.9,
  //   median: 48.0,
  //   standardDeviation: 8.0,
  //   range: 30,
  //   samplesNumber: 11
  // },
  // plansAndPricing: {
  //   min: 24.0,
  //   max: 61.0,
  //   mean: 45.4,
  //   median: 47.0,
  //   standardDeviation: 8.0,
  //   range: 37,
  //   samplesNumber: 59
  // },
  landing: {
    name: "Landing",
    min: 37.0,
    max: 85.0,
    mean: 55.0,
    median: 54.0,
    standardDeviation: 8.4,
    range: 48,
    samplesNumber: 56,
  },
  modal: {
    name: "Modal",
    min: 30.0,
    max: 85.0,
    mean: 60.0,
    median: 60.0,
    standardDeviation: 10.6,
    range: 55,
    samplesNumber: 36,
  },
  docs: {
    name: "Documentation",
    min: 17.0,
    max: 70.0,
    mean: 44.5,
    median: 48.0,
    standardDeviation: 17.1,
    range: 53,
    samplesNumber: 11,
  },
  terms: {
    name: "Terms & Policy",
    min: 1.0,
    max: 62.0,
    mean: 23.3,
    median: 15.5,
    standardDeviation: 17.3,
    range: 61,
    samplesNumber: 32,
  },
  login: {
    name: "Log in",
    min: 48.0,
    max: 81.0,
    mean: 61.6,
    median: 62.0,
    standardDeviation: 6.6,
    range: 33,
    samplesNumber: 58,
  },
  comparison: {
    name: "App compare articles",
    min: 30.0,
    max: 50.0,
    mean: 41.5,
    median: 44.0,
    standardDeviation: 6.5,
    range: 20,
    samplesNumber: 13,
  },
  search: {
    name: "Search",
    min: 41.0,
    max: 70.0,
    mean: 55.7,
    median: 55.5,
    standardDeviation: 8.7,
    range: 29,
    samplesNumber: 10,
  },
  list: {
    name: "Lists",
    min: 24.0,
    max: 65.0,
    mean: 50.6,
    median: 53.5,
    standardDeviation: 12.7,
    range: 41,
    samplesNumber: 22,
  },
  forgot: {
    name: "Forgot Password",
    min: 48.0,
    max: 87.0,
    mean: 61.8,
    median: 62.0,
    standardDeviation: 8.3,
    range: 39,
    samplesNumber: 31,
  },
  invite: {
    name: "Invite",
    min: 34.0,
    max: 71.0,
    mean: 57.0,
    median: 60.5,
    standardDeviation: 9.7,
    range: 37,
    samplesNumber: 22,
  },
  support: {
    name: "Support",
    min: 28.0,
    max: 67.0,
    mean: 50.1,
    median: 52.0,
    standardDeviation: 9.3,
    range: 39,
    samplesNumber: 35,
  },
  signup: {
    name: "Sign up",
    min: 41.0,
    max: 82.0,
    mean: 59.9,
    median: 62.0,
    standardDeviation: 8.9,
    range: 41,
    samplesNumber: 53,
  },
  status: {
    name: "Status page",
    min: 22.0,
    max: 59.0,
    mean: 44.6,
    median: 46.0,
    standardDeviation: 7.2,
    range: 37,
    samplesNumber: 21,
  },
  templates: {
    name: "List of Templates",
    min: 15.0,
    max: 69.0,
    mean: 47.5,
    median: 52.0,
    standardDeviation: 14.9,
    range: 54,
    samplesNumber: 12,
  },
  intro: {
    name: "Intro Modal",
    min: 42.0,
    max: 83.0,
    mean: 61.9,
    median: 62.0,
    standardDeviation: 8.4,
    range: 41,
    samplesNumber: 64,
  },
  payment: {
    name: "Payment",
    min: 23.0,
    max: 58.0,
    mean: 46.8,
    median: 50.5,
    standardDeviation: 11.9,
    range: 35,
    samplesNumber: 6,
  },
  settings: {
    name: "Settings",
    min: 21.0,
    max: 65.0,
    mean: 46.1,
    median: 48.0,
    standardDeviation: 11.7,
    range: 44,
    samplesNumber: 64,
  },
  dashboard: {
    name: "Dashboard",
    min: 41.0,
    max: 72.0,
    mean: 56.7,
    median: 57.0,
    standardDeviation: 8.8,
    range: 31,
    samplesNumber: 24,
  },
  record: {
    name: "Record",
    min: 33.0,
    max: 59.0,
    mean: 46.7,
    median: 49.0,
    standardDeviation: 8.6,
    range: 26,
    samplesNumber: 7,
  },
  onboarding: {
    name: "On boarding",
    min: 29.0,
    max: 77.0,
    mean: 54.6,
    median: 55.0,
    standardDeviation: 10.7,
    range: 48,
    samplesNumber: 39,
  },

  referral: {
    name: "Referral",
    min: 47.0,
    max: 47.0,
    mean: 47.0,
    median: 47.0,
    standardDeviation: 0.0,
    range: 0,
    samplesNumber: 1,
  },
  integrations: {
    name: "Integrations",
    min: 13.0,
    max: 72.0,
    mean: 47.8,
    median: 50.0,
    standardDeviation: 11.7,
    range: 59,
    samplesNumber: 37,
  },
  addrecord: {
    name: "Add Record",
    min: 30.0,
    max: 80.0,
    mean: 57.9,
    median: 59.0,
    standardDeviation: 9.9,
    range: 50,
    samplesNumber: 24,
  },
  profile: {
    name: "Profile",
    min: 41.0,
    max: 65.0,
    mean: 52.1,
    median: 53.0,
    standardDeviation: 6.5,
    range: 24,
    samplesNumber: 19,
  },
  emptyState: {
    name: "Empty State",
    min: 52.0,
    max: 77.0,
    mean: 63.6,
    median: 64.0,
    standardDeviation: 6.5,
    range: 25,
    samplesNumber: 12,
  },
  calendar: {
    name: "Calendar",
    min: 30.0,
    max: 67.0,
    mean: 53.0,
    median: 54.5,
    standardDeviation: 10.3,
    range: 37,
    samplesNumber: 8,
  },
  blog: {
    name: "Blog",
    min: 14.0,
    max: 64.0,
    mean: 42.5,
    median: 41.5,
    standardDeviation: 11.9,
    range: 50,
    samplesNumber: 28,
  },
  editor: {
    name: "Editor",
    min: 41.0,
    max: 69.0,
    mean: 56.3,
    median: 57.5,
    standardDeviation: 8.3,
    range: 28,
    samplesNumber: 10,
  },
  productupdates: {
    name: "Product Updates",
    min: 36.0,
    max: 52.0,
    mean: 42.0,
    median: 38.0,
    standardDeviation: 7.1,
    range: 16,
    samplesNumber: 3,
  },
  conversations: {
    name: "Conversations",
    min: 46.0,
    max: 57.0,
    mean: 53.2,
    median: 54.0,
    standardDeviation: 4.1,
    range: 11,
    samplesNumber: 5,
  },
  faq: {
    name: "FAQ",
    min: 24.0,
    max: 54.0,
    mean: 39.8,
    median: 40.5,
    standardDeviation: 12.4,
    range: 30,
    samplesNumber: 6,
  },
  pressKit: {
    name: "Press Kit",
    min: 38.0,
    max: 61.0,
    mean: 50.2,
    median: 49.5,
    standardDeviation: 7.4,
    range: 23,
    samplesNumber: 14,
  },
  roadmap: {
    name: "Roadmap",
    min: 25.0,
    max: 30.0,
    mean: 27.5,
    median: 27.5,
    standardDeviation: 2.5,
    range: 5,
    samplesNumber: 2,
  },
  error: {
    name: "Error",
    min: 52.0,
    max: 59.0,
    mean: 55.5,
    median: 55.5,
    standardDeviation: 3.5,
    range: 7,
    samplesNumber: 2,
  },
};

// Prepare score ranges for clarity
export const categories = Object.keys(fullScroll)
  .filter(
    (category) =>
      fullScroll[category].standardDeviation < 10 &&
      fullScroll[category].samplesNumber > 10 &&
      category !== "comparison"
  )
  .sort(function(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (b.name > a.name) {
      return 1;
    }
    return 0;
  });
