import React from "react";
import styled from "styled-components";
import CheckboxGroup from "react-checkbox-group";
import NoPredictions from "./NoPredictions";
import { AddNew } from "zoozoo";
import { Trunk } from "../../../../layouts/Global";
import dayjs from "dayjs";

const EachPredict = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 16px;
  position: relative;
  min-height: 48px;

  label > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
`;

const EachPredictBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 16px;

  & > div:first-child {
    display: flex;
    align-items: center;
    width: 25px;
    justify-content: center;
  }

  & > div:nth-child(2) {
    display: flex;
    align-items: center;
    width: 90%;

    & > div {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      h3 {
        font-weight: normal;
        font-size: 12px;
        color: #212121;
        margin: 0 0 6px 0;
        ${Trunk}
      }
      span {
        font-weight: normal;
        font-size: 12px;
        color: #bdbdbd;
      }
    }
  }

  & > div:last-child {
    display: flex;
    align-items: center;
  }
`;

const CustomCheck = styled.div`
  background: "#fff";
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid #c3baf5;
`;

const SearchResults = ({ searchResults, predictList, setList }) => {
  return searchResults.length > 0 ? (
    searchResults.map((x, i) => (
      <div key={i}>
        {!predictList.includes(x.id) && (
          <React.Fragment>
            <CheckboxGroup
              name="predictList"
              value={predictList}
              onChange={setList}
            >
              {(Checkbox) => (
                <EachPredict key={i}>
                  <EachPredictBody>
                    <div>
                      <AddNew width={36} height={36} />
                    </div>
                    <div>
                      <div>
                        <h3>{x.name}</h3>

                        <span>
                          Created on{" "}
                          {dayjs(x.created_at).format("DD MMMM YYYY")}
                        </span>
                      </div>
                    </div>
                    <div>
                      <CustomCheck></CustomCheck>
                    </div>
                  </EachPredictBody>
                  <label>
                    <Checkbox value={x.id} />
                  </label>
                </EachPredict>
              )}
            </CheckboxGroup>
          </React.Fragment>
        )}
      </div>
    ))
  ) : (
    <NoPredictions />
  );
};

export default SearchResults;
