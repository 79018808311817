const width =
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;
const height =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export function isMobile() {
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i) ||
    width < 780
  ) {
    return true;
  } else {
    return false;
  }
}

// export function isLandscape() {
//   // console.log(window.screen.orientation.type);
//   if (window.screen.orientation.type === 'landscape-primary') {
//     return true;
//   } else {
//     return false;
//   }
// }

// window.addEventListener('orientationchange', isLandscape);
