import React from "react";
import Modal from "react-awesome-modal";
import { Body, H2, Check } from "desisystem";
import styled from "styled-components";

const ModalBody = styled.div`
  padding: 40px;
  border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  text-align: center;

  h2 {
    font-size: 22px;
    margin: 32px 0 24px 0;
    font-weight: bold;
    color: #212121;

    p {
      font-size: 22px;
      color: #3e21de;
      font-weight: bold;
    }
    p::first-letter {
      text-transform: uppercase;
    }
  }
`;

const ModalButtons = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: flex-end;

  p {
    color: #3e21de;
    font-size: 16px;
    padding: 0 0 0 26px;
    cursor: pointer;
    font-weight: bold;
  }
`;

const VerifySuccessModal = ({ verifySuccess, closeVerifySuccess, name }) => {
  return (
    <Modal
      visible={verifySuccess}
      effect="fadeInUp"
      onClickAway={() => closeVerifySuccess()}
    >
      <ModalBody>
        <Check width={40} height={40} fill="#58B149" />
        <H2>
          Thanks for verifying, <Body>{name}.</Body>
        </H2>
        <Body>You've unlocked all the credits of your free account.</Body>
        <ModalButtons>
          <Body onClick={() => closeVerifySuccess()}>Close</Body>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
export default VerifySuccessModal;
