import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { DoubleLeft, DoubleRight } from "zoozoo";

const NavButton = styled.button`
  background: transparent;
  color: ${(props) => (props.disabled ? "#C9C9C9" : "#3E21DE")};
  padding: 0;
  margin: 0 20px;
  cursor: ${(props) => (props.disabled ? "initial" : "pointer")};
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 14px;
  border-radius: 4px;
  transition: all 0.4s;
  display: flex;
  align-items: center;

  svg {
    margin: 0 10px;
    stroke: ${(props) => (props.disabled ? "#C9C9C9" : "#3E21DE")};
  }
`;

const Pagination = ({
  projectId,
  pagination,
  paginationIds,
  parentPath,
  isExample = false,
}) => {
  const { prevLink, nextLink } = pagination;
  const history = useHistory();

  return (
    <React.Fragment>
      {projectId !== null && (
        <React.Fragment>
          <NavButton
            disabled={pagination.prevLink === null}
            onClick={() =>
              history.push(
                isExample
                  ? `/examples/predictions/${prevLink}`
                  : `/predictions/${prevLink}`,
                {
                  paginationIds,
                  pathOrigin: parentPath,
                }
              )
            }
          >
            <DoubleLeft width={20} height={20} />
            Previous
          </NavButton>
          <NavButton
            disabled={pagination.nextLink === null}
            onClick={() =>
              history.push(
                isExample
                  ? `/examples/predictions/${nextLink}`
                  : `/predictions/${nextLink}`,
                {
                  paginationIds,
                  pathOrigin: parentPath,
                }
              )
            }
          >
            Next
            <DoubleRight width={20} height={20} />
          </NavButton>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Pagination;
