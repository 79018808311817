import React from "react";
import PropTypes from "prop-types";

const GoUp = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M6.5 12.9167V14.6418V14.6418C6.5 17.2222 8.5625 19.3333 11.0833 19.3333V19.3333C13.6042 19.3333 15.6667 17.2222 15.6667 14.6418V4.62265"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8.28932L15.6667 4.62265L19.3333 8.28932"
      stroke={props.stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

GoUp.propTypes = {
  stroke: PropTypes.string
};

GoUp.defaultProps = {
  // stroke: "red"
  // stroke: "#3E21DE"
};

export default GoUp;
