import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import PredictOptions from "./screens/PredictOptions";
import CardIcon from "./screens/CardIcon";
import { Desktop, Mobile } from "zoozoo";

const CardContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  border-radius: ${(props) => props.theme.radius.soft};
  overflow: hidden;
  transition: all 0.3s;
  position: relative;

  &:hover {
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.12);
    transition: all 0.3s;
  }
`;

const UpperCard = styled.div`
  background-image: url(${(props) => props.url});
  background-size: ${(props) =>
    props.model === "mobile" ? "auto 70%" : "cover"};
  background-repeat: no-repeat;
  background-position: center;
  background-color: #4f4f4f;
  min-height: 158px;
  box-sizing: border-box;
  transform: scale(1);
  transition: all 0.3s;

  &:hover {
    transform: scale(1.05);
    transition: all 0.3s;
  }

  @media (min-width: 1600px) {
    min-height: 214px;
  }
`;

const BottomCard = styled.div`
  padding: 24px 16px 24px 16px;
  max-height: 82px;
  box-sizing: border-box;
  background: #fff;

  p {
    color: ${(props) => props.theme.neutral[4]};
    font-size: ${(props) => props.theme.fontSize[12]};
    margin: 0;
    display: flex;
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h2 {
    margin: 0 0 6px 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 240px;

    @media (min-width: 1100px) {
      width: 280px;
    }

    @media (min-width: 1200px) {
      width: 300px;
    }

    @media (min-width: 1300px) {
      width: 200px;
    }

    @media (min-width: 1400px) {
      width: 230px;
    }

    @media (min-width: 1500px) {
      width: 250px;
    }

    @media (min-width: 1600px) {
      width: 264px;
    }
  }
`;

const StyledLink = styled(Link)`
  font-size: ${(props) => props.theme.fontSize[12]};
  color: ${(props) => props.theme.primary[5]};
  padding-right: 6px;
  margin-right: 6px;
  border-right: 1px solid #e0e0e0;

  span {
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;

const DeviceIcon = styled.span`
  padding: 0 10px 0 0;
  display: inline-flex;
  align-items: center;
`;

const Card = ({
  prediction,
  pathOrigin,
  deleteType,
  paginationIds = null,
  isExample = false,
}) => {
  const {
    id,
    name,
    dimensions,
    project_name,
    project_id,
    thumb_serve_path,
    platform,
    model,
  } = prediction;

  return (
    <CardContainer>
      <Link
        to={{
          pathname: isExample
            ? `/examples/predictions/${id}`
            : `/predictions/${id}`,
          state: {
            paginationIds,
            pathOrigin,
          },
        }}
      >
        <UpperCard url={thumb_serve_path} model={model}></UpperCard>
      </Link>
      <div>{platform !== "dashboard" && <CardIcon platform={platform} />}</div>

      <BottomCard>
        <CardTitle>
          <Link
            to={{
              pathname: `/predictions/${id}`,
              state: {
                paginationIds,
                pathOrigin,
              },
            }}
            title={name}
          >
            <h2>{name}</h2>
          </Link>

          {!isExample && (
            <PredictOptions
              predictName={name}
              predictId={id}
              projectId={project_id}
              pathOrigin={pathOrigin}
              deleteType={deleteType}
              projectName={project_name}
              paginationIds={paginationIds}
            />
          )}
        </CardTitle>

        <p>
          {project_name && !isExample && (
            <StyledLink to={`/projects/${project_id}`}>
              <span> {project_name}</span>
            </StyledLink>
          )}
          <DeviceIcon>
            {model === "desktop" ? (
              <Desktop stroke="#212121" />
            ) : (
              <Mobile stroke="#212121" />
            )}
          </DeviceIcon>
          {dimensions}
        </p>
      </BottomCard>
    </CardContainer>
  );
};

export default Card;
