import React from "react";
import styled from "styled-components";
import { Body, H3 } from "desisystem";
import { useSelector } from "react-redux";
import discount15 from "../../../../assets/tags/discount15.svg";
import { Plus } from "zoozoo";
import { subscriptionSelector } from "../../../../redux/slices/subs";

const PricingBox = styled.div`
  & > div {
    text-align: center;
  }

  & > p {
    color: #9e9e9e;
    display: inline-block;
    margin-left: 8px;
    font-size: 14px;
    color: #212121;
  }

  h3 {
    font-size: 64px;
    font-weight: normal;
    position: relative;

    span {
      font-size: ${(props) => props.theme.fontSize[20]};
      color: #212121;
    }
  }

  .per-year {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
  }

  .year-price {
    color: #212121;
    margin: 0 5px;
    font-size: ${(props) => props.theme.fontSize[14]};
    font-weight: bold;
    margin-left: 4px;
  }
`;

const ProRatedBox = styled.div`
  background: #c3baf5;
  border-radius: 4px;
  min-height: 40px;
  margin: 8px auto 0 auto;
  display: inline-flex;
  align-items: center;
  padding: 0 4px;

  p {
    font-size: 20px;
    margin: 0 4px 0 0;
    font-weight: bold;
  }

  p + p {
    font-weight: normal;
    font-size: 14px;
    color: #221279;
  }

  span {
    color: #212121;
  }
`;

const CurrentPrice = ({ planSwitchState }) => {
  const {
    subs: { custom, tier },
    allPrices,
  } = useSelector(subscriptionSelector);
  const { payingYear, payingMonth, perYear, perMonth } = allPrices;

  let calcPerMonthFromYear = `${perYear / 12}`.match(/[^.]*/i)[0] || 0;

  return (
    <PricingBox>
      {!planSwitchState ? (
        <div>
          <H3>${perMonth}</H3>

          <Body size="14px">per month</Body>

          {tier === "basic" && !custom && (
            <ProRatedBox>
              <Plus width={20} height={20} />

              <p>${payingMonth}</p>
              <p>due today</p>
            </ProRatedBox>
          )}
        </div>
      ) : (
        <React.Fragment>
          <div>
            <img src={discount15} alt="discount" />

            <H3>${calcPerMonthFromYear}</H3>

            <span className="per-year">
              per month, billed
              {tier === "basic" && !custom ? (
                <span className="year-price">${payingYear} one time</span>
              ) : (
                <span className="year-price">${perYear} one time</span>
              )}
            </span>
          </div>
        </React.Fragment>
      )}
    </PricingBox>
  );
};

export default CurrentPrice;
