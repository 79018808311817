import React from "react";
import PropTypes from "prop-types";

const SvgRed = props => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
    <path
      d="M6.99992 0.333374C3.31992 0.333374 0.333252 3.31671 0.333252 7.00004C0.333252 10.6834 3.31992 13.6667 6.99992 13.6667C10.6799 13.6667 13.6666 10.6834 13.6666 7.00004C13.6666 3.31671 10.6799 0.333374 6.99992 0.333374ZM7.66659 10.3334H6.33325V9.00004H7.66659V10.3334ZM7.66659 7.66671H6.33325V3.66671H7.66659V7.66671Z"
      fill="#D53C3C"
    />
  </svg>
);

export default SvgRed;
