import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fbTrack } from "../../../utils/analytics";
import { Button } from "desisystem";
import dayjs from "dayjs";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
// screens
import Card from "./screens/Card";
import GlobalLoader from "../../ui/loadings/GlobalLoader";
import Layout from "../../layouts/Layout";
import SubSidebar from "./screens/SubSidebar";
import SubscriptionModal from "../../modals/SubscriptionModal";
import ConfirmPaymentModal from "../../ui/modals/ConfirmPaymentModal";
import isEmpty from "../../../utils/helperFunctions";

// constants
import { freePlan, basicPlan, proPlan } from "../../../constants/planFeatures";

// actions
import {
  fetchSubscription,
  subscriptionSelector,
  downgradeSubscription,
  renewSubscription,
  getProPrice,
  getProration,
} from "../../../redux/slices/subs";

import { closeFeatureForAll } from "../../../redux/ducks/storage";
import { fetchUser, userSelector } from "../../../redux/slices/user";

const SubsCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 24px 0;
  grid-auto-rows: min-content;

  @media (max-width: 1060px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }

  .active {
    border: 2px solid #3e21de;
  }
`;

const SubscriptionSidebar = styled.div`
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
  background: #fff;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  min-width: 330px;
  padding: 64px 40px 24px 40px;
  overflow: auto;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    width: calc(50vw);
    left: -50vw;
  }

  @media (max-width: 1060px) {
    position: initial;
    height: auto;
    padding-bottom: 0;
  }
`;

const SubscriptionBody = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(326px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 24px;
  padding: 64px 48px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 14px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 0 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  transition: all 0.4s;
  max-width: 160px;

  &:hover {
    transition: all 0.4s;
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

const freeButtonState = (checkTier, checkDowngraded) => {
  if (checkTier === "free") {
    return "CURRENT";
  } else if (checkTier !== "free" && checkDowngraded === "") {
    return "DOWNGRADE_FREE";
  } else {
    return;
  }
};

const basicButtonState = (checkTier, checkDowngraded, hasPaymentMethod) => {
  if (checkTier === "basic" && checkDowngraded === "") {
    return "BASIC_CURRENT";
  } else if (checkTier === "free") {
    return "BASIC_GET_STARTED";
  } else if (
    checkTier === "basic" &&
    checkDowngraded !== "" &&
    hasPaymentMethod
  ) {
    return "BASIC_RENEW";
  } else if (
    checkTier === "basic" &&
    checkDowngraded !== "" &&
    !hasPaymentMethod
  ) {
    return "ADD_BASIC_PAYMENT_METHOD";
  } else {
    return;
  }
};

const proButtonState = (
  checkTier,
  checkDowngraded,
  checkCustomPayment,
  hasPaymentMethod
) => {
  if (checkTier === "pro" && checkDowngraded === "") {
    return "PRO_CURRENT";
  } else if (
    checkTier === "pro" &&
    checkDowngraded !== "" &&
    hasPaymentMethod
  ) {
    return "PRO_RENEW";
  } else if (
    checkTier === "pro" &&
    checkDowngraded !== "" &&
    !hasPaymentMethod
  ) {
    return "ADD_PRO_PAYMENT_METHOD";
  } else if (checkTier === "free") {
    return "FREE_PLAN";
  } else if (checkTier === "basic" && checkCustomPayment) {
    return "BASIC_CUSTOM";
  } else if (checkTier === "basic" && !checkCustomPayment) {
    return "BASIC_NO_CUSTOM";
  } else {
    return;
  }
};

const STRIPE_PUBLIC_KEY =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY
    : process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const Subscription = (props) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [downgradeFreeOpen, setDowngradeFreeOpen] = useState(false);
  const [renewBasicOpen, setRenewBasicOpen] = useState(false);
  const [renewProOpen, setRenewProOpen] = useState(false);
  const [freeState, setFreeButtonState] = useState("CURRENT");
  const [basicState, setBasicButtonState] = useState("CURRENT");
  const [proState, setProButtonState] = useState("CURRENT");
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [fromRenewButton, setFromRenewButton] = useState(false);

  const { subs, proration, proPrice, loading } = useSelector(
    subscriptionSelector
  );
  const {
    receiptLink,
    user: { first_name, email },
  } = useSelector(userSelector);
  const { expires_at, tier, downgrade_to, custom, payment_method } = subs;
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const openDeleteModal = () => {
    setOpenDelete(true);
  };

  const openEditModal = () => {
    setOpenEdit(true);
  };

  const openEditFromRenewModal = () => {
    setOpenEdit(true);
    setFromRenewButton(true);
  };

  const openConfirmModal = () => {
    setConfirmOpen(true);
  };

  const openDowngradeFreeModal = () => {
    setDowngradeFreeOpen(true);
  };

  const openBasicRenewModal = () => {
    setRenewBasicOpen(true);
  };

  const openProRenewModal = () => {
    setRenewProOpen(true);
  };

  const closeModal = () => {
    setConfirmOpen(false);
    setDowngradeFreeOpen(false);
    setRenewProOpen(false);
    setRenewBasicOpen(false);
    setOpenDelete(false);
    setOpenEdit(false);
    setFromRenewButton(false);
  };

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchSubscription());
    dispatch(getProPrice());
    dispatch(getProration());
    dispatch(closeFeatureForAll());
  }, [dispatch]);

  const hasMethod = !isEmpty(payment_method);

  useEffect(() => {
    setFreeButtonState(freeButtonState(tier, downgrade_to));
    setBasicButtonState(basicButtonState(tier, downgrade_to, hasMethod));
    setProButtonState(proButtonState(tier, downgrade_to, custom, hasMethod));
  }, [tier, downgrade_to, custom, hasMethod]);

  const downGrade = () => {
    dispatch(downgradeSubscription());
  };

  const renewAccount = () => {
    dispatch(renewSubscription());
    closeModal();
  };

  const downgradePlan = () => {
    downGrade();
    closeModal();
  };

  function renderFreeButton(state) {
    switch (state) {
      case "DOWNGRADE_FREE":
        return (
          <Button
            appearance="secondary"
            width="100"
            onClick={() => openDowngradeFreeModal()}
          >
            Change to Free
          </Button>
        );

      case "CURRENT":
        return (
          <Button disabled={true} width="100">
            Current plan
          </Button>
        );

      default:
        return;
    }
  }

  function renderBasicButton(state) {
    switch (state) {
      case "BASIC_CURRENT":
        return (
          <Button disabled={true} width="100">
            Current plan
          </Button>
        );

      case "BASIC_GET_STARTED":
        return (
          <span onClick={() => fbTrack("InitiateCheckout")}>
            <StyledLink
              to={{
                pathname: "/upgrade",
                state: {
                  plan: "basic",
                  pathname,
                },
              }}
            >
              Get started
            </StyledLink>
          </span>
        );

      case "BASIC_RENEW":
        return (
          <Button width="100" onClick={() => openBasicRenewModal()}>
            Renew
          </Button>
        );

      case "ADD_BASIC_PAYMENT_METHOD":
        return (
          <Button width="100" onClick={() => openEditFromRenewModal()}>
            Renew
          </Button>
        );

      default:
        return;
    }
  }

  function renderProButton(state) {
    switch (state) {
      case "PRO_CURRENT":
        return (
          <Button disabled={true} width="100">
            Current plan
          </Button>
        );

      case "PRO_RENEW":
        return (
          <Button width="100" onClick={() => openProRenewModal()}>
            Renew
          </Button>
        );

      case "ADD_PRO_PAYMENT_METHOD":
        return (
          <Button width="100" onClick={() => openEditFromRenewModal()}>
            Renew
          </Button>
        );

      case "FREE_PLAN":
      case "BASIC_CUSTOM":
        return (
          <StyledLink
            to={{
              pathname: "/upgrade",
              state: {
                plan: "pro",
                pathname,
              },
            }}
          >
            Get started
          </StyledLink>
        );

      case "BASIC_NO_CUSTOM":
        return (
          <Button width="100" onClick={() => openConfirmModal()}>
            Get started
          </Button>
        );

      default:
        return;
    }
  }

  function renderModal(state) {
    switch (state) {
      case "DOWNGRADE_FREE":
        return (
          <SubscriptionModal
            open={downgradeFreeOpen}
            closeModal={closeModal}
            content={downgradeContent}
            action={downgradePlan}
            modalType="hybrid"
            tierToGo="free"
            isDowngrading="true"
          />
        );

      case "BASIC_NO_CUSTOM":
        return (
          <ConfirmPaymentModal
            open={confirmOpen}
            closeModal={closeModal}
            proration={proration}
            proPrice={proPrice}
          />
        );

      case "BASIC_RENEW":
        return (
          <SubscriptionModal
            open={renewBasicOpen}
            closeModal={closeModal}
            content={renewContent}
            action={renewAccount}
            isDowngrading={false}
            modalType="hybrid"
          />
        );

      case "PRO_RENEW":
        return (
          <SubscriptionModal
            open={renewProOpen}
            closeModal={closeModal}
            content={renewContent}
            action={renewAccount}
            modalType="renew"
          />
        );

      default:
        return;
    }
  }

  let downgradeContent = (
    <React.Fragment>
      <h2>
        Are you sure you want to downgrade your <p>{tier}</p> plan?
      </h2>
      <p>
        Your subscription will be downgraded to Free on{" "}
        <span>{dayjs(expires_at).format("DD MMM, YYYY")}</span>.
      </p>
    </React.Fragment>
  );

  let renewContent = (
    <React.Fragment>
      <h2>
        Great news, <p>{first_name}</p>. You are about to renew your{" "}
        <p>{tier}</p> plan.
      </h2>
      <p> Are you sure that you want to renew your existing plan?</p>
    </React.Fragment>
  );

  return (
    <Layout>
      {!loading ? (
        <SubsCardsContainer>
          <SubscriptionSidebar>
            <Elements stripe={stripePromise}>
              <SubSidebar
                subscription={subs}
                receiptLink={receiptLink}
                email={email}
                closeModal={closeModal}
                openEditModal={openEditModal}
                openDeleteModal={openDeleteModal}
                openDelete={openDelete}
                openEdit={openEdit}
                openEditFromRenewModal={openEditFromRenewModal}
                fromRenewButton={fromRenewButton}
                afterProAction={openProRenewModal}
                afterBasicAction={openBasicRenewModal}
              />
            </Elements>
          </SubscriptionSidebar>

          <SubscriptionBody>
            <Card
              active={subs.tier === "pro" ? 1 : 0}
              subscription={subs}
              plan={proPlan}
              proration={proration}
              proPrice={proPrice}
              modalStates={renderModal(proState)}
              buttonStates={renderProButton(proState)}
            />
            <Card
              active={subs.tier === "basic" ? 1 : 0}
              subscription={subs}
              plan={basicPlan}
              proration={proration}
              proPrice={proPrice}
              modalStates={renderModal(basicState)}
              buttonStates={renderBasicButton(basicState)}
            />
            <Card
              active={subs.tier === "free" ? 1 : 0}
              subscription={subs}
              plan={freePlan}
              proration={proration}
              proPrice={proPrice}
              modalStates={renderModal(freeState)}
              buttonStates={renderFreeButton(freeState)}
            />
          </SubscriptionBody>
        </SubsCardsContainer>
      ) : (
        <GlobalLoader />
      )}
    </Layout>
  );
};

export default Subscription;
