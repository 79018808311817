import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  loading: false,
  projectIdForGlobal: null,
  deleteModalOpen: false,
  deleteData: {},
};

const globalActionsSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    closeDelete: (state) => {
      state.deleteModalOpen = false;
    },
    openDelete: (state, { payload }) => {
      state.deleteModalOpen = true;
      state.deleteData = payload;
    },
    globalCreation: (state, { payload }) => {
      state.projectIdForGlobal = payload;
    },
  },
});

export const {
  closeDelete,
  openDelete,
  globalCreation,
} = globalActionsSlice.actions;
export const globalActionsSelector = (state) => state.globalActions;
export default globalActionsSlice.reducer;

export const closeDeleteModal = () => (dispatch) => {
  dispatch(closeDelete());
};

export const openDeleteModal = (deleteId, deleteType, pathOrigin) => (
  dispatch
) => {
  dispatch(openDelete({ deleteId, deleteType, pathOrigin }));
};

export const globalCreationMenu = (projectId) => (dispatch) => {
  dispatch(globalCreation(projectId));
};
