import React, { Fragment, useState } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import { Button, H2, Input } from "desisystem";
import { useDispatch } from "react-redux";
import {
  HiCheckCircle,
  HiOutlineClock,
  HiOutlinePlus,
  HiX,
  HiXCircle,
} from "react-icons/hi";

// actions
import {
  deleteTeamMember,
  getTeamMembers,
  sendTeamInvites,
} from "../../../../redux/slices/user";
import TeamMembersModal from "../../../modals/TeamMembersModal";
import DeleteTeamMemberModal from "../../../modals/DeleteTeamMemberModal";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";

const FormContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;

  & + p {
    color: red;
    margin-bottom: 0;
  }

  input::placeholder {
    color: #e2e0ec;
  }

  .disabled-email {
    color: #d1d1d1;
  }
`;

const StyledBtn = styled(Button)`
  margin-top: 80px;
`;

const AddButton = styled.button`
  width: 100%;
  border: 1px dashed #cecece;
  background-color: transparent;
  border-radius: 4px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  font-size: 14px;
  min-height: 40px;
  color: ${(props) => (props.disabled ? "#aaa" : "#333")};
  padding: 0 2rem;

  transition: all 0.4s;
  margin-top: 1rem;
`;

const TeamManagement = ({
  members = [],
  tier = "pro",
  userEmail,
  hasDowngraded = false,
}) => {
  const dispatch = useDispatch();
  const [emails, setEmails] = React.useState([]);
  // const [members, setMembers] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = React.useState(false);
  const [memberToRemove, setMemberToRemove] = React.useState(null);

  React.useEffect(() => {
    //get EMails
    //TODO: get active members API call
    dispatch(getTeamMembers());
    // setMembers([{ email: "raptis@loceye.io" }, { email: "ale@loceye.io" }]);
  }, []);

  function addEmail() {
    setEmails([...emails, ""]);
  }
  function updateEmail(index, value) {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  }
  function removeEmail(index) {
    const newEmails = [...emails.slice(0, index), ...emails.slice(index + 1)];
    setEmails(newEmails);
  }
  function removeMember() {
    dispatch(deleteTeamMember(memberToRemove.email));
    setMemberToRemove(null);
    closeRemoveModalModal();

    //TODO: delete API call
  }

  const sendInvites = (event) => {
    // TODO:  send invites API call
    const formdata = new FormData();
    formdata.append("emails", JSON.stringify(emails));
    dispatch(sendTeamInvites(formdata));
    closeModal();
  };

  function openModal(event) {
    event.preventDefault();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openRemoveModalModal(index) {
    setMemberToRemove(members[index]);
    setIsRemoveModalOpen(true);
  }

  function closeRemoveModalModal() {
    setIsRemoveModalOpen(false);
  }

  const hasEmails = emails.length > 0;
  const trimmedEmails = emails.map((email) => email.trim());
  const isDisabled =
    !hasEmails ||
    trimmedEmails.some(
      (email) => !validateEmail(email) || email === userEmail
    ) ||
    hasDuplicates(trimmedEmails);

  console.log(tier);

  if (tier !== "pro") {
    return (
      <Fragment>
        <H2>Team Management</H2>
        <p>Invite and manage members of your team from your account.</p>
        <FormContainer style={{ color: "blue" }}>
          Only, users on the PRO plan can add team members on their account!
          <br />
          Please,{" "}
          <Link to="/upgrade/choose-plan">
            <b style={{ textTransform: "underline" }}>upgrade your account</b>
          </Link>
          .
        </FormContainer>{" "}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <H2>Team Management</H2>
      <p>Invite and manage members of your team from your account.</p>
      <form onSubmit={openModal}>
        <FormContainer>
          {members.map((member, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  color: member.status === "accepted" ? "#07b355" : "#e2e0ec",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "10px",
                  textTransform: "uppercase",
                  marginTop: "1rem",
                }}
              >
                {member.status === "accepted" ? (
                  <HiCheckCircle
                    size={18}
                    color="#07b355"
                    style={{ marginRight: "0.25rem", cursor: "pointer" }}
                  />
                ) : (
                  <HiOutlineClock
                    size={18}
                    color="#e2e0ec "
                    style={{ marginRight: "0.25rem", cursor: "pointer" }}
                  />
                )}
                {member.status}
              </div>

              <div
                style={{
                  // maxWidth: "350px",
                  width: "calc(100% - 7rem)",
                }}
              >
                <Input
                  disabled
                  label="E-MAIL"
                  type="email"
                  size="10px"
                  value={member.email}
                  style={{ color: "#e2e0ec !important" }}
                  className="disabled-email"
                />
              </div>

              <Tooltip
                disableFocusListener
                disableTouchListener
                title="Remove the member from your team"
                placement="left"
              >
                <span>
                  <HiX
                    size={24}
                    color="#e2e0ec"
                    style={{ marginTop: "1rem", cursor: "pointer" }}
                    onClick={() => openRemoveModalModal(index)}
                  />
                </span>
              </Tooltip>
            </div>
          ))}
          {emails.map((email, index) => (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ width: "calc(100% - 2rem)" }}>
                <Input
                  onChange={(e) => updateEmail(index, e.target.value)}
                  label="E-MAIL"
                  type="email"
                  size="10px"
                  value={email}
                />
              </div>

              <Tooltip
                disableFocusListener
                disableTouchListener
                title="Cancel this new member"
                placement="left"
              >
                <span>
                  <HiX
                    size={24}
                    color="#e2e0ec"
                    style={{ marginTop: "1rem", cursor: "pointer" }}
                    onClick={() => removeEmail(index)}
                  />
                </span>
              </Tooltip>
            </div>
          ))}

          <AddButton onClick={addEmail} type="button" disabled={hasDowngraded}>
            <HiOutlinePlus size={14} style={{ marginRight: "0.25rem" }} />
            {members.length > 0 || emails.length > 0
              ? "Add More"
              : "Add Your First Member"}
          </AddButton>

          {hasDowngraded && (
            <p style={{ fontSize: "14px", color: "#ff0000" }}>
              Please, update your payment method before adding new members
            </p>
          )}
        </FormContainer>
        <StyledBtn
          type="submit"
          disabled={isDisabled}
          appearance="primary"
          width="100"
          onClick={openModal}
        >
          Send Invites
        </StyledBtn>
      </form>
      <TeamMembersModal
        close={closeModal}
        visible={isOpen}
        members={emails.length}
        sendInvites={sendInvites}
      />
      <DeleteTeamMemberModal
        close={closeRemoveModalModal}
        visible={isRemoveModalOpen}
        member={memberToRemove}
        removeMember={removeMember}
      />
    </Fragment>
  );
};

export default TeamManagement;

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function hasDuplicates(array) {
  return new Set(array).size !== array.length;
}
