import React from "react";
import styled from "styled-components";
import PreviewProjectCard from "../../../../global/PreviewProjectCard";
import nothing from "../../../../../assets/illustrations/nothing-found.svg";

const OuterCard = styled.div`
  border-radius: 10px;
  position: relative;

  ${props =>
    props.active &&
    `
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #3E21DE;
    border-radius: 10px;
  }
`}
`;

const NoProjects = styled.div`
  img {
    display: block
    margin: 32px auto 0 auto;
  }
  h4 {
    text-align: center;
  }
`;

const SearchResults = ({ searchResults, prepareToAdd, projectId }) => {
  return searchResults.length > 0 ? (
    searchResults.map((x, i) => (
      <OuterCard
        key={i}
        onClick={e => prepareToAdd(x.id)}
        active={projectId === x.id}
      >
        <PreviewProjectCard info={x} />
      </OuterCard>
    ))
  ) : (
    <NoProjects>
      <img src={nothing} alt="al" />
      <h4>Nothing found . . .</h4>
    </NoProjects>
  );
};

export default SearchResults;
