import React from "react";
import PropTypes from "prop-types";

const SvgBrand = props => (
  <svg width="1em" height="1em" viewBox="0 0 149 34" fill="none" {...props}>
    <path
      d="M0.0929337 3.20915H4.1779L11.0406 20.2026L17.2498 3.20915H21.1714L12.5439 24.7778H9.57006L0.0929337 3.20915Z"
      fill={props.fill}
    />
    <path
      d="M27.0468 24.7778H23.4521V9.7451H27.0468V24.7778ZM22.9292 4.9085C22.9292 4.60349 22.9836 4.30937 23.0926 4.02614C23.2233 3.74292 23.3976 3.49238 23.6155 3.27451C23.8333 3.03486 24.073 2.84967 24.3344 2.71896C24.6176 2.58824 24.9226 2.52288 25.2494 2.52288C25.5545 2.52288 25.8486 2.58824 26.1318 2.71896C26.415 2.84967 26.6656 3.03486 26.8834 3.27451C27.1231 3.49238 27.3083 3.74292 27.439 4.02614C27.5697 4.30937 27.6351 4.60349 27.6351 4.9085C27.6351 5.23529 27.5697 5.54031 27.439 5.82353C27.3083 6.08497 27.1231 6.32462 26.8834 6.54248C26.6656 6.76035 26.415 6.93464 26.1318 7.06536C25.8486 7.17429 25.5545 7.22876 25.2494 7.22876C24.6176 7.22876 24.073 7 23.6155 6.54248C23.1579 6.08497 22.9292 5.54031 22.9292 4.9085Z"
      fill={props.fill}
    />
    <path
      d="M36.1333 11.9346C35.4144 11.9346 34.8479 12.0763 34.434 12.3595C34.0418 12.6209 33.8457 13.0022 33.8457 13.5033C33.8457 13.8301 33.9547 14.1351 34.1725 14.4183C34.4122 14.6797 34.9024 14.8976 35.6431 15.0719L37.9634 15.5948C38.8784 15.7909 39.6083 16.0741 40.1529 16.4444C40.7194 16.8148 41.1551 17.2179 41.4601 17.6536C41.7869 18.0893 42.0048 18.5359 42.1137 18.9935C42.2227 19.451 42.2771 19.8758 42.2771 20.268C42.2771 20.9651 42.1355 21.6187 41.8523 22.2288C41.5691 22.817 41.166 23.329 40.6431 23.7647C40.1203 24.2004 39.4884 24.5381 38.7477 24.7778C38.007 25.0392 37.1791 25.1699 36.264 25.1699C35.1094 25.1699 34.0418 25.0065 33.0614 24.6797C32.081 24.3312 31.2967 23.9499 30.7085 23.5359L31.5582 20.4314C32.2989 21.1503 33.1268 21.6841 34.0418 22.0327C34.9786 22.3813 35.8392 22.5556 36.6235 22.5556C37.4514 22.5556 38.0832 22.3813 38.5189 22.0327C38.9547 21.6623 39.1725 21.1612 39.1725 20.5294C39.1725 20.159 39.0636 19.854 38.8457 19.6144C38.6279 19.3529 38.1922 19.1351 37.5386 18.9608L34.4013 18.1765C33.6606 18.0022 33.0505 17.7516 32.5712 17.4248C32.1137 17.098 31.7433 16.7386 31.4601 16.3464C31.1987 15.9325 31.0135 15.5076 30.9046 15.0719C30.8174 14.6362 30.7738 14.2222 30.7738 13.8301C30.7738 12.3704 31.2858 11.2484 32.3098 10.4641C33.3338 9.65795 34.6627 9.2549 36.2967 9.2549C37.1464 9.2549 38.0614 9.37473 39.0418 9.61438C40.0222 9.83224 40.8501 10.159 41.5255 10.5948L40.5778 13.5359C40.142 13.1874 39.532 12.8388 38.7477 12.4902C37.9634 12.1198 37.0919 11.9346 36.1333 11.9346Z"
      fill={props.fill}
    />
    <path
      d="M49.16 9.7451V19.5163C49.16 20.4096 49.3888 21.0959 49.8463 21.5752C50.3256 22.0327 50.9356 22.2614 51.6764 22.2614C52.4389 22.2614 53.1361 22.0109 53.7679 21.5098C54.3997 20.9869 54.8681 20.2462 55.1731 19.2876V9.7451H58.8006V24.7778H55.9901L55.794 22.6863H55.6633C55.4236 23.1874 55.1186 23.6013 54.7483 23.9281C54.3779 24.2331 53.964 24.4837 53.5064 24.6797C53.0707 24.854 52.6132 24.9739 52.1339 25.0392C51.6546 25.1264 51.1862 25.1699 50.7287 25.1699C50.0097 25.1699 49.3343 25.0828 48.7025 24.9085C48.0925 24.7342 47.5478 24.451 47.0685 24.0588C46.611 23.6449 46.2406 23.1111 45.9574 22.4575C45.696 21.7821 45.5653 20.9543 45.5653 19.9739V9.7451H49.16Z"
      fill={props.fill}
    />
    <path
      d="M72.4627 24.7778L72.2013 22.719H72.0706C71.4823 23.4597 70.6762 24.0588 69.6523 24.5163C68.6501 24.9521 67.6261 25.1699 66.5804 25.1699C65.2078 25.1699 64.1294 24.7778 63.3451 23.9935C62.5608 23.1874 62.1686 22.1307 62.1686 20.8235C62.1686 19.9303 62.3538 19.1351 62.7242 18.4379C63.1163 17.719 63.6719 17.1198 64.3908 16.6405C65.1098 16.1394 65.9704 15.7582 66.9725 15.4967C67.9747 15.2353 69.0967 15.1046 70.3385 15.1046H71.7438V14.6144C71.7438 13.7647 71.4714 13.1438 70.9268 12.7516C70.3603 12.3595 69.478 12.1634 68.2797 12.1634C66.6675 12.1634 65.2296 12.5229 63.966 13.2418L63.2797 10.5294C64.7394 9.67974 66.5041 9.2549 68.5738 9.2549C70.7525 9.2549 72.4083 9.70153 73.5412 10.5948C74.064 11.0087 74.4889 11.5425 74.8157 12.1961C75.1643 12.8497 75.3385 13.634 75.3385 14.549V24.7778H72.4627ZM67.9856 22.2614C68.6828 22.2614 69.3799 22.098 70.0771 21.7712C70.7743 21.4227 71.3298 20.9651 71.7438 20.3987V17.4248H71.1229C69.3364 17.4248 68.0292 17.6536 67.2013 18.1111C66.3952 18.5686 65.9921 19.2985 65.9921 20.3007C65.9921 21.6078 66.6566 22.2614 67.9856 22.2614Z"
      fill={props.fill}
    />
    <path
      d="M83.475 24.7778H79.8803V0.594772H83.475V24.7778Z"
      fill={props.fill}
    />
    <path
      d="M98.9287 14.9739H92.3928V21.5098H101.772V24.7778H88.6346V3.20915H101.118V6.47713H92.3928V11.7059H98.9287V14.9739Z"
      fill={props.fill}
    />
    <path
      d="M111.304 20.4314L115.487 9.7451H118.951L112.742 24.9085C112.437 25.671 112.012 26.5207 111.467 27.4575C110.944 28.4161 110.312 29.3094 109.572 30.1373C108.853 30.9869 108.036 31.695 107.121 32.2614C106.206 32.8497 105.214 33.1438 104.147 33.1438C103.972 33.1438 103.842 33.1329 103.755 33.1111C103.667 33.1111 103.569 33.0893 103.46 33.0458L102.84 30.2353C102.905 30.2571 102.981 30.2789 103.068 30.3007C103.155 30.3224 103.341 30.3333 103.624 30.3333C104.038 30.3333 104.441 30.2898 104.833 30.2026C105.247 30.1155 105.661 29.9412 106.075 29.6797C106.859 29.1787 107.535 28.4815 108.101 27.5882C108.689 26.695 109.256 25.5948 109.8 24.2876L102.317 9.7451H106.075L111.304 20.4314Z"
      fill={props.fill}
    />
    <path
      d="M128.289 9.2549C130.207 9.2549 131.688 9.7451 132.734 10.7255C133.78 11.6841 134.303 13.0458 134.303 14.8105C134.303 15.0501 134.281 15.3115 134.237 15.5948C134.215 15.8562 134.172 16.1176 134.106 16.3791C134.063 16.6405 133.998 16.8802 133.91 17.098C133.845 17.2941 133.769 17.4575 133.682 17.5882H124.172C124.215 18.2418 124.335 18.8627 124.531 19.451C124.727 20.0174 125.011 20.5185 125.381 20.9543C125.751 21.3682 126.198 21.7059 126.721 21.9673C127.265 22.207 127.897 22.3268 128.616 22.3268C129.422 22.3268 130.272 22.1743 131.165 21.8693C132.08 21.5643 132.854 21.183 133.486 20.7255L134.074 23.4052C133.791 23.5795 133.442 23.7756 133.028 23.9935C132.614 24.1895 132.146 24.3747 131.623 24.549C131.1 24.7233 130.533 24.8649 129.923 24.9739C129.335 25.1046 128.714 25.1699 128.061 25.1699C126.841 25.1699 125.762 24.963 124.825 24.549C123.889 24.1133 123.104 23.5359 122.472 22.817C121.841 22.0763 121.361 21.2266 121.035 20.268C120.708 19.3094 120.544 18.3072 120.544 17.2614C120.544 16.1939 120.708 15.1808 121.035 14.2222C121.383 13.2418 121.884 12.3813 122.538 11.6405C123.191 10.8998 123.998 10.3224 124.956 9.9085C125.937 9.47277 127.048 9.2549 128.289 9.2549ZM128.191 12.0654C127.276 12.0654 126.492 12.3377 125.838 12.8824C125.207 13.4052 124.749 14.1242 124.466 15.0392H130.708C130.73 14.9739 130.74 14.8976 130.74 14.8105C130.762 14.7233 130.773 14.6144 130.773 14.4837C130.773 13.7865 130.533 13.2092 130.054 12.7516C129.575 12.2941 128.954 12.0654 128.191 12.0654Z"
      fill={props.fill}
    />
    <path
      d="M142.534 11.9346C141.815 11.9346 141.249 12.0763 140.835 12.3595C140.442 12.6209 140.246 13.0022 140.246 13.5033C140.246 13.8301 140.355 14.1351 140.573 14.4183C140.813 14.6797 141.303 14.8976 142.044 15.0719L144.364 15.5948C145.279 15.7909 146.009 16.0741 146.554 16.4444C147.12 16.8148 147.556 17.2179 147.861 17.6536C148.188 18.0893 148.405 18.5359 148.514 18.9935C148.623 19.451 148.678 19.8758 148.678 20.268C148.678 20.9651 148.536 21.6187 148.253 22.2288C147.97 22.817 147.567 23.329 147.044 23.7647C146.521 24.2004 145.889 24.5381 145.148 24.7778C144.408 25.0392 143.58 25.1699 142.665 25.1699C141.51 25.1699 140.442 25.0065 139.462 24.6797C138.482 24.3312 137.697 23.9499 137.109 23.5359L137.959 20.4314C138.7 21.1503 139.527 21.6841 140.442 22.0327C141.379 22.3813 142.24 22.5556 143.024 22.5556C143.852 22.5556 144.484 22.3813 144.92 22.0327C145.355 21.6623 145.573 21.1612 145.573 20.5294C145.573 20.159 145.464 19.854 145.246 19.6144C145.029 19.3529 144.593 19.1351 143.939 18.9608L140.802 18.1765C140.061 18.0022 139.451 17.7516 138.972 17.4248C138.514 17.098 138.144 16.7386 137.861 16.3464C137.599 15.9325 137.414 15.5076 137.305 15.0719C137.218 14.6362 137.175 14.2222 137.175 13.8301C137.175 12.3704 137.686 11.2484 138.71 10.4641C139.734 9.65795 141.063 9.2549 142.697 9.2549C143.547 9.2549 144.462 9.37473 145.442 9.61438C146.423 9.83224 147.251 10.159 147.926 10.5948L146.978 13.5359C146.543 13.1874 145.933 12.8388 145.148 12.4902C144.364 12.1198 143.493 11.9346 142.534 11.9346Z"
      fill={props.fill}
    />
  </svg>
);

SvgBrand.propTypes = {
  fill: PropTypes.string
};

SvgBrand.defaultProps = {
  fill: "#3E21DE"
};

export default SvgBrand;
