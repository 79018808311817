import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link, Redirect } from "react-router-dom";

import useForm from "react-hook-form";
import { handleError } from "../../../redux/ducks/errors";
import SocialProofLayout from "../../layouts/SocialProofLayout";
import { config } from "../../../constants/endpoints";

// design system
import { Input, PasswordInput } from "desisystem";

// actions
import {
  createAppsumoUser,
  loginUser,
  setShowAppsumo,
  socialAuth,
  upgradeExistingUserFromAppsumo,
} from "../../../redux/ducks/auth";

import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";
import InlineErrorMessage from "../../ui/globals/InlineErrorMessage";
import getAllUrlParams from "../../../utils/GetAllUrlParams";
import { CircularProgress } from "@material-ui/core";
import { errorToast, successToast } from "../../../utils/helperFunctions";
import axios from "axios";

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.subHeading};
  }
`;

const FormBody = styled.div`
  margin-top: 40px;
`;

const NotMember = styled.div`
  margin-top: 16px;
  font-size: ${(props) => props.theme.fontSize[12]};
  color: ${(props) => props.theme.colors.black};

  a {
    color: ${(props) => props.theme.colors.brandPurple};
    text-decoration: none;
  }
`;

const AppsumoLogin = ({ location: { state = null }, ...props }) => {
  const [isLoading, setIsLoading] = React.useState(true);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [firstname, setFirstname] = useState("");
  const [password, setPassword] = useState("");
  const [futurePlan, setFuturePlan] = useState("");
  const [futureDuration, setFutureDuration] = useState("");
  const { isAuthenticated, bearerAuth } = useSelector((state) => state.auth);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const dispatch = useDispatch();
  const { errorMsg } = useSelector((state) => state.errors);
  const { loading } = useSelector((state) => state.loadings);

  // console.log(state);
  useEffect(() => {
    const id = props.match.params.id;
    setCode(id);

    if (isAuthenticated) {
      axios
        .post(`${config.URL}/user/appsumo/${id}`, null, {
          headers: { Authorization: `Bearer ${bearerAuth}` },
        })
        .then((res) => {
          successToast("You succesfully reedemed your AppSumo code!");
          const data = res.data;
          dispatch(setShowAppsumo(data));
          setIsLoading(false);
        })
        .catch((err) => {
          errorToast(err.response.data.reason);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (state !== null) {
      if (state.fromSiteData) {
        const { plan, duration } = state.fromSiteData;
        setFuturePlan(plan);
        setFutureDuration(duration);
      }
    }
  }, [futurePlan, futureDuration, state]);

  useEffect(() => {
    dispatch(handleError(null));
  }, [dispatch]);

  const handleSubmitLogin = (e) => {
    const userData = new FormData();
    userData.append("email", email);
    userData.append("password", password);
    userData.append("first_name", firstname);

    dispatch(createAppsumoUser(userData, code));
  };

  const isDisabled = email === "" || password === "" || firstname === "";

  if (isLoading) {
    return (
      <CircularProgress
        style={{ width: "26px", height: "26px", color: "#333" }}
      />
    );
  } else if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return (
    <SocialProofLayout isAppsumo>
      <AuthInnerContainer>
        <Heading>
          <h1>Welcome Sumo-lings!</h1>
          <p>
            Reedem the code: <b>{code}</b>
          </p>
        </Heading>

        <FormBody>
          <form onSubmit={handleSubmit(handleSubmitLogin)}>
            <div>
              <Input
                label="FIRST NAME"
                type="text"
                size="10px"
                onChange={(e) => setFirstname(e.target.value)}
                name="firstname"
              />
            </div>

            <div className={errors.loginEmail || errorMsg ? "is-valid" : ""}>
              <Input
                label="E-MAIL"
                type="email"
                size="10px"
                onChange={(e) => setEmail(e.target.value)}
                name="loginEmail"
                validate={register({
                  // pattern: /^([a-z\d\._]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
                  required: true,
                })}
              />
              {errors.loginEmail && (
                <span className="error-msg">Field must be a valid email</span>
              )}
            </div>
            <div className={errorMsg ? "is-valid" : ""}>
              <PasswordInput
                handleChange={setPassword}
                label="PASSWORD"
                name="password"
                size="10px"
                hasError={errors["password"]}
                register={register}
              />
            </div>

            {errorMsg && <InlineErrorMessage errorMsg={errorMsg} />}

            <div style={{ marginTop: "2rem" }}>
              <ButtonWithLoading
                loading={loading}
                title="Get Started"
                width="100"
                disabled={isDisabled}
              />
            </div>
          </form>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default AppsumoLogin;
