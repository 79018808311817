import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "desisystem";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import SvgLogo from "../../icons/SvgLogo";
import SvgBrand from "../../icons/SvgBrand";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideReportPop } from "../../../redux/ducks/storage";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const StyledSnack = styled(Snackbar)`
  && {
    .MuiSnackbarContent-root {
      background-color: #5940e3;
      padding: 16px;
      height: 228px;
      width: 340px;
      border-radius: 10px;
    }

    .MuiSnackbarContent-message {
      padding: 0;
      display: none;
    }

    .MuiSnackbarContent-action {
      margin: 0;
      padding: 0;
      height: 100%;
    }
  }
`;

const SnackbarBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & > div:first-child {
    margin-bottom: 10px;
  }

  span {
    font-weight: normal;
    font-size: 12px;
    color: #ffffff;
    cursor: pointer;
    margin-top: 16px;
  }

  p {
    margin-top: 24px;
    color: #fff;
    margin: 0;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
  }

  button {
    width: 200px;
    margin-top: 24px;
    background: #fff;
    color: #3e21de;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const SimpleSnackbar = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const redirectAndHide = () => {
    history.push("/auth/create");
    dispatch(hideReportPop());
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <StyledSnack
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        action={
          <SnackbarBody>
            <div>
              <SvgLogo fill="#fff" width={36} height={36} />
              <SvgBrand fill="#fff" width={110} height={28} />
            </div>
            <p>
              Optimize your testing process with the most accurate predictive
              analytics. Sign up now.{" "}
            </p>
            <Button onClick={() => redirectAndHide()}>Sign up for free</Button>
            <span onClick={() => dispatch(hideReportPop())}>No, thanks</span>
          </SnackbarBody>
        }
      />
    </div>
  );
};

export default SimpleSnackbar;
