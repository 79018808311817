import React, { useEffect, useState } from "react";
import styled from "styled-components";
// import { useHistory } from "react-router-dom";
import Masonry from "react-masonry-css";
import { Grid as GridIcon } from "zoozoo";
import BenchmarkIcon from "../../../../icons/BenchmarkIcon";
import { Button } from "desisystem";
import isEmpty from "../../../../../utils/helperFunctions";
import ZoomInOut from "../../../../ui/globals/ZoomInOut";

import { PREDICTION_TITLES } from "../../../../../constants/GenericContent";
import EachComparison from "./EachComparison";
import PredictReportCard from "../../../../ui/cards/PredictReportCard";

import ReportTemplate from "../../../../ui/templates/reportTemplate/ReportTemplate";
import LoadingTypewriter from "../../../../ui/loadings/LoadingTypewriter";
import PopUpBox from "../../../../ui/globals/PopUpBox";
import { gaViewGridMode } from "../../../../../utils/analytics";

const Grid = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 52px 32px 48px 32px;
  background: #fafafa;
`;

const Body = styled.div`
  width: ${(props) => props.width}%;
  margin: 0 auto;
  padding-bottom: 48px;

  .my-masonry-grid {
    display: flex;
    margin-left: -24px;
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 24px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 24px;
  }
`;

const PredictsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 24px;
`;

const ZoomButton = styled.div`
  position: fixed;
  left: 150px;
  bottom: 56px;
  z-index: 7;
`;

const ProjectReport = ({
  projectReport,
  groupImage,
  changeGridState,
  open,
  belongsToProject = false,
  history,
  loading,
  loadingDownload,
  completed,
  seenReportPop,
  isAuthenticated,
}) => {
  const { name, avatar, user_name, shared_compare, uid, tier } = projectReport;
  const { ORIGINAL, ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;
  const [type, setType] = useState(ATTENTION);
  const [checkType, setCheckType] = useState("attention");
  const [compareMode, setCompareMode] = useState(
    belongsToProject.belongsToProject ? false : true || true
  );
  const [scale, setScale] = useState(100);

  useEffect(() => {
    if (!shared_compare && shared_compare !== undefined) {
      setCompareMode(false);
    }
  }, [shared_compare]);

  const openPreviewModal = (id) => {
    let newGridState = groupImage.map((m, i) => {
      if (m.id === id) {
        return { ...m, open_modal: true };
      } else {
        return m;
      }
    });
    if (!open) {
      changeGridState(newGridState);
    }
  };

  const closeModal = (id) => {
    let newGridState = groupImage.map((m, i) => {
      if (m.id === id) {
        return { ...m, open_modal: false };
      } else {
        return m;
      }
    });

    changeGridState(newGridState);
  };

  const handleChange = (name) => {
    if (name === "original") {
      setCheckType(name);
      setType(ORIGINAL);
    } else if (name === "attention") {
      setCheckType(name);
      setType(ATTENTION);
    } else if (name === "aoi") {
      setCheckType(name);
      setType(AOI);
    } else if (name === "clarity") {
      setCheckType(name);
      setType(CLARITY);
    }
  };

  const findTypeUrl = (type, ori, att, cla) => {
    if (type === ORIGINAL || type === AOI) {
      return ori;
    } else if (type === ATTENTION) {
      return att;
    } else if (type === CLARITY) return cla;
  };

  const changeToGrid = () => {
    setCompareMode(!compareMode);
  };

  const changeGridWithAnalytics = () => {
    changeToGrid();
    gaViewGridMode();
  };

  const gridModeButton = () => {
    return compareMode ? (
      <Button appearance="secondary" onClick={() => changeGridWithAnalytics()}>
        <GridIcon stroke="#3E21DE" width={24} height={24} />
        <span>View Grid Mode</span>
      </Button>
    ) : (
      <Button appearance="secondary" onClick={() => changeToGrid()}>
        <BenchmarkIcon width={24} height={24} stroke="#3E21DE" />
        <span>View Compare Mode</span>
      </Button>
    );
  };

  const getScaleWidth = (newWidth) => {
    setScale(newWidth);
  };

  return (
    <ReportTemplate
      name={name}
      avatar={avatar}
      userName={user_name}
      gridModeButton={gridModeButton()}
      compareMode={compareMode}
      handleChange={handleChange}
      checkType={checkType}
      type={type}
      loading={loading}
      secondLoading={loadingDownload}
      sharedCompare={shared_compare}
      uid={uid}
    >
      <Grid>
        {compareMode ? (
          <Body width={scale}>
            {groupImage.length > 0 && !loading && !loadingDownload ? (
              <React.Fragment>
                <ZoomButton>
                  <ZoomInOut
                    width={scale}
                    getScaleWidth={getScaleWidth}
                    minScale={40}
                  />
                </ZoomButton>
                <Masonry
                  breakpointCols={2}
                  className="my-masonry-grid"
                  columnClassName="my-masonry-grid_column"
                >
                  {groupImage.map((compare, i) => {
                    let typeUrl = findTypeUrl(
                      type,
                      compare.original_serve_path,
                      compare.attention_serve_path,
                      compare.clarity_serve_path
                    );

                    return (
                      <EachComparison
                        key={i}
                        compare={compare}
                        openPreviewModal={openPreviewModal}
                        closeModal={closeModal}
                        type={type}
                        typeUrl={typeUrl}
                        tier={tier}
                      />
                    );
                  })}
                </Masonry>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {shared_compare ? (
                  <LoadingTypewriter completed={completed} uid={uid} />
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </Body>
        ) : (
          <Body width={100}>
            <div>
              {projectReport.length > 0 || !isEmpty(projectReport) ? (
                <PredictsGrid>
                  {projectReport.reports.map((report, i) => {
                    return <PredictReportCard key={i} report={report} />;
                  })}
                </PredictsGrid>
              ) : null}
            </div>
          </Body>
        )}

        {!seenReportPop && !isAuthenticated && !loading && !loadingDownload && (
          <PopUpBox />
        )}
      </Grid>
    </ReportTemplate>
  );
};

export default ProjectReport;
