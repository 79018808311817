import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SvgFigma from "../../../icons/plugin-logos/SvgFigma";
import SvgChrome from "../../../icons/plugin-logos/SvgChrome";
import SvgSketch from "../../../icons/plugin-logos/SvgSketch";
import SvgAdobe from "../../../icons/plugin-logos/SvgAdobe";

const Platform = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`;

const EachTool = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  justify-content: center;

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const CardPlatformIcon = ({ platform }) => {
  return (
    <Platform>
      {platform === "figma" && (
        <EachTool>
          <SvgFigma />
        </EachTool>
      )}
      {platform === "sketch" && (
        <EachTool>
          <SvgSketch />
        </EachTool>
      )}
      {platform === "adobeXD" && (
        <EachTool>
          <SvgAdobe />
        </EachTool>
      )}
      {platform === "chrome" && (
        <EachTool>
          <SvgChrome />
        </EachTool>
      )}
    </Platform>
  );
};

export default CardPlatformIcon;
