import React from "react";
import styled from "styled-components";
import { CircleMinus, CirclePlus } from "zoozoo";

const ZoomButtons = styled.div`
  background: #fff;
  border-radius: 50px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  width: auto;
  padding: 6px;
  height: auto;

  div:first-child {
    cursor: ${(props) => (props.minus ? "initial" : "pointer")};
    display: flex;
  }

  div:last-child {
    cursor: ${(props) => (props.plus ? "initial" : "pointer")};
    display: flex;
  }

  span {
    display: block;
    text-align: center;
    font-size: 14px;
    min-width: 1px;
    padding: 0 0.5rem;
    font-weight: 500;
  }
`;

const ZoomInOut = ({ width, getScaleWidth, minScale = 10 }) => {
  const zoomOut = (w) => {
    if (w > minScale) {
      getScaleWidth(w - 10);
    }
  };

  const zoomIn = (w) => {
    if (w < 100) {
      getScaleWidth(w + 10);
    }
  };

  return (
    <ZoomButtons
      minus={width === minScale ? 1 : 0}
      plus={width === 100 ? 1 : 0}
    >
      <div>
        <CircleMinus
          onClick={() => zoomOut(width)}
          width={24}
          height={24}
          stroke={width === minScale ? "#E0E0E0" : "#3E21DE"}
        />
      </div>
      <span>{width}%</span>
      <div>
        <CirclePlus
          onClick={() => zoomIn(width)}
          width={24}
          height={24}
          stroke={width === 100 ? "#E0E0E0" : "#3E21DE"}
        />
      </div>
    </ZoomButtons>
  );
};

export default ZoomInOut;
