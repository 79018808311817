import React from "react";
import { createGlobalStyle, css } from "styled-components";

const GlobalStyle = createGlobalStyle`
	@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
	body {
	background: #fafafa;
	overflow-x: hidden;
	margin: 0;
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	}

	* {
		box-sizing: border-box;
	} 	


	a {
		text-decoration: none;
		color: unset;
	}

	.bold-text {
		font-weight: bold;
	}	

	.error-msg {
		color: #D53C3C;
	}

	.is-valid {
		input {
			border-color: '#D53C3C';
			outline-color: '#D53C3C';
			&:focus,
			&:active {
				outline-color: '#D53C3C';
				border-color: '#D53C3C';
			}
		}
	}

	.input-type {
		position: relative;
		span {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.MuiDialog-paperFullScreen {
		border-radius: 0!important;
	}


    .MuiSnackbar-root {
      z-index: 10!important;
    }

	.MuiBackdrop-root {
        background-color: rgba(0, 0, 0, ${(props) =>
          props.modalOpacity}) !important;
      }

`;

export const NonSelectable = css`
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
`;

export const Trunk = css`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${"" /* width: 88%; */}
`;

export const GoogleButton = css`
  width: 100%;
  box-shadow: none !important;
  border: 2px solid #3e21de !important;
  border-radius: 4px !important;
  color: #3e21de !important;
  position: relative;
  margin-bottom: 8px;

  span {
    position: absolute;
    left: 50%;
    font-weight: bold !important;
    font-size: 14px;
    transform: translate(-50%);
    padding: 0 !important;
  }
`;

const Global = ({ modalOpacity = "0.8" }) => {
  return <GlobalStyle modalOpacity={modalOpacity} />;
};

export default Global;
