import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Button } from "desisystem";
import styled from "styled-components";
import Global from "../layouts/Global";
import woman from "../../assets/woman.png";
import { config } from "../../constants/endpoints";

const TOP_PADDING = "2rem";

const StyledPageNotFound = styled.div`
  padding: 2rem 0 0 0;
  text-align: center;
  background: #fff;
  background: #fff;
  height: calc(100vh - ${TOP_PADDING});
  display: flex;
  justify-content: center;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 4vh;
  }
`;

const Message404 = styled.div`
  h1 {
    color: #212121;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 500;
    width: 70%;
    margin: 0 auto 40px auto;
  }
`;

const StyledLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  font-size: 14px;
  min-height: 40px;
  color: rgb(255, 255, 255);
  outline: 0px;
  background: rgb(62, 33, 222);
  border-radius: 4px;
  padding: 0px 2rem;
  transition: all 0.4s ease 0s;
`;

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentWillMount() {
    if (process.env.REACT_APP_SENTRY !== "false") {
      Sentry.init({
        dsn: config.SENTRY,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    if (
      !process.env.NODE_ENV ||
      process.env.NODE_ENV === "development" ||
      process.env.REACT_APP_SENTRY === "false"
    ) {
      console.log(error);
    } else {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
      });

      Sentry.captureException(error);
    }
  }
  render() {
    if (this.state.error) {
      return (
        <div>
          <Global />
          <StyledPageNotFound>
            <div>
              <div style={{ marginBottom: "32px" }}>
                <img src={woman} alt="404" />
              </div>
              <Message404>
                <h1>
                  Looks like there is a problem. We're trying to resolve it as
                  soon as possible!
                </h1>
              </Message404>
              {/* <Button onClick={() => Sentry.showReportDialog()}>
                Report feedback
              </Button> */}
              <StyledLink href="https://www.visualeyes.design/contact">
                Report feedback
              </StyledLink>
            </div>
          </StyledPageNotFound>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
