import React from "react";

const Errorheck = props => (
  <svg width="1em" height="1em" viewBox="0 0 23 22" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 2.75V2.75C16.0568 2.75 19.75 6.44325 19.75 11V11C19.75 15.5568 16.0568 19.25 11.5 19.25V19.25C6.94325 19.25 3.25 15.5568 3.25 11V11C3.25 6.44325 6.94325 2.75 11.5 2.75Z"
      stroke="#D53C3C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 11.4583V6.875"
      stroke="#D53C3C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.4991 14.6667C11.3726 14.6667 11.2699 14.7693 11.2708 14.8958C11.2708 15.0223 11.3735 15.125 11.5 15.125C11.6265 15.125 11.7292 15.0223 11.7292 14.8958C11.7292 14.7693 11.6265 14.6667 11.4991 14.6667"
      stroke="#D53C3C"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Errorheck;
