import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import partying from "../../../../assets/illustrations/hooray.svg";
import Global from "../../../layouts/Global";
import SvgLogo from "../../../icons/SvgLogo";
import { Link } from "react-router-dom";
import { H1, Body } from "desisystem";
import { fetchUser, userSelector } from "../../../../redux/slices/user";

const SuccessLayout = styled.div`
  padding: 40px 80px 0 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;

  h1 {
    margin: 24px 0;
    color: #212121;
    font-size: 80px;
    font-weight: normal;
    text-align: center;
  }

  span {
    font-size: 22px;
    font-weight: normal;
    display: flex;
    align-items: baseline;
    color: #212121;

    p {
      text-align: center;
      color: #3e21de;
      font-size: 22px;
      margin: 0 0 0 6px;
      display: inline-block;
      font-weight: bold;
    }

    p::first-letter {
      text-transform: uppercase;
    }
  }

  button {
    margin-top: 50px;
  }

  img {
    margin-top: auto;
  }
`;

const StyleLink = styled(Link)`
  margin-top: 50px;
  text-decoration: none;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 14px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 0.8rem 2rem;

  &:hover {
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

const SuccessPage = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  return (
    <SuccessLayout>
      <Global />
      <div>
        <SvgLogo width={80} height={80} />
      </div>
      <div>
        <H1>Hooray!</H1>
        <span>
          You have an upgraded account now, <Body> {user.first_name}</Body>.
        </span>
      </div>
      <StyleLink to="/">Back to Dashboard</StyleLink>
      <img src={partying} alt="success" />
    </SuccessLayout>
  );
};

export default SuccessPage;
