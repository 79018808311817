import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { EditPen } from "zoozoo";
import useOnClickOutside from "../../custom-hooks/useOnClickOutside";

const MsgBox = styled.div`
  position: absolute;
  width: 100%;
  top: 0;

  p {
    color: red;
    font-size: 12px;
    margin: 0;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
  }

  button {
    font-size: ${(props) => props.theme.fontSize[14]};
    height: 40px;
    padding: 0 2rem;
  }

  span {
    font-size: 1.25rem;
    min-height: 40px;
    display: block;
    color: #3e21de;
    transition: all 0.3s;
    position: absolute;
    top: 8px;
    right: 4px;
    top: 0;
    padding-right: 8px;
    z-index: 7;
    font-weight: bold;
    font-size: 12px;
    color: #3e21de;
    cursor: pointer;
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const ValueBox = styled.div`
  span {
    font-size: ${(props) => props.theme.fontSize[20]};
    min-height: 40px;
    display: block;
    color: ${(props) => props.theme.colors.black};
    transition: all 0.3s;
    font-weight: normal;
    z-index: 0;
    width: 90%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    padding-right: 8px;
    z-index: 2;
    cursor: pointer;
    height: 40px;
  }
`;

const Editable = ({
  text,
  type,
  placeholder,
  children,
  childRef,
  name,
  updateInfo,
  id,
  setTask,
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);

  useEffect(() => {
    if (childRef && childRef.current && isEditing === true) {
      childRef.current.focus();
    }
  }, [isEditing, childRef]);

  const handleKeyDown = (event, type) => {
    const { key } = event;
    const enterKey = "Enter";
    const allKeys = [enterKey];
    if (type === "input" && allKeys.indexOf(key) > -1) {
      setEditing(false);
      updateInfo(text, id);
    }
  };

  const clickOutside = () => {
    setEditing(false);
    // updateInfo(text, id);
  };

  const ref = React.useRef();
  useOnClickOutside(ref, (e) => getPreviousValue(e));

  const getPreviousValue = () => {
    setTask(name);
  };

  return (
    <section {...props} ref={ref}>
      {text !== name ? (
        <MsgBox>
          {text === "" && <p>Name can not be empty</p>}

          <span className="act" onClick={() => updateInfo(text, id)}>
            UPDATE
          </span>
        </MsgBox>
      ) : (
        <EditPen stroke="#9E9E9E" />
      )}
      {isEditing ? (
        <div
          onBlur={() => clickOutside()}
          onKeyDown={(e) => handleKeyDown(e, type)}
        >
          {children}
        </div>
      ) : (
        <ValueBox onClick={() => setEditing(true)}>
          <span>{text || placeholder || "Editable content"}</span>
        </ValueBox>
      )}
    </section>
  );
};

export default Editable;
