import React, { useEffect } from "react";
import styled from "styled-components";
import Layout from "../../layouts/Layout";
import { useSelector, useDispatch } from "react-redux";
import ProfileSettings from "./screens/ProfileSettings";
import PasswordChange from "./screens/PasswordChange";
import TeamManagement from "./screens/TeamManagement";
import GenerateKey from "./screens/GenerateKey";
import { closeFeatureForAll } from "../../../redux/ducks/storage";
import { fetchUser, userSelector } from "../../../redux/slices/user";

const ProfileCardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  padding: 32px 48px;
  grid-auto-rows: min-content;
  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .error-msg {
    color: red;
    margin-top: 10px;
    display: block;
  }
`;

const ProfileCards = styled.div`
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 40px;
  display: grid;
  grid-auto-rows: auto;

  h2 {
    font-size: 22px;
    font-weight: bold;
  }
`;

const Profile = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(closeFeatureForAll());
  }, [dispatch]);

  const isEmpty = () => {
    return Object.entries(user).length === 0 && user.constructor === Object;
  };

  return (
    <Layout>
      <ProfileCardsContainer>
        <ProfileCards>
          {isEmpty() ? null : <ProfileSettings user={user} />}
        </ProfileCards>
        {!user.only_social && (
          <ProfileCards>
            <PasswordChange />
          </ProfileCards>
        )}
        {/* <ProfileCards>
          <TeamManagement members={user.team_members} />
        </ProfileCards> */}
        <div>
          <ProfileCards>
            <GenerateKey user={user} />
          </ProfileCards>
        </div>
      </ProfileCardsContainer>
    </Layout>
  );
};

export default Profile;
