import React from "react";
import styled from "styled-components";
import PredictCard from "../../../ui/cards/PredictCard";
import { Link } from "react-router-dom";
import FirstOpening from "../../openings/FirstOpening";
import OpeningInfo from "../../openings/OpeningInfo";
import { SECOND_PREDICTION_OPENING_CONTENT } from "../../../../constants/GenericContent";
import DASHBOARD_STATES from "../../../../constants/DashboardState";
import PredictionLoading from "../../../loadings/PredictionLoading";
import useDimensions from "react-use-dimensions";
import { DELETE_TYPES } from "../../../../constants/deleteTypes";
import { gaAllPredictions } from "../../../../utils/analytics";
import { config } from "../../../../constants/endpoints";

const Predicts = styled.div`
  & > h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 8px 0;
  }
`;

const PredictsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  & > p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.softGrey};
    margin: 0;
  }
`;

const PredictsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const StyledLink = styled(Link)`
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: ${(props) => props.theme.colors.brandPurple};
`;

const DashboardPredictions = ({
  latestPredictions,
  dashboardState,
  pathOrigin,
  uid,
}) => {
  const [ref, { width }] = useDimensions();

  // for loading cards
  let customWidth;
  let customViewport;
  if (width) {
    customWidth = width;
    customViewport = `0 0 ${width} 260`;
  }

  function renderDashboardContent(state) {
    switch (state) {
      case DASHBOARD_STATES.NONE:
        return !config.WHITE_LABEL.map((user) => user.id).includes(uid) ? (
          <OpeningInfo />
        ) : null;

      case DASHBOARD_STATES.ONLY_PROJECTS:
        return (
          <FirstOpening openingContent={SECOND_PREDICTION_OPENING_CONTENT} />
        );

      case DASHBOARD_STATES.BOTH:
      case DASHBOARD_STATES.ONLY_PREDICTION:
        return (
          <Predicts>
            <h2>Predictions</h2>
            <PredictsHeader>
              <p>Your latest uploads</p>
              <StyledLink to="/predictions" onClick={() => gaAllPredictions()}>
                View all predictions
              </StyledLink>
            </PredictsHeader>
            <PredictsGrid>
              {latestPredictions.map((prediction, i) => {
                return (
                  <div ref={ref} key={i}>
                    <PredictCard
                      prediction={prediction}
                      pathOrigin={pathOrigin}
                      deleteType={DELETE_TYPES.PREDICT}
                    />
                  </div>
                );
              })}
            </PredictsGrid>
          </Predicts>
        );

      case DASHBOARD_STATES.LOADING:
        return (
          <Predicts>
            <h2>Predictions</h2>
            <PredictsHeader>
              <p>Your latest uploads</p>
              <StyledLink to="/predictions">View all predictions</StyledLink>
            </PredictsHeader>
            <PredictsGrid>
              <PredictionLoading
                customWidth={customWidth}
                customViewport={customViewport}
              />
              <PredictionLoading
                customWidth={customWidth}
                customViewport={customViewport}
              />
              <PredictionLoading
                customWidth={customWidth}
                customViewport={customViewport}
              />
            </PredictsGrid>
          </Predicts>
        );
      default:
        return "error!!!";
    }
  }

  return renderDashboardContent(dashboardState);
};

export default DashboardPredictions;
