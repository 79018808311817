import React from "react";
import styled from "styled-components";
import FullPage from "../../../icons/FullPage";
import AboveTheFold from "../../../icons/AboveTheFold";
import Select from "react-select";
import { PREDICTION_TITLES } from "../../../../constants/GenericContent";
import ReactTooltip from "react-tooltip";
import ScoreRange from "./ScoreRange";
import CircleScore from "../../globals/CircleScore";
import { Question } from "zoozoo";
import { gaLearnMoreClarityRange } from "../../../../utils/analytics";

const SelectBox = styled.div`
  padding-top: 3px;

  p {
    font-size: ${(props) => props.theme.fontSize[10]};
    font-weight: bold;
    color: ${(props) => props.theme.primary[10]};
  }
`;

const TypeOfDesign = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  p {
    font-size: ${(props) => props.theme.fontSize[10]};
    font-weight: bold;
    color: ${(props) => props.theme.primary[10]};
  }
`;

const TypeBox = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: ${(props) => (props.disabled ? "none" : "")};
    width: 100px;
    height: 58px;
  }

  span {
    font-size: ${(props) => props.theme.fontSize[10]};
    text-align: center;
  }

  & > div:first-child {
    background: ${(props) =>
      props.type ? props.theme.primary[10] : props.theme.neutral[2]};
    border-radius: 4px 0 0 4px;

    svg {
      path:nth-child(1) {
        stroke: ${(props) =>
          props.type ? props.theme.neutral[2] : props.theme.primary[10]};
      }
      path:nth-child(2) {
        fill: ${(props) =>
          props.type ? props.theme.neutral[2] : props.theme.primary[10]};
      }
      path:nth-child(3) {
        fill: ${(props) =>
          props.type ? props.theme.neutral[2] : props.theme.primary[10]};
      }
      path:nth-child(4) {
        stroke: ${(props) =>
          props.type ? props.theme.neutral[2] : props.theme.primary[10]};
      }
    }

    span {
      margin-top: 4px;
      color: ${(props) =>
        props.type ? props.theme.neutral[2] : props.theme.primary[10]};
    }
  }

  & > div:last-child {
    background: ${(props) =>
      props.type ? props.theme.neutral[2] : props.theme.primary[10]};
    border-radius: 0 4px 4px 0;

    svg {
      path:nth-child(1) {
        stroke: ${(props) =>
          props.type ? props.theme.primary[10] : props.theme.neutral[2]};
      }
      path:nth-child(2) {
        fill: ${(props) =>
          props.type ? props.theme.primary[10] : props.theme.neutral[2]};
      }
      path:nth-child(3) {
        stroke: ${(props) =>
          props.type ? props.theme.primary[10] : props.theme.neutral[2]};
      }
      path:nth-child(4) {
        stroke: ${(props) =>
          props.type ? props.theme.primary[10] : props.theme.neutral[2]};
      }
    }

    span {
      margin-top: 4px;
      color: ${(props) =>
        props.type ? props.theme.primary[10] : props.theme.neutral[2]};
    }
  }
`;

const ScoreRangeBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  p {
    position: absolute;
    font-size: ${(props) => props.theme.fontSize[10]};
    font-weight: bold;
    color: ${(props) => props.theme.primary[10]};
  }
`;

const QuestionBox = styled.div`
  display: flex;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 0.9fr 1.1fr;
  height: 280px;
  padding: 40px;
`;

const LeftSide = styled.div`
  border-right: 1px solid #e0e0e0;
  display: grid;
  padding-right: 36px;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
`;

const MainText = styled.p`
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #212121;
  max-width: 225px;

  span {
    font-weight: bold;
  }
`;

const SmallText = styled.p`
  font-style: italic;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #9e9e9e;
  max-width: 225px;
`;

const RightSide = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const SideMetric = styled.div`
  border: 1px solid #3e21de;
  border-radius: ${(props) => props.theme.radius.soft};
  background: #f1effd;
  padding: 10px;
  margin-left: 16px;
  width: 160px;
  display: flex;
  justify-content: center;
`;

const SideMetricBox = styled.div`
  text-align: center;

  h5 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[10]};
    text-align: center;
    color: #9b8cee;
    letter-spacing: 0.2em;
  }

  & > p {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #3e21de;
    margin: 12px 0 0 0;
  }

  span {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #3e21de;
    display: block;
    text-align: center;
    margin-top: 2px;

    p {
      margin: 0;
      display: inline-block;
    }

    p::first-letter {
      text-transform: uppercase;
    }
  }
`;

const Dimensions = styled.div`
  margin: 0 auto;
  background: #fff;
  width: 80px;
  border-radius: ${(props) => props.theme.radius.round};
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const selectStyles = {
  container: (provided) => ({
    ...provided,
    margin: "0 10px 0px 0px",
    textAlign: "left",
    display: "inline-block",
    height: "32px",
  }),
  control: (provided) => ({
    ...provided,
    minWidth: "160px",
    border: "1px solid #1d0f68",
    borderRadius: 50,
    padding: "0 5px",
    minHeight: "32px",
    height: 32,
    background: "#fafafa",
    flexWrap: "nowrap",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#1d0f68",
    position: "static",
    transform: "translateY(0%)",
    fontSize: 14,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
  indicatorContainer: (provided) => ({
    ...provided,
    color: "red",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: 12,
    backgroundColor: "transparent",
    fontWeight: "500",
    color: "#1D0F68",
  }),
  menuList: (provided) => ({
    ...provided,
    height: "150px",
  }),
};

const ClarityRangeBox = ({
  type,
  model,
  sortedCategories,
  alteredCategories,
  selectCategory,
  selectFold,
  isFullScreen,
  clarityScore,
  category,
  categoryLabel,
  designMetric,
}) => {
  const { CLARITY } = PREDICTION_TITLES;

  const medianMetric = designMetric[category].median;
  const standardDeviationMetric = designMetric[category].standardDeviation;
  const showMetricBox =
    clarityScore > medianMetric - standardDeviationMetric &&
    clarityScore < medianMetric + standardDeviationMetric;

  return (
    <React.Fragment>
      {type === CLARITY && model !== "mobile" && (
        <Grid>
          <LeftSide>
            <div>
              <MainText>
                Clarity Ranges are the recommended range for your design's
                clarity score. They are dependent on a{" "}
                <span> specific category </span> and the
                <span> type of design</span>.
              </MainText>
              <SmallText>
                Ranges have been established via large-scale analysis of
                websites throughout the web.
              </SmallText>
              <QuestionBox>
                <a
                  data-tip
                  data-for="question"
                  href="https://bit.ly/2SzeRn1"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => gaLearnMoreClarityRange()}
                >
                  <Question width={26} height={26} />
                </a>

                <ReactTooltip id="question" effect="solid" place={"top"}>
                  <span>
                    Learn more <br /> about Clarity Ranges
                  </span>
                </ReactTooltip>
              </QuestionBox>
            </div>

            <div>
              <SelectBox>
                <p>Category:</p>
                <Select
                  options={sortedCategories}
                  styles={selectStyles}
                  onChange={(e) => selectCategory(e.value, e.label)}
                  defaultValue={alteredCategories[6]}
                  isDisabled={model === "mobile"}
                />
              </SelectBox>
              <TypeOfDesign>
                <p>Type of design:</p>
                <TypeBox
                  type={isFullScreen ? 1 : 0}
                  disabled={model === "mobile" ? 1 : 0}
                >
                  <div
                    type={isFullScreen ? 1 : 0}
                    onClick={() => selectFold(true)}
                  >
                    <FullPage width={22} height={22} />
                    <span>Full Page</span>
                  </div>
                  <div
                    onClick={() => selectFold(false)}
                    type={!isFullScreen ? 1 : 0}
                  >
                    <AboveTheFold width={22} height={22} />
                    <span>1st Screenfull</span>
                  </div>
                </TypeBox>
              </TypeOfDesign>
            </div>
          </LeftSide>

          <RightSide>
            <ScoreRangeBox>
              <ScoreRange
                category={category}
                score={clarityScore}
                samplesNumber={designMetric[category].samplesNumber}
                median={designMetric[category].median}
                standardDeviation={designMetric[category].standardDeviation}
                customHeight="150px"
              />
            </ScoreRangeBox>
            {showMetricBox && (
              <SideMetric>
                <SideMetricBox>
                  <h5>YOUR SCORE</h5>
                  <Dimensions>
                    <CircleScore score={clarityScore} />
                  </Dimensions>
                  <p>
                    Top{" "}
                    {Math.round(designMetric[category].standardDeviation * 2)}%
                  </p>
                  <span>
                    in <p>{categoryLabel}</p>
                  </span>
                </SideMetricBox>
              </SideMetric>
            )}
          </RightSide>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default ClarityRangeBox;
