import React from "react";
import styled from "styled-components";
import ProjectOptions from "./screens/ProjectOptions";
import { Link } from "react-router-dom";
import ProjectIcon from "../../icons/ProjectIcon";

const CardContainer = styled.div`
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: ${(props) => props.theme.radius.soft};
  min-height: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 48px 0 0;
  box-sizing: border-box;
  margin-bottom: 24px;
  background: #fff;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.12);
    transition: all 0.3s;
  }
`;

const CardContentBox = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 180px;
  display: flex;
  align-items: center;
  padding: 0 0 0 48px;

  span {
    color: ${(props) => props.theme.neutral[4]};
    font-size: ${(props) => props.theme.fontSize[14]};
  }
`;

const RightContent = styled.div`
  display: flex;
  margin-left: 80px;

  & > div {
    margin: 0 0 0 40px;
  }

  h3 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;

  & > div:last-child {
    margin: 0 0 0 40px;
    max-width: 180px;
    min-width: 180px;
  }

  h2 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize[20]};
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    word-break: break-word;
  }
`;

const CardOptionsBox = styled.div`
  margin: 0 22px;
`;

const LargeProjectCard = ({ cardInfo, deleteType, pathname }) => {
  return (
    <CardContainer>
      <CardContentBox
        to={{
          pathname: `/projects/${cardInfo.id}`,
          state: {
            pathname,
          },
        }}
      >
        <LeftContent>
          <div>
            <ProjectIcon width={38} height={38} />
          </div>

          <div>
            <h2>{cardInfo.name}</h2>
            <span>Created at {cardInfo.created_at}</span>
          </div>
        </LeftContent>
        <RightContent>
          <div>
            <h3>{cardInfo.total_predictions}</h3>
            <span>Predictions</span>
          </div>
        </RightContent>
      </CardContentBox>
      <CardOptionsBox>
        <ProjectOptions
          projectId={cardInfo.id}
          predictionsLength={cardInfo.predictions.length}
          deleteType={deleteType}
          pathOrigin={pathname}
        />
      </CardOptionsBox>
    </CardContainer>
  );
};

export default LargeProjectCard;
