import React from "react";
import styled from "styled-components";
import {
  PREDICTION_TITLES,
  REPORT_CONTENT,
} from "../../../../constants/GenericContent";
import { Warning } from "zoozoo";

const { ORIGINAL, ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;

const MetricBox = styled.div`
  border-radius: 10px;
  padding: 20px 0;
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #1d0f68;
    margin: 0 0 0 16px;
  }
`;

const MetricBoxHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h5 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #1d0f68;
    margin: 0 0 0 16px;
    white-space: nowrap;
  }
`;

const MetricText = ({ type }) => {
  return (
    <MetricBox>
      <MetricBoxHeader>
        {type !== ORIGINAL && (
          <Warning stroke="#3E21De" width={20} height={20} />
        )}
        <h5>
          {type === ATTENTION && "Attention Map"}
          {type === CLARITY && "Clarity Score"}
          {type === AOI && "Areas of Interest"}
        </h5>
      </MetricBoxHeader>
      <p>
        {type === ATTENTION && REPORT_CONTENT.ATTENTION_MAP}
        {type === CLARITY && REPORT_CONTENT.CLARITY_SCORE}
        {type === AOI && REPORT_CONTENT.AOI_CONTENT}
      </p>
    </MetricBox>
  );
};

export default MetricText;
