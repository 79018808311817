import React from "react";
import styled from "styled-components";
import Global from "../../../layouts/Global";
import ReportNav from "../../navigation/reportNavigation/ReportNav";

const StyledLayout = styled.div`
  padding: 0;
  background: ${(props) => props.theme.colors.globalBg};
  min-height: 100vh;
  position: relative;
`;

const ReportLayout = ({
  name,
  avatar,
  userName,
  compareMode,
  backToProject = null,
  handleChange,
  checkType,
  gridModeButton = null,
  type,
  loading,
  secondLoading = null,
  sharedCompare,
  uid,
  ...props
}) => {
  return (
    <div>
      <Global />
      <StyledLayout>
        {secondLoading && compareMode ? null : (
          <ReportNav
            name={name}
            avatar={avatar}
            userName={userName}
            gridModeButton={gridModeButton}
            compareMode={compareMode}
            backToProject={backToProject}
            handleChange={handleChange}
            checkType={checkType}
            type={type}
            loading={loading}
            secondLoading={secondLoading}
            sharedCompare={sharedCompare}
            uid={uid}
          />
        )}

        {props.children}
      </StyledLayout>
    </div>
  );
};

export default ReportLayout;
