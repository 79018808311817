// state
const initialState = {
  loading: false
};

export const LOADING = "auth/LOADING";
export const UN_LOAD = "auth/UN_LOAD";

// actions
export const load = () => dispatch => {
  dispatch({
    type: LOADING
  });
};

export const unLoad = () => dispatch => {
  dispatch({
    type: UN_LOAD
  });
};

// reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case UN_LOAD:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
}
