import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Button } from "desisystem";
import Tooltip from "@material-ui/core/Tooltip";
import { Link } from "react-router-dom";
// import ConfirmPaymentModal from "../../../ui/modals/ConfirmPaymentModal";

const Card = styled.div`
  height: 100%;
  position: relative;
  margin: 16px;

  background: #fff;
  padding: 48px 48px 32px 48px;
  max-width: 290px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  button {
    margin-top: 32px;
  }

  .active {
    border: 2px solid #3e21de;
  }
`;

const FreeCardBody = styled.div`
  & > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
      font-size: 14px;
      color: #212121;
    }
  }
`;

const EachOption = styled.div`
  margin-top: 8px;

  svg {
    stroke: ${props => (props.disabled ? "#C9C9C9" : "#3E21DE")};
  }

  span {
    display: flex;
    align-items: center;

    img {
      width: 18px;
      margin-right: 4px;
    }

    strong {
      margin-right: 4px;
    }
  }
`;

const CardImage = styled.div`
  img {
    width: 70%;
    margin: 0 auto;
    display: block;
  }
`;

const CardMiddle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;

  h2::first-letter {
    text-transform: uppercase;
  }

  h2 {
    font-weight: bold;
    font-size: 26px;
    color: #212121;
    margin: 0 0 8px 0;

    img {
      margin-left: 4px;
      transform: translateY(2px);
    }
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    color: #7864e8;
    margin: 0 0 8px 0;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
  }

  button {
    max-width: 160px;
    padding: 0;
  }
`;

// const StyleLink = styled(Link)`
//   margin-top: 32px;
//   text-decoration: none;
//   cursor: pointer;
//   font-weight: bold;
//   line-height: 1;
//   outline: 0;
//   border: none;
//   font-size: 14px;
//   background: #3e21de;
//   border-radius: 4px;
//   color: #fff;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   &:hover {
//     box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
//   }
// `;

const PlanCard = ({
  plan,
  tag,
  // custom,
  // proration,
  // proPrice,
  buttonCardStates
}) => {
  // const [open, setOpen] = useState(false);

  // const openModal = () => {
  //   setOpen(true);
  // };

  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <Card>
      <CardImage>{/* <img src={plan.image} alt="img" /> */}</CardImage>

      <CardMiddle>
        <div>
          <h2>
            {plan.title} {tag && <img src={tag} alt="tag" />}
          </h2>
          <h3>{plan.price} </h3>
          <p>{plan.description}</p>
        </div>
      </CardMiddle>

      <FreeCardBody>
        {plan.options.map((f, i) => {
          return (
            <EachOption key={i} disabled={f.disabled}>
              {f.icon}
              <Tooltip title={f.tooltip} placement="top" arrow>
                {f.option}
              </Tooltip>
            </EachOption>
          );
        })}
      </FreeCardBody>
      {buttonCardStates}
      {/* <ConfirmPaymentModal
        open={open}
        closeModal={closeModal}
        proration={proration}
        proPrice={proPrice}
      /> */}
    </Card>
  );
};

export default PlanCard;
