import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "desisystem";
import { PREDICTION_TITLES } from "../../../../constants/GenericContent";
import { AddAoi, ChevronUp, MagGlass } from "zoozoo";
import AttentionSwitch from "../../../ui/elements/prediction/AttentionSwitch";
import IntensitySwitch from "../../../ui/elements/prediction/IntensitySwitch";
import ClarityDrawer from "../../../ui/elements/prediction/ClarityDrawer";
import ClarityRangeBox from "../../../ui/elements/prediction/ClarityRangeBox";

const FooterBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
`;

const Footer = styled.footer`
  background: #fff;
  display: flex;
  align-items: center;
  height: 100%;
`;

const AoiFooter = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100%;
  max-width: 1920px;
  padding: 0 40px;

  & > div:first-child {
    display: flex;
  }

  & > div:last-child {
    justify-self: end;
    display: flex;
  }
`;

const EmptyAoi = styled.div`
  display: flex;
  align-items: center;
  justify-self: center;

  h4 {
    font-weight: bold;
    font-size: 14px;
    color: #212121;
    margin: 0 16px 0 0;

    span {
      color: ${(props) => props.theme.colors.brandPurple};
    }
  }

  button {
    color: #3e21de;
    background: transparent;
    padding: 4px;
    text-decoration: underline;
    min-height: initial;

    &:hover {
      box-shadow: none;
    }
  }
`;

const ImageAction = styled.div`
  justify-self: end;

  button {
    width: 120px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 0 1rem;
  }
`;

const ClarityFooter = styled.footer`
  width: 100%;
  height: 100%;

  & > div {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    max-width: 1920px;
    padding: 0 40px;
    height: 100%;
    cursor: pointer;
  }
`;

const ClarityBarTag = styled.div`
  display: flex;
  align-items: center;

  h4 {
    font-weight: normal;
    font-size: 14px;
    margin: 0 0 0 16px;
    color: #212121;
  }
`;

const ClarityBarIcon = styled.div`
  justify-self: end;
  align-self: center;

  svg {
    cursor: pointer;
  }
`;

const SinglePredictionFooter = ({
  type,
  attentionInAoi,
  handleAttentionInAoi,
  prediction,
  openEditAoiModal,
  model,
  sortedCategories,
  alteredCategories,
  selectCategory,
  selectFold,
  isFullScreen,
  clarityScore,
  category,
  categoryLabel,
  designMetric,
  isAoiTypePercentage,
  handleAoiMetricType,
  handleAoiMetricTypeClick,
}) => {
  const { CLARITY, AOI } = PREDICTION_TITLES;
  const [clarityDrawer, setClarityDrawer] = useState(false);
  const { areas } = prediction;

  const openDrawer = () => {
    setClarityDrawer(true);
  };

  const closeDrawer = () => {
    setClarityDrawer(false);
  };

  return (
    <FooterBar clarityDrawer={clarityDrawer}>
      <Footer>
        {type === AOI && (
          <AoiFooter>
            <div>
              <IntensitySwitch
                checked={!isAoiTypePercentage}
                onChangeSwitch={handleAoiMetricType}
                onClickSwitch={handleAoiMetricTypeClick}
              />
              <AttentionSwitch
                checked={attentionInAoi}
                onChangeSwitch={handleAttentionInAoi}
              />
            </div>
            <div>
              <EmptyAoi>
                {areas.length === 0 ? (
                  <React.Fragment>
                    <h4
                      style={{
                        fontWeight: "normal",
                        color: "#9E9E9E",
                      }}
                    >
                      No AOI detected.
                    </h4>
                  </React.Fragment>
                ) : (
                  <h4>
                    <span>{areas.length}</span> AOI detected.
                  </h4>
                )}
              </EmptyAoi>
              <ImageAction>
                <Button
                  appearance="secondary"
                  onClick={() => openEditAoiModal()}
                >
                  <AddAoi stroke="#3E21DE" width={20} height={20} />
                  {areas.length === 0 ? "Add AΟΙ" : "Edit AΟΙ"}
                </Button>
              </ImageAction>
            </div>
          </AoiFooter>
        )}
        {type === CLARITY && (
          <ClarityFooter>
            <div onClick={() => openDrawer()}>
              <ClarityBarTag>
                <MagGlass stroke="#3E21DE" width={26} height={26} />
                <h4>Clarity Range Recommendation</h4>
              </ClarityBarTag>

              <ClarityBarIcon>
                <ChevronUp width={30} height={30} />
              </ClarityBarIcon>
            </div>

            <ClarityDrawer
              clarityDrawer={clarityDrawer}
              closeDrawer={closeDrawer}
              openDrawer={openDrawer}
            >
              <ClarityRangeBox
                type={type}
                model={model}
                sortedCategories={sortedCategories}
                alteredCategories={alteredCategories}
                selectCategory={selectCategory}
                selectFold={selectFold}
                isFullScreen={isFullScreen}
                clarityScore={clarityScore}
                category={category}
                categoryLabel={categoryLabel}
                designMetric={designMetric}
              />
            </ClarityDrawer>
          </ClarityFooter>
        )}
      </Footer>
    </FooterBar>
  );
};

export default SinglePredictionFooter;
