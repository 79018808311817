import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadows.cardShadow};
  border-radius: ${(props) => props.theme.radius.soft};
`;

const UpperCard = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  min-height: 158px;
  box-sizing: border-box;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

const BottomCard = styled.div`
  padding: 24px 16px 24px 16px;
  max-height: 82px;
  box-sizing: border-box;
  background: #fff;

  p {
    color: ${(props) => props.theme.neutral[4]};
    font-size: ${(props) => props.theme.fontSize[10]};
    margin: 0;

    a {
      font-size: ${(props) => props.theme.fontSize[10]};
      color: ${(props) => props.theme.primary[5]};
      margin-right: 6px;
    }
  }
`;

const CardTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h3 {
    margin: 0 0 6px 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 90%;
  }

  span {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[10]};
    color: ${(props) => props.theme.neutral[4]};
  }
`;

const PreviewPredictCard = ({ backgroundImage, fileName, size }) => {
  return (
    <CardContainer>
      <UpperCard url={backgroundImage}></UpperCard>
      <BottomCard>
        <CardTitle>
          <div>
            <h3>{fileName}</h3>
            <span>SIZE: {size}</span>
          </div>
        </CardTitle>
      </BottomCard>
    </CardContainer>
  );
};

export default PreviewPredictCard;
