import React from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

// screens
import Navbar from "./screens/Navbar";
import { subscriptionSelector } from "../../../../redux/slices/subs";
import { userSelector } from "../../../../redux/slices/user";

const NavbarContainer = (props) => {
  const { user } = useSelector(userSelector);
  const { subs } = useSelector(subscriptionSelector);
  const { pathname } = props.location;

  const { is_verified, avatar, credits_left, api_key, uid } = user;
  const { tier, custom } = subs;

  return (
    <Navbar
      avatar={avatar}
      requests={credits_left}
      apiKey={api_key}
      verified={is_verified}
      tier={tier}
      pathname={pathname}
      custom={custom}
      uid={uid}
    />
  );
};

export default withRouter(NavbarContainer);
