import React, { Fragment, useState } from "react";
import styled from "styled-components";
import useForm from "react-hook-form";
import { Button, H2, PasswordInput } from "desisystem";
import { useDispatch } from "react-redux";

// actions
import { updatePassword } from "../../../../redux/slices/user";

const PasswordForm = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;

  & + p {
    color: red;
    margin-bottom: 0;
  }

  input::placeholder {
    color: #e2e0ec;
  }
`;

const StyledBtn = styled(Button)`
  margin-top: 80px;
`;

const PasswordChange = () => {
  const dispatch = useDispatch();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const { register, handleSubmit, errors } = useForm({
    reValidateMode: "onSubmit",
  });

  const changePassword = (e) => {
    const passwordData = new FormData();
    passwordData.append("old_password", oldPassword);
    passwordData.append("new_password", newPassword);

    dispatch(updatePassword(passwordData));
  };

  const isDisabled =
    newPassword === "" || oldPassword === "" || newPassword !== repeatPassword;

  const isSame =
    newPassword !== "" &&
    repeatPassword !== "" &&
    newPassword !== repeatPassword;

  return (
    <Fragment>
      <H2>Password</H2>
      <form onSubmit={handleSubmit(changePassword)}>
        <PasswordForm>
          <PasswordInput
            handleChange={setOldPassword}
            label="CURRENT PASSWORD"
            size="10px"
            placeholder="Your current password"
            name="old_password"
            hasError={errors["old_password"]}
            register={register}
          />
          <PasswordInput
            handleChange={setNewPassword}
            label="NEW PASSWORD"
            size="10px"
            placeholder="Your new password"
            name="new_password"
            hasError={errors["new_password"]}
            register={register}
          />
          <PasswordInput
            handleChange={setRepeatPassword}
            label="REPEAT NEW PASSWORD"
            size="10px"
            placeholder="Repeat your new password"
            name="repeat_new_password"
            hasError={errors["repeat_new_password"]}
            register={register}
          />
        </PasswordForm>
        {isSame && <p>Passwords don't match</p>}
        <StyledBtn
          type="submit"
          disabled={isDisabled}
          appearance="primary"
          width="100"
        >
          Save new password
        </StyledBtn>
      </form>
    </Fragment>
  );
};

export default PasswordChange;
