import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Sharing, ArrowLeft } from "zoozoo";
import BenchmarkIcon from "../../../icons/BenchmarkIcon";

import GlobalCreationButton from "../../../ui/buttons/GlobalCreationButton";
import PredictCard from "../../../ui/cards/PredictCard";
import { Button } from "desisystem";
import FullScreenModal from "../../../modals/FullScreenModal";
import ProjectPageOptions from "../../../ui/cards/screens/ProjectPageOptions";
import ReportModal from "../../../ui/modals/ReportModal";
import EmptyHistory from "../../openings/EmptyHistory";

import { DELETE_FULLSCREEN_CONTENT } from "../../../../constants/GenericContent";
import { DELETE_TYPES } from "../../../../constants/deleteTypes";
import { EMPTY_HISTORY_SINGLE_PROJECT } from "../../../../constants/GenericContent";

import {
  updateProjectInfo,
  toggleSharing,
  toggleCompareAvailability,
} from "../../../../redux/slices/projects";
import {
  globalCreationMenu,
  globalActionsSelector,
} from "../../../../redux/slices/globalActions";
import { gaViewCompareMode } from "../../../../utils/analytics";
import CustomEditBox from "../../../ui/globals/CustomEditBox";
import { isEmpty } from "../../../../utils/helperFunctions";

const { PROJECT_CONTENT } = DELETE_FULLSCREEN_CONTENT;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 24px;
`;

const ProjectsSidebar = styled.div`
  height: calc(100vh - 80px);
  position: sticky;
  top: 80px;
  background: #fff;
  padding: 0 0 50px 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    height: 100%;
    width: calc(50vh);
    left: -50vh;
  }

  h1 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[12]};
    margin: 0 0 8px 0;
    width: 90%;
  }
`;

const GlobalBtn = styled.div`
  position: absolute;
  right: 50px;
  bottom: 50px;
`;

const PredictsGrid = styled.div`
  padding: 94px 40px 48px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GoBack = styled.div`
  position: relative;
  margin: 72px 0 16px 0;
`;

const GoToPreviousPage = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]};
  cursor: pointer;

  svg {
    margin-right: 6px;
    transition: all 0.3s;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};

    svg {
      margin-right: 6px;
      transform: translateX(-2px);
      transition: all 0.3s;
    }
  }
`;

const SidebarHeader = styled.div`
  position: relative;
  padding-right: 24px;
`;

const SidebarContent = styled.div`
  padding-right: 24px;
  padding-top: 16px;

  h3 {
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #9e9e9e;
  }

  p {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
  }
`;

const PredictionNum = styled.div`
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  display: flex;
  align-items: center;

  h4 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #212121;
    margin: 16px 0;
  }

  span {
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #9e9e9e;
    margin-left: 4px;
  }
`;

const ProjectSidebarActions = styled.div`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    span {
      margin-left: 6px;
    }
  }
`;

const VerticalMenu = styled.div`
  transform: rotate(90deg);
  margin: 0 auto;
  position: absolute;
  right: 20px;
  top: 10px;

  svg {
    fill: #212121;
    width: 18px;
    height: 18px;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 14px;
  min-height: 40px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 0 2rem;
  width: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  height: 40px;

  span {
    margin-left: 6px;
  }
`;

const SingleProject = ({
  project = null,
  pathOrigin,
  directShare = false,
  history,
  tier,
  parentPath,
}) => {
  const { projectIdForGlobal, deleteModalOpen } = useSelector(
    globalActionsSelector
  );
  const [openSharing, setOpenSharing] = useState(directShare ? true : false);
  const [paginationIds, setPaginationIds] = useState([]);
  const dispatch = useDispatch();

  const { name, id, shareable, report_url, shared_compare } = project;

  useEffect(() => {
    getPredictionId();
  }, []);

  const getPredictionId = () => {
    let groupId = !isEmpty(project) ? project.predictions.map((n) => n.id) : 0;
    setPaginationIds(groupId);
  };

  useEffect(() => {
    dispatch(globalCreationMenu(id));
  }, [dispatch, id]);

  const closeSharingModal = () => {
    setOpenSharing(false);
    history.replace();
  };

  const updateInfo = (newName, id) => {
    const projectInfo = new FormData();
    projectInfo.append("id", id);
    projectInfo.append("name", newName);

    dispatch(updateProjectInfo(projectInfo, id, newName));
  };

  const handleSharingState = () => {
    const reportData = new FormData();
    reportData.append("id", id);
    reportData.append("shareable", !shareable);

    dispatch(toggleSharing(reportData, id, !shareable));
  };

  const handleCompareViewState = () => {
    const reportData = new FormData();
    reportData.append("id", id);
    reportData.append("shared_compare", !shared_compare);

    dispatch(toggleCompareAvailability(reportData, id, !shared_compare));
  };

  return (
    <Grid>
      <ProjectsSidebar>
        <GoBack>
          <GoToPreviousPage to={parentPath}>
            <ArrowLeft width={20} height={20} stroke="#3E21DE" />
            Go Back
          </GoToPreviousPage>
        </GoBack>

        <SidebarHeader>
          <CustomEditBox name={name} updateInfo={updateInfo} id={id} />

          <VerticalMenu>
            <ProjectPageOptions
              projectId={id}
              predictionsLength={
                !isEmpty(project) ? project.predictions.length : 0
              }
              deleteType={DELETE_TYPES.PROJECT}
            />
          </VerticalMenu>
        </SidebarHeader>

        <SidebarContent>
          <PredictionNum>
            {project && (
              <h4>{!isEmpty(project) ? project.predictions.length : 9}</h4>
            )}
            <span>Predictions</span>
          </PredictionNum>
          <ProjectSidebarActions>
            <Button width="100" onClick={() => setOpenSharing(true)}>
              <Sharing width={20} height={20} />
              <span>Share</span>
            </Button>
            <StyledLink
              to={`/compare/project/${project.id}`}
              onClick={() => gaViewCompareMode()}
            >
              <BenchmarkIcon stroke="#fff" width={20} height={20} />
              <span>View Compare Mode</span>
            </StyledLink>
          </ProjectSidebarActions>
        </SidebarContent>
      </ProjectsSidebar>

      {!isEmpty(project) && (
        <React.Fragment>
          {project.predictions.length > 0 ? (
            <div>
              <PredictsGrid>
                {project.predictions.map((prediction, i) => {
                  return (
                    <PredictCard
                      key={i}
                      prediction={prediction}
                      pathOrigin={pathOrigin}
                      deleteType={DELETE_TYPES.PREDICT_IN_PROJECT}
                      paginationIds={paginationIds}
                    />
                  );
                })}
              </PredictsGrid>
            </div>
          ) : (
            <EmptyHistory
              openingContent={EMPTY_HISTORY_SINGLE_PROJECT}
              projectIdForGlobal={id}
              projectName={project.name}
            />
          )}
        </React.Fragment>
      )}
      {deleteModalOpen && (
        <FullScreenModal isOpen={deleteModalOpen} content={PROJECT_CONTENT} />
      )}

      {!deleteModalOpen && (
        <GlobalBtn>
          <GlobalCreationButton
            projectIdForGlobal={projectIdForGlobal}
            projectName={project.name}
          />
        </GlobalBtn>
      )}
      <ReportModal
        open={openSharing}
        closeModal={closeSharingModal}
        fromProject={true}
        reportUrl={report_url}
        share={shareable}
        sharedCompare={shared_compare}
        handleSharingState={handleSharingState}
        handleCompareViewState={handleCompareViewState}
      />
    </Grid>
  );
};

export default SingleProject;
