import React from "react";
import Global from "../layouts/Global";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Minus } from "desisystem";
import unavailable from "../../assets/illustrations/Unavailable.svg";
import SvgLogo from "../icons/SvgLogo";
import SvgBrand from "../icons/SvgBrand";

const TOP_PADDING = "2rem";

const StyledUnavailableReport = styled.div`
  padding: 2rem 0 0 0;
  text-align: center;
  background: #fff;
  background: #fff;
  height: calc(100vh - ${TOP_PADDING});
  display: flex;
  justify-content: center;
  align-items: center;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 4vh;
    height: 80%;
    max-height: 748px;
  }
`;

const Message404 = styled.div`
  h1 {
    font-weight: bold;
    font-size: 26px;
    text-align: center;
    color: #212121;
    margin: 8px 0 0 0;
  }

  p {
    font-weight: normal;
    font-size: 20px;
    color: #212121;
    margin: 0;
  }
`;

const LogoBox = styled.div`
  display: flex;
  align-items: center;
`;

const UnavailableReport = () => {
  return (
    <div>
      <Global />
      <StyledUnavailableReport>
        <div>
          <LogoBox>
            <SvgLogo width={48} height={48} />
            <SvgBrand
              fill="#1D0F68"
              width={130}
              height={48}
              style={{ transform: "translateY(2px)" }}
            />
          </LogoBox>
          <div>
            <img src={unavailable} alt="unavailable" />
          </div>
          <Message404>
            <p>Sorry! Please come back another time.</p>
            <h1>
              The report you are trying to access is currently unavailable.
            </h1>
          </Message404>
        </div>
      </StyledUnavailableReport>
    </div>
  );
};

export default UnavailableReport;
