import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "desisystem";

const LoadingBtn = styled(Button)`
  padding: 0;
  min-height: 40px;
  min-width: 200px;
`;

const ButtonWithLoading = ({
  loading,
  title,
  onClick,
  type,
  width,
  disabled,
}) => {
  return (
    <React.Fragment>
      {loading ? (
        <LoadingBtn width={width} disabled={true}>
          <CircularProgress
            style={{ width: "26px", height: "26px", color: "#333" }}
          />
        </LoadingBtn>
      ) : (
        <Button
          type={type ? type : null}
          onClick={() => (onClick ? onClick() : null)}
          width={width}
          disabled={disabled}
        >
          {title}
        </Button>
      )}
    </React.Fragment>
  );
};

export default ButtonWithLoading;
