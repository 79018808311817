import React from "react";
import PropTypes from "prop-types";

const ProjectIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20 7.765L12 12.529L4 7.765L12 3L20 7.765Z"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 12L12 16.765L20 12"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 16.2354L12 21.0004L20 16.2354"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

ProjectIcon.propTypes = {
  currentColor: PropTypes.string
};

ProjectIcon.defaultProps = {
  stroke: "#323232"
};

export default ProjectIcon;
