import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import useForm from "react-hook-form";
// import { handleError } from "../../../redux/ducks/errors";
import SocialProofLayout from "../../layouts/SocialProofLayout";
import getAllUrlParams from "../../../utils/GetAllUrlParams";

// design system
import { PasswordInput } from "desisystem";

// actions
import { resetPassword, accountSelector } from "../../../redux/slices/account";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.subHeading};
  }
`;

const FormBody = styled.div`
  margin-top: 40px;

  & > form button {
    margin-top: 30px;
  }
`;

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const dispatch = useDispatch();
  const { loading } = useSelector(accountSelector);

  let i = window.location.href;
  let token = getAllUrlParams(i).token;

  const handleResetPassword = (e) => {
    const resetData = new FormData();
    resetData.append("token", token);
    resetData.append("password", password);

    dispatch(resetPassword(resetData));
  };

  const isDisabled =
    password === "" || confirmPassword === "" || password !== confirmPassword;

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Set up your new password</h1>
          <p>It happens to everyone - we'll help you reset it!</p>
        </Heading>

        <FormBody>
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <PasswordInput
              handleChange={setPassword}
              label="NEW PASSWORD"
              size="10px"
              name="resetPasswordField"
              hasError={errors["resetPasswordField"]}
              register={register}
            />

            <PasswordInput
              handleChange={setConfirmPassword}
              label="CONFIRM NEW PASSWORD"
              size="10px"
              name="resetPasswordConfirm"
              hasError={errors["resetPasswordConfirm"]}
              register={register}
            />

            <ButtonWithLoading
              disabled={isDisabled}
              width="100"
              loading={loading}
              title="Set new password"
            />
          </form>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default ResetPassword;
