import React from "react";
import styled from "styled-components";
// import { Button } from "desisystem";
import Dialog from "@material-ui/core/Dialog";

const FullPage = styled.div`
  background: ${(props) => props.bg};

  && {
    .MuiDialog-paperFullScreen {
      border-radius: 0 !important;
    }
  }
`;

const FullPageBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow-y: auto;
`;

const GenericModal = ({
  open,
  closeModal,
  content,
  action,
  modalType,
  children,
}) => {
  return (
    <FullPage bg="#fff">
      <Dialog fullScreen open={open}>
        <FullPageBody>
          <div>{children}</div>
        </FullPageBody>
      </Dialog>
    </FullPage>
  );
};

export default GenericModal;
