import React from "react";
import PropTypes from "prop-types";

const PredictionIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5 20.25H6.5C4.98108 20.25 3.75 19.0189 3.75 17.5V6.5C3.75 4.98108 4.98108 3.75 6.5 3.75H17.5C19.0189 3.75 20.25 4.98108 20.25 6.5V17.5C20.25 19.0189 19.0189 20.25 17.5 20.25Z"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1555 7.88651C10.7819 8.51297 10.7819 9.52868 10.1555 10.1551C9.52901 10.7816 8.5133 10.7816 7.88684 10.1551C7.26038 9.52867 7.26038 8.51297 7.88684 7.88651C8.51331 7.26004 9.52901 7.26004 10.1555 7.88651"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.2503 10.2666C19.8378 10.2079 19.4189 10.1667 18.9899 10.1667C14.1169 10.1667 10.167 14.1166 10.167 18.9896C10.167 19.4186 10.2082 19.8375 10.2669 20.25"
      stroke={props.currentColor}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

PredictionIcon.propTypes = {
  currentColor: PropTypes.string
};

PredictionIcon.defaultProps = {
  // stroke: "#323232"
  stroke: "#212121"
};

export default PredictionIcon;
