import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";
import { successToast, errorToast } from "../../utils/helperFunctions";
import { SUCCESS_TOAST } from "../../constants/ToastMessages";

// base url
let url = config.URL;

export const initialState = {
  loading: false,
  hasErrors: false,
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    load: (state) => {
      state.loading = true;
    },

    unLoad: (state) => {
      state.loading = false;
    },

    accountFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const { load, unLoad, accountFailure } = accountSlice.actions;
export const accountSelector = (state) => state.account;
export default accountSlice.reducer;

export const forgotPassEmail = (email, placeholderEmail) => async (
  dispatch
) => {
  dispatch(load());

  let apiUrl = `${url}/accounts/password/send`;

  try {
    await axios.post(apiUrl, email);

    dispatch(unLoad());
    dispatch(push("/auth/forgot/success", { placeholderEmail }));
  } catch (error) {
    if (error) {
      dispatch(accountFailure());
      errorToast("Something went wrong. Please try again.");
    }
  }
};

export const resetPassword = (resetData) => async (dispatch) => {
  dispatch(load());

  let apiUrl = `${url}/accounts/password/reset`;

  try {
    await axios.post(apiUrl, resetData);

    dispatch(unLoad());
    dispatch(push("/auth/login"));
    successToast(SUCCESS_TOAST.RESET_PASSWORD);
  } catch (error) {
    if (error) {
      dispatch(accountFailure());
      errorToast("Something went wrong. Please try again.");
    }
  }
};

export const setUserPassword = (setData) => async (dispatch) => {
  dispatch(load());

  let apiUrl = `${url}/accounts/password/set`;

  try {
    await axios.post(apiUrl, setData);

    dispatch(unLoad());
    dispatch(push("/auth/login"));
    successToast(SUCCESS_TOAST.RESET_PASSWORD);
  } catch (error) {
    if (error) {
      dispatch(accountFailure());
      errorToast("Something went wrong. Please try again.");
    }
  }
};

export const unsubscribeUser = (uid) => {
  axios.post(`${url}/accounts/${uid}/unsubscribe`);
};
