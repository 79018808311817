import React from "react";
import styled from "styled-components";
import { Link, withRouter } from "react-router-dom";

const EmptyHistoryLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & > div {
    text-align: center;
    justify-self: center;
  }

  h2 {
    margin-top: 50px;
    font-size: ${props => props.theme.fontSize[26]};
    color: ${props => props.theme.colors.black};
    margin: 0;
    text-align: center;
  }

  p {
    font-weight: normal;
    font-size: ${props => props.theme.fontSize[14]};
    color: ${props => props.theme.colors.black};
  }
`;

const Illustration = styled.div`
  margin-bottom: 80px;

  img {
    width: 330px;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: ${props => props.theme.fontSize[14]};
  color: #fff;
  outline: 0;
  background: rgb(62, 33, 222);
  border-radius: ${props => props.theme.radius.soft};
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;
  margin-top: 32px;

  &:hover {
    transition: all 0.3s;
    box-shadow: ${props => props.theme.shadows.darkShadowHover};
  }
`;

const EmptyHistory = ({
  openingContent,
  projectIdForGlobal = null,
  projectName,
  ...props
}) => {
  const { HEADER_TITLE, HEADER_PARAGRAPH, BUTTON, LINK, SRC } = openingContent;
  const { pathname } = props.location;

  return (
    <EmptyHistoryLayout>
      <div>
        <Illustration>
          <img src={SRC} alt="al" />
        </Illustration>
        <div>
          <h2>{HEADER_TITLE}</h2>
          <p>{HEADER_PARAGRAPH}</p>
        </div>
        <div>
          <StyledLink
            to={{
              pathname: LINK,
              state: {
                projectIdForGlobal,
                pathname,
                projectName
              }
            }}
          >
            {BUTTON}
          </StyledLink>
        </div>
      </div>
    </EmptyHistoryLayout>
  );
};

export default withRouter(EmptyHistory);
