import React from "react";
import PropTypes from "prop-types";

const Warnings = props => (
  <svg width="1em" height="1em" viewBox="0 0 64 64" fill="none" {...props}>
    <path
      d="M32.0005 34.7379V25.5957"
      stroke={props.currentColor}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.9981 42.083C31.6608 42.083 31.387 42.3568 31.3894 42.6941C31.3894 43.0315 31.6632 43.3053 32.0006 43.3053C32.3379 43.3053 32.6117 43.0315 32.6117 42.6941C32.6117 42.3568 32.3379 42.083 31.9981 42.083"
      stroke={props.currentColor}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.9613 12.7837L55.6833 45.5491C57.8588 49.3575 55.1088 54.0973 50.7235 54.0973H13.2795C8.8917 54.0973 6.1417 49.3575 8.3197 45.5491L27.0417 12.7837C29.2344 8.94351 34.7686 8.94351 36.9613 12.7837Z"
      stroke={props.currentColor}
      strokeWidth="4"
      stroke-inecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

Warnings.propTypes = {
  currentColor: PropTypes.string
};

Warnings.defaultProps = {
  stroke: "#323232"
};

export default Warnings;
