import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import StepsLayout from "../../layouts/StepsLayout";
import rightQuote from "../../icons/onboarding/QuoteRight.svg";
import rightKid from "../../icons/onboarding/rightKid.jpg";
import stepThree from "../../icons/onboarding/stepThree.jpg";
import { ChevronLeft, ChevronRight } from "zoozoo";
import Fade from "react-reveal/Fade";
import { clarityOnBoardingContent } from "../../../constants/PredictionContent";
import { IMAGE_CONTAINER_HEIGHT } from "../../../constants/onboardingContent";
import { gaSkipTour } from "../../../utils/analytics";

const StepContainer = styled.div`
  max-width: 660px;
  margin: 0 auto;
  position: relative;
  max-height: ${IMAGE_CONTAINER_HEIGHT};
  display: flex;
  justify-content: center;
`;

const ImageContainer = styled.div`
  max-width: 660px;
  height: 80%;
  display: flex;
  justify-content: center;

  img {
    height: 100%;
    display: block;
    margin: 0 auto;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #fff;
  outline: 0;
  background: rgb(62, 33, 222);
  border-radius: ${(props) => props.theme.radius.soft};
  height: 40px;
  min-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;

  &:hover {
    transition: all 0.3s;
    box-shadow: ${(props) => props.theme.shadows.darkShadowHover};
  }
`;

const StepActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  a:last-child {
    color: ${(props) => props.theme.colors.brandPurple};
    margin-top: 24px;
  }
`;

const ArrowStyles = css`
  transform: translate(0, -50%);
  z-index: 2;
  position: absolute;
  top: 45%;
  background: #eeeeee;
  border-radius: ${(props) => props.theme.radius.round};
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.brandPurple};
    transition: all 0.3s;

    svg {
      transition: all 0.3s;
      stroke: ${(props) => props.theme.colors.white};
    }
  }
`;

const ArrowsLeft = styled(Link)`
  ${ArrowStyles}
  left: 0;
`;

const ArrowsRight = styled(Link)`
  ${ArrowStyles}
  right: 0;
`;

const Quote = styled.div`
  position: fixed;
  bottom: 168px;
  right: 43px;
  width: 270px;

  img {
    width: 100%;
  }
`;

const Kid = styled.div`
  position: fixed;
  bottom: -4px;
  right: 0;
  height: 168px;

  img {
    height: 100%;
  }
`;

const FlyingStuff = styled.div`
  & > div:nth-child(1) {
    position: absolute;
    top: 30%;
    left: -16%;
  }

  & > div:nth-child(2) {
    position: absolute;
    top: 40%;
    right: -14%;
  }

  & > div:nth-child(3) {
    position: absolute;
    top: 50%;
    left: -20%;
  }
`;

const Each = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  padding: 16px 24px;
  border-radius: ${(props) => props.theme.radius.round};
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);

  h2 {
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 4px 0;
  }

  p {
    margin: 0;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #9e9e9e;
  }
`;

const Icon = styled.div`
  background: #f1effd;
  width: 30px;
  height: 30px;
  border-radius: ${(props) => props.theme.radius.round};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

const StepThree = () => {
  const [show, setShow] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);

  const showQuote = () => {
    setTimeout(() => {
      setShow(true);
    }, 3000);
  };
  showQuote();

  return (
    <StepsLayout>
      <ArrowsLeft to="/onboarding/step-two">
        <ChevronLeft width={66} height={66} stroke="#BDBDBD" />
      </ArrowsLeft>
      <StepContainer>
        <div>
          {startAnimation && (
            <FlyingStuff>
              {clarityOnBoardingContent.map((stuff, i) => {
                return (
                  <Fade
                    key={i}
                    right={stuff.right}
                    left={stuff.left}
                    delay={stuff.delay}
                  >
                    <Each>
                      <div>
                        <Icon>{stuff.icon}</Icon>
                      </div>
                      <div>
                        <h2>{stuff.title}</h2>
                        <p>{stuff.content}</p>
                      </div>
                    </Each>
                  </Fade>
                );
              })}
            </FlyingStuff>
          )}
          <ImageContainer>
            <img
              src={stepThree}
              onLoad={() => setStartAnimation(true)}
              alt="step images"
            />
          </ImageContainer>
          <StepActions>
            <StyledLink to="/onboarding/step-four">Next</StyledLink>
            <Link to="/" onClick={() => gaSkipTour(3)}>
              Skip
            </Link>
          </StepActions>
        </div>
      </StepContainer>
      <ArrowsRight to="/onboarding/step-four">
        <ChevronRight width={66} height={66} stroke="#BDBDBD" />
      </ArrowsRight>
      <Quote>
        <Fade when={show}>
          <img src={rightQuote} alt="left-quote" />
        </Fade>
      </Quote>
      <Kid>
        <img src={rightKid} alt="left-man" />
      </Kid>
    </StepsLayout>
  );
};

export default StepThree;
