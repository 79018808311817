import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ProjectIcon from "../../icons/ProjectIcon";
import { Book } from "zoozoo";
import {
  gaViewAllGuides,
  gaAttentionGuide,
  gaClarityGuide,
  gaOptimizeFeatureGuide,
  gaCompetitorsAnalysisGuide,
  gaUberCaseStudyGuide,
  gaQuickVisualTestsGuide,
} from "../../../utils/analytics";

const CardContainer = styled.div`
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: ${(props) => props.theme.radius.soft};
  min-height: 180px;
  box-sizing: border-box;
  margin-bottom: 24px;
  background: #fff;
  transition: all 0.3s;

  &:hover {
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.12);
    transition: all 0.3s;
  }
`;

const CardContentBox = styled(Link)`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 24px;
  min-height: 180px;
  padding: 0 0 0 48px;

  span {
    color: ${(props) => props.theme.neutral[4]};
    font-size: ${(props) => props.theme.fontSize[14]};
  }
`;

const LeftContent = styled.div`
  display: flex;
  align-items: center;

  & > div:last-child {
    margin: 0 0 0 40px;
    max-width: 180px;
    min-width: 180px;
  }

  h2 {
    margin: 0;
    font-size: ${(props) => props.theme.fontSize[20]};
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    word-break: break-word;
  }
`;

const CardOptionsBox = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 180px;
  width: 100%;
  border-radius: 0 4px 4px 0;
`;

const LargeProjectCard = ({ cardInfo, pathname }) => {
  const guideAction = (name) => {
    if (name === "Unlock Attention Maps") {
      gaAttentionGuide();
    } else if (name === "Understand Visual Clarity") {
      gaClarityGuide();
    } else if (name === "Optimize Featured Images") {
      gaOptimizeFeatureGuide();
    } else if (name === "Competitors Analysis") {
      gaCompetitorsAnalysisGuide();
    } else if (name === "Uber Case Study") {
      gaUberCaseStudyGuide();
    } else if (name === "Quick visual A/B tests") {
      gaQuickVisualTestsGuide();
    }
  };

  return (
    <CardContainer>
      <CardContentBox
        to={{
          pathname: `/examples/projects/${cardInfo.id}`,
          state: {
            pathname,
          },
        }}
        onClick={() => guideAction(cardInfo.name)}
      >
        <LeftContent>
          <div>
            <Book width={32} height={32} />
          </div>

          <div>
            <h2>{cardInfo.name}</h2>
            <span>{cardInfo.category}</span>
          </div>
        </LeftContent>
        <CardOptionsBox url={cardInfo.thumbnail_path}></CardOptionsBox>
      </CardContentBox>
    </CardContainer>
  );
};

export default LargeProjectCard;
