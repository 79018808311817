import React, { useEffect, useState } from "react";
import { useStripe, Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import UpgradeForm from "./UpgradeForm";
import { useSelector, useDispatch } from "react-redux";

// ui
import GlobalLoader from "../../ui/loadings/GlobalLoader";

// redux
import {
  fetchSubscription,
  getProPrice,
  getBasicPrice,
  getProration,
  subscriptionSelector,
  fetchAllPricing,
  finalizeUpgrade,
  stop3dLoading,
} from "../../../redux/slices/subs";
import { fetchUser, userSelector } from "../../../redux/slices/user";
import { successToast, errorToast } from "../../../utils/helperFunctions";

const STRIPE_PUBLIC_KEY =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_DEV_STRIPE_PUBLIC_KEY
    : process.env.REACT_APP_PROD_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const StripeProvider = ({ children }) => {
  return <Elements stripe={stripePromise}> {children} </Elements>;
};

const UpgradeContent = ({ location: { state = null }, history, ...props }) => {
  const stripe = useStripe();

  const [planDuration, setPlanDuration] = useState(null);
  const [planTier, setPlanTier] = useState(null);
  const [fromSite, setFromSite] = useState(false);

  const {
    proPrice,
    basicPrice,
    proration,
    loading: subscriptionLoading,
    subs: { custom, tier, period },
    allPrices,
    redirectUrl,
  } = useSelector(subscriptionSelector);
  const { user } = useSelector(userSelector);
  const { loading } = useSelector((state) => state.loadings);
  const dispatch = useDispatch();

  const redirectTo3dSecure = redirectUrl;
  const has3dSecure = redirectUrl !== "";

  function setupListener() {
    if (stripe) {
      window.addEventListener(
        "message",
        function(ev) {
          console.log(ev);
          const data = JSON.parse(ev.data);
          const { type, secretKey } = data;

          if (type && secretKey && type === "3DS-authentication-complete") {
            dispatch(finalizeUpgrade());

            // stripe.retrievePaymentIntent(secretKey).then((result) => {
            //   console.log(result);

            //   if (result.error) {
            //     history.push("/upgrade/choose-plan");
            //     // PaymentIntent client secret was invalid
            //     errorToast("Payment failed. Please try again.");
            //   } else {
            //     if (result.paymentIntent.status === "succeeded") {
            //       // Show your customer that the payment has succeeded
            //       successToast("Authentication succeeded!");
            //       dispatch(finalizeUpgrade());
            //     } else if (
            //       result.paymentIntent.status === "requires_payment_method"
            //     ) {
            //       history.push("/upgrade/choose-plan");
            //       errorToast(
            //         "Authentication failed. Please enter another payment method"
            //       );
            //       dispatch(stop3dLoading());
            //     }
            //   }
            // });
          }
        },
        false
      );
    }
  }

  useEffect(() => {
    setupListener();
  }, [stripe]);

  const { pathname: pathOrigin = "/" } = state || { pathOrigin: "/" };

  useEffect(() => {
    dispatch(fetchSubscription());
    dispatch(fetchUser());
    dispatch(getProPrice());
    dispatch(getBasicPrice());
    dispatch(getProration());
  }, [dispatch]);

  useEffect(() => {
    const couponData = new FormData();
    couponData.append("coupon", "");
    couponData.append("tier", planTier);

    if (planTier !== null) {
      dispatch(fetchAllPricing(couponData));
    }
  }, [dispatch, planTier]);

  useEffect(() => {
    if (tier === "pro") {
      props.history.push("/");
    }
  }, [props.history, tier]);

  useEffect(() => {
    if (state !== null) {
      if (state.direct) {
        setPlanDuration(state.direct.duration);
        setFromSite(state.fromSite);
      } else if (state.duration) {
        setPlanDuration(state.duration);
      }
    }
  }, [planDuration, state]);

  useEffect(() => {
    if (state !== null) {
      if (state.plan) {
        setPlanTier(state.plan);
      } else if (state.direct) {
        setPlanTier(state.direct.plan);
      }
    }
  }, [planTier, state]);

  return (
    <>
      <div>
        {!loading && !subscriptionLoading ? (
          <UpgradeForm
            user={user}
            duration={planDuration}
            proPrice={proPrice}
            basicPrice={basicPrice}
            planTier={planTier}
            fromSite={fromSite}
            tier={tier}
            custom={custom}
            proration={proration}
            period={period}
            pathOrigin={pathOrigin}
            allPrices={allPrices}
          />
        ) : (
          <GlobalLoader />
        )}
      </div>

      {has3dSecure && (
        <iframe
          width="100%"
          height="100%"
          frameBorder="0"
          src={redirectTo3dSecure}
          style={{
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0px",
            left: "0px",
            right: "0px",
            bottom: "0px",
            zIndex: 100,
          }}
        />
      )}
    </>
  );
};

const Upgrade = (props) => {
  return (
    <StripeProvider>
      <UpgradeContent {...props} />
    </StripeProvider>
  );
};
export default Upgrade;
