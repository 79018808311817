import React, { useState } from "react";
import styled from "styled-components";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { useDispatch } from "react-redux";
import MyInfo from "./MyInfo";
import MyPayments from "./MyPayments";
import isEmpty from "../../../../utils/helperFunctions";
import { H2 } from "desisystem";
import DeletePaymentModal from "../../../ui/modals/DeletePaymentModal";
import EditPaymentModal from "../../../ui/modals/EditPaymentModal";
import {
  updatePaymentMethod,
  addPaymentMethod,
} from "../../../../redux/slices/subs";

const SubsCardsVertical = styled.div`
  &:first-child {
    grid-row: 1 / 2;
  }

  .payment-method-box {
    margin-top: 24px;
  }

  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 24px;
  }
`;

const SubsCards = styled.div`
  &:first-child {
    padding-bottom: 32px;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 24px;
  }

  &:last-child {
    margin-top: 32px;
  }
`;

const PaymentAction = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #3e21de;
  cursor: pointer;
`;

const DeletePaymentAction = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: #d53c3c;
  cursor: pointer;
`;

const SubSidebar = ({
  subscription,
  receiptLink,
  email,
  closeModal,
  openEditModal,
  openDeleteModal,
  openDelete,
  openEdit,
  openEditFromRenewModal,
  fromRenewButton,
  afterProAction,
  afterBasicAction,
  ...props
}) => {
  const [stripeError, setStripeError] = useState("");
  const [loading, setLoading] = useState(false);
  const { expires_at, tier } = subscription;
  const dispatch = useDispatch();

  const hasMethod = !isEmpty(subscription.payment_method);
  const afterAction = tier === "pro" ? afterProAction : afterBasicAction;

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = () => {
    setLoading(true);
    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then((result) => {
      if (result.error) {
        setStripeError(result.error.message);
        setLoading(false);
        console.log("[error]", result.error.message);
      } else {
        const token = result.token.id;
        const upgradeData = new FormData();
        upgradeData.append("token", token);

        hasMethod
          ? dispatch(updatePaymentMethod(upgradeData))
          : dispatch(
              addPaymentMethod(upgradeData, afterAction, fromRenewButton)
            );

        closeModal();
        setLoading(false);
      }
    });
  };

  return (
    <SubsCardsVertical>
      <SubsCards>
        <MyInfo subscription={subscription} receiptLink={receiptLink} />
      </SubsCards>
      <SubsCards>
        <H2>Payment Methods</H2>
        {isEmpty(subscription.payment_method) ? null : (
          <MyPayments subscription={subscription} />
        )}
        {isEmpty(subscription.payment_method) && (
          <PaymentAction onClick={() => openEditModal()}>
            Add payment method
          </PaymentAction>
        )}
        {!isEmpty(subscription.payment_method) && (
          <PaymentAction onClick={() => openEditModal()}>
            Change payment method
          </PaymentAction>
        )}
        {!isEmpty(subscription.payment_method) && (
          <DeletePaymentAction onClick={() => openDeleteModal()}>
            Remove payment method
          </DeletePaymentAction>
        )}
      </SubsCards>

      <DeletePaymentModal
        open={openDelete}
        closeModal={closeModal}
        expiresAt={expires_at}
        email={email}
      />

      <EditPaymentModal
        open={openEdit}
        closeModal={closeModal}
        email={email}
        handleSubmit={handleSubmit}
        loading={loading}
        stripeError={stripeError}
        hasMethod={hasMethod}
      />
    </SubsCardsVertical>
  );
};

export default SubSidebar;
