import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const EachPredict = styled.div`
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  margin-bottom: 16px;
  position: relative;
  min-height: 48px;

  label > input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const EachPredictBody = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;

  & > div:first-child {
    display: flex;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
      margin-left: 16px;

      h3 {
        font-weight: normal;
        font-size: 12px;
        color: #212121;
        margin: 0 0 6px 0;
      }
      span {
        font-weight: normal;
        font-size: 12px;
        color: #bdbdbd;
      }
    }
  }
`;

const StyledContentLoader = styled(ContentLoader)`
  && {
    padding: 0;
  }
`;

const AddPredictionLoading = () => {
  const LoadingProject = () => (
    <StyledContentLoader
      speed={2}
      width={400}
      height={45}
      viewBox="0 0 400 100"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      {/* <rect x="48" y="8" rx="3" ry="3" width="88" height="6" />
      <rect x="48" y="26" rx="3" ry="3" width="52" height="6" /> */}
      <rect x="0" y="56" rx="3" ry="3" width="510" height="6" />
      <rect x="0" y="72" rx="3" ry="3" width="580" height="6" />
      {/* <circle cx="20" cy="20" r="20" /> */}
    </StyledContentLoader>
  );

  return (
    <EachPredict>
      <EachPredictBody>
        <LoadingProject />
      </EachPredictBody>
    </EachPredict>
  );
};

export default AddPredictionLoading;
