import React from "react";
import styled, { keyframes } from "styled-components";
import LinearProgress from "@material-ui/core/LinearProgress";
// import Typewriter from "../globals/Typewriter";
import SvgLogo from "../../icons/SvgLogo";
import SvgBrand from "../../icons/SvgBrand";
import useTypewriter from "../../custom-hooks/useTypewriter";
import { config } from "../../../constants/endpoints";

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translate(-50%);

  img {
    width: 115px;
  }
`;

const WaitMessage = styled.p`
  color: ${(props) => props.theme.colors.black};
  font-size: ${(props) => props.theme.fontSize[10]};
  text-align: center;
  font-weight: 500;
  letter-spacing: 0.2em;
`;

const blinkTextCursor = keyframes`
    from {
      border-right-color: #212121;
    }
    
    to {
      border-right-color: transparent;
    }
`;

const LoaderStatus = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 640px;
  transform: translate(-50%, -50%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .MuiLinearProgress-root {
    height: 8px;
    border-radius: 10px;
    width: 400px;
    margin: 30px auto 0 auto;
  }

  .MuiLinearProgress-barColorPrimary {
    background: linear-gradient(90deg, #5940e3 0%, #927fff 100%);
  }

  .MuiLinearProgress-colorPrimary {
    background: #e2e0ec;
  }

  span {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 2px solid #212121;
    animation: ${blinkTextCursor} 0.5s infinite;
  }
`;

const TypeBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    font-weight: normal;
    margin: 16px 6px 16px 0;
  }
`;

const LoadingTypewriter = ({ completed, uid }) => {
  const currentWord = useTypewriter({
    words: [
      "Attention Maps",
      "Clarity Scores",
      "Clarity Maps",
      "Areas of Interest",
    ],
    eraseDelay: 600,
  });

  return (
    <LoaderStatus>
      <Logo>
        {!config.WHITE_LABEL.map((user) => user.id).includes(uid) ? (
          <React.Fragment>
            <SvgLogo width={48} height={48} />
            <SvgBrand fill="#1D0F68" width={110} height={28} />
          </React.Fragment>
        ) : (
          <img
            src={config.WHITE_LABEL.find((user) => user.id === uid).logo}
            alt="logo"
          />
        )}
      </Logo>
      <WaitMessage>PLEASE WAIT</WaitMessage>
      <TypeBox>
        <p>Analysing</p>
        <span>{currentWord}</span>
      </TypeBox>
      <LinearProgress variant="determinate" value={completed} />
    </LoaderStatus>
  );
};

export default LoadingTypewriter;
