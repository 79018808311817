import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";
import { refreshToken, authorizedToken, getRefreshToken } from "./auth";
import { successToast, errorToast } from "../../utils/helperFunctions";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../constants/ToastMessages";
import { authLogout, initAnalytics } from "../ducks/auth";
import { subsLogout } from "./subs";
import { predictionsLogout } from "./predictions";
import { predictionLogout } from "./prediction";
import { projectsLogout } from "./projects";

// base url
let url = config.URL;

export const initialState = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    credits_left: "",
    api_key: "",
    avatar: "",
    is_verified: false,
    only_social: false,
    onboarding_filled: false,
    use_percentage: true,
    total_predictions: 0,
    uid: "",
    team_members: [],
  },
  genKey: "",
  imagePath: "",
  imageData: {},
  receiptLink: "",
  verifySuccess: false,
  isInvited: false,
  inviteId: null,
  loading: false,
  hasErrors: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    loadUser: (state) => {
      state.loading = true;
    },

    userFetchSuccess: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
      state.loading = false;
      state.hasErrors = false;
    },

    updateUserSuccess: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
      state.loading = false;
      state.hasErrors = false;
    },

    onBoardingUserSuccess: (state, { payload }) => {
      state.user = { ...state.user, ...payload };
      state.loading = false;
      state.hasErrors = false;
    },

    generateApiKeySuccess: (state, { payload }) => {
      state.user = { ...state.user, api_key: payload };
      state.loading = false;
      state.hasErrors = false;
    },

    getTeamMembersSuccess: (state, { payload }) => {
      state.user = { ...state.user, team_members: payload };
      state.loading = false;
      state.hasErrors = false;
    },

    verifyStartSuccess: (state) => {
      state.isInvited = true;
    },

    saveInviteId: (state, { payload }) => {
      state.inviteId = payload;
    },
    deleteInviteId: (state) => {
      state.inviteId = null;
    },

    verifyIsOverSuccess: (state) => {
      state.verifySuccess = false;
      state.isInvited = false;
    },

    changeToPercentageSuccess: (state, { payload }) => {
      state.user = payload;
    },

    logoutSuccess: (state) => {
      state.user = {};
      state.imagePath = "";
      state.imageData = {};
      state.coupon = "";
      state.couponCode = "";
    },

    userFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});

export const {
  loadUser,
  userFetchSuccess,
  updateUserSuccess,
  onBoardingUserSuccess,
  generateApiKeySuccess,
  getTeamMembersSuccess,
  verifyIsOverSuccess,
  verifyStartSuccess,
  storeCouponCode,
  applyCouponSuccess,
  logoutSuccess,
  changeToPercentageSuccess,
  userFailure,
  saveInviteId,
  deleteInviteId,
} = userSlice.actions;
export const userSelector = (state) => state.user;
export default userSlice.reducer;

export const fetchUser = () => async (dispatch, getState) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/profile`;

  try {
    const res = await axios.get(apiUrl, config);

    dispatch(initAnalytics(res.data.api_key));
    dispatch(userFetchSuccess(res.data));

    // if (getState().user.inviteId) dispatch(acceptInvite());
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchUser));
      } else {
        dispatch(userFailure());
        errorToast(ERROR_TOAST.OUT_OF_ORDER);
        dispatch(push("/"));
      }
    } else {
      dispatch(userFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const updateUser = (updatedData) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/profile`;

  try {
    const res = await axios.patch(apiUrl, updatedData, config);

    dispatch(updateUserSuccess(res.data));
    successToast(SUCCESS_TOAST.UPDATE_USER);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, updateUser, updatedData));
      } else {
        dispatch(userFailure());
        errorToast(ERROR_TOAST.OUT_OF_ORDER);
        dispatch(push("/"));
      }
    } else {
      dispatch(userFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const onBoardingUser = (updatedData) => async (dispatch) => {
  dispatch(loadUser());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/profile`;

  try {
    const res = await axios.patch(apiUrl, updatedData, config);

    dispatch(onBoardingUserSuccess(res.data));
    dispatch(push("/onboarding/step-one"));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, onBoardingUser, updatedData));
      } else {
        dispatch(userFailure());
        errorToast(ERROR_TOAST.OUT_OF_ORDER);
        dispatch(push("/"));
      }
    } else {
      dispatch(userFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const downloadSurvey = (surveyData) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/profile`;

  try {
    await axios.patch(apiUrl, surveyData, config);

    successToast("Thanks a bunch!");
  } catch (error) {
    if (error) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, downloadSurvey, surveyData));
      } else {
        errorToast(ERROR_TOAST.OUT_OF_ORDER);
      }
    }
  }
};

export const aoiUsePercentage = (selected) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/profile`;

  try {
    const res = await axios.patch(apiUrl, selected, config);
    dispatch(changeToPercentageSuccess(res.data));
    // console.log(res.data);
  } catch (error) {
    if (error) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, aoiUsePercentage, selected));
      } else {
        errorToast(ERROR_TOAST.OUT_OF_ORDER);
      }
    }
  }
};

export const generateApiKey = () => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/key/generate`;

  try {
    const res = await axios.post(apiUrl, [], config);

    dispatch(generateApiKeySuccess(res.data.api_key));
    successToast(SUCCESS_TOAST.GENERATE_KEY);
  } catch (error) {
    if (error) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, generateApiKey));
      } else {
        errorToast(ERROR_TOAST.GENERATE_KEY);
      }
    }
  }
};

export const sendTeamInvites = (payload) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/invitations/send`;

  try {
    const res = await axios.post(apiUrl, payload, config);

    successToast("We've send invites via email to your members!");
    window.location.reload();
  } catch (error) {
    if (error) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, sendTeamInvites));
      } else {
        errorToast(ERROR_TOAST.GENERATE_KEY);
      }
    }
  }
};

export const getTeamMembers = () => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/members`;

  try {
    const res = await axios.get(apiUrl, config);

    if (res.data && res.data.members) {
      dispatch(getTeamMembersSuccess(res.data.members));
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, getTeamMembers));
      } else {
        errorToast(ERROR_TOAST.UPDATE_PASSWORD);
      }
    }
  }
};

export const updatePassword = (passwordData) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/password`;

  try {
    await axios.patch(apiUrl, passwordData, config);

    window.location.reload();
    successToast(SUCCESS_TOAST.UPDATE_PASSWORD);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, updatePassword, passwordData));
      } else {
        errorToast(ERROR_TOAST.UPDATE_PASSWORD);
      }
    }
  }
};

export const updateAvatar = (imageData) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/avatar`;

  try {
    await axios.patch(apiUrl, imageData, config);

    setTimeout(() => {
      window.location.reload();
    }, 1000);
    successToast(SUCCESS_TOAST.UPDATE_AVATAR);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, updateAvatar, imageData));
      } else {
        errorToast(error.response.data.reason);
      }
    }
  }
};

export const verifyUser = (token) => async (dispatch) => {
  let apiUrl = `${url}/accounts/verify`;

  try {
    await axios.post(apiUrl, token);

    dispatch(verifyStartSuccess());
    dispatch(push("/"));
  } catch (error) {
    if (error.response) {
      errorToast(error.response.data.reason);
      dispatch(push("/"));
    }
  }
};

export const deleteTeamMember = (email) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/members?emails=${email}`;

  try {
    await axios.delete(apiUrl, config);

    successToast(`Your member ${email} deleted from the team.`);
    window.location.reload();
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, deleteTeamMember, email));
      } else {
        errorToast(ERROR_TOAST.GENERIC);
      }
    } else {
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const acceptInvite = () => async (dispatch, getState) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  const inviteId = getState().user.inviteId;

  let apiUrl = `${url}/user/invitations/${inviteId}/accept`;

  const formdata = new FormData();
  formdata.append("invitation_uid", inviteId);

  try {
    console.log("here2");
    axios.post(apiUrl, formdata, config).then((res) => {
      console.log("here");
      dispatch(push("/"));
      successToast(
        "We informed your team leader that you accepted the invite!"
      );
      dispatch(deleteInviteId());
    });
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, acceptInvite));
      } else if (error.response.status === 500) {
      } else {
        errorToast(
          "Your invite didn't redeemed. Contact us for more information!"
        );
        dispatch(deleteInviteId());
      }
    }
  }
};

export const verifiedSuccessOver = () => (dispatch) => {
  dispatch(verifyIsOverSuccess());
};

export const resendVerificationEmail = () => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/verify/resend`;

  try {
    await axios.post(apiUrl, [], config);

    successToast("Look again!! Verification email has been sent!");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, resendVerificationEmail));
      } else {
        errorToast(ERROR_TOAST.GENERIC);
      }
    }
  }
};

export const logout = () => (dispatch) => {
  dispatch(authLogout());
  dispatch(subsLogout());
  dispatch(projectsLogout());
  dispatch(predictionsLogout());
  dispatch(predictionLogout());

  dispatch(logoutSuccess());
};
