import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import SvgFigma from "../../../icons/plugin-logos/SvgFigma";
import SvgSketch from "../../../icons/plugin-logos/SvgSketch";
import SvgAdobe from "../../../icons/plugin-logos/SvgAdobe";
import SvgChrome from "../../../icons/plugin-logos/SvgChrome";

const Platform = styled.div`
  ${"" /* margin-left: 16px; */}
`;

const Divider = styled.div`
  width: 1px;
  background: #f5f5f5;
  height: 90%;
  ${"" /* margin-right: 16px; */}
`;

const EachTool = styled.div`
  display: flex;
  align-items: center;

  p:first-letter {
    text-transform: uppercase;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #212121;
    margin-left: 0;
    margin: 0 0 0 16px;
  }

  & > svg {
    width: 20px;
    height: 20px;
  }
`;

const PlatformTool = ({ platform }) => {
  return (
    <Platform>
      <Divider />
      {platform === "figma" && (
        <EachTool>
          <SvgFigma />
          {/* <p>figma</p> */}
        </EachTool>
      )}
      {platform === "sketch" && (
        <EachTool>
          <SvgSketch />
          {/* <p>sketch</p> */}
        </EachTool>
      )}
      {platform === "adobeXD" && (
        <EachTool>
          <SvgAdobe />
          {/* <p>adobeXD</p> */}
        </EachTool>
      )}
      {platform === "chrome" && (
        <EachTool>
          <SvgChrome />
          {/* <p>Chrome</p> */}
        </EachTool>
      )}
    </Platform>
  );
};

export default PlatformTool;
