import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import SvgLogo from "../../../icons/SvgLogo";
import SvgBrand from "../../../icons/SvgBrand";
import AvatarActions from "../../../ui/navigation/mainNavigation/screens/AvatarActions";
import { ArrowLeft } from "zoozoo";
import { fbTrack } from "../../../../utils/analytics";
import { config } from "../../../../constants/endpoints";

const GoBack = styled.div``;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 115px;
  }
`;

const GoToPreviousPage = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]};
  cursor: pointer;

  svg {
    margin-right: 6px;
    transition: all 0.3s;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};

    svg {
      margin-right: 6px;
      transform: translateX(-2px);
      transition: all 0.3s;
    }
  }
`;

const Nav = styled.nav`
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 64px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
  padding: 0 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
`;

const NavGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  align-items: center;

  & > div {
    justify-self: center;
  }

  & > div:first-child {
    justify-self: start;
  }

  & > div:last-child {
    justify-self: end;
  }
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  & > div {
    padding: 0 10px;
  }
  & > div:last-child {
    padding: 0;
  }
`;

const StyledLink = styled(Link)`
  padding: 0.75rem 1.5rem;
  background: ${(props) => props.theme.colors.brandPurple};
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  margin-left: 2rem;
  text-decoration: none;
  transition: all 0.3s;
  font-weight: bold;

  @media (max-width: 1090px) {
    margin-left: 0;
  }

  &:hover {
    color: #fff;
    transition: all 0.3s;
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

const SinglePredictionNav = ({ avatar, tier, pathname, uid }) => {
  return (
    <Nav>
      <NavGrid>
        <div>
          <GoBack>
            <GoToPreviousPage to={pathname}>
              <ArrowLeft width={20} height={20} stroke="#3E21DE" />
              Go Back
            </GoToPreviousPage>
          </GoBack>
        </div>
        <div>
          <Logo to="/">
            {!config.WHITE_LABEL.map((user) => user.id).includes(uid) ? (
              <React.Fragment>
                <SvgLogo width={38} height={38} />
                <SvgBrand fill="#1D0F68" width={110} height={28} />
              </React.Fragment>
            ) : (
              <img
                src={config.WHITE_LABEL.find((user) => user.id === uid).logo}
                alt="logo"
              />
            )}
          </Logo>
        </div>
        <div>
          <Box>
            <AvatarActions avatar={avatar} />

            {tier === "free" || tier === "basic" ? (
              <span onClick={() => fbTrack("InitiateCheckout")}>
                <StyledLink
                  to={{
                    pathname: "/upgrade/choose-plan",
                    state: {
                      pathname,
                    },
                  }}
                >
                  Upgrade
                </StyledLink>
              </span>
            ) : null}
          </Box>
        </div>
      </NavGrid>
    </Nav>
  );
};

export default SinglePredictionNav;
