import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { push } from "connected-react-router";
import { fbTrack, gaPurchase } from "../../utils/analytics";
import { cancelFreeStorage } from "../ducks/storage";
import { refreshToken, authorizedToken, getRefreshToken } from "./auth";

// constants
import { config } from "../../constants/endpoints";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../constants/ToastMessages";
import { errorToast, successToast } from "../../utils/helperFunctions";

// base url
let url = config.URL;

const initialState = {
  subs: {},
  upgradeLoading: false,
  proPrice: "",
  basicPrice: "",
  proration: "",
  coupon: {},
  couponCode: "",
  allPrices: {},
  loading: false,
  redirectUrl: "",
};

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    loadSubscription: (state) => {
      state.loading = true;
    },

    stop3dLoading: (state) => {
      state.loading = false;
      state.redirectUrl = "";
    },

    set3DRedirectUrl: (state, { payload }) => {
      state.redirectUrl = payload;
    },

    getSubscriptionFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    getSubscriptionSuccess: (state, { payload }) => {
      state.subs = payload;
      state.loading = false;
      state.hasErrors = false;
      state.coupon = {};
      state.couponCode = "";
    },

    proPriceSuccess: (state, { payload }) => {
      state.loading = false;
      state.proPrice = payload;
    },

    basicPriceSuccess: (state, { payload }) => {
      state.loading = false;
      state.basicPrice = payload;
    },

    prorationPriceSuccess: (state, { payload }) => {
      state.loading = false;
      state.proration = payload;
    },

    updatePaymentSuccess: (state, { payload }) => {
      state.loading = false;
      state.subs.payment_method = payload;
    },

    addPaymentSuccess: (state, { payload }) => {
      state.loading = false;
      state.subs.payment_method = payload;
    },

    storeCouponCode: (state, { payload }) => {
      state.couponCode = payload;
    },

    applyCouponSuccess: (state, { payload }) => {
      state.coupon = payload;
    },

    fetchAllPricingSuccess: (state, { payload }) => {
      state.allPrices = payload;
    },

    emptySubscription: (state) => {
      state.subs = {};
      state.loading = false;
      state.proPrice = "";
      state.basicPrice = "";
    },
  },
});

export const {
  loadSubscription,
  stop3dLoading,
  set3DRedirectUrl,
  getSubscriptionFailure,
  getSubscriptionSuccess,
  proPriceSuccess,
  basicPriceSuccess,
  prorationPriceSuccess,
  updatePaymentSuccess,
  addPaymentSuccess,
  storeCouponCode,
  applyCouponSuccess,
  fetchAllPricingSuccess,
  emptySubscription,
} = subscriptionSlice.actions;
export const subscriptionSelector = (state) => state.subs;
export default subscriptionSlice.reducer;

export const fetchSubscription = () => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/subscription`;

  try {
    const res = await axios.get(apiUrl, config);

    dispatch(getSubscriptionSuccess(res.data));
  } catch (error) {
    if (error.response) {
      console.log(error.response.status);
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchSubscription));
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const upgradePlan = (
  upgradeData,
  fromSite = false,
  firstName,
  planTier
) => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/upgrade`;

  try {
    const res = await axios.post(apiUrl, upgradeData, config);
    const { status } = res.data;

    const doesNeedAction = status === "pending";
    if (doesNeedAction) {
      const { redirect_url } = res.data;
      dispatch(set3DRedirectUrl(redirect_url));
      return;
    }

    dispatch(getSubscriptionSuccess(res.data));

    if (fromSite) {
      dispatch(push("/onboarding/about"));
      dispatch(cancelFreeStorage());
      successToast(`Congrats, ${firstName}. Enjoy your ${planTier} account!`);
    } else {
      dispatch(push("/upgrade/success"));
      dispatch(cancelFreeStorage());
    }
    fbTrack("Purchase");
    gaPurchase();
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(
            userData,
            upgradePlan,
            upgradeData,
            fromSite,
            firstName,
            planTier
          )
        );
      } else if (
        error.response.status === 400 ||
        error.response.status === 402
      ) {
        dispatch(getSubscriptionFailure(false));
        errorToast(error.response.data.reason);
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const finalizeUpgrade = () => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/upgrade/complete`;

  try {
    dispatch(stop3dLoading());

    const res = await axios.post(apiUrl, "", config);

    dispatch(push("/upgrade/success"));
    dispatch(cancelFreeStorage());

    fbTrack("Purchase");
    gaPurchase();
    successToast("Authentication succeeded!");
  } catch (error) {
    console.log(error.response);
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, finalizeUpgrade));
      } else if (
        error.response.status === 400 ||
        error.response.status === 402
      ) {
        dispatch(getSubscriptionFailure(false));
        dispatch(cancelFreeStorage());
        dispatch(push("/upgrade"));
        errorToast(
          "Authentication failed. Please enter another payment method"
        );
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const downgradeSubscription = () => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/downgrade`;

  try {
    await axios.post(apiUrl, "", config);
    dispatch(fetchSubscription());
    dispatch(push("/subscription"));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, downgradeSubscription));
      } else if (
        error.response.status === 402 ||
        error.response.status === 400
      ) {
        dispatch(getSubscriptionFailure(false));
        errorToast(error.response.data.reason);
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const renewSubscription = () => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/renew`;

  try {
    await axios.post(apiUrl, "", config);

    dispatch(fetchSubscription());
    dispatch(push("/subscription"));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, renewSubscription));
      } else if (
        error.response.status === 402 ||
        error.response.status === 400
      ) {
        dispatch(getSubscriptionFailure(false));
        errorToast(error.response.data.reason);
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const getProPrice = () => async (dispatch) => {
  dispatch(loadSubscription());

  let apiUrl = `${url}/prices/pro`;

  try {
    const res = await axios.get(apiUrl);
    dispatch(proPriceSuccess(res.data));
  } catch (error) {
    if (error.response) {
      console.log("error");
    }
  }
};

export const getBasicPrice = () => async (dispatch) => {
  dispatch(loadSubscription());

  let apiUrl = `${url}/prices/basic`;

  try {
    const res = await axios.get(apiUrl);
    dispatch(basicPriceSuccess(res.data));
  } catch (error) {
    if (error.response) {
      console.log("error");
    }
  }
};

export const getProration = () => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/proration`;

  try {
    const res = await axios.get(apiUrl, config);

    dispatch(prorationPriceSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, getProration));
      } else if (
        error.response.status === 402 ||
        error.response.status === 400
      ) {
        dispatch(getSubscriptionFailure(false));
        errorToast(error.response.data.reason);
      } else {
        dispatch(getSubscriptionFailure());
        // errorToast(ERROR_TOAST.GENERIC);
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      // errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const getNewProration = (couponCode) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/proration?coupon=${couponCode}`;

  try {
    const res = await axios.get(apiUrl, config);

    dispatch(prorationPriceSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, getProration));
      } else {
        dispatch(getSubscriptionFailure());
        // errorToast(ERROR_TOAST.GENERIC);
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      // errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const deletePaymentMethod = (closeModal) => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/source`;

  try {
    await axios.delete(apiUrl, config);
    dispatch(fetchSubscription());
    dispatch(push("/subscription"));
    closeModal();
    successToast("Payment method removed successfully.");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, deletePaymentMethod, closeModal));
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const updatePaymentMethod = (methodInfo) => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/source`;

  try {
    await axios.patch(apiUrl, methodInfo, config);

    dispatch(fetchSubscription());
    dispatch(push("/subscription"));

    successToast("Payment method updated successfully.");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, updatePaymentMethod, methodInfo));
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const addPaymentMethod = (
  methodInfo,
  afterAction,
  fromRenewButton
) => async (dispatch) => {
  dispatch(loadSubscription());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/source`;

  try {
    const res = await axios.post(apiUrl, methodInfo, config);
    dispatch(addPaymentSuccess(res.data.payment_method));
    successToast("Payment method added successfully.");
    if (fromRenewButton) {
      afterAction();
    }
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(
            userData,
            addPaymentMethod,
            methodInfo,
            afterAction,
            fromRenewButton
          )
        );
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const applyCoupon = (couponData, code) => async (dispatch) => {
  dispatch(getCouponCode(code));

  let apiUrl = `${url}/coupons/check`;

  try {
    const res = await axios.post(apiUrl, couponData);

    dispatch(applyCouponSuccess(res.data));

    dispatch(getNewProration(code));

    if (res.data.coupon === "not valid") {
      errorToast(ERROR_TOAST.COUPON);
    } else {
      dispatch(fetchAllPricing(couponData));
      successToast(SUCCESS_TOAST.COUPON);
    }
  } catch (error) {
    if (error) {
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export const getCouponCode = (couponData) => (dispatch) => {
  dispatch(storeCouponCode(couponData));
};

export const fetchAllPricing = (couponData) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/price`;

  try {
    const res = await axios.post(apiUrl, couponData, config);

    dispatch(fetchAllPricingSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchAllPricing, couponData));
      } else {
        dispatch(getSubscriptionFailure());
        dispatch(push("/"));
      }
    } else {
      dispatch(getSubscriptionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const subsLogout = () => (dispatch) => {
  dispatch(emptySubscription());
};
