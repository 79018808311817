import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const StyledLoadingCard = styled(ContentLoader)`
  background: #fff;
  height: 240px;
  width: 100%;
  border-radius: ${props => props.theme.radius.soft};
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
`;

const PredictionLoading = ({ customWidth, customViewport }) => (
  <StyledLoadingCard
    speed={2}
    width={customWidth}
    height={220}
    viewBox={customViewport}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="0" y="14" rx="0" ry="0" width={customWidth} height="170" />
    <rect x="15" y="190" rx="2" ry="2" width="140" height="10" />
    <rect x="15" y="210" rx="2" ry="2" width="90" height="10" />
  </StyledLoadingCard>
);

export default PredictionLoading;
