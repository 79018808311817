import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import useForm from "react-hook-form";

// design system
import { Input, Checkbox, PasswordInput } from "desisystem";

import SocialProofLayout from "../../layouts/SocialProofLayout";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";

// assets
import { dummy } from "../../icons/dummy";

// actions
import { handleError } from "../../../redux/ducks/errors";
import { createUser } from "../../../redux/ducks/auth";

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${props => props.theme.weight.bold};
    font-size: ${props => props.theme.fontSize[26]};
    color: ${props => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${props => props.theme.fontSize[14]};
    color: ${props => props.theme.colors.subHeading};
  }
`;

const TwoInputRow = styled.div`
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr 1fr;
`;

const FormBody = styled.div`
  margin-top: 40px;

  & > form button {
    margin-top: 30px;
  }
`;

const CheckboxBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  label > span:last-child {
    font-size: 12px;
  }
  a {
    color: #3e21de;
    text-decoration: none;
    padding: 0 4px;
  }
`;

const GiveawayAccount = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [giveawayCode, setGiveawayCode] = useState("");
  const [checked, setChecked] = useState(true);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const dispatch = useDispatch();
  const { errorMsg } = useSelector(state => state.errors);
  const { loading } = useSelector(state => state.loadings);

  useEffect(() => {
    dispatch(handleError(null));
  }, []);

  const handleGiveawayUser = e => {
    const userData = new FormData();
    userData.append("first_name", firstName);
    userData.append("last_name", lastName);
    userData.append("email", email);
    userData.append("password", password);
    userData.append("code", giveawayCode);
    userData.append("avatar", dummy);

    dispatch(createUser(userData));
  };

  const isDisabled =
    firstName === "" ||
    lastName === "" ||
    email === "" ||
    password === "" ||
    giveawayCode === "" ||
    checked === false;

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Thanks for signing up</h1>
          {/* <p>We just need a few more things and you'll be ready to go.</p> */}
        </Heading>

        <FormBody>
          <form onSubmit={handleSubmit(handleGiveawayUser)}>
            <TwoInputRow>
              <div>
                <Input
                  label="FIRST NAME"
                  type="text"
                  size="10px"
                  onChange={e => setFirstName(e.target.value)}
                  name="createFirstName"
                  validate={register({
                    minLength: 3,
                    required: true
                  })}
                />
                {errors.createFirstName && (
                  <span className="error-msg">Field must not be empty</span>
                )}
              </div>
              <div>
                <Input
                  label="LAST NAME"
                  type="text"
                  size="10px"
                  onChange={e => setLastName(e.target.value)}
                  name="createNastName"
                  validate={register({
                    minLength: 2,
                    required: true
                  })}
                />
                {errors.createNastName && (
                  <span className="error-msg">Field must not be empty</span>
                )}
              </div>
            </TwoInputRow>
            <div className={errors.createEmail || errorMsg ? "is-valid" : ""}>
              <Input
                label="E-MAIL"
                type="email"
                size="10px"
                onChange={e => setEmail(e.target.value)}
                name="createEmail"
                validate={register({
                  pattern: /^([a-z\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
                  required: true
                })}
              />
              {errors.createEmail && (
                <span className="error-msg">Field must be a valid email</span>
              )}
            </div>
            <PasswordInput
              handleChange={setPassword}
              label="PASSWORD"
              name="createPassword"
              size="10px"
              hasError={errors["createPassword"]}
              register={register}
            />
            <Input
              onChange={e => setGiveawayCode(e.target.value)}
              label="GIVEAWAY CODE"
              name="giveawayCode"
              size="10px"
              type="text"
            />
            <CheckboxBox>
              <Checkbox
                checked={checked}
                msg={
                  <span>
                    I have read and accepted the
                    <a
                      href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Terms-of-Services-Provision.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms
                    </a>
                    and
                    <a
                      href="https://d34cuim5v38p5e.cloudfront.net/legal/VisualEyes/Data-Protection-Policy.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Data
                    </a>
                    Policy.
                  </span>
                }
                handleCheckboxChange={handleCheckboxChange}
              />
            </CheckboxBox>
            <ButtonWithLoading
              loading={loading}
              title="Continue"
              type="submit"
              width="100"
              disabled={isDisabled}
            />
          </form>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default GiveawayAccount;
