import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import CircleScore from "../../../../ui/globals/CircleScore";

import GoTop from "../../../../ui/globals/ScrollToTop";
import { NonSelectable } from "../../../../layouts/Global";
import GradientBox from "../../../../ui/globals/GradientBox";
import ZoomInOut from "../../../../ui/globals/ZoomInOut";

// utils
import {
  debounceAction,
  drawAoiCoords,
} from "../../../../../utils/helperFunctions";

// constants
import { PREDICTION_TITLES } from "../../../../../constants/GenericContent";

// redux
import SinglePredictionTemplate from "../../../../ui/templates/SinglePredictionTemplate";
import useDrawInCanvas from "../../../../custom-hooks/useDrawInCanvas";

const Grid = styled.div`
  position: relative;
  margin: 0 auto;
  max-width: 1920px;
`;

const PredictBody = styled.div`
  padding: 24px 48px 88px 48px;
  position: relative;
`;

const ImageResult = styled.div`
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  width: ${(props) => props.width}%;
  margin: 40px auto 0 auto;
  ${NonSelectable}

  & > div {
    display: flex;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const SingleImageResult = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  canvas {
    position: absolute;
  }
`;

const ZoomButton = styled.div`
  position: fixed;
  left: 150px;
  bottom: ${(props) => (props.isAoiOrClarity ? "100px" : "56px")};
  z-index: 7;
`;

const VerticalBox = styled.div`
  position: fixed;
  right: 40px;
  bottom: ${(props) => (props.isAoiOrClarity ? "100px" : "40px")};
  z-index: 7;
`;

const Dimensions = styled.div`
  position: absolute;
  top: 20px;
  background: #fff;
  display: inline-block;
  width: 90px;
  z-index: 1;
  right: 19px;
  border-radius: 50px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const SingleExamplePredict = ({
  prediction,
  tier,
  type,
  parentPath,
  user,
  attentionInAoi,
  setDownloadAoi,
  nav,
  footer,
  scale,
  getScaleWidth,
  setDimensions,
  dimensions,
}) => {
  const {
    attention_serve_path,
    clarity_serve_path,
    original_serve_path,
    clarity_score,
    model,
    areas,
  } = prediction;

  const { use_percentage: percentageAoi } = user;

  const { ORIGINAL, ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;

  const dimensionsRef = useRef();
  const canvasRef = useRef();

  const [downloadAoi] = useDrawInCanvas(
    dimensions.h,
    dimensions.w,
    areas,
    type,
    scale,
    canvasRef,
    percentageAoi
  );

  useEffect(() => {
    setDownloadAoi(downloadAoi);
  }, [setDownloadAoi, downloadAoi]);

  useEffect(() => {
    const debounceHandleResize = debounceAction(function handleResize() {
      if (type === AOI) {
        drawAoiCoords(
          dimensionsRef.current.clientHeight,
          dimensionsRef.current.clientWidth,
          areas,
          canvasRef,
          percentageAoi
        );
      }
    }, 800);

    window.addEventListener("resize", debounceHandleResize);

    return (_) => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  });

  const getDimensions = () => {
    setDimensions({
      w: dimensionsRef.current.clientWidth,
      h: dimensionsRef.current.clientHeight,
    });
  };

  const isAoiOrClarity =
    type === AOI || (type === CLARITY && model !== "mobile");

  function getOverlayImage() {
    switch (type) {
      case ORIGINAL:
        return original_serve_path;
      case ATTENTION:
        return attention_serve_path;
      case CLARITY:
        return clarity_serve_path;
      case AOI:
        return original_serve_path;
      default:
        return null;
    }
  }
  const overlayImage = getOverlayImage();

  useEffect(() => {
    setDimensions({
      w: dimensionsRef.current.clientWidth,
      h: dimensionsRef.current.clientHeight,
    });
  }, [scale]);

  return (
    <SinglePredictionTemplate
      tier={tier}
      avatar={user.avatar}
      parentPath={parentPath}
      uid={user.uid}
    >
      {nav}
      <Grid>
        <PredictBody>
          <div>
            <ImageResult model={model} width={scale}>
              <div>
                <img
                  src={original_serve_path}
                  alt="original-result"
                  onLoad={() => getDimensions()}
                  ref={dimensionsRef}
                />
              </div>
              {type === ATTENTION && (
                <SingleImageResult>
                  <img src={overlayImage} alt="attention-result" />
                </SingleImageResult>
              )}
              {type === CLARITY && (
                <SingleImageResult>
                  <img src={overlayImage} alt="clarity-result" />

                  <Dimensions>
                    <CircleScore score={clarity_score} />
                  </Dimensions>
                </SingleImageResult>
              )}
              {type === AOI && (
                <SingleImageResult>
                  {attentionInAoi && (
                    <img src={attention_serve_path} alt="clarity-result" />
                  )}
                  <canvas ref={canvasRef}></canvas>
                </SingleImageResult>
              )}
            </ImageResult>

            <GoTop isAoiOrClarity={isAoiOrClarity} />
          </div>

          {areas && areas.length === 0 && type === AOI ? null : (
            <ZoomButton isAoiOrClarity={isAoiOrClarity}>
              <ZoomInOut width={scale} getScaleWidth={getScaleWidth} />
            </ZoomButton>
          )}

          {type === ATTENTION || type === CLARITY ? (
            <VerticalBox isAoiOrClarity={isAoiOrClarity}>
              <GradientBox type={type} />
            </VerticalBox>
          ) : null}

          {isAoiOrClarity ? footer : null}
        </PredictBody>
      </Grid>
    </SinglePredictionTemplate>
  );
};

export default SingleExamplePredict;
