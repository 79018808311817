import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button } from "desisystem";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import SvgLogo from "../../icons/SvgLogo";
import SvgBrand from "../../icons/SvgBrand";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { closeFeatureForAll } from "../../../redux/ducks/storage";
import { Delete } from "zoozoo";

function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}

const StyledSnack = styled(Snackbar)`
  && {
    .MuiSnackbarContent-root {
      background-color: #fff;
      padding: 24px;
      height: 225px;
      width: 430px;
      border-radius: 4px;
    }

    .MuiSnackbarContent-message {
      padding: 0;
      display: none;
    }

    .MuiSnackbarContent-action {
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
    }
  }
`;

const SnackbarBody = styled.div`
  ${"" /* display: flex;
  flex-direction: column;
  align-items: center; */}
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;

  > div {
    width: 100%;
  }

  svg {
    cursor: pointer;
  }
`;

const Top = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 34px;
    ${"" /* margin-top: 4px; */}
  }
`;

// const Labels = styled.p`
//   font-weight: 500;
//   font-size: 10px;
//   letter-spacing: 0.2em;
//   color: #3e21de;
//   margin: 0 0 4px 0;
// `;

const Heading = styled.h3`
  font-weight: bold;
  font-size: 20px;
  color: #212121;
  margin: 0;

  span {
    font-weight: normal;
  }
`;

const Text = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: #212121;
  margin: 0;
`;

const FirstText = styled.p`
  font-weight: normal;
  font-size: 12px;
  color: #212121;
  margin: 0;

  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  display: inline-block;
`;

const First = styled.div`
  width: 100%;
  padding: 0 0 16px 0;
`;

const Second = styled.div`
  width: 100%;
  padding: 0 0 16px 0;
`;

const StyledButton = styled(Button)`
  width: 100%;
  ${"" /* margin-top: 16px; */}
`;

const SimpleSnackbar = () => {
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // console.log("fsdfsfdf");
    setOpen(false);
  };

  //   const redirectAndHide = () => {
  //     history.push("/auth/create");
  //     dispatch(hideReportPop());
  //   };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      <StyledSnack
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={open}
        onClose={handleClose}
        TransitionComponent={SlideTransition}
        action={
          <SnackbarBody>
            <div>
              <Top>
                <span> 🎉 </span>
                {/* <Delete
                width={30}
                height={30}
                onClick={() => dispatch(closeFeatureForAll())}
              /> */}
              </Top>
            </div>
            <div>
              <First>
                {/* <Labels> PROJECTS FOR BASIC PLANS </Labels> */}
                <Heading>
                  5 live projects, <span> instead of 1.</span>
                </Heading>
                <FirstText>
                  We just expanded Project limits for Basic accounts. Enjoy!{" "}
                </FirstText>
              </First>
              <Second>
                {/* <Labels> SHARING </Labels> */}
                <Heading>Sharing is available for everyone!</Heading>
                <Text>Now you can share your great work with others. </Text>
              </Second>
              <StyledButton onClick={() => dispatch(closeFeatureForAll())}>
                Got it!
              </StyledButton>
            </div>
          </SnackbarBody>
        }
      />
    </div>
  );
};

export default SimpleSnackbar;
