import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";
import { refreshToken, authorizedToken, getRefreshToken } from "./auth";
import { successToast, errorToast } from "../../utils/helperFunctions";
import { ERROR_TOAST, SUCCESS_TOAST } from "../../constants/ToastMessages";
import { gaEveryPrediction, gaFirstPrediction } from "../../utils/analytics";
import { clearPredictData } from "../ducks/storage";
import { fetchSingleProjects } from "./projects";

// base url
let url = config.URL;

export const initialState = {
  loading: false,
  hasErrors: false,
  prediction: {},
  limitedClarityModal: false,
};

const predictionSlice = createSlice({
  name: "prediction",
  initialState,
  reducers: {
    loadPrediction: (state) => {
      state.loading = true;
    },

    getPredictionSuccess: (state, { payload }) => {
      state.prediction = payload;
      state.loading = false;
      state.hasErrors = false;
    },

    createSuccess: (state, { payload }) => {
      state.prediction = payload;
    },

    updatePredictionSuccess: (state, { payload }) => {
      state.loading = false;
      state.prediction.name = payload;
    },

    sharingSuccess: (state, { payload }) => {
      state.prediction.shareable = payload;
    },

    updateSuccess: (state, { payload }) => {
      state.prediction.message = payload;
    },

    setAoiPoints: (state, { payload }) => {
      state.prediction.areas = payload;
    },

    getPredictionFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    emptyPrediction: (state) => {
      state.prediction = {};
    },
  },
});

export const {
  loadPrediction,
  getPredictionFailure,
  getPredictionSuccess,
  updatePredictionSuccess,
  createSuccess,
  sharingSuccess,
  updateSuccess,
  setAoiPoints,
  emptyPrediction,
} = predictionSlice.actions;
export const predictionSelector = (state) => state.prediction;
export default predictionSlice.reducer;

export const fetchSinglePrediction = (id) => async (dispatch) => {
  // dispatch(loadPrediction());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/${id}`;

  try {
    const res = await axios.get(apiUrl, config);
    dispatch(emptyPrediction());
    dispatch(getPredictionSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchSinglePrediction, id));
      } else {
        dispatch(getPredictionFailure());
        errorToast(ERROR_TOAST.OUT_OF_ORDER);

        dispatch(push("/"));
      }
    } else {
      dispatch(getPredictionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const handleAnalytics = (predictLength) => {
  if (window.location.origin === "https://app.visualeyes.design") {
    if (predictLength === 0) {
      gaFirstPrediction();
    } else {
      gaEveryPrediction();
    }
  }
};

export const createPrediction = (predictData, predictLength) => async (
  dispatch
) => {
  handleAnalytics(predictLength);
  dispatch(loadPrediction());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions`;

  try {
    const res = await axios.post(apiUrl, predictData, config);
    dispatch(createSuccess(res.data));
    const { prediction_id } = res.data;
    dispatch(push(`/predictions/${prediction_id}`));
    successToast(SUCCESS_TOAST.CREATE_PREDICTION);
    dispatch(clearPredictData());
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, createPrediction, predictData, predictLength)
        );
      } else {
        dispatch(getPredictionFailure());
        errorToast(ERROR_TOAST.OUT_OF_ORDER);
        dispatch(push("/"));
      }
    } else {
      dispatch(getPredictionFailure());
      dispatch(push("/"));
      errorToast(ERROR_TOAST.GENERIC);
    }
  }
};

export function updatePredictionName(predictionName, id, newName) {
  return async (dispatch) => {
    dispatch(loadPrediction());

    const userData = await dispatch(getRefreshToken());
    const config = await dispatch(authorizedToken());

    let apiUrl = `${url}/predictions/${id}`;

    try {
      await axios.patch(apiUrl, predictionName, config);
      dispatch(updatePredictionSuccess(newName));
      dispatch(push(`/predictions/${id}`));
      successToast(SUCCESS_TOAST.UPDATE_PREDICTION);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(
            refreshToken(
              userData,
              updatePredictionName,
              predictionName,
              id,
              newName
            )
          );
        } else {
          dispatch(getPredictionFailure());
          errorToast(ERROR_TOAST.OUT_OF_ORDER);
          dispatch(push("/"));
        }
      }
      // else {
      //   dispatch(getPredictionFailure());
      //   dispatch(push("/"));
      //   errorToast(ERROR_TOAST.GENERIC);
      // }
    }
  };
}

export const removeFromProject = (removeData, projectId) => async (
  dispatch
) => {
  // dispatch(loadPrediction());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/remove`;

  try {
    await axios.post(apiUrl, removeData, config);
    dispatch(push(`/projects/${projectId}`));
    dispatch(fetchSingleProjects(projectId));
    successToast(SUCCESS_TOAST.REMOVE_PREDICTION);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, removeFromProject, removeData, projectId)
        );
      } else {
        dispatch(getPredictionFailure());
        errorToast(ERROR_TOAST.GENERIC);
        dispatch(push("/"));
      }
    } else {
      dispatch(getPredictionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const moveΤοProject = (moveData, projectId) => async (dispatch) => {
  dispatch(loadPrediction());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/move`;

  try {
    await axios.post(apiUrl, moveData, config);
    dispatch(push(`/projects/${projectId}`));
    successToast(SUCCESS_TOAST.MOVE_PREDICTION);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, moveΤοProject, moveData, projectId));
      } else {
        dispatch(getPredictionFailure());
        errorToast(ERROR_TOAST.GENERIC);
        dispatch(push("/"));
      }
    } else {
      dispatch(getPredictionFailure());
      errorToast(ERROR_TOAST.GENERIC);
      dispatch(push("/"));
    }
  }
};

export const sendImagePathBack = (imageUrl) => async (dispatch, getState) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/user/download`;

  try {
    await axios.post(apiUrl, imageUrl, config);
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, sendImagePathBack, imageUrl));
      } else {
        errorToast(error.response.data.reason);
      }
    }
  }
};

export const toggleSharing = (reportData, id, isShareable) => async (
  dispatch
) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/${id}/report`;

  try {
    await axios.patch(apiUrl, reportData, config);
    dispatch(sharingSuccess(isShareable));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, toggleSharing, reportData, id, isShareable)
        );
      } else {
        errorToast(ERROR_TOAST.GENERIC);
      }
    }
  }
};

export const updatePredictionReport = (
  reportData,
  id,
  recipientMessage
) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/${id}/report`;

  try {
    await axios.patch(apiUrl, reportData, config);

    dispatch(updateSuccess(recipientMessage));
    successToast("Your reports message is updated.");
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(
            userData,
            updatePredictionReport,
            reportData,
            id,
            recipientMessage
          )
        );
      } else {
        errorToast(ERROR_TOAST.UPDATE_PROJECT);
      }
    }
  }
};

export const predictionLogout = () => (dispatch) => {
  dispatch(emptyPrediction());
};

export const sendAoi = (aoiData, id) => async (dispatch) => {
  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/${id}/areas`;

  try {
    const res = await axios.patch(apiUrl, aoiData, config);
    dispatch(setAoiPoints(res.data.areas));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, sendAoi, aoiData, id));
      } else {
        errorToast(ERROR_TOAST.GENERIC);
      }
    }
  }
};
