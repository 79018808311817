import { combineReducers } from "redux";
import auth from "./ducks/auth";
import errors from "./ducks/errors";
import loadings from "./ducks/loadings";
import storage from "./ducks/storage";
import { reducer as toastrReducer } from "react-redux-toastr";
import { connectRouter } from "connected-react-router";
import predictions from "./slices/predictions";
import prediction from "./slices/prediction";
import authS from "./slices/auth";
import globalActions from "./slices/globalActions";
import projects from "./slices/projects";
import subs from "./slices/subs";
import reports from "./slices/reports";
import account from "./slices/account";
import user from "./slices/user";

const allReducers = (history) =>
  combineReducers({
    auth,
    errors,
    loadings,
    user,
    storage,
    authS,
    predictions,
    prediction,
    globalActions,
    projects,
    subs,
    reports,
    account,
    toastr: toastrReducer,
    router: connectRouter(history),
  });

export default allReducers;
