import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import useForm from "react-hook-form";
import { handleError } from "../../../redux/ducks/errors";
import SocialProofLayout from "../../layouts/SocialProofLayout";

// design system
import { Input, PasswordInput } from "desisystem";

// actions
import { loginUser, socialAuth } from "../../../redux/ducks/auth";
import { GoogleButton } from "../../layouts/Global";
import GoogleLogin from "react-google-login";

import { config } from "../../../constants/endpoints";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";
import InlineErrorMessage from "../../ui/globals/InlineErrorMessage";

//  Google auth key
let googleAuthKey = config.GOOGLE_AUTH;

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.subHeading};
  }
`;

const StyledGoogleLogin = styled(GoogleLogin)`
  ${GoogleButton}

  background-color: ${(props) => props.theme.colors.globalBg} !important;

  & > div {
    background: transparent !important;
  }
`;

const HorizontalLine = styled.div`
  position: relative;
  padding: 24px;
  margin: 0 1.5rem;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: #e0e0e0;
    z-index: 1;
  }

  p {
    color: #c9c9c9;
    font-size: 12px;
    background: ${(props) => props.theme.colors.globalBg};
    margin: 16px auto;
    display: inline-block;
    padding: 0 24px;
    z-index: 2;
    font-weight: bold;
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const ForgotPass = styled.div`
  text-align: right;
  margin-top: 24px;
  margin-bottom: 40px;

  a {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[12]};
    color: ${(props) => props.theme.colors.brandPurple};
    text-decoration: none;
  }
`;

const FormBody = styled.div`
  margin-top: 40px;
`;

const NotMember = styled.div`
  margin-top: 16px;
  font-size: ${(props) => props.theme.fontSize[12]};
  color: ${(props) => props.theme.colors.black};

  a {
    color: ${(props) => props.theme.colors.brandPurple};
    text-decoration: none;
  }
`;

const Login = ({ location: { state = null } }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [futurePlan, setFuturePlan] = useState("");
  const [futureDuration, setFutureDuration] = useState("");

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const dispatch = useDispatch();
  const { errorMsg } = useSelector((state) => state.errors);
  const { loading } = useSelector((state) => state.loadings);

  // console.log(state);

  useEffect(() => {
    if (state !== null) {
      if (state.fromSiteData) {
        const { plan, duration } = state.fromSiteData;
        setFuturePlan(plan);
        setFutureDuration(duration);
      }
    }
  }, [futurePlan, futureDuration, state]);

  useEffect(() => {
    dispatch(handleError(null));
  }, [dispatch]);

  const handleSubmitLogin = (e) => {
    const directData = {
      plan: futurePlan,
      duration: futureDuration,
    };

    const userData = new FormData();
    userData.append("email", email);
    userData.append("password", password);
    dispatch(loginUser(userData, directData));
  };

  const isDisabled = email === "" || password === "";

  const responseGoogle = (response) => {
    if (!response.error) {
      const directData = {
        plan: futurePlan,
        duration: futureDuration,
      };

      const { accessToken } = response;
      const userData = new FormData();
      userData.append("access_token", accessToken);

      dispatch(socialAuth(userData, directData));
    }
  };

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Sign in</h1>
        </Heading>

        <FormBody>
          <StyledGoogleLogin
            clientId={googleAuthKey}
            buttonText="Sign in with Google"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={"single_host_origin"}
          />
          <HorizontalLine>
            <p>OR USE YOUR EMAIL</p>
          </HorizontalLine>

          <form onSubmit={handleSubmit(handleSubmitLogin)}>
            <div className={errors.loginEmail || errorMsg ? "is-valid" : ""}>
              <Input
                label="E-MAIL"
                type="email"
                size="10px"
                onChange={(e) => setEmail(e.target.value)}
                name="loginEmail"
                validate={register({
                  // pattern: /^([a-z\d\._]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
                  required: true,
                })}
              />
              {errors.loginEmail && (
                <span className="error-msg">Field must be a valid email</span>
              )}
            </div>
            <div className={errorMsg ? "is-valid" : ""}>
              <PasswordInput
                handleChange={setPassword}
                label="PASSWORD"
                name="password"
                size="10px"
                hasError={errors["password"]}
                register={register}
              />
            </div>
            <ForgotPass>
              <Link to="/auth/forgot">Forgot your password?</Link>
            </ForgotPass>

            {errorMsg && <InlineErrorMessage errorMsg={errorMsg} />}

            <ButtonWithLoading
              loading={loading}
              title="Login"
              width="100"
              disabled={isDisabled}
            />

            <NotMember>
              Not a member yet?{" "}
              <Link
                to={{
                  pathname: "/auth/create",
                  state: {
                    fromSiteData: state !== null ? state.fromSiteData : null,
                  },
                }}
              >
                Create your account now.
              </Link>
            </NotMember>
          </form>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default Login;
