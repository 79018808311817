import React, { useEffect } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  dynamicClass,
  removeDynamicClass,
  projectsSelector,
} from "../../../../../redux/slices/projects";
import {
  gaProjectLinkInNavigation,
  gaNavViewAllGuides,
} from "../../../../../utils/analytics";

const UL = styled.ul`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  font-weight: bold;

  li {
    margin-right: 30px;
  }

  a {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #c9c9c9;
    text-decoration: none;
    transition: all 0.3s;
    &:hover {
      color: ${(props) => props.theme.colors.brandPurple};
      transition: all 0.3s;
    }

    &:focus {
      color: #3e21de;
    }
  }

  .active {
    color: #3e21de;
  }
`;

const MenuLinks = ({ pathname }) => {
  const { activeRoute } = useSelector(projectsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      pathname.includes("projects") &&
      !pathname.includes("examples/projects")
    ) {
      dispatch(dynamicClass());
    } else {
      dispatch(removeDynamicClass());
    }
  }, [dispatch, pathname]);

  return (
    <UL>
      <li>
        <NavLink exact to="/">
          Home
        </NavLink>
      </li>
      <li>
        <NavLink
          exact
          to="/projects"
          className={activeRoute ? "active" : ""}
          onClick={() => gaProjectLinkInNavigation()}
        >
          Projects
        </NavLink>
      </li>
      <li>
        <NavLink
          exact
          to="/examples/projects"
          onClick={() => gaNavViewAllGuides()}
        >
          Guides
        </NavLink>
      </li>
    </UL>
  );
};

export default MenuLinks;
