import React, { useEffect, useState } from "react";
import GoUp from "../../icons/GoUp";
import styled from "styled-components";
import { debounceAction } from "../../../utils/helperFunctions";

const Round = styled.div`
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: fixed;
  bottom: ${(props) => (props.isAoiOrClarity ? "90px" : "40px")};
  left: 50%;
  transform: translate(-50%);
  cursor: pointer;
  border: 2px solid rgba(62, 33, 222, 1);

  svg {
    stroke: rgba(62, 33, 222, 1);
  }

  &:hover {
    transition: all 0.3s;
    border: 2px solid rgba(62, 33, 222, 1);
    background: rgba(62, 33, 222, 1);

    svg {
      stroke: rgba(255, 255, 255, 1);
    }
  }
`;

const ScrollToTop = ({ isAoiOrClarity }) => {
  const [showButton, setShowButton] = useState(false);

  const scrollToTop = () => {
    // window.scrollTo(0, 0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const debounceHandleResize = debounceAction(function handleResize() {
      window.scrollY > 170 ? setShowButton(true) : setShowButton(false);
    }, 500);

    window.addEventListener("scroll", debounceHandleResize);

    return (_) => {
      window.removeEventListener("scroll", debounceHandleResize);
    };
  });

  return (
    <React.Fragment>
      {showButton && (
        <div onClick={() => scrollToTop()}>
          <Round isAoiOrClarity={isAoiOrClarity}>
            <GoUp width={26} height={26} />
          </Round>
        </div>
      )}
    </React.Fragment>
  );
};

export default ScrollToTop;
