import React from "react";
import styled from "styled-components";
import { Button, Body } from "desisystem";
import FullPageModal from "../../global/FullPageModal";
import { CardElement } from "@stripe/react-stripe-js";
import ButtonWithLoading from "../buttons/ButtonWithLoading";

const ContentBox = styled.div`
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 16px 0;
    text-align: center;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: #9e9e9e;

    span {
      color: ${(props) => props.theme.colors.black};
    }
  }
`;

const StripeBox = styled.div`
  margin: 23px 0 40px 0;

  span {
    font-weight: 500;
    font-size: 10px;
    color: #9e9e9e;
    letter-spacing: 0.2em;
    margin-bottom: 8px;
    display: block;
  }

  .stripe-box {
    border: 1px solid
      ${(props) => (props.error ? "red" : props.theme.colors.brandPurple)};
    padding: 12px;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
  }

  p {
    color: red;
    font-size: 14px;
  }
`;

const ActionButtonBox = styled.div`
  max-width: 360px;
  margin: 40px auto 0 auto;

  button {
    width: 100%;
    min-width: 160px;
    height: 40px;
    padding: 0;
    margin: 4px 0;
    font-size: ${(props) => props.theme.fontSize[14]};

    &:hover {
      box-shadow: none;
    }
  }
`;

const EditPaymentModal = ({
  open,
  closeModal,
  email,
  handleSubmit,
  loading,
  stripeError,
  hasMethod,
}) => {
  return (
    <FullPageModal open={open}>
      <ContentBox>
        <h2>{`${hasMethod ? "Change" : "Add"}`} your payment method</h2>
        <p>
          Logged in as <span>{email}</span>
        </p>
      </ContentBox>
      <ActionButtonBox>
        <StripeBox error={stripeError}>
          <span>ENTER YOUR NEW CARD INFO</span>
          <CardElement
            className="stripe-box"
            options={{
              style: {
                base: {
                  fontSize: "16px",
                },
              },
            }}
          />
          <p>{stripeError}</p>
        </StripeBox>

        <ButtonWithLoading
          loading={loading}
          title={`${hasMethod ? "Upgrade" : "Add"} payment method`}
          onClick={handleSubmit}
        />
        <Button appearance="secondary" onClick={() => closeModal()}>
          Go Back
        </Button>
      </ActionButtonBox>
    </FullPageModal>
  );
};

export default EditPaymentModal;
