import React from "react";
import styled from "styled-components";
import Global from "../layouts/Global";
import SvgLogo from "../icons/SvgLogo";
import SvgBrand from "../icons/SvgBrand";
import { NavLink } from "react-router-dom";

const StyledLayout = styled.div`
  background: ${props => props.theme.colors.white};
  position: relative;
`;

const Container = styled.div`
  padding: 7vh 6vw 24px 6vw;
  height: 100vh;
  position: relative;
  background: ${props => props.theme.colors.white};

  & > div:first-child {
    display: flex;
    align-items: center;
  }
`;

const LinkBox = styled.div`
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translate(-50%);

  span {
    background: #e0e0e0;
    width: 10px;
    height: 10px;
    border-radius: ${props => props.theme.radius.round};
    display: inline-block;
    margin: 0 5px;
  }

  .active {
    span {
      background: ${props => props.theme.colors.brandPurple};
    }
  }
`;

const dotsLinks = [
  { path: "/onboarding/step-one" },
  { path: "/onboarding/step-two" },
  { path: "/onboarding/step-three" },
  { path: "/onboarding/step-four" }
];

const StepsLayout = props => {
  return (
    <Container>
      <Global />
      <div>
        <SvgLogo width={48} height={48} />
        <SvgBrand fill="#1D0F68" width={110} height={28} />
      </div>
      <LinkBox>
        {dotsLinks.map((dot, i) => (
          <NavLink key={i} to={dot.path}>
            <span></span>
          </NavLink>
        ))}
      </LinkBox>
      <StyledLayout>{props.children}</StyledLayout>
    </Container>
  );
};

export default StepsLayout;
