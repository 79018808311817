import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  gaViewAllGuides,
  gaDashAttentionGuide,
  gaDashClarityGuide,
} from "../../../../utils/analytics";

const Container = styled.div`
  padding-bottom: 48px;

  & > h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 8px 0;
  }
`;

const ExamplesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  & > p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.softGrey};
    margin: 0;
  }
`;

const ExamplesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }

  a {
    transition: all 0.4s;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

    &:hover {
      transition: all 0.4s;
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
    }
  }
`;

const ExampleCard = styled.div`
  background-image: url(${(props) => props.url});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  min-height: 150px;
  border-radius: 4px;
`;

const DashboardExamples = ({ firstExamples, pathOrigin }) => {
  return (
    <Container>
      <h2>Getting started </h2>
      <ExamplesHeader>
        <p>
          Great to have you onboard! You are free to explore or give a go to our
          curated guides just below.
        </p>
      </ExamplesHeader>
      <ExamplesGrid>
        {firstExamples.map((prediction, i) => {
          return (
            <Link
              to={`/examples/projects/${prediction.id}`}
              key={i}
              onClick={
                prediction.name === "Unlock Attention Maps"
                  ? () => gaDashAttentionGuide()
                  : () => gaDashClarityGuide()
              }
            >
              <ExampleCard url={prediction.thumbnail_path}></ExampleCard>
            </Link>
          );
        })}
        <Link to="/examples/projects" onClick={() => gaViewAllGuides()}>
          <ExampleCard url="https://loceye-master-app.s3-eu-west-1.amazonaws.com/visualeyes/examples/all-guides.jpg"></ExampleCard>
        </Link>
      </ExamplesGrid>
    </Container>
  );
};

export default DashboardExamples;
