import { hot, setConfig } from "react-hot-loader";
import React, { useEffect } from "react";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Helmet } from "react-helmet";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import allReducers from "./redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { isMobile } from "./utils/Mobile";
import MobileLayout from "./components/layouts/MobileLayout";
import ReduxToastr from "react-redux-toastr";
import "./styles/toast.css";
import ErrorBoundary from "./components/layouts/ErrorBoundary";
import { ThemeProvider } from "styled-components";
import { my } from "./my";
import { HeadProvider } from "react-head";

// auth
import Verify from "./components/auth/Verify";
import AcceptInvite from "./components/auth/AcceptInvite";
import Unsubscribe from "./components/auth/Unsubscribe";

// auth / createAccount
import AddEmail from "./components/auth/createAccount/AddEmail";
import AddName from "./components/auth/createAccount/AddName";

// auth / login
import LoginAccount from "./components/auth/loginAccount/Login";
import AppsumoLogin from "./components/auth/appsumo/Auth";

// auth / forgot password
import ForgotPassword from "./components/auth/forgotPassword/ForgotPassword";
import ForgotPasswordSuccess from "./components/auth/forgotPassword/screens/ForgotPasswordSuccess";

// auth / reset/set password
import ResetPassword from "./components/auth/resetPassword/ResetPassword";
import SetPassword from "./components/auth/resetPassword/SetPassword";

// auth / giveaway
import GiveawayAccount from "./components/auth/giveawayAccount/Giveaway";

// on boarding
import AboutYou from "./components/pages/onboarding/AboutYou";
import StepOne from "./components/pages/onboarding/StepOne";
import StepTwo from "./components/pages/onboarding/StepTwo";
import StepThree from "./components/pages/onboarding/StepThree";
import StepFour from "./components/pages/onboarding/StepFour";

// components Routes
import PrivateRoute from "./utils/PrivateRoute";
import RedirectRoute from "./utils/RedirectRoute";

// dashboard
import Dashboard from "./components/pages/dashboard/Dashboard";

// profile
import Profile from "./components/pages/profile/Profile";
import Team from "./components/pages/profile/Team";

// subscription
import Subscription from "./components/pages/subscription/Subscription";

// upgrades
import Upgrade from "./components/pages/upgrades/Upgrade";
import SuccessPage from "./components/pages/upgrades/screens/SuccessPage";
import Success3DPage from "./components/pages/upgrades/screens/Success3DPage";
import ChoosePaidPlan from "./components/pages/upgrades/ChoosePaidPlan";

// projects
import Projects from "./components/pages/projects/ProjectsContainer";
import SingleProject from "./components/pages/projects/SingleProjectContainer";

// predicts
import SinglePredict from "./components/pages/predicts/SinglePredictionContainer";
import AllPredictions from "./components/pages/predicts/PredictionsContainer";

// create
import MakePrediction from "./components/pages/create/createPrediction/ChooseDevice";
import Upload from "./components/pages/create/createPrediction/UploadImage";
import MakeProject from "./components/pages/create/createProject/CreateProject";
import MovePredict from "./components/pages/create/movePrediction/MovePredictionContainer";
import AddPredictionsToProject from "./components/pages/create/addPredictions/AddPredictions";

// reporting
import PredictionReport from "./components/pages/reporting/prediction/PredictionReportContainer";
import ProjectReport from "./components/pages/reporting/project/ProjectReportContainer";

// benchmark
import BenchmarkProject from "./components/pages/benchmark/BenchmarkProject";

// examples
import ExampleProjects from "./components/pages/examples/project/ExampleProjectsContainer";
import SingleExampleProject from "./components/pages/examples/project/SingleExampleProjectContainer";
import SingleExamplePrediction from "./components/pages/examples/prediction/SingleExamplePredictionContainer";

// layout
import NotFound404 from "./components/layouts/NotFound404";
import UnavailableReport from "./components/layouts/UnavailableReport";

import { fbInit, gaInit } from "./utils/analytics";
import { Meta, Title } from "react-head";
import DirectPay from "./components/auth/DirectPay";
import FromPlugins from "./components/auth/FromPlugins";
import { useSelector } from "react-redux";
import { userSelector } from "./redux/slices/user";
import { config } from "./constants/endpoints";
// import setupIntercom from "./utils/intercom";

const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "user", "storage"],
};

const rootReducers = allReducers(history);

const persistedReducer = persistReducer(persistConfig, rootReducers);

const historyMiddleware = routerMiddleware(history);

const middleware = [thunk, historyMiddleware];

const enhancer = composeWithDevTools(applyMiddleware(...middleware));

let store = createStore(persistedReducer, enhancer);
let persistor = persistStore(store);

function AppwithHoc() {
  return <RedirectToNeurons />;
  // return (
  //   <ErrorBoundary>
  //     <HeadProvider>
  //       <Provider store={store}>
  //         <ThemeProvider theme={my}>
  //           <PersistGate loading={null} persistor={persistor}>
  //             {isMobile() ? <MobileLayout /> : <App history={history} />}
  //             <ReduxToastr
  //               timeout={0}
  //               newestOnTop={true}
  //               preventDuplicates
  //               position="bottom-center"
  //               transitionIn="bounceIn"
  //               transitionOut="bounceOut"
  //             />
  //           </PersistGate>
  //         </ThemeProvider>
  //       </Provider>
  //     </HeadProvider>
  //   </ErrorBoundary>
  // );
}

function RedirectToNeurons({}) {
  window.location.href = "https://app.neuronsinc.com";
  return null;
}

function App({ history }) {
  const { isAuthenticated, analyticsApiKey } = useSelector(
    (state) => state.auth
  );
  const { user } = useSelector(userSelector);
  const { first_name, last_name, email } = user;

  // useEffect(() => {
  //   setupIntercom(first_name, last_name, email);
  // }, [first_name, last_name, email]);

  useEffect(() => {
    fbInit();
    if (isAuthenticated && analyticsApiKey !== undefined) {
      gaInit(analyticsApiKey);
    }
  }, [analyticsApiKey, isAuthenticated]);

  // window.Intercom("update");

  return (
    <React.Fragment>
      <ConnectedRouter history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/profile" component={Profile} />
          <PrivateRoute exact path="/team" component={Team} />
          <PrivateRoute exact path="/subscription" component={Subscription} />
          {/* <PrivateRoute exact path="/upgrade" component={Upgrade} />
          <PrivateRoute exact path="/upgrade/success" component={SuccessPage} /> */}
          <PrivateRoute
            exact
            path="/upgrade/3dsuccess"
            component={Success3DPage}
          />
          {/* <PrivateRoute
            exact
            path="/upgrade/choose-plan"
            component={ChoosePaidPlan}
          /> */}

          <PrivateRoute exact path="/projects" component={Projects} />
          <PrivateRoute exact path="/projects/:id" component={SingleProject} />
          <PrivateRoute exact path="/predictions" component={AllPredictions} />
          <PrivateRoute
            exact
            path="/predictions/:id"
            component={SinglePredict}
          />

          {/* create */}
          <PrivateRoute
            exact
            path="/predict/create/device"
            component={MakePrediction}
          />
          <PrivateRoute
            exact
            path="/predict/create/upload"
            component={Upload}
          />
          <PrivateRoute exact path="/create/projects" component={MakeProject} />
          <PrivateRoute exact path="/predict/move" component={MovePredict} />
          <PrivateRoute
            exact
            path="/add/predictions/"
            component={AddPredictionsToProject}
          />
          {/* create */}

          {/* on-boarding */}
          <PrivateRoute exact path="/onboarding/about" component={AboutYou} />
          <PrivateRoute exact path="/onboarding/step-one" component={StepOne} />
          <PrivateRoute exact path="/onboarding/step-two" component={StepTwo} />
          <PrivateRoute
            exact
            path="/onboarding/step-three"
            component={StepThree}
          />
          <PrivateRoute
            exact
            path="/onboarding/step-four"
            component={StepFour}
          />
          {/* on-boarding */}

          <Route path="/verify" component={Verify} />
          <Route path="/accept-invite" component={AcceptInvite} />
          <Route path="/unsubscribe" component={Unsubscribe} />
          <Route path="/direct" component={DirectPay} />
          <Route path="/plugins" component={FromPlugins} />

          {/* <RedirectRoute
            exact
            path="/auth/giveaway"
            component={GiveawayAccount}
          /> */}

          {/* reporting  */}
          <Route
            exact
            path="/reporting/project/:id"
            component={ProjectReport}
          />
          <Route exact path="/reporting/:id" component={PredictionReport} />
          <Route
            exact
            path="/unavailable/reporting"
            component={UnavailableReport}
          />

          {/* reporting  */}

          {/* examples */}

          <PrivateRoute
            exact
            path="/examples/projects"
            component={ExampleProjects}
          />

          <PrivateRoute
            exact
            path="/examples/projects/:id"
            component={SingleExampleProject}
          />

          <PrivateRoute
            exact
            path="/examples/predictions/:id"
            component={SingleExamplePrediction}
          />

          {/* examples  */}

          <PrivateRoute
            exact
            path="/compare/project/:id"
            component={BenchmarkProject}
          />

          {/* auth */}
          <RedirectRoute exact path="/auth/login" component={LoginAccount} />
          <RedirectRoute
            exact
            path="/auth/create"
            component={RedirectToNeurons}
          />
          <RedirectRoute
            exact
            path="/auth/create/name"
            component={RedirectToNeurons}
          />
          <RedirectRoute exact path="/auth/forgot" component={ForgotPassword} />
          <RedirectRoute
            exact
            path="/auth/forgot/success"
            component={ForgotPasswordSuccess}
          />
          <RedirectRoute exact path="/auth/reset" component={ResetPassword} />
          <RedirectRoute exact path="/auth/set" component={SetPassword} />

          {/* Appsumo */}
          <Route exact path="/appsumo/:id" component={AppsumoLogin} />

          <Route component={NotFound404} />
        </Switch>
      </ConnectedRouter>

      {/* i@am.trash */}
      {user &&
        config.WHITE_LABEL.map((userCustom) => userCustom.id).includes(
          user.uid
        ) && (
          <Helmet>
            <title>
              {
                config.WHITE_LABEL.find(
                  (userCustom) => userCustom.id === user.uid
                ).name
              }
            </title>
            <link
              rel="icon"
              href={
                config.WHITE_LABEL.find(
                  (userCustom) => userCustom.id === user.uid
                ).favicon
              }
            />
          </Helmet>
        )}

      {/* for head */}
      {process.env.REACT_APP_GOOGLE_AUTH_STAGING_VERIFICATION && (
        <Meta
          name="google-site-verification"
          content="I-hIVs28y4q2BZc57VW8VLM4IvDnsS-pQHm1wevNum0"
        />
      )}
    </React.Fragment>
  );
}

setConfig({ logLevel: "debug", ureSFC: true });

export default AppwithHoc;
