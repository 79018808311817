import React, { useState } from "react";
import Modal from "react-awesome-modal";
import { Input, Button, H2, H3 } from "desisystem";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Switch from "../../ui/globals/Switch";
import { Warning, Delete, Redirect } from "zoozoo";
import { useCopyClipboard } from "react-recipes";
import ReactTooltip from "react-tooltip";
// import { REPORT_CONTENT } from "../../../constants/GenericContent";

const ModalBody = styled.div`
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  min-width: 520px;
  max-width: 520px;
  position: relative;

  h2 {
    color: #212121;
    font-weight: bold;
    font-size: 26px;
    margin: 0 0 16px 0;
    text-align: left;
  }

  h2 + svg {
    position: absolute;
    top: 45px;
    right: 40px;
    cursor: pointer;
  }

  button {
    margin-top: 40px;
  }
`;

const SharingStatus = styled.div`
  text-align: left;
  margin-top: 40px;

  h3 {
    font-weight: bold;
    font-size: 14px;
    color: #212121;
    margin-bottom: 8px;
  }

  p {
    margin: 0 0 8px 0;
    font-size: 12px;
    color: #9e9e9e;
    display: flex;
    align-items: center;
  }

  a {
    color: ${(props) => props.theme.colors.brandPurple};
    display: inline-block;
    margin-left: 4px;
    text-decoration: underline;
  }
`;

const SharingUrl = styled.div`
  text-align: left;
  margin-top: 30px;

  label {
    display: none;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    color: #212121;
    margin-bottom: 8px;
  }

  p {
    margin: 0 0 8px 0;
    font-size: 12px;
    color: #9e9e9e;
  }
`;

const SharingMessage = styled.div`
  text-align: left;
  margin-top: 30px;

  h3 {
    font-weight: bold;
    font-size: 14px;
    color: #212121;
    margin-bottom: 8px;
  }

  p {
    margin: 0 0 8px 0;
    font-size: 12px;
    color: #9e9e9e;
  }
`;

// const TextArea = styled.textarea`
//   font-family: "Roboto";
//   width: 100%;
//   border: 1px solid #9e9e9e;
//   border-radius: 4px;
//   resize: none;
//   padding: 8px;
//   height: 100px;
//   font-size: 14px;
//   color: #212121;

//   &::placeholder {
//     color: #9e9e9e;
//   }

//   &:focus {
//     outline: 0;
//     border: 1px solid #3e21de;
//     background: #f1effd;
//   }
// `;

const SwitchBox = styled.div`
  transform: translateX(-8px);

  .MuiSwitch-root {
    height: 40px;
  }
  .MuiSwitch-switchBase {
    top: 4px;
    left: 5px;
  }

  .MuiSwitch-thumb {
    width: 13px;
    height: 13px;
    color: #fff;
    box-shadow: none;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(17px);
  }

  .Mui-checked .MuiSwitch-thumb {
    color: #fff;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #3e21de;
    opacity: 1;
    border-radius: 50px;
  }

  .MuiSwitch-colorPrimary + .MuiSwitch-track {
    background-color: #e0e0e0;
    opacity: 1;
    border-radius: 50px;
  }

  ${(props) =>
    props.isBound &&
    `
  .Mui-checked .MuiSwitch-thumb {
    color: #c3baf5;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #F1EFFD;
    opacity: 1;
  }
  `}
`;

const SharingUrlBox = styled.div`
  display: flex;
  align-items: center;

  .customStyles {
    text-align: center;
  }

  input {
    color: #3e21de;
    border: 1px solid #c3baf5;
    background: #f1effd;
    cursor: pointer;
  }

  & > div:first-child {
    width: 100%;

    > div {
      margin: 0;
    }
  }
`;

const TooltipLink = styled.a`
  width: 100%;

  > div {
    > div {
      margin: 0;
    }
  }
`;

const RedirectLink = styled.div`
  a {
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all 0.3s;
  }

  a:hover {
    transition: all 0.3s;
    transform: translateY(-3px);
  }

  svg {
    margin-right: 16px;
  }
`;

const Fly = styled.span`
  width: 100px;

  .__react_component_tooltip.show {
    min-width: 140px;
    font-size: 12px;
    border-radius: 4px;
  }
`;

const HiddenSection = styled.div``;

const ReportModal = ({
  open,
  closeModal,
  fromProject = false,
  // prediction,
  projectId,
  projectName,
  predictId,
  action,
  reportUrl,
  share,
  isBound = false,
  message,
  sharedCompare,
  handleSharingState,
  handleCompareViewState,
}) => {
  const [isCopied, setIsCopied] = useCopyClipboard();

  let baseUrl = window.location.origin;
  let reportBaseUrl = `${baseUrl}/${reportUrl}`;

  const handleFocus = (event) => {
    event.target.select();
  };

  const copy = () => {
    setIsCopied(reportBaseUrl);
  };

  return (
    <Modal
      visible={open}
      effect="fadeInUp"
      onClickAway={() => closeModal()}
      style={{ height: "auto!important", marginTop: "100px!important" }}
    >
      <ModalBody>
        <H2>Sharing options</H2>
        <Delete width={27} height={27} onClick={() => closeModal()} />
        <SharingStatus>
          <H3>Sharing Status</H3>
          {isBound ? (
            <p>
              <Warning
                stroke="#D53C3C"
                width={17}
                height={17}
                style={{ marginRight: "2px" }}
              />
              Project {projectName} is public.
              <Link
                to={{
                  pathname: `/projects/${projectId}`,
                  state: {
                    directShare: true,
                  },
                }}
              >
                Want to change it's sharing options?
              </Link>
              .
            </p>
          ) : (
            <React.Fragment>
              {share ? (
                <p>Anyone with the link can access this report.</p>
              ) : (
                <p>Manage your report's availability to 3rd parties.</p>
              )}
            </React.Fragment>
          )}

          <SwitchBox isBound={isBound ? 1 : 0}>
            <Switch
              disabled={isBound}
              checked={share}
              onChange={() => handleSharingState()}
            />
          </SwitchBox>
        </SharingStatus>

        {share && fromProject && (
          <SharingStatus>
            <H3>Compare mode availability</H3>

            <p>
              Would you like to also have the Compare mode view of your project?
            </p>

            <SwitchBox isBound={isBound ? 1 : 0}>
              <Switch
                disabled={isBound}
                checked={sharedCompare}
                onChange={() => handleCompareViewState()}
              />
            </SwitchBox>
          </SharingStatus>
        )}

        {share && (
          <HiddenSection>
            <Fly>
              <ReactTooltip
                className="customStyles"
                place="top"
                type="dark"
                effect="solid"
                getContent={() => {
                  return isCopied ? "Copied" : "Copy to Clipboard";
                }}
              />
            </Fly>
            <SharingUrl>
              <H3>Share URL</H3>
              <SharingUrlBox>
                <TooltipLink href="#0" data-tip>
                  <Input
                    defaultValue={reportBaseUrl}
                    onClick={copy}
                    onFocus={(e) => handleFocus(e)}
                  />
                </TooltipLink>
                <RedirectLink>
                  <a href={reportBaseUrl} target="_black">
                    <Redirect fill="#212121" width={15} height={15} />
                  </a>
                </RedirectLink>
              </SharingUrlBox>
            </SharingUrl>
          </HiddenSection>
        )}
      </ModalBody>
    </Modal>
  );
};
export default ReportModal;
