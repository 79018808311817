import React, { useState } from "react";
import { H2 } from "desisystem";
import styled from "styled-components";
import { PREDICTION_TITLES } from "../../../constants/GenericContent";
import { Delete } from "zoozoo";
import Dialog from "@material-ui/core/Dialog";
import { drawAoiCoords } from "../../../utils/helperFunctions";
import GradientBox from "../../ui/globals/GradientBox";
import CircleScore from "../../ui/globals/CircleScore";
import GoUp from "../../icons/GoUp";
import watermarkDark from "../../../assets/image.png";
import watermarkLight from "../../../assets/watermark-light.svg";

const ModalBody = styled.div`
  min-width: 600px;
  max-width: 80vw;
  box-sizing: border-box;
`;

const ModalHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 2;
  background: #fff;
  padding: 16px 32px;

  h2 {
    font-size: ${(props) => props.theme.fontSize[14]};
    margin: 0;
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.black};
    text-align: center;
  }
`;

const PreviewImage = styled.div`
  position: relative;

  img {
    display: flex;
    width: 100%;
    height: 100%;
  }
`;

const WatermarkImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-image: url(${(props) => props.watermark});
  background-size: 25% auto;
`;

const AoiPreview = styled.div`
  z-index: ${(props) => (!props.active ? -1 : 1)};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const CloseModal = styled.div`
  position: fixed;
  right: 40px;
  top: 20px;
  cursor: pointer;
`;

const VerticalBox = styled.div`
  position: fixed;
  right: ${(props) => (props.attention ? "50px" : "40px")};
  top: 50%;
  transform: translateY(-50%);
  z-index: 7;
`;

const ScoreCircle = styled.div`
  position: absolute;
  top: 25px;
  background: #fff;
  display: inline-block;
  width: 90px;
  height: 90px;
  right: 19px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 1;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const Round = styled.div`
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: fixed;
  bottom: 40px;
  right: 20px;
  transform: translate(-50%);
  cursor: pointer;
  border: 2px solid rgba(62, 33, 222, 1);

  svg {
    stroke: rgba(62, 33, 222, 1);
  }

  &:hover {
    transition: all 0.3s;
    border: 2px solid rgba(62, 33, 222, 1);
    background: rgba(62, 33, 222, 1);

    svg {
      stroke: rgba(255, 255, 255, 1);
    }
  }
`;

const SurveyModal = ({ open, closeModal, src, id, type, compare, tier }) => {
  const { use_percentage: percentageAoi } = compare;
  const { AOI, CLARITY, ATTENTION } = PREDICTION_TITLES;
  const [aoiUrl, setAoiUrl] = useState("");

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const calcDimensions = (e, areas, id) => {
    let w = e.target.offsetWidth;
    let h = e.target.offsetHeight;

    if (type === AOI) {
      let aoiPoints = drawAoiCoords(h, w, areas, null, percentageAoi);
      setAoiUrl(aoiPoints);
    }
  };

  const toTop = () => {
    document
      .querySelector(".MuiDialog-scrollBody")
      .scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={() => closeModal(id)}
      scroll="body"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <ModalBody>
        <ModalHeader>
          <H2>{compare.name} </H2>
        </ModalHeader>
        <PreviewImage>
          <img
            src={src}
            alt="preview img"
            onLoad={(e) => calcDimensions(e, compare.areas, compare.id)}
          />

          {tier === "free" && type === ATTENTION && (
            <WatermarkImage watermark={watermarkDark}></WatermarkImage>
          )}

          {tier === "free" && type === CLARITY && (
            <WatermarkImage watermark={watermarkLight}></WatermarkImage>
          )}

          <AoiPreview active={type === AOI}>
            <img src={aoiUrl} alt="aoi preview" />
          </AoiPreview>
          {type === CLARITY && (
            <ScoreCircle>
              <CircleScore score={compare.clarity_score} />
            </ScoreCircle>
          )}
        </PreviewImage>
      </ModalBody>
      <CloseModal onClick={() => closeModal(id)}>
        <Delete stroke="#fff" width={50} height={50} />
      </CloseModal>

      {type === ATTENTION || type === CLARITY ? (
        <VerticalBox attention={type === ATTENTION}>
          <GradientBox type={type} />
        </VerticalBox>
      ) : null}

      <Round onClick={() => toTop()}>
        <GoUp width={26} height={26} />
      </Round>
    </Dialog>
  );
};
export default SurveyModal;
