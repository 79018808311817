import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";

import { Body, H2, ArrowBack } from "desisystem";

// layout
import UpgradeLayout from "../../layouts/UpgradeLayout";

// redux
import { finalizeUpgrade, upgradePlan } from "../../../redux/slices/subs";
import UpgradeCard from "./screens/UpgradeCard";
import { subscriptionSelector } from "../../../redux/slices/subs";

const UpgradeContainer = styled.div`
  z-index: 1;
  position: relative;
`;

const UpgradeHeader = styled.div`
  text-align: center;
  position: relative;

  h2 {
    font-size: 22px;

    p {
      font-size: 22px;
      font-weight: bold;
      color: ${(props) => props.theme.colors.brandPurple};
      display: inline-block;
    }

    p::first-letter {
      text-transform: uppercase;
    }
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #212121;
  position: absolute;
  top: 0;
  left: 80px;
  font-size: 12px;
  display: inline-flex;
  align-items: center;

  > svg {
    margin-right: 16px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const UpgradeForm = ({
  duration,
  proPrice,
  basicPrice,
  planTier,
  fromSite,
  tier,
  custom,
  proration,
  period,
  pathOrigin,
  allPrices,
  ...props
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const dispatch = useDispatch();
  const [planSwitchState, setPlanSwitchState] = useState(false);
  const [newPeriod, setNewPeriod] = useState("month");
  const [showCoupon, setShowCoupon] = useState(true);
  const [stripeError, setStripeError] = useState("");

  const { couponCode, coupon } = useSelector(subscriptionSelector);

  const { first_name, email } = props.user;

  useEffect(() => {
    setNewPeriod(planSwitchState ? "year" : "month");
  }, [planSwitchState]);

  useEffect(() => {
    if (duration !== null) {
      setPlanSwitchState(duration === "annually" ? true : false);
    }
  }, [duration]);

  const handleChange = () => {
    if (period !== "annual") setPlanSwitchState(!planSwitchState);
  };

  const handleChecked = (name) => {
    if (period !== "annual") {
      if (name === "monthly") {
        setPlanSwitchState(false);
      } else {
        setPlanSwitchState(true);
      }
    }
  };

  const handleSubmit = (optionalFields) => (e) => {
    e.preventDefault();

    // console.log(optionalFields);
    // return;
    const cardElement = elements.getElement(CardElement);

    stripe.createToken(cardElement).then((result) => {
      console.log(result);
      if (result.error) {
        setStripeError(result.error.message);
        console.log("[error]", result.error.message);
      } else {
        const token = result.token.id;

        const upgradeData = new FormData();
        upgradeData.append("period", newPeriod);
        upgradeData.append("payment_token", token);
        upgradeData.append("tier", planTier);

        if (optionalFields.newPeriod)
          upgradeData.append("period", optionalFields.newPeriod);
        if (optionalFields.token)
          upgradeData.append("payment_token", optionalFields.token);
        if (optionalFields.planTier)
          upgradeData.append("tier", optionalFields.planTier);
        if (optionalFields.billingName)
          upgradeData.append("billing_name", optionalFields.billingName);
        if (optionalFields.billingEmail)
          upgradeData.append("billing_email", optionalFields.billingEmail);
        if (optionalFields.address)
          upgradeData.append("address", optionalFields.address);
        if (optionalFields.city)
          upgradeData.append("city", optionalFields.city);
        if (optionalFields.country)
          upgradeData.append("country", optionalFields.country);
        if (optionalFields.postalCode)
          upgradeData.append("postal_code", optionalFields.postalCode);
        if (optionalFields.vatType)
          upgradeData.append("vat_type", optionalFields.vatType);
        if (optionalFields.taxId)
          upgradeData.append("vat_value", optionalFields.taxId);

        if (couponCode !== "") {
          upgradeData.append("coupon", couponCode);
        }

        dispatch(upgradePlan(upgradeData, fromSite, first_name, planTier));
      }
    });
  };

  const handleShowCoupon = (isOpen) => {
    setShowCoupon(isOpen);
  };

  return (
    <>
      <UpgradeLayout>
        <UpgradeContainer>
          <UpgradeHeader>
            <StyledLink to={pathOrigin}>
              <ArrowBack width={20} height={20} fill="#3E21DE" />
              Go Back
            </StyledLink>
            <H2>
              It's great to see you here, <Body> {first_name}.</Body>
            </H2>
          </UpgradeHeader>

          <UpgradeCard
            coupon={coupon}
            email={email}
            planSwitchState={planSwitchState}
            handleChecked={handleChecked}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleShowCoupon={handleShowCoupon}
            proPrice={proPrice}
            basicPrice={basicPrice}
            showCoupon={showCoupon}
            stripeError={stripeError}
            planTier={planTier}
            tier={tier}
            custom={custom}
            proration={proration}
            allPrices={allPrices}
          />
        </UpgradeContainer>
      </UpgradeLayout>
    </>
  );
};

export default UpgradeForm;
