import React from "react";
import Global from "../layouts/Global";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Minus } from "desisystem";
import pape404 from "../../assets/illustrations/404.svg";

const TOP_PADDING = "2rem";

const StyledPageNotFound = styled.div`
  padding: 2rem 0 0 0;
  text-align: center;
  background: #fff;
  background: #fff;
  height: calc(100vh - ${TOP_PADDING});
  display: flex;
  justify-content: center;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 4vh;
  }
`;

const Message404 = styled.div`
  h1 {
    color: #3e21de;
    margin-bottom: 0;
    font-size: 36px;
    font-weight: 500;
  }
`;

const StyleLink = styled(Link)`
  margin-top: 30px;
  text-decoration: none;
  cursor: pointer;
  ${"" /* font-family: "Roboto"; */}
  ${"" /* font-style: normal; */}
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  height: 40px;
  font-size: 14px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 1rem 2rem;

  &:hover {
    box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  }
`;

const NotFound = () => {
  return (
    <div>
      <Global />
      <StyledPageNotFound>
        <div>
          <div>
            <img src={pape404} alt="404" />
          </div>
          <Message404>
            <h1>Looks like you are lost.</h1>
          </Message404>
          <StyleLink to="/">Back to Dashboard</StyleLink>
        </div>
      </StyledPageNotFound>
    </div>
  );
};

export default NotFound;
