import React from "react";
import Modal from "react-awesome-modal";
import { Body, H2, Check, Button } from "desisystem";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { ERROR_TOAST } from "../../constants/ToastMessages";
import { refreshToken as refreshTokenFunc } from "../../redux/slices/auth";
import Axios from "axios";
import { errorToast } from "../../utils/helperFunctions";
import { config as constantsConfig } from "../../constants/endpoints";

const ModalBody = styled.div`
  padding: 40px;
  border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  text-align: center;

  h2 {
    font-size: 22px;
    margin: 32px 0 24px 0;
    font-weight: bold;
    color: #212121;

    p {
      font-size: 22px;
      color: #3e21de;
      font-weight: bold;
    }
    p::first-letter {
      text-transform: uppercase;
    }
  }
`;

const ModalButtons = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #3e21de;
    font-size: 16px;
    padding: 0 0 0 26px;
    cursor: pointer;
    font-weight: bold;
  }
`;

const TeamMembersModal = ({ members, visible, close, sendInvites }) => {
  const dispatch = useDispatch();
  const { bearerAuth, refreshToken } = useSelector((state) => state.auth);

  const [immediateTotal, setImmediateTotal] = React.useState(null);
  const [nextInvoiceSum, setNextInvoiceSum] = React.useState(null);

  React.useEffect(() => {
    if (visible) {
      fetchPrice(members);
    }
  }, [visible]);

  async function fetchPrice(num) {
    const config = {
      headers: { Authorization: `Bearer ${bearerAuth}` },
    };

    const apiUrl = `${constantsConfig.URL}/user/invitations/price?invitations=${members}`;

    try {
      const res = await Axios.get(apiUrl, config);

      const { immediate_total, next_invoice_sum } = res.data;
      setImmediateTotal(immediate_total);
      setNextInvoiceSum(next_invoice_sum);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          const userData = new FormData();
          userData.append("refresh", refreshToken);

          dispatch(refreshTokenFunc(userData, fetchPrice));
        } else {
          errorToast(ERROR_TOAST.GENERIC);
        }
      }
    }
  }

  return (
    <Modal visible={visible} effect="fadeInUp" onClickAway={close}>
      <ModalBody>
        <Check width={40} height={40} fill="#58B149" />
        <H2>Confirm your action</H2>
        <Body>
          You're about to add {members} new members to your team.{" "}
          <b>You'll be charged ${immediateTotal}</b> when your members accept
          their invitations. Also, your <b>next invoice</b> will be at $
          {nextInvoiceSum}.
        </Body>
        <ModalButtons>
          <Button onClick={sendInvites}>Send Invites</Button>
          <Body onClick={close} style={{ marginTop: "1rem", padding: 0 }}>
            Close
          </Body>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
export default TeamMembersModal;
