// state
const initialState = {
  errorMsg: "",
};

export const ERROR_FORM = "auth/ERROR_FORM";

// actions
export const handleError = (err) => (dispatch) => {
  dispatch({
    type: ERROR_FORM,
    payload: err,
  });
};

// reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ERROR_FORM:
      return {
        ...state,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
