import React from "react";
import styled from "styled-components";
import { useSpring, animated } from "react-spring";
import send from "../../../../assets/send.svg";
import { Delete, LightBulb } from "zoozoo";
import ReactTooltip from "react-tooltip";
import {
  PREDICTION_TITLES,
  NUTSHELL_CONTENT,
} from "../../../../constants/GenericContent";
import { gaLearnMoreOverlay } from "../../../../utils/analytics";

const { ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;

const StyledBtn = styled.button`
  cursor: pointer;
  color: #3e21de;
  font-size: ${(props) => props.theme.fontSize[12]};
  text-decoration: none;
  transition: all 0.3s;
  font-weight: bold;
  border: none;
  outline: 0;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: ${(props) =>
    props.openDropdown ? "center" : "space-between"};
    background: transparent;

  & > div {
    letter-spacing: 0.01rem;
    transition: all 0.3s;
    transition-delay: 0.2s;
    font-weight: ${(props) => props.theme.weight.bold}
    font-size: ${(props) => props.theme.fontSize[12]};
    opacity: ${(props) => (props.openDropdown ? "0" : "1")};
  }

`;

const PredictDrop = styled(animated.div)`
  background: #ffffff;
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 24px 32px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: -8px;
    background: #fff;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    right: 50px;
  }
`;

const PredictDropHead = styled.div`
  display: flex;

  h3 {
    color: #212121;
    margin-left: 10px;
    font-size: ${(props) => props.theme.fontSize[20]};
    font-weight: bold;
    margin: 4px 0;
  }
`;

const PredictDropBody = styled.div`
  margin-top: 16px;
  text-align: left;
`;

const EachOption = styled.div`
  display: flex;
  margin-bottom: 32px;

  & > div:last-child {
    margin-left: 24px;
    text-align: left;

    h4 {
      color: #212121;
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSize[14]};
      margin: 0 0 6px 0;
    }
    p {
      font-weight: normal;
      font-size: ${(props) => props.theme.fontSize[12]};
      margin: 0;
      color: #757575;
    }

    p::first-word {
      font-weight: bold;
    }
  }
`;

const IconBox = styled.div`
  width: 40px;
  height: 40px;
  background: #f1effd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CaseStudyLink = styled.a`
  text-decoration: none;
  font-weight: normal;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #3e21de;
  display: inline-block;
  transition: all 0.5s;

  & > img {
    margin-left: 7px;
    width: 12px;
    transition: all 0.5s;
  }

  &:hover {
    transition: all 0.5s;

    & > img {
      transition: all 0.5s;
      transform: translateY(-6px);
    }
  }
`;

const DescriptionHead = styled.div`
  h3 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[20]};
    color: #212121;
    margin: 0;
  }
`;

const DescriptionBody = styled.div`
  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #212121;
    line-height: 1.5;
  }
`;

export default function LearnMoreDropdown({
  openDropdown,
  onClick,
  dropdownContent,
  type,
}) {
  const [props, set] = useSpring(() => ({
    from: {
      position: "absolute",
      right: "0px",
      top: "85px",
      width: "50%",
      maxWidth: "300px",
      maxHeight: "500px",
      minHeight: "480px",
      opacity: 0,
      zIndex: 2,
    },
  }));

  !openDropdown
    ? set({
        to: {
          width: "50%",
          maxWidth: "300px",
          opacity: 0,
          display: "none",
        },
      })
    : set({
        to: {
          width: "50%",
          maxWidth: "300px",
          opacity: 1,
          display: "block",
        },
      });

  const learnMoreWithGA = () => {
    gaLearnMoreOverlay();
    onClick();
  };

  return (
    <React.Fragment>
      <ReactTooltip id="learnMore" effect="solid" place={"top"}>
        <span>Learn more</span>
      </ReactTooltip>
      <StyledBtn
        onClick={() => learnMoreWithGA()}
        openDropdown={openDropdown}
        data-tip
        data-for="learnMore"
      >
        {openDropdown ? (
          <Delete stroke="#3E21DE" width={28} height={27} />
        ) : (
          <LightBulb stroke="#3E21DE" width={20} height={20} />
        )}
      </StyledBtn>

      <PredictDrop style={props}>
        <DescriptionHead>
          <h3>In a nutshell</h3>
        </DescriptionHead>
        <DescriptionBody>
          <p>
            {type === ATTENTION && NUTSHELL_CONTENT.ATTENTION}
            {type === CLARITY && NUTSHELL_CONTENT.CLARITY}
            {type === AOI && NUTSHELL_CONTENT.AOI}
          </p>
        </DescriptionBody>
        <PredictDropHead>
          <h3>Key Insights</h3>
        </PredictDropHead>
        <PredictDropBody>
          {dropdownContent &&
            dropdownContent.map((p, i) => {
              return (
                <EachOption key={i}>
                  <div>
                    <IconBox>
                      <img src={p.icon} alt={p.icon} />
                    </IconBox>
                  </div>
                  <div>
                    <h4>{p.title}</h4>
                    <p>{p.description}</p>
                  </div>
                </EachOption>
              );
            })}
          <CaseStudyLink
            target="_blank"
            href="https://www.visualeyes.design/tools/use-cases/?ref=visualeyes-platform"
          >
            View our examples
            <img src={send} alt="send" />
          </CaseStudyLink>
        </PredictDropBody>
      </PredictDrop>
    </React.Fragment>
  );
}
