import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Input, Close, Button } from "desisystem";
import SearchResults from "./SearchResults";
import Global from "../../../../layouts/Global";
import SelectedResults from "./SelectedResults";
// import { moveΤοProject } from "../../../../../redux/ducks/predictions";
import { moveΤοProject } from "../../../../../redux/slices/prediction";

import AddPredictionLoading from "../../../../loadings/AddPredictionLoading";
import {
  ADD_PREDICTION_STATES,
  ADD_PREDICTION_BUTTON,
} from "../../../../../constants/renderStates";
import NoPredictions from "./NoPredictions";
import { Search } from "zoozoo";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const Create = styled.div`
  background: #fafafa;
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 140px;
  justify-content: center;

  h2 {
    text-align: center;
  }

  form {
    input {
      border: 1px solid #c3baf5;
      box-sizing: border-box;
      border-radius: 4px;
      min-width: 600px;
      height: 40px;
      padding-left: 56px;
    }
  }

  input:focus {
    border: 1px solid #3e21de;
  }
`;

const SearchPredictsGrid = styled.div`
  max-width: 360px;
  margin: 40px auto 0 auto;
`;

const CloseLink = styled(Link)`
  & > svg {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 40px;
  }
`;

const LinkBox = styled.div`
  margin: 40px auto 50px auto;
  text-align: center;

  button {
    height: 40px;
    width: 200px;
    transition: all 0.3s;
    padding: 0;
    font-size: 14px;
  }
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  color: #3e21de;
  margin-top: 16px;
  display: inline-block;
`;

const ScrollBar = styled.div`
  height: ${(props) => (props.length ? "290px" : "auto")};
  display: ${(props) => props.exist && "none"};

  .scrollbar-container {
    background: #ffffff;
  }

  .ps__thumb-y {
    background: ${(props) => props.theme.colors.brandPurple};
  }

  .ps__rail-y:hover > .ps__thumb-y {
    background: ${(props) => props.theme.colors.brandPurple};
  }
`;

const AddPredictions = ({
  searchResults,
  onChange,
  projectName,
  projectId,
  pathname,
  allPredictions,
  addPredictionsState,
  loading,
}) => {
  const [predictList, setPredictList] = useState([]);
  const [buttonState, setButtonState] = useState("");
  const dispatch = useDispatch();
  const setList = (currentItem) => {
    setPredictList(currentItem);
  };

  // console.log(searchResults);

  const addToProject = () => {
    const movePredictInfo = new FormData();
    movePredictInfo.append("predictions", JSON.stringify(predictList));
    movePredictInfo.append("project_id", projectId);

    dispatch(moveΤοProject(movePredictInfo, projectId));
  };

  function renderAddPredictionContent(state) {
    switch (state) {
      case ADD_PREDICTION_STATES.LOADING:
        return (
          <React.Fragment>
            <AddPredictionLoading />
            <AddPredictionLoading />
            <AddPredictionLoading />
          </React.Fragment>
        );
      // case ADD_PREDICTION_STATES.EMPTY:
      //   return <NoPredictions />;
      case ADD_PREDICTION_STATES.READY:
        return (
          <SearchResults
            searchResults={searchResults}
            predictList={predictList}
            setList={setList}
          />
        );
      default:
        return;
    }
  }

  const findButtonState = (searchR, predictL) => {
    if (searchR === null || predictL === null) {
      return;
    } else if (predictL.length > 0) {
      return ADD_PREDICTION_BUTTON.HAVE_SELECT;
    } else if (predictL.length === 0 && searchR.length === 0) {
      return ADD_PREDICTION_BUTTON.NO_PROJECTS;
    } else if (predictL.length === 0) {
      return ADD_PREDICTION_BUTTON.NO_SEARCH;
    }
  };

  useEffect(() => {
    setButtonState(findButtonState(searchResults, predictList));
  }, [searchResults, predictList]);

  function renderSubmitContent(state) {
    switch (state) {
      case ADD_PREDICTION_BUTTON.HAVE_SELECT:
        return <Button onClick={addToProject}>Add to Project</Button>;
      case ADD_PREDICTION_BUTTON.NO_PROJECTS:
        return (
          <StyledLink
            to={{
              pathname: "/predict/create/device",
              state: {
                projectId,
                pathname: "/projects",
              },
            }}
          >
            Create new prediction
          </StyledLink>
        );
      case ADD_PREDICTION_BUTTON.NO_SEARCH:
        return <Button disabled={true}>Add to Project</Button>;
      default:
        return;
    }
  }

  // returns all the predictions that are not selected
  let moreThanFour;
  let emptyResults;
  if (searchResults !== null) {
    moreThanFour =
      searchResults.filter((x) => !predictList.includes(x.id)).length >= 4;
    emptyResults =
      searchResults.filter((x) => !predictList.includes(x.id)).length === 0;
  }

  return (
    <Create>
      <Global />
      <CloseLink to={pathname}>
        <Close width={30} height={30} />
      </CloseLink>

      <div>
        <h2>Add predictions in {projectName}</h2>
        <form onSubmit={(e) => e.preventDefault()}>
          <Input
            placeholder="Search predictions"
            onChange={onChange}
            leftIcon={<Search width={26} height={26} />}
          />
        </form>
        <SearchPredictsGrid>
          <SelectedResults
            predictList={predictList}
            setList={setList}
            allPredictions={allPredictions}
          />
          <ScrollBar length={moreThanFour} exist={emptyResults}>
            <PerfectScrollbar
              component="div"
              options={{ suppressScrollX: true, scrollingThreshold: 2 }}
            >
              <div>{renderAddPredictionContent(addPredictionsState)}</div>
            </PerfectScrollbar>
          </ScrollBar>
          {loading && <AddPredictionLoading />}
          {allPredictions !== null &&
            !loading &&
            searchResults.length === 0 && <NoPredictions />}
        </SearchPredictsGrid>

        <LinkBox>{renderSubmitContent(buttonState)}</LinkBox>
      </div>
    </Create>
  );
};

export default AddPredictions;
