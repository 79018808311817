import React from "react";
import PropTypes from "prop-types";

const MobileIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 70 70" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.6118 61.125H23.3895C20.182 61.125 17.584 58.527 17.584 55.3194V14.6806C17.584 11.473 20.182 8.875 23.3895 8.875H46.6118C49.8193 8.875 52.4173 11.473 52.4173 14.6806V55.3194C52.4173 58.527 49.8193 61.125 46.6118 61.125Z"
      stroke={props.currentColor}
      strokeWidth="4.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34.9973 50.2394C34.5967 50.2394 34.2716 50.5645 34.2745 50.9651C34.2745 51.3656 34.5996 51.6908 35.0002 51.6908C35.4008 51.6908 35.7259 51.3656 35.7259 50.9651C35.7259 50.5645 35.4008 50.2394 34.9973 50.2394"
      stroke={props.currentColor}
      strokeWidth="4.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M31.3721 17.5832H38.629"
      stroke={props.currentColor}
      strokeWidth="4.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

MobileIcon.propTypes = {
  currentColor: PropTypes.string
};

MobileIcon.defaultProps = {
  stroke: "#212121"
};

export default MobileIcon;
