import React from "react";
import styled from "styled-components";
import SvgLogo from "../../../icons/SvgLogo";
import SvgBrand from "../../../icons/SvgBrand";
import TypeNavBar from "./TypeNavBar";
import MetricText from "./MetricText";
import { config } from "../../../../constants/endpoints";

const StickyBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
`;

const GoBack = styled.div``;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 115px;
  }
`;

const ChangeModeButton = styled.div`
  justify-self: end;
  transition: all 0.3s;

  button:hover {
    background: ${(props) => props.theme.colors.brandPurple};
    transition: all 0.3s;

    svg {
      stroke: #fff;
    }

    span {
      color: #fff;
    }
  }
`;

const Header = styled.div`
  background: #fafafa;
  padding: 16px 32px;
`;

const InnerHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1420px;
  margin: 0 auto;

  & > div {
    align-self: center;
  }

  button {
    border-width: 1px;
    display: flex;
    align-items: center;

    span {
      margin-left: 6px;
    }
  }
`;

const Nav = styled.nav`
  background: #ffffff;
  padding: 0 32px;
`;

const InnerNav = styled.div`
  ${"" /* min-height: 100px; */}
  max-width: 1420px;
  margin: 0 auto;
  border-bottom: 1px solid #eeeeee;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  h2 {
    margin: 0;
    font-weight: 500;
    color: #212121;
    font-size: 20px;
  }

  span {
    height: 30px;
    background: #e0e0e0;
    width: 1px;
    display: block;
    margin-left: 16px;
  }

  & > div {
    display: flex;
    align-items: center;

    h3 {
      font-weight: normal;
      font-size: 14px;
      color: #212121;
      margin-left: 16px;
    }
  }

  img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    margin-left: 16px;
  }
`;

const Description = styled.div`
  background: #fff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
`;

const ReportNav = ({
  name,
  avatar,
  userName,
  gridModeButton = null,
  compareMode = true,
  handleChange,
  checkType,
  type,
  backToProject = null,
  loading,
  secondLoading = false,
  sharedCompare,
  uid,
}) => {
  return (
    <React.Fragment>
      <StickyBar>
        <Header>
          <InnerHeader>
            <GoBack>{backToProject}</GoBack>
            <Logo>
              {!config.WHITE_LABEL.map((user) => user.id).includes(uid) ? (
                <React.Fragment>
                  <SvgLogo width={48} height={48} />
                  <SvgBrand fill="#1D0F68" width={110} height={28} />
                </React.Fragment>
              ) : (
                <img
                  src={config.WHITE_LABEL.find((user) => user.id === uid).logo}
                  alt="logo"
                />
              )}
            </Logo>

            {sharedCompare && (
              <ChangeModeButton>{gridModeButton}</ChangeModeButton>
            )}
          </InnerHeader>
        </Header>
        <Nav>
          <InnerNav>
            {!loading && (
              <Title>
                <h2>{name}</h2>
                <span></span>
                <div>
                  <img src={avatar} alt="avatar" />
                  <h3>{userName}</h3>
                </div>
              </Title>
            )}

            {compareMode && (
              <TypeNavBar handleChange={handleChange} checkType={checkType} />
            )}
          </InnerNav>
        </Nav>
      </StickyBar>
      {compareMode && (
        <Description>
          {!secondLoading && <MetricText type={type} />}
        </Description>
      )}
    </React.Fragment>
  );
};

export default ReportNav;
