import React from "react";
import styled from "styled-components";
import ProjectOptions from "./screens/ProjectOptions";
import { Link } from "react-router-dom";
import ProjectIcon from "../../icons/ProjectIcon";

const CardContainer = styled.div`
  box-shadow: ${props => props.theme.shadows.cardShadow};
  border-radius: ${props => props.theme.radius.soft};
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  box-sizing: border-box;
  transition: all 0.3s;
  background: #fff;

  &:hover {
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.12);
    transition: all 0.3s;
  }

  & > a {
    display: flex;
    width: 96%;
    padding: 20px 0 20px 22px;
    height: 100%;
    align-items: center;
  }

  & > a > div {
    h2 {
      margin: 0;
      font-size: ${props => props.theme.fontSize[14]};
      color: ${props => props.theme.colors.black};
      font-weight: normal;
      text-align: left;
      font-weight: normal;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      width: 88%;
    }

    span {
      color: ${props => props.theme.neutral[4]};
      font-size: ${props => props.theme.fontSize[12]};
    }
  }

  & > a > div:nth-child(2) {
    width: 100%;
    margin: 0 22px;
  }
`;

const ProjectCard = ({ info, pathOrigin, deleteType }) => {
  return (
    <CardContainer>
      <Link to={`/projects/${info.id}`}>
        <div>
          <ProjectIcon width={26} height={26} />
        </div>
        <div>
          <h2>{info.name}</h2>
          <span>{info.total_predictions} predictions</span>
        </div>
      </Link>
      <div>
        <ProjectOptions
          projectId={info.id}
          pathOrigin={pathOrigin}
          deleteType={deleteType}
        />
      </div>
    </CardContainer>
  );
};

export default ProjectCard;
