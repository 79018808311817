import React from "react";
import PropTypes from "prop-types";

const DesktopIcon = props => (
  <svg width="1em" height="1em" viewBox="0 0 76 76" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.1526 52.0293H15.8446C12.637 52.0293 10.0391 49.4313 10.0391 46.2238V17.8607C10.0391 14.6532 12.637 12.0552 15.8446 12.0552H60.1497C63.3573 12.0552 65.9553 14.6532 65.9553 17.8607V46.2267C65.9582 49.4313 63.3573 52.0293 60.1526 52.0293Z"
      stroke={props.currentColor}
      strokeWidth="4.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.6367 64.1251C28.8821 60.2557 47.1173 60.2557 58.3656 64.1251"
      stroke={props.currentColor}
      strokeWidth="4.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

DesktopIcon.propTypes = {
  currentColor: PropTypes.string
};

DesktopIcon.defaultProps = {
  stroke: "#212121"
};

export default DesktopIcon;
