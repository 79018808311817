import React from "react";
import styled from "styled-components";
import NoCredits from "../../../../icons/NoCredits";
// import { colors, primary } from "../../../theme";
import Tooltip from "@material-ui/core/Tooltip";
import { useCopyClipboard } from "react-recipes";

const UserInfo = styled.div`
  display: flex;
  align-items: center;

  & > p {
    color: #aaa;
    margin: 3px 0 3px 4px;
    font-size: ${(props) => props.theme.fontSize[12]};
    display: flex;
    white-space: nowrap;

    span {
      color: ${(props) => props.theme.primary[10]};
      font-weight: bold;
      margin-left: 8px;
      display: flex;
      align-items: center;
    }
  }

  ${(props) =>
    props.hasCopy &&
    `
    cursor: pointer;
`}
`;

const NavbarInfo = ({
  hasWarning,
  icon,
  label,
  value,
  hasCopy,
  copyText,
  tooltipMessage,
}) => {
  const [isCopied, setIsCopied] = useCopyClipboard();

  const copy = () => {
    if (hasCopy) {
      setIsCopied(copyText);
    }
  };

  return (
    <Tooltip
      disableFocusListener
      disableTouchListener
      title={
        hasCopy ? (isCopied ? "Copied" : "Copy to Clipboard") : tooltipMessage
      }
      placement="bottom-end"
    >
      <UserInfo hasCopy={hasCopy} onClick={copy}>
        {icon}
        <p>
          {label}
          <span>{value}</span>
          {hasWarning && <NoCredits style={{ paddingLeft: ".25rem" }} />}
        </p>
      </UserInfo>
    </Tooltip>
  );
};

export default NavbarInfo;
