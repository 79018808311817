import React from "react";
import styled from "styled-components";
import { deviceType } from "../../../../../constants/deviceType";
import { Check } from "zoozoo";

const ChooseMetric = styled.div`
  && {
    display: flex;
    justify-content: center;
    margin-top: 48px;

    h2::first-letter {
      text-transform: uppercase;
    }

    h2 {
      font-weight: normal;
      font-size: 14px;
      text-align: center;
      color: #212121;
    }

    & > div {
      margin: 20px;
      width: 160px;
      height: 160px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      cursor: pointer;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04);
    }

    svg {
      stroke: #e0e0e0;
    }

    span {
      display: none;
    }

    .active {
      background: #ffffff;
      border-radius: 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;

      span {
        content: "";
        position: absolute;
        top: -10px;
        width: 24px;
        height: 24px;
        background: #3e21de;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          stroke: #ffffff;
        }
      }

      svg {
        stroke: #212121;
      }
    }
  }
`;

const DeviceType = ({ device, setCurrentDevice }) => {
  return (
    <ChooseMetric>
      {deviceType.map((d, i) => (
        <div
          key={i}
          onClick={() => setCurrentDevice(d.type)}
          className={device === d.type ? "active" : ""}
        >
          <span>
            <Check stroke="#ffffff" width={18} height={18} />
          </span>
          {d.icon}
          <h2>{d.type}</h2>
        </div>
      ))}
    </ChooseMetric>
  );
};

export default DeviceType;
