import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";
import SoonTag from "../../icons/SoonTag";
import UpgradeTagIcon from "../../icons/UpgrageTag";
import { Plus } from "zoozoo";
import ConfirmPaymentModal from "../modals/ConfirmPaymentModal";
import {
  creationButtonContent,
  singleProjectButtonContent,
} from "../../../constants/CreationButton";
import { projectsSelector } from "../../../redux/slices/projects";
import { subscriptionSelector } from "../../../redux/slices/subs";
import {
  gaMakePrediction,
  gaNewPredictionToProject,
  gaExistingPredictionToProject,
} from "../../../utils/analytics";
import { userSelector } from "../../../redux/slices/user";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    width: "360px",
    height: "auto",
    bottom: "50px",
    top: "initial!important",
    left: "initial!important",
    right: "75px",
  },
})((props) => (
  <Menu
    elevation={20}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    style={{ marginBottom: "2rem" }}
    {...props}
  />
));

const StyledMenuItem = withStyles((props) => ({
  root: {
    backgroundColor: "#fff",
    color: `${(props) => props.theme.primary[10]}`,
    fontStyle: "normal",
    fontSize: "14px",
    padding: "0",
  },
}))(MenuItem);

const StyledButtonOptions = styled(StyledMenuItem)`
  && {
    position: relative;

    &:hover {
      cursor: initial;
      background: ${(props) => (props.isdisabled ? "initial" : "#f1effd")};

      h3 {
        color: ${(props) =>
          props.isdisabled ? "#E0E0E0" : props.theme.primary[5]};
      }
    }
  }
`;

const StyledDropdown = styled(StyledMenu)`
  && {
    border-radius: 1rem !important;
  }
  ul {
    padding: 0;
  }
`;

const CircleOptions = styled.button`
  border: none;
  background-color: ${(props) => props.theme.primary[5]};
  border-radius: ${(props) => props.theme.radius.round};
  width: 64px;
  height: 64px;
  cursor: pointer;
  color: #fff;
  outline: 0;
  z-index: 1821;
  position: fixed;
  bottom: 56px;
  right: 53px;
  transition: all 0.3s;
  transform: ${(props) => (props.open ? "rotate(45deg)" : "rotate(0deg)")};
  box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);

  &:hover {
    outline: 0;
  }
`;

const StyledLink = styled(Link)`
  && {
    pointer-events: ${(props) => (props.isdisabled ? "none" : "initial")};
    &:hover {
      background: ${(props) => (props.isdisabled ? "initial" : "#f1effd")};

      h3 {
        color: ${(props) =>
          props.isdisabled ? "#E0E0E0" : props.theme.primary[5]};
      }

      svg {
        stroke: ${(props) =>
          props.isdisabled ? "#E0E0E0" : props.theme.primary[5]};
      }
    }
  }

  text-decoration: none;
  color: unset;
  width: 100%;
  padding: 24px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 14px;
    color: ${(props) => (props.isdisabled ? "#E0E0E0" : " #212121")};
  }

  p {
    margin: 0;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[12]};
    color: ${(props) => (props.isdisabled ? "#E0E0E0" : " #9e9e9e")};
    white-space: normal;
  }
`;

const WithTag = styled.div`
  display: flex;
  align-items: center;
`;

const UpgradeLink = styled(Link)`
  color: #3e21de;
  font-weight: bold;
  position: absolute;
  top: 24px;
  left: 195px;
  display: flex;
  align-items: center;
`;

const IconBox = styled(ListItemIcon)`
  svg {
    margin-right: 1rem;
    stroke: ${(props) => (props.isdisabled ? "#E0E0E0" : "#212121")};
  }
`;

const ButtonOfCreationMenu = ({
  projectIdForGlobal,
  projectName,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openOverlay, setOpenOverlay] = useState(false);
  const { pathname } = props.location;
  const {
    proration,
    proPrice,
    subs: { tier, custom },
  } = useSelector(subscriptionSelector);
  const {
    user: { credits_left: creditsLeft },
  } = useSelector(userSelector);
  const { seenNewLimitedModal, seenFreeLimitedModal } = useSelector(
    (state) => state.storage
  );
  const { projects } = useSelector(projectsSelector);

  let contentState =
    projectIdForGlobal === null
      ? creationButtonContent
      : singleProjectButtonContent;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenOverlay(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenOverlay(false);
  };

  const [open, setOpen] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  // const hasLimitedProjects =
  //   (tier === "free" || tier === "basic") &&
  //   projects.length === 5 &&
  //   seenNewLimitedModal;
  const hasLimitedProjects =
    tier === "basic" && projects.length === 5 && seenNewLimitedModal;

  const hasOneLimitedProject =
    tier === "free" && projects.length === 1 && seenFreeLimitedModal;
  // console.log(hasLimitedProjects);
  // console.log(projects.length);
  // console.log(seenNewLimitedModal);
  // console.log(tier);

  // Add an existing Prediction
  return (
    <div>
      <CircleOptions
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={openOverlay ? handleClose : handleClick}
        open={openOverlay}
      >
        <Plus stroke="#fff" width={36} height={36} />
      </CircleOptions>

      <StyledDropdown
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {contentState &&
          contentState.map((option, i) => {
            const isDisabled =
              option.disabled ||
              (creditsLeft === 0 &&
                (option.title === "Make a Prediction" ||
                  option.title === "Add a New Prediction")) ||
              (hasLimitedProjects &&
                tier === "basic" &&
                option.title === "Create a Project") ||
              (hasOneLimitedProject &&
                tier === "free" &&
                option.title === "Create a Project");

            const hasUpgradeTag =
              (hasLimitedProjects &&
                tier === "basic" &&
                option.title === "Create a Project") ||
              (hasOneLimitedProject &&
                tier === "free" &&
                option.title === "Create a Project");

            const hasSoonTag = option.disabled;

            return (
              <StyledButtonOptions key={i} isdisabled={isDisabled ? 1 : 0}>
                <StyledLink
                  to={{
                    pathname: option.link,
                    state: {
                      projectIdForGlobal,
                      pathname,
                      projectName,
                    },
                  }}
                  onClick={
                    option.title === "Make a Prediction"
                      ? () => gaMakePrediction()
                      : option.title === "Add a New Prediction"
                      ? () => gaNewPredictionToProject()
                      : option.title === "Add an existing Prediction"
                      ? () => gaExistingPredictionToProject()
                      : null
                  }
                  isdisabled={isDisabled ? 1 : 0}
                >
                  <IconBox isdisabled={isDisabled ? 1 : 0}>
                    {option.icon}
                  </IconBox>
                  <div>
                    <WithTag>
                      <h3>{option.title}</h3>
                      {hasSoonTag && (
                        <SoonTag
                          width={50}
                          height={30}
                          style={{ marginLeft: "6px" }}
                        />
                      )}
                    </WithTag>
                    <p>{option.description}</p>
                  </div>
                </StyledLink>
                {hasUpgradeTag && (
                  <div>
                    {tier === "basic" && !custom ? (
                      <UpgradeLink width="100" onClick={() => openModal()}>
                        <UpgradeTagIcon width={60} height={19} />
                      </UpgradeLink>
                    ) : (
                      <UpgradeLink
                        to={{
                          pathname: "/upgrade",
                          state: {
                            plan: "pro",
                            pathname,
                          },
                        }}
                      >
                        <UpgradeTagIcon width={60} height={19} />
                      </UpgradeLink>
                    )}
                  </div>
                )}
              </StyledButtonOptions>
            );
          })}

        <ConfirmPaymentModal
          open={open}
          closeModal={closeModal}
          proration={proration}
          proPrice={proPrice}
        />
      </StyledDropdown>
    </div>
  );
};

export default withRouter(ButtonOfCreationMenu);

// <UpgradeLink
// to={{
//   pathname: "/upgrade",
//   state: {
//     plan: "pro",
//     pathname,
//   },
// }}
// >
// <UpgradeTagIcon width={60} height={19} />
// </UpgradeLink>
