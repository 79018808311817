import React from "react";
import styled from "styled-components";
import NavContainer from "../ui/navigation/mainNavigation/NavbarContainer";
import Global from "../layouts/Global";

const StyledLayout = styled.div`
  padding: 80px 0 0 0;
  min-height: calc(100% - 80px);
  max-width: 1920px;
  margin: 0 auto;
`;

const Layout = (props) => {
  return (
    <React.Fragment>
      <Global />
      <NavContainer />
      <StyledLayout>{props.children}</StyledLayout>
    </React.Fragment>
  );
};

export default Layout;
