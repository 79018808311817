import React from "react";
import styled from "styled-components";
import { Button } from "desisystem";
import FullPageModal from "../../global/FullPageModal";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { deletePaymentMethod } from "../../../redux/slices/subs";

const ContentBox = styled.div`
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 16px 0;
    text-align: center;
  }

  p {
    font-weight: normal;
    font-size: 12px;
    color: #9e9e9e;

    span {
      color: ${(props) => props.theme.colors.black};
    }
  }

  p:last-child {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 auto;
    line-height: 1.5;

    span {
      color: ${(props) => props.theme.colors.brandPurple};
    }
  }
`;

const ActionButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;


  button {
    min-width: 160px;
    height: 40px;
    padding: 0;
    margin: 0 12px
    font-size: ${(props) => props.theme.fontSize[14]};

    &:hover {
        box-shadow: none;
    }
  }
`;

const DeleteButton = styled(Button)`
  background: #d53c3c;
`;

const DeletePaymentModal = ({ open, closeModal, expiresAt, email }) => {
  const dispatch = useDispatch();
  return (
    <FullPageModal open={open}>
      <ContentBox>
        <h2>Are you sure want to remove your payment method?</h2>
        <p>
          Logged in as <span>{email}</span>
        </p>
        <p>
          If you do, your subscription will not be renewed. <br /> Your
          subscription will be downgraded to Free on{" "}
          <span>{dayjs(expiresAt).format("DD MMM, YYYY")}</span>.
        </p>
      </ContentBox>
      <ActionButtonBox>
        <DeleteButton onClick={() => dispatch(deletePaymentMethod(closeModal))}>
          Yes, remove it
        </DeleteButton>
        <Button appearance="secondary" onClick={() => closeModal()}>
          Go Back
        </Button>
      </ActionButtonBox>
    </FullPageModal>
  );
};

export default DeletePaymentModal;
