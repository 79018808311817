import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import useForm from "react-hook-form";
import { Link } from "react-router-dom";

// design system
import { Input } from "desisystem";

import SocialProofLayout from "../../layouts/SocialProofLayout";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";

// actions
import {
  forgotPassEmail,
  accountSelector,
} from "../../../redux/slices/account";
// import { handleError } from "../../../redux/ducks/errors";

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.subHeading};
  }
`;

const FormBody = styled.div`
  margin-top: 40px;

  & > form button {
    margin-top: 30px;
  }
`;

const NotMember = styled.div`
  margin-top: 16px;
  font-size: ${(props) => props.theme.fontSize[12]};
  color: ${(props) => props.theme.colors.black};

  a {
    color: ${(props) => props.theme.colors.brandPurple};
    text-decoration: none;
  }
`;

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  // const [checked, setChecked] = useState(true);

  const { register, handleSubmit, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
  });

  const dispatch = useDispatch();
  const { loading } = useSelector(accountSelector);
  // const { errorMsg } = useSelector((state) => state.errors);
  // const { loading } = useSelector((state) => state.loadings);

  // useEffect(() => {
  //   dispatch(handleError(null));
  // }, []);

  const handleForgotPassword = (e) => {
    const userEmail = new FormData();
    userEmail.append("email", email);
    dispatch(forgotPassEmail(userEmail, email));
  };

  const isDisabled = email === "";

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Forgot password?</h1>
          <p>It happens to everyone - we'll help you reset it!</p>
        </Heading>

        <FormBody>
          <form onSubmit={handleSubmit(handleForgotPassword)}>
            <div>
              <Input
                label="E-MAIL"
                type="email"
                size="10px"
                onChange={(e) => setEmail(e.target.value)}
                name="forgotPassField"
                validate={register({
                  // pattern: /^([a-z\d\.-_]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/,
                  required: true,
                })}
              />
              {errors.forgotPassField && (
                <span className="error-msg">Field must be a valid email</span>
              )}
            </div>

            <ButtonWithLoading
              disabled={isDisabled}
              width="100"
              loading={loading}
              title="Send reset instructions"
            />
          </form>

          <NotMember>
            Not a member yet?{" "}
            <Link to="/auth/create">Create your account now.</Link>
          </NotMember>
        </FormBody>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default ForgotPassword;
