import React from "react";
import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoaderBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const GlobalLoader = () => {
  return (
    <LoaderBox>
      <CircularProgress
        style={{ width: "50px", height: "50px", marginLeft: "5px" }}
      />
    </LoaderBox>
  );
};

export default GlobalLoader;
