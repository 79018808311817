import React from "react";
import styled from "styled-components";
import Switch from "../../globals/Switch";
import { Question } from "zoozoo";
import ReactTooltip from "react-tooltip";
import { AOI_METRIC_TOOLTIP } from "../../../../constants/GenericContent";

const IntensitySwitchBox = styled.div`
  display: inline-flex;
  align-items: center;
  height: 38px;
  padding: 0 10px;

  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    color: #1d0f68;
    cursor: pointer;
  }

  & > a {
    margin-left: 24px;
  }

  .MuiSwitch-root {
    height: 42px;
  }
  .MuiSwitch-switchBase {
    top: 5px;
    left: 5px;
  }

  .MuiSwitch-thumb {
    width: 14px;
    height: 14px;
    color: #3e21de;
    box-shadow: none;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(17px);
  }

  .Mui-checked .MuiSwitch-thumb {
    color: #3e21de;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #f1effd;
    opacity: 1;
    border-radius: 50px;
  }

  .MuiSwitch-colorPrimary + .MuiSwitch-track {
    background-color: #f1effd;
    opacity: 1;
    border-radius: 50px;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 35px;
  background: #e0e0e0;
  margin-left: 24px;
`;

const IntensitySwitch = ({ checked, onChangeSwitch, onClickSwitch }) => {
  return (
    <React.Fragment>
      <ReactTooltip id="question" effect="solid" place={"top"}>
        <span> Learn more about ‘Areas of Interest’ metrics. </span>
      </ReactTooltip>

      <ReactTooltip id="absolute" effect="solid" place={"right"}>
        {AOI_METRIC_TOOLTIP.ABSOLUTE}
      </ReactTooltip>

      <ReactTooltip id="relative" effect="solid" place={"right"}>
        {AOI_METRIC_TOOLTIP.RELATIVE}
      </ReactTooltip>
      <IntensitySwitchBox>
        <p
          style={{ fontWeight: !checked ? "bold" : "normal" }}
          data-tip
          data-for="absolute"
          onClick={() => onClickSwitch("absolute")}
        >
          Attention Percentage
        </p>
        <Switch checked={checked} onChange={() => onChangeSwitch()} />
        <p
          style={{ fontWeight: checked ? "bold" : "normal" }}
          data-tip
          data-for="relative"
          onClick={() => onClickSwitch("relative")}
        >
          Intensity Factor
        </p>
        <a
          href="https://www.notion.so/What-is-an-Area-of-Interest-1221225b9e0d47518820b1d6ff401a05"
          title=""
          target="_blank"
          rel="noopener noreferrer"
        >
          <Question width={26} height={26} data-tip data-for="question" />
        </a>
        <Divider />
      </IntensitySwitchBox>
    </React.Fragment>
  );
};

export default IntensitySwitch;
