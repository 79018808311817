import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import PredictPageOptions from "../../cards/screens/PredictPageOptions";
import CustomEditBox from "../../globals/CustomEditBox";

const PredictionHeadingContainer = styled.div`
  display: flex;
`;

const SidebarHeader = styled.div`
  position: relative;
  padding-right: 24px;
  width: 300px;
`;

const VerticalMenu = styled.div`
  margin: 0 auto;
  position: absolute;
  right: 10px;
  top: 10px;
  transform: rotate(90deg);

  svg {
    fill: #212121;
    width: 16px;
    height: 16px;
  }
`;

const Divider = styled.span`
  max-width: 2px;
  min-width: 2px;
  background-color: #9e9e9e;
  height: 1.4rem;
  margin: 0 1rem;
  transform: rotate(15deg);
`;

const ProjectName = styled.div`
  display: flex;
  align-items: center;

  span {
    color: #3e21de;
    font-weight: normal;
    font-size: 20px;
  }
`;

const PredictionHeading = ({
  name,
  id,
  projectId,
  projectName,
  updateInfo,
}) => {
  return (
    <PredictionHeadingContainer>
      {projectName && (
        <ProjectName>
          <span>
            <Link to={`/projects/${projectId}`}>{projectName}</Link>
          </span>
          <Divider />
        </ProjectName>
      )}
      <SidebarHeader>
        <CustomEditBox name={name} updateInfo={updateInfo} id={id} />

        <VerticalMenu>
          <PredictPageOptions
            predictId={id}
            predictName={name}
            projectName={projectName}
            projectId={projectId}
            deleteType="predict"
          />
        </VerticalMenu>
      </SidebarHeader>
    </PredictionHeadingContainer>
  );
};

export default PredictionHeading;
