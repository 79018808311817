import React from "react";

import cross from "../assets/cross.svg";
import layer from "../assets/layer.svg";
import ab from "../assets/ab.svg";

import book from "../assets/book.svg";
import dash from "../assets/dash.svg";
import screen from "../assets/screen.svg";

import business from "../assets/options/business.svg";
import doubleRect from "../assets/options/doubleRect.svg";
import doubleSquare from "../assets/options/doubleSquare.svg";

import {
  Hierarchy,
  Usability,
  Target,
  AbTesting,
  Dashboard,
  Readability,
} from "zoozoo";

//
//////////////////////////
// only for modals
/////////////////////////
//
//

export const attentionOnBoardingContent = [
  {
    icon: <Target fill="#3E21DE" />,
    title: "Focal Points",
    content: "Find areas of optical focus in your designs.",
    left: false,
    right: true,
    delay: 0,
  },
  {
    icon: <Hierarchy stroke="#3E21DE" />,
    title: "Visual Hierarchy",
    content: "Assess the visual order of elements.",
    left: true,
    right: false,
    delay: 1000,
  },
  {
    icon: <AbTesting stroke="#3E21DE" />,
    title: "Quick A/B Tests",
    content: "Test, iterate and enhance your design.",
    left: false,
    right: true,
    delay: 2000,
  },
];

export const clarityOnBoardingContent = [
  {
    icon: <Dashboard stroke="#3E21DE" />,
    title: "Benchmarking",
    content: "Analyse and compare with competitor’s site.",
    left: true,
    right: false,
    delay: 0,
  },
  {
    icon: <Readability stroke="#3E21DE" />,
    title: "Readability",
    content: "Ease up the acquiring of information.",
    left: false,
    right: true,
    delay: 1000,
  },
  {
    icon: <Usability fill="#3E21DE" />,
    title: "Usability Effect",
    content: "Make your design more usable by decluttering.",
    left: true,
    right: false,
    delay: 2000,
  },
];

//
////////////////////////
// only for dropdown
///////////////////////
//
//

export const attentionOptions = [
  {
    icon: cross,
    title: "Focal Points",
    description: "Find areas of optical focus in your designs.",
  },
  {
    icon: layer,
    title: "Visual Hierarchy",
    description: "Assess the visual order of elements.",
  },
  {
    icon: ab,
    title: "Quick A/B Tests",
    description: "Test, iterate and enhance your design swiftly.",
  },
];

export const clarityOptions = [
  {
    icon: book,
    title: "Readability",
    description: "Ease up the acquiring of information.",
  },
  {
    icon: screen,
    title: "Usability Effect",
    description: "Make your design more usable by clearing up your design.",
  },
  {
    icon: dash,
    title: "Benchmarking",
    description: "Analyse and compare with competitor’s site.",
  },
];

export const aoiOptions = [
  {
    icon: doubleRect,
    title: "Visual Hierarchy",
    description: "Assess the visual order of elements.",
  },
  {
    icon: doubleSquare,
    title: "Compare design elements",
    description: "Rapid, reliable assessment between the marked areas.",
  },
  {
    icon: business,
    title: "Measurable results",
    description: "Percentage values show differences in attention clearly.",
  },
];
