export const IMAGE_CONTAINER_WIDTH = "66Opx";
export const IMAGE_CONTAINER_HEIGHT = "84vh";

export const JOB_TITLE = [
  { value: "visual designer", label: "visual designer" },
  { value: "ux researcher", label: "UX researcher" },
  { value: "product manager", label: "manager" },
  { value: "marketer", label: "marketer" },
  { value: "other", label: "in a role not listed" },
];

export const TEAM = [
  { value: "freelancing", label: "freelancing" },
  { value: "agency", label: "a digital agency" },
  { value: "product", label: "a product team" },
  { value: "other", label: "other" },
];

export const GOAL = [
  { value: "reporting", label: "reporting to stakeholders" },
  { value: "optimizing", label: "optimizing designs" },
  { value: "user_testing", label: "quick user testing" },
  { value: "benchmarking", label: "benchmarking" },
];
