import React from "react";
import { Success } from "zoozoo";
import infinity from "../assets/Infinity.svg";
import freeImage from "../assets/illustrations/free-card.svg";
import basicImage from "../assets/illustrations/basicPlan.svg";
import proImage from "../assets/illustrations/pro-card.svg";

// content for cards in subscription page
export const cardSubtitles = {
  PRO_SUBTITLE: "For sophisticated teams and professional designers.",
  FREE_SUBTITLE:
    "For hobbyists, early startups or rising professional freelancers.",
};

export const freePlan = {
  image: freeImage,
  title: "free",
  price: "$0/month",
  description:
    "For hobbyists, early startups or rising professional freelancers.",
  options: [
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          <strong>5 </strong> Predictions
        </span>
      ),
      tooltip: "5 on-off image analyses.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          {" "}
          <strong>1</strong> active Project{" "}
        </span>
      ),
      tooltip: "Projects will help you organize and share your work.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>All sizes</span>,
      disabled: true,
      tooltip: "Image dimensions are not restricted.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Download</span>,
      disabled: true,
      tooltip: "Download your prediction for offline reporting.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          {/* <img src={infinity} alt="infinity" /> */}
          Sharing
        </span>
      ),
      tooltip:
        "Share your predictions and projects with your colleagues/clients.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Plugins</span>,
      disabled: false,
      tooltip:
        "Exclusive access to our Figma, Sketch, Adobe XD and Chrome plugins.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>White labeling </span>,
      tooltip: "Remove watermark and use your own branding in shared pages.",
      disabled: true,
    },
  ],
};

export const basicPlan = {
  image: basicImage,
  title: "basic",
  price: "$20/month",
  description: "For small- size teams and startups.",
  options: [
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          {/* <img src={infinity} alt="infinity" /> */}
          <strong>15 </strong> Predictions
        </span>
      ),
      tooltip: "15 image analyses per month (not cumulative).",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          {" "}
          <strong>5</strong> active Projects
        </span>
      ),
      tooltip: "Projects will help you organize and share your work.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>All sizes</span>,
      disabled: false,
      tooltip: "Image dimensions are not restricted.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Download</span>,
      disabled: false,
      tooltip: "Download your prediction for offline reporting.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Sharing</span>,
      tooltip:
        "Share your predictions and projects with your colleagues/clients.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Plugins</span>,
      disabled: false,
      tooltip:
        "Exclusive access to our Figma, Sketch, Adobe XD and Chrome plugins.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>White labeling </span>,
      tooltip: "Remove watermark and use your own branding in shared pages.",
      disabled: true,
    },
  ],
};

export const proPlan = {
  image: proImage,
  title: "pro",
  price: "$50/month",
  description: "For medium-sized teams and digital agencies.",
  options: [
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          {/* <img src={infinity} alt="infinity" /> */}
          <strong>99 </strong> Predictions
        </span>
      ),
      tooltip: "99 image analyses per month (not cumulative).",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: (
        <span>
          {/* <img src={infinity} alt="infinity" /> active Projects */}
          <strong>15</strong> active Projects
        </span>
      ),
      tooltip: "Projects will help you organize and share your work.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>All sizes</span>,
      disabled: false,
      tooltip: "Image dimensions are not restricted.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Download</span>,
      disabled: false,
      tooltip: "Download your prediction for offline reporting.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Sharing</span>,
      tooltip:
        "Share your predictions and projects with your colleagues/clients.",
      disabled: false,
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>Plugins</span>,
      disabled: false,
      tooltip:
        "Exclusive access to our Figma, Sketch, Adobe XD and Chrome plugins.",
    },
    {
      icon: <Success width={26} height={26} />,
      option: <span>White labeling </span>,
      tooltip: "Remove watermark and use your own branding in shared pages.",
      disabled: false,
    },
  ],
};
