import React from "react";
import styled from "styled-components";
import Global from "../../layouts/Global";
import Navbar from "../navigation/singlePredictionNavigation/Navbar";
// import dots from "../../../assets/back.svg";
import dots from "../../../assets/dark-dots.svg";

const StyledLayout = styled.div`
  padding: 0;
  background: ${(props) => props.theme.colors.globalBg};
  min-height: 100vh;
  position: relative;

  background-image: url(${(props) => props.url});
  background-attachment: fixed;
  background-size: cover;
`;

const SinglePredictionTemplate = ({
  avatar,
  tier,
  parentPath,
  uid,
  ...props
}) => {
  return (
    <div>
      <Global />
      <StyledLayout url={dots}>
        <Navbar avatar={avatar} tier={tier} pathname={parentPath} uid={uid} />

        {props.children}
      </StyledLayout>
    </div>
  );
};

export default SinglePredictionTemplate;
