import React, { useState, useRef } from "react";
import styled from "styled-components";
import Editable from "./Editable";

const StyledEditable = styled(Editable)`
  height: 40px;
  position: relative;
  width: 95%;

  > div:first-child {
    height: 40px;
  }

  &:hover {
    background: #eeeeee;
  }

  svg {
    display: none;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }

  &:focus,
  &:hover {
    svg {
      display: flex;
    }
  }

  input {
    outline: 1px solid #c3baf5;
    border: none;
    height: 40px;
    width: 100%;
    padding: 0 25% 0 0;
    font-size: 20px;
  }
`;

const CustomEditBox = ({ name, updateInfo, id }) => {
  const inputRef = useRef();
  const [task, setTask] = useState(name);

  return (
    <StyledEditable
      text={task}
      childRef={inputRef}
      type="input"
      name={name}
      updateInfo={updateInfo}
      id={id}
      setTask={setTask}
    >
      <input
        ref={inputRef}
        type="text"
        name="task"
        value={task}
        onChange={(e) => setTask(e.target.value)}
        autoComplete="off"
      />
    </StyledEditable>
  );
};

export default CustomEditBox;
