import React, { useState } from "react";
import styled from "styled-components";
import { Link, useHistory } from "react-router-dom";
import Masonry from "react-masonry-css";
import { ArrowLeft } from "zoozoo";
import { PREDICTION_TITLES } from "../../../../constants/GenericContent";
import EachComparison from "./EachComparison";
import ReportNav from "../../../ui/navigation/reportNavigation/ReportNav";
import ZoomInOut from "../../../ui/globals/ZoomInOut";

const Grid = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 52px 32px 48px 32px;
  background: #fafafa;
`;

const GoBack = styled.div``;

const GoToPreviousPage = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]};
  cursor: pointer;

  svg {
    margin-right: 6px;
    transition: all 0.3s;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};

    svg {
      margin-right: 6px;
      transform: translateX(-2px);
      transition: all 0.3s;
    }
  }
`;

const Body = styled.div`
  width: ${(props) => props.width}%;
  margin: 0 auto;
  padding-bottom: 48px;

  .my-masonry-grid {
    display: flex;
    margin-left: -24px;
    width: auto;
  }
  .my-masonry-grid_column {
    padding-left: 24px;
    background-clip: padding-box;
  }

  .my-masonry-grid_column > div {
    margin-bottom: 24px;
  }
`;

const ZoomButton = styled.div`
  position: fixed;
  left: 150px;
  bottom: 56px;
  z-index: 7;
`;

const ProjectReport = ({
  comparisons,
  groupImage,
  changeGridState,
  open,
  uid,
  tier,
}) => {
  const { name, avatar, user_name } = comparisons;
  const { goBack } = useHistory();
  const { ORIGINAL, ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;
  const [type, setType] = useState(ATTENTION);
  const [checkType, setCheckType] = useState("attention");
  const [scale, setScale] = useState(70);

  const openPreviewModal = (id) => {
    let newGridState = groupImage.map((m, i) => {
      if (m.id === id) {
        return { ...m, open_modal: true };
      } else {
        return m;
      }
    });
    if (!open) {
      changeGridState(newGridState);
    }
  };

  const closeModal = (id) => {
    let newGridState = groupImage.map((m, i) => {
      if (m.id === id) {
        return { ...m, open_modal: false };
      } else {
        return m;
      }
    });

    changeGridState(newGridState);
  };

  const handleChange = (name) => {
    if (name === "original") {
      setCheckType(name);
      setType(ORIGINAL);
    } else if (name === "attention") {
      setCheckType(name);
      setType(ATTENTION);
    } else if (name === "aoi") {
      setCheckType(name);
      setType(AOI);
    } else if (name === "clarity") {
      setCheckType(name);
      setType(CLARITY);
    }
  };

  const findTypeUrl = (type, ori, att, cla) => {
    if (type === ORIGINAL || type === AOI) {
      return ori;
    } else if (type === ATTENTION) {
      return att;
    } else if (type === CLARITY) return cla;
  };

  const getScaleWidth = (newWidth) => {
    setScale(newWidth);
  };

  const BackToProjectLink = () => {
    return (
      <GoBack>
        <GoToPreviousPage to="#0" onClick={() => goBack()}>
          <ArrowLeft width={20} height={20} stroke="#3E21DE" />
          Go Back
        </GoToPreviousPage>
      </GoBack>
    );
  };

  return (
    <React.Fragment>
      <ReportNav
        name={name}
        avatar={avatar}
        userName={user_name}
        backToProject={<BackToProjectLink />}
        handleChange={handleChange}
        checkType={checkType}
        type={type}
        uid={uid}
      />

      <Grid>
        <Body width={scale}>
          <ZoomButton>
            <ZoomInOut width={scale} getScaleWidth={getScaleWidth} />
          </ZoomButton>

          {groupImage.length > 0 && (
            <Masonry
              breakpointCols={2}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {groupImage.map((compare, i) => {
                let typeUrl = findTypeUrl(
                  type,
                  compare.original_serve_path,
                  compare.attention_serve_path,
                  compare.clarity_serve_path
                );

                return (
                  <EachComparison
                    key={i}
                    compare={compare}
                    openPreviewModal={openPreviewModal}
                    closeModal={closeModal}
                    type={type}
                    typeUrl={typeUrl}
                    tier={tier}
                  />
                );
              })}
            </Masonry>
          )}
        </Body>
      </Grid>
    </React.Fragment>
  );
};

export default ProjectReport;
