import React, { useState } from "react";
import Modal from "react-awesome-modal";
import { Body, H2 } from "desisystem";
import styled from "styled-components";
import ButtonWithLoading from "../buttons/ButtonWithLoading";
// import reporting from "../../../assets/illustrations/Reporting.svg";
// import benchmarking from "../../../assets/illustrations/Benchmarking.svg";
import { useDispatch } from "react-redux";
import { downloadSurvey } from "../../../redux/slices/user";

const ModalBody = styled.div`
  padding: 40px;
  border-radius: ${(props) => props.theme.radius.medium};
  min-width: 500px;
  max-width: 500px;
  box-sizing: border-box;

  p {
    margin: 0;
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    text-align: left;
  }

  h2 {
    font-size: ${(props) => props.theme.fontSize[26]};
    margin: 16px 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    color: ${(props) => props.theme.colors.black};
    text-align: left;
  }
`;

const SurveyOptions = styled.div`
  margin-top: 40px;
`;

const EachOption = styled.div`
width: 100%
background: #FFFFFF;
border: 2px solid ${(props) => (props.active ? "#3E21DE" : "#C9C9C9")};
border-radius: ${(props) => props.theme.radius.soft};
min-height: 80px;
padding: 0 24px;
display: flex;
align-items: center;
justify-content: space-between;
margin-bottom: 16px;
cursor: pointer;
transition: all .3s;


h3 {
  margin: 0;
  font-weight: ${(props) => props.theme.weight.bold};
  font-size: ${(props) => props.theme.fontSize[20]};
  color: ${(props) => (props.active ? "#3E21DE" : "#212121")};
}

h3:first-letter {
  text-transform: uppercase;
}

&:hover {
  transition: all .3s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #3E21DE;


  h3 {
    color: #3E21DE;
  }
}
`;

const ModalButtons = styled.div`
  margin-top: 48px;
  text-align: center;

  button {
    width: 100%;
    max-width: 240px;
  }

  p {
    margin: 16px 0 0 0;
    font-weight: ${(props) => props.theme.weight.normal};
    font-size: ${(props) => props.theme.fontSize[14]};
    color ${(props) => props.theme.colors.brandPurple};
    text-align: center; 
    cursor: pointer;
  }
`;

const SurveyModal = ({ openSurveyModal, closeSurveyModal, surveyInfo }) => {
  const dispatch = useDispatch();
  const [reasonOfUse, setReasonOfUse] = useState("");

  let isDisabled = reasonOfUse === "";

  const submitSurvey = () => {
    const userData = new FormData();
    userData.set("download_reason", reasonOfUse);

    dispatch(downloadSurvey(userData));
    closeSurveyModal();
  };

  return (
    <Modal
      visible={openSurveyModal}
      effect="fadeInUp"
      onClickAway={() => closeSurveyModal()}
    >
      <ModalBody>
        <p>Just a quick question. Thanks in advance!</p>
        <H2>
          What’s the most likely action to do with your downloaded images?
        </H2>
        <SurveyOptions>
          {surveyInfo.map((info, i) => (
            <EachOption
              key={i}
              active={reasonOfUse === info.name}
              onClick={() => setReasonOfUse(info.name)}
            >
              <h3>{info.name}</h3>
              <img src={info.img} alt="reporting" />
            </EachOption>
          ))}
        </SurveyOptions>
        <ModalButtons>
          <ButtonWithLoading
            disabled={isDisabled}
            title="Submit"
            onClick={() => submitSurvey()}
          />
          <Body onClick={() => closeSurveyModal()}>
            I don’t have the time to answer right now.
          </Body>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
export default SurveyModal;
