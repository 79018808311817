import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";
import { renewAccess } from "../ducks/auth";
import { logout } from "./user";
// import { ERROR_TOAST, SUCCESS_TOAST } from "../../constants/ToastMessages";

// utils
// import { successToast, errorToast } from "../../utils/helperFunctions";

// base url
let url = config.URL;

export const initialState = {
  loading: false,
  isAuthenticated: false,
  bearerAuth: "",
  refreshToken: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginLoading: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, { payload }) => {
      state.isAuthenticated = true;
      state.bearerAuth = payload.access;
      state.refreshToken = payload.refresh;
      state.loading = false;
    },
    loginFailure: (state) => {
      state.loading = false;
    },
    // renewAccess: (state, { payload }) => {
    //   state.bearerAuth = payload;
    // },
    // logout: (state) => {
    //   state.isAuthenticated = false;
    //   state.bearerAuth = "";
    //   state.refreshToken = "";
    // },
  },
});

export const {
  loginLoading,
  loginSuccess,
  loginFailure,
  // renewAccess,
  // logout,
} = authSlice.actions;
export const authSelector = (state) => state.authS;
export default authSlice.reducer;

export function login(userData) {
  return async (dispatch) => {
    dispatch(loginLoading());

    try {
      const res = await axios.post(`${url}/accounts/login`, userData);
      dispatch(loginSuccess(res.data));
      dispatch(push("/"));
    } catch (error) {
      dispatch(loginFailure());
    }
  };
}

export const authorizedToken = () => (dispatch, getState) => {
  return {
    headers: { Authorization: `Bearer ${getState().auth.bearerAuth}` },
  };
};

export const getRefreshToken = () => (dispatch, getState) => {
  const userData = new FormData();
  userData.append("refresh", getState().auth.refreshToken);

  return userData;
};

export const refreshToken = (token, onSuccess, ...rest) => (dispatch) => {
  axios
    .post(`${url}/user/token/refresh`, token)
    .then((res) => {
      dispatch(renewAccess(res.data.access));
      dispatch(onSuccess(...rest));
    })
    .catch(function(error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    });
};
