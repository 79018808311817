import React from "react";
import styled from "styled-components";
import { Input, Body, H2, Dropdown, Add } from "desisystem";
import Switch from "../../../ui/globals/Switch";
import Coupon from "./Coupon";
import CurrentPrice from "./CurrentPrice";
import PoweredByStripe from "../../../icons/PoweredByStripe";
import { CardElement } from "@stripe/react-stripe-js";
import ButtonWithLoading from "../../../ui/buttons/ButtonWithLoading";
import CouponIcon from "../../../icons/CouponIcon";
import { useSelector } from "react-redux";
import { subscriptionSelector } from "../../../../redux/slices/subs";
import isEmpty from "../../../../utils/helperFunctions";
import { ArrowRight } from "@material-ui/icons";
import { ChevronDown, ChevronUp } from "zoozoo";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import BillingInfoModal from "../../../modals/BillingInfoModal";

const UpgradeBody = styled.div`
  max-width: 432px;
  margin: 24px auto;
  background: #fff;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 40px;

  h2 {
    font-size: ${(props) => props.theme.fontSize[22]};
    color: ${(props) => props.theme.colors.brandPurple};
    font-weight: bold;

    p::first-letter {
      text-transform: uppercase;
    }

    p {
      margin: 0;
      display: inline-block;
    }
  }

  span {
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #c3baf5;
  }

  .sub-email {
    color: #212121;
  }
`;

const Chips = styled.div`
  background: ${(props) => props.theme.colors.brandPurple};
  height: 64px;
  position: relative;
  box-shadow: 0px 20px 30px rgba(62, 33, 222, 0.2);
  border-radius: 10px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #fff;
    margin: 0 10px;
    cursor: pointer;
    font-weight: bold;
  }

  .active-option {
    color: #c3baf5;
  }

  .MuiSwitch-thumb {
    color: #fff;
    width: 18px;
    height: 18px;
  }
  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #2e18a4;
  }
  .MuiSwitch-root {
    width: 70px;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(30px);
  }
`;

const Payment = styled.div`
  margin-top: 40px;

  form p {
    text-align: center;
    margin: 0 0 16px 0;
  }
`;

const CouponQuestion = styled.div`
  margin: 32px 0 24px 0;
  color: #3e21de;
  display: inline-block;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: ${(props) => props.theme.fontSize[14]};

  > svg {
    margin-right: 10px;
  }
`;

const StripeBox = styled.div`
  margin: 23px 0 24px 0;

  .stripe-box {
    border: 1px solid ${(props) => props.theme.colors.brandPurple};
    padding: 12px;
    border-radius: 5px;
    height: 40px;
    font-size: 14px;
  }
`;

const StripeLogo = styled.div`
  margin-top: 24px;
  text-align: center;
`;

const UpgradeCard = ({
  email,
  planSwitchState,
  handleChecked,
  handleChange,
  handleShowCoupon,
  proPrice,
  basicPrice,
  stripeError,
  handleSubmit,
  coupon,
  showCoupon,
  planTier,
  tier,
  custom,
  proration,
  allPrices,
}) => {
  const [hasAdvancedOptions, setHasAdvancedOptions] = React.useState(false);
  const [optionalFields, setOptionalFields] = React.useState(false);

  const {
    loading,
    subs: { period },
  } = useSelector(subscriptionSelector);
  const { month } = proration;

  return (
    <UpgradeBody>
      <H2>
        Upgrade to <p> {planTier}</p>
      </H2>
      <span>
        Logged in as <span className="sub-email">{email}</span>
      </span>
      <Chips>
        <Body
          className={planSwitchState ? "" : "active-option"}
          onClick={() => handleChecked("annual")}
        >
          Annual
        </Body>
        <Switch
          checked={period !== "annual" ? !planSwitchState : false}
          onChange={() => handleChange()}
        />
        <Body
          className={planSwitchState ? "active-option" : ""}
          onClick={() => handleChecked("monthly")}
        >
          Monthly
        </Body>
      </Chips>
      <Payment>
        {proPrice !== "" && !isEmpty(allPrices) && (
          <CurrentPrice planSwitchState={planSwitchState} />
        )}

        {showCoupon ? (
          <CouponQuestion onClick={() => handleShowCoupon(!showCoupon)}>
            <CouponIcon />
            You have a coupon?
          </CouponQuestion>
        ) : (
          <Coupon couponError={coupon.coupon} planTier={planTier} />
        )}
        <form onSubmit={handleSubmit(optionalFields)}>
          <StripeBox>
            <CardElement
              className="stripe-box"
              options={{
                style: {
                  base: {
                    fontSize: "16px",
                  },
                },
              }}
            />
          </StripeBox>
          {stripeError && <p className="error-msg">{stripeError}</p>}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              marginBottom: "1rem",
            }}
            onClick={() => setHasAdvancedOptions(!hasAdvancedOptions)}
          >
            <Add
              width="16px"
              height="16px"
              fill="#999"
              style={{ marginRight: "0.25rem" }}
            />
            <Body style={{ margin: 0, color: "#999" }}>
              Customize your invoice
            </Body>
          </div>

          <BillingInfoModal
            open={hasAdvancedOptions}
            close={() => setHasAdvancedOptions(false)}
            setOptionalFields={setOptionalFields}
          />

          <ButtonWithLoading
            title={
              tier === "basic" && !custom
                ? !planSwitchState
                  ? `Pay $${month}  and upgrade `
                  : "Upgrade"
                : "Upgrade"
            }
            width="100"
            type="submit"
            loading={loading}
          />
        </form>
        <StripeLogo>
          <PoweredByStripe />
        </StripeLogo>
      </Payment>
    </UpgradeBody>
  );
};

export default UpgradeCard;
