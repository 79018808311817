import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import AvatarEditor from "react-avatar-editor";
import { useDispatch } from "react-redux";
import { Body, ImageUpload } from "desisystem";

import { updateAvatar } from "../../../../redux/slices/user";

const SIZE = 200;

const UploadOpen = styled.div`
    svg {
      margin-right: 4px;
    }
  }
`;

const UploadTitle = styled(Body)`
  && {
    color: #3e21de;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: initial;
  }
`;

const UpContainer = styled.div`
  padding: 2rem;
  min-width: ${SIZE}px;
  box-sizing: border-box;
`;

const UploadActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 40px;

  div > {
    button {
      margin-bottom: 16px;
      width: 100%;
    }
  }
  input[type="button"] {
    cursor: pointer;
    font-family: "Roboto";
    font-style: normal;
    font-weight: bold;
    line-height: 1;
    outline: 0;
    border: none;
    font-size: 16px;
    padding: 0 0 0 1rem;
    color: #3e21de;
    background: none;
  }

  p {
    cursor: pointer;
    text-align: center;
    font-weigth: bold !important;
  }
`;

// const InsideBtn = styled.div`
//   width: ${SIZE}px;
//   height: ${SIZE}px;
//   background: #eeeeee;
//   border-radius: 10px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

function SimpleDialog(props) {
  const { onClose, open } = props;
  const dispatch = useDispatch();

  const [image, setImage] = useState("");
  const [up, setUp] = useState("");
  const inputEl = React.useRef(null);
  let setEditorRef = React.useRef(null);

  const onButtonClick = () => {
    if (inputEl.current) {
      inputEl.current.click();
    } else {
      setTimeout(onButtonClick, 300);
    }
  };

  const handleClose = () => {
    onClose();
  };

  // const handleListItemClick = () => {
  //   onClose();
  // };

  const handleNewImage = (e) => {
    e.preventDefault();
    setImage(e.target.files[0]);
  };

  const handleSuccessLoad = () => {
    const editor = setEditorRef.current;
    const initialImage = editor
      .getImageScaledToCanvas()
      .toDataURL("image/jpeg");
    setUp(initialImage);
  };

  const handleCropChange = (prop) => {
    const editor = setEditorRef.current;
    const croppedImage = editor
      .getImageScaledToCanvas()
      .toDataURL("image/jpeg");

    setUp(croppedImage);
  };

  const handleSave = () => {
    const imageData = new FormData();
    imageData.append("avatar", up);

    dispatch(updateAvatar(imageData));
    onClose();
  };

  useEffect(() => {
    onButtonClick();
  }, []);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <input
        ref={inputEl}
        style={{ display: "none" }}
        name="newImage"
        type="file"
        onChange={handleNewImage}
      />
      {image !== "" && (
        <UpContainer>
          <AvatarEditor
            image={image}
            ref={setEditorRef}
            width={SIZE}
            height={SIZE}
            border={0}
            color={[255, 255, 255, 0.6]} // RGBA
            scale={1}
            rotate={0}
            onLoadSuccess={handleSuccessLoad}
            onPositionChange={handleCropChange}
          />
          <input
            ref={inputEl}
            style={{ display: "none" }}
            name="newImage"
            type="file"
            onChange={handleNewImage}
          />
          <UploadActions>
            <Body onClick={handleClose}>Cancel</Body>
            <input type="button" onClick={handleSave} value="Save" />
          </UploadActions>
        </UpContainer>
      )}
    </Dialog>
  );
}

export default function SimpleDialogDemo() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <UploadOpen>
      <UploadTitle onClick={handleClickOpen}>
        <ImageUpload
          width={20}
          height={20}
          fill="#3e21de"
          style={{ marginRight: "1rem" }}
        />
        Upload new image
      </UploadTitle>
      {open && <SimpleDialog open onClose={handleClose} />}
    </UploadOpen>
  );
}
