import axios from "axios";
import { push } from "connected-react-router";
import { config } from "../../constants/endpoints";
import { handleError } from "./errors";
import { acceptInvite, logout } from "../slices/user";
import { load, unLoad } from "./loadings";
import { gaConvert, fbTrack } from "../../utils/analytics";
import { ERROR_TOAST } from "../../constants/ToastMessages";
import { errorToast, successToast } from "../../utils/helperFunctions";

// base url
let url = config.URL;

// types
export const SET_SHOW_APPSUMO = "auth/SET_SHOW_APPSUMO";
export const CLEAR_SHOW_APPSUMO = "auth/CLEAR_SHOW_APPSUMO";
export const FIRST_SET_CURRENT_USER = "auth/FIRST_SET_CURRENT_USER";
export const SET_CURRENT_USER = "auth/SET_CURRENT_USER";
export const RENEW_ACCESS = "auth/RENEW_ACCESS";
export const UNSET_CURRENT_USER = "auth/UNSET_CURRENT_USER";
export const SOCIAL_AUTH = "auth/SOCIAL_AUTH";
export const CHECK = "auth/CHECK";
export const LOGOUT = "auth/LOGOUT";
export const CHECK_STEP = "auth/CHECK_STEP";
export const SET_ANALYTICS_API_KEY = "auth/SET_ANALYTICS_API_KEY";

// state
const initialState = {
  isAuthenticated: false,
  bearerAuth: "",
  refreshToken: "",
  user: {},
  email: "",
  analyticsApiKey: "",
  showAppsumo: false,
};

export const socialAuth = (userData, direct) => (dispatch, getState) => {
  dispatch(load());
  axios
    .post(`${url}/accounts/social`, userData)
    .then((res) => {
      const data = res.data;
      const is_new = res.data.is_new;
      dispatch(initAnalytics(data.api_key));
      dispatch(unLoad());
      if (is_new) {
        if (direct.plan !== "" && direct.duration !== "") {
          dispatch(firstTimeSetCurrentUserFromSite(data, direct));
        } else {
          dispatch(firstTimeSetCurrentUser(data));
        }

        // gaConvert();
        // fbTrack("StartTrial");
      } else {
        if (direct.plan !== "" && direct.duration !== "") {
          dispatch(setCurrentUserFromSite(data, direct));
        } else {
          dispatch(setCurrentUser(data));
        }
      }

      if (getState().user.inviteId) dispatch(acceptInvite());
    })
    .catch((err) => {
      if (err.response) {
        if (err) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
        if (err.response.status === 400) {
          dispatch(unLoad());
          errorToast(ERROR_TOAST.OUT_OF_ORDER);
          dispatch(handleError(err.response.data.reason));
        }
      }
      //  else {
      //   dispatch(unLoad());
      //   errorToast(ERROR_TOAST.OUT_OF_ORDER);
      // }
    });
};

export const checkEmail = (userEmail, email, directData, pluginNewUser) => (
  dispatch
) => {
  dispatch(load());

  axios
    .post(`${url}/accounts/check`, userEmail)
    .then((res) => {
      dispatch(unLoad());
      if (!res.data.exists) {
        dispatch(
          push("/auth/create/name", {
            state: {
              email,
              directData,
              pluginNewUser,
            },
          })
        );
      } else {
        dispatch(unLoad());
        dispatch(handleError(res.data));
      }
    })
    .catch((err) => {
      if (err.response) {
        if (err) {
          dispatch(unLoad());
          errorToast("Email already exists.");
        }
      }
    });
};

export const createUser = (userData, direct) => (dispatch, getState) => {
  dispatch(load());
  axios
    .post(`${url}/accounts`, userData)
    .then((res) => {
      const data = res.data;
      dispatch(initAnalytics(data.api_key));
      dispatch(unLoad());
      if (direct.plan !== "" && direct.duration !== "") {
        dispatch(firstTimeSetCurrentUserFromSite(data, direct));
      } else {
        dispatch(firstTimeSetCurrentUser(data));
      }

      if (getState().user.inviteId) dispatch(acceptInvite());
      // console.log(data);
      // gaConvert();
      // fbTrack("StartTrial");
    })
    .catch((err) => {
      if (err.response) {
        if (err) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
        if (err.response.status === 404) {
          dispatch(unLoad());
          errorToast(ERROR_TOAST.OUT_OF_ORDER);
          // dispatch(handleError(ERROR_TOAST.OUT_OF_ORDER));
        }
      } else {
        dispatch(unLoad());
        // errorToast(ERROR_TOAST.OUT_OF_ORDER);
        // dispatch(handleError(err.response.data.reason));
      }
    });
};

export const firstTimeSetCurrentUser = (data) => (dispatch) => {
  dispatch({
    type: FIRST_SET_CURRENT_USER,
    payload: data,
  });

  gaConvert();
  fbTrack("StartTrial");
  dispatch(handleError(null));
  dispatch(push("/onboarding/about"));
};

export const firstTimeSetCurrentUserFromSite = (data, direct) => (dispatch) => {
  dispatch({
    type: FIRST_SET_CURRENT_USER,
    payload: data,
  });

  gaConvert();
  fbTrack("StartTrial");
  dispatch(handleError(null));
  dispatch(
    push("/upgrade", {
      direct,
      fromSite: true,
    })
  );
};

export const createAppsumoUser = (userData, appsumoCode) => (
  dispatch,
  getState
) => {
  dispatch(load());
  axios
    .post(`${url}/accounts/appsumo/${appsumoCode}`, userData)
    .then((res) => {
      const data = res.data;
      dispatch(initAnalytics(data.api_key));
      dispatch(unLoad());
      dispatch(setShowAppsumo(data));
      dispatch(setCurrentUser(data));
    })
    .catch((err) => {
      if (err.response) {
        if (err) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
        if (err.response.status === 404) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
      } else {
        dispatch(unLoad());
        // dispatch(handleError(err.response.data.reason));
      }
    });
};

export const upgradeExistingUserFromAppsumo = (appsumoCode, token) => (
  dispatch,
  getState
) => {
  dispatch(load());
  const config = { headers: { Authorization: `Bearer ${token}` } };

  // console.log("here");
  axios
    .post(`${url}/user/appsumo/${appsumoCode}`, {}, config)
    .then((res) => {
      successToast("Done");
      const data = res.data;
      dispatch(initAnalytics(data.api_key));
      dispatch(unLoad());
      dispatch(setShowAppsumo(data));
      dispatch(setCurrentUser(data));
    })
    .catch((err) => {
      errorToast(err.response.data.reason);

      if (err.response) {
        if (err) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
        if (err.response.status === 404) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
      } else {
        dispatch(unLoad());
        // dispatch(handleError(err.response.data.reason));
      }
    });
};

export const loginUser = (userData, direct) => (dispatch, getState) => {
  dispatch(load());
  axios
    .post(`${url}/accounts/login`, userData)
    .then((res) => {
      const data = res.data;
      dispatch(initAnalytics(data.api_key));
      dispatch(unLoad());

      if (direct.plan !== "" && direct.duration !== "") {
        dispatch(setCurrentUserFromSite(data, direct));
      } else {
        dispatch(setCurrentUser(data));
      }

      if (getState().user.inviteId) dispatch(acceptInvite());
    })
    .catch((err) => {
      if (err.response) {
        if (err) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
        if (err.response.status === 404) {
          dispatch(unLoad());
          dispatch(handleError(err.response.data.reason));
        }
      } else {
        dispatch(unLoad());
        // dispatch(handleError(err.response.data.reason));
      }
    });
};

export const setCurrentUser = (data) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: data,
  });
  // console.log(data.api_key);
  // gaInit();
  // gaInitUserId(data.api_key);
  // dispatch(initAnalytics(data.api_key));
  dispatch(handleError(null));
  dispatch(push("/"));
};

export const setCurrentUserFromSite = (data, direct) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: data,
  });
  // gaInit();
  // gaInitUserId(data.api_key);
  // dispatch(initAnalytics(data.api_key));
  dispatch(handleError(null));
  dispatch(
    push("/upgrade", {
      direct,
      fromSite: true,
    })
  );
};

// init unique user for analytics
export const initAnalytics = (apiKey) => (dispatch) => {
  // gaInitUserId(apiKey);
  dispatch({
    type: SET_ANALYTICS_API_KEY,
    payload: apiKey,
  });
};

// refresh users token
export const refreshToken = (token, onSuccess, data = null) => (dispatch) => {
  axios
    .post(`${url}/user/token/refresh`, token)
    .then((res) => {
      dispatch(renewAccess(res.data.access));
      dispatch(onSuccess(data));
    })
    .catch(function(error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(logout());
        }
      }
    });
};

export const renewAccess = (token) => (dispatch) => {
  dispatch({
    type: RENEW_ACCESS,
    payload: token,
  });
};

export const authLogout = () => (dispatch) => {
  dispatch(unLoad());
  dispatch({
    type: LOGOUT,
  });
};

export const setShowAppsumo = () => (dispatch) => {
  dispatch({
    type: SET_SHOW_APPSUMO,
  });
};

export const clearShowAppsumo = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHOW_APPSUMO,
  });
};

// reducers
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SOCIAL_AUTH:
      return {
        ...state,
        isAuthenticated: true,
        bearerAuth: action.payload.access,
        refreshToken: action.payload.refresh,
      };
    case FIRST_SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        bearerAuth: action.payload.access,
        refreshToken: action.payload.refresh,
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        bearerAuth: action.payload.access,
        refreshToken: action.payload.refresh,
        verifyModal: false,
      };
    case SET_SHOW_APPSUMO:
      return {
        ...state,
        showAppsumo: true,
      };
    case CLEAR_SHOW_APPSUMO:
      return {
        ...state,
        showAppsumo: false,
      };
    case CHECK:
      return {
        ...state,
        email: action.payload,
      };

    case SET_ANALYTICS_API_KEY:
      return {
        ...state,
        analyticsApiKey: action.payload,
      };

    case RENEW_ACCESS:
      return {
        ...state,
        bearerAuth: action.payload,
      };
    case UNSET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: action.payload,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        bearerAuth: "",
        refreshToken: "",
      };
    default:
      return state;
  }
}
