import React from "react";
import styled from "styled-components";
import { Desktop, Mobile } from "zoozoo";

const Device = styled.div`
  display: flex;

  & > div {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0 0 0 8px;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
  }
`;

const DeviceType = ({ model }) => {
  return (
    <Device>
      {model === "desktop" ? (
        <div>
          <Desktop stroke="#212121" width={18} height={18} />
          {/* <p>Desktop</p> */}
        </div>
      ) : (
        <div>
          <Mobile stroke="#212121" width={18} height={18} />
          {/* <p>Mobile</p> */}
        </div>
      )}
    </Device>
  );
};

export default DeviceType;
