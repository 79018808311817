import React, { useState } from "react";
import styled from "styled-components";
import { Body, H2 } from "desisystem";
import dayjs from "dayjs";
import { useSelector, useDispatch } from "react-redux";
import { errorOptions } from "../../../../constants/ToastMessages";
import { toastr } from "react-redux-toastr";
import axios from "axios";
import { config } from "../../../../constants/endpoints";
import CircularProgress from "@material-ui/core/CircularProgress";

let url = config.URL;

const DetailsBox = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;

  @media (max-width: 1100px) {
    ${"" /* grid-template-columns: repeat(1, 1fr); */}
  }
`;

const Detail = styled.div`
  span {
    display: block;
    margin-bottom: 10px;
    font-size: ${props => props.theme.fontSize[10]};
    font-weight: ${props => props.theme.weight.medium};
    color: ${props => props.theme.colors.overline}
    letter-spacing: 0.2rem;
  }
  p {
    font-weight: bold;
  }

  p::first-letter {
    text-transform: uppercase;
  }
`;

const LinkBox = styled.div`
  margin-top: 40px;
  & > div {
    cursor: pointer;
    color: ${props => props.theme.colors.brandPurple};
    margin: 10px 0;
    font-size: ${props => props.theme.fontSize[14]};
  }

  .disabled-link {
    display: none;
  }
`;

const MyInfo = ({ subscription }) => {
  const {
    tier,
    period,
    started_at,
    expires_at,
    custom,
    downgrade_to
  } = subscription;
  const [loading, setLoading] = useState(false);

  const { bearerAuth } = useSelector(state => state.auth);

  const getAsyncReceipt = async () => {
    try {
      setLoading(true);
      let res = await axios.get(`${url}/user/receipt`, {
        headers: { Authorization: `Bearer ${bearerAuth}` }
      });
      let setLink = await res.data.url;
      getReceipt(setLink);
    } catch (e) {
      setLoading(false);
      toastr.error("Something went wrong.", errorOptions);
    }
  };

  const getReceipt = receiptUrl => {
    var link = document.createElement("a");
    link.href = receiptUrl;
    link.click();
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  };

  return (
    <div>
      <H2>Your Subscription</H2>
      <DetailsBox>
        <Detail>
          <span>PLAN</span>
          <Body>{tier}</Body>
        </Detail>
        <Detail>
          <span>PERIOD</span>
          <Body>{period ? period : "-"}</Body>
        </Detail>
        <Detail>
          <span>{downgrade_to !== "" ? "VALID UNTIL" : "RENEWING ON"}</span>
          <Body>{dayjs(expires_at).format("MMM DD, YYYY")}</Body>
        </Detail>
        <Detail>
          <span>ISSUED ON</span>
          <Body>{dayjs(started_at).format("MMM DD, YYYY")}</Body>
        </Detail>
      </DetailsBox>
      {tier === "free" || custom ? null : (
        <LinkBox>
          <div onClick={getAsyncReceipt}>
            Download your receipt.
            <span>
              {loading && (
                <CircularProgress
                  style={{ width: "16px", height: "16px", marginLeft: "5px" }}
                />
              )}
            </span>
          </div>
        </LinkBox>
      )}
    </div>
  );
};

export default MyInfo;
