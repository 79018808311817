import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

// ds
import { Button } from "desisystem";

// components
import Dialog from "@material-ui/core/Dialog";
import zombie from "../../assets/illustrations/delete.svg";

// redux
import {
  deleteProject,
  deletePredictionFromProject,
  projectsSelector,
} from "../../redux/slices/projects";

import {
  deletePrediction,
  predictionsSelector,
} from "../../redux/slices/predictions";

import {
  globalActionsSelector,
  closeDeleteModal,
} from "../../redux/slices/globalActions";

import ButtonWithLoading from "../ui/buttons/ButtonWithLoading";

import { DELETE_FULLSCREEN_CONTENT } from "../../constants/GenericContent";
import { DELETE_TYPES } from "../../constants/deleteTypes";
import { gaDeletePrediction } from "../../utils/analytics";

const { PREDICT_CONTENT } = DELETE_FULLSCREEN_CONTENT;

const FullPage = styled.div`
  background: #fff;

  && {
    .MuiDialog-paperFullScreen {
      border-radius: 0 !important;
    }
  }
`;

const FullPageBody = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const ImageBox = styled.div`
  max-width: 340px;
  max-height: 340px;
  margin: 0 auto;

  & > img {
    width: 100%;
    height: 100%;
  }
`;

const ContentBox = styled.div`
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[22]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    max-width: 80%;
    margin: 0 auto;
  }
`;

const ActionButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  button {
    min-width: 160px;
    height: 40px;
    padding: 0;
    margin: 0 20px
    font-size: ${(props) => props.theme.fontSize[14]};
    transition: all .3s;
  }
`;

const DeleteFullScreenModal = ({ isOpen, content, isLatest = false }) => {
  const dispatch = useDispatch();
  const { deleteData } = useSelector(globalActionsSelector);
  const { loading: predictLoader } = useSelector(predictionsSelector);
  const { loading: projectLoader } = useSelector(projectsSelector);
  const { pathOrigin, deleteId, deleteType } = deleteData;

  const deletePredictionWithGA = () => {
    gaDeletePrediction();
    dispatch(deletePrediction(deleteId, pathOrigin, isLatest));
  };

  const deletePredictionFromProjectWithGA = () => {
    gaDeletePrediction();
    dispatch(deletePredictionFromProject(deleteId, pathOrigin));
  };

  return (
    <FullPage>
      <Dialog fullScreen open={isOpen}>
        <FullPageBody>
          <div>
            <ImageBox>
              <img src={zombie} alt="al" />
            </ImageBox>
            <ContentBox>
              <h2>Are you sure?</h2>
              {deleteType === DELETE_TYPES.PREDICT_IN_PROJECT ? (
                <p>{content}</p>
              ) : (
                <p>{PREDICT_CONTENT}</p>
              )}
            </ContentBox>
            <ActionButtonBox>
              {deleteType === DELETE_TYPES.PREDICT_IN_PROJECT && (
                <ButtonWithLoading
                  loading={projectLoader}
                  title="Delete"
                  onClick={() => deletePredictionFromProjectWithGA()}
                />
              )}

              {deleteType === DELETE_TYPES.PREDICT && (
                <ButtonWithLoading
                  loading={predictLoader}
                  title="Delete"
                  onClick={() => deletePredictionWithGA()}
                />
              )}

              {deleteType === DELETE_TYPES.PROJECT && (
                <ButtonWithLoading
                  loading={projectLoader}
                  title="Delete"
                  onClick={() =>
                    dispatch(deleteProject(deleteId, pathOrigin, isLatest))
                  }
                />
              )}

              <Button
                appearance="secondary"
                onClick={() => dispatch(closeDeleteModal())}
              >
                Cancel
              </Button>
            </ActionButtonBox>
          </div>
        </FullPageBody>
      </Dialog>
    </FullPage>
  );
};

export default DeleteFullScreenModal;
