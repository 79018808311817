import React from "react";
import GreenCheck from "../components/icons/GreenCheck";
import ErrorCheck from "../components/icons/ErrorCheck";

// options for toast messages
export const errorOptions = {
  icon: <ErrorCheck width={22} height={22} />,
  showCloseButton: true,
};

export const successOptions = {
  icon: <GreenCheck width={20} height={20} />,
  showCloseButton: true,
};

export const ERROR_TOAST = {
  // GENERIC
  GENERIC: "Something went wrong.",
  OUT_OF_ORDER: "Our servers are temporary out of order.",

  // PROFILE
  UPDATE_USER: "Something went wrong.",
  GENERATE_KEY: "Something went wrong.",
  UPDATE_PASSWORD: "Your current password in not valid.",
  UPDATE_AVATAR: "Something went wrong.",

  // UPGRADE
  UPGRADE_PLAN: "Something went wrong. Please try again.",
  COUPON: "The coupon is not valid.",
  RENEW_SUBSCRIPTION: "Something went wrong.",

  // PROJECTS
  CREATE_PROJECT: "Something went wrong.",
  PROJECT_NAME_EXISTS: "Project with the same name already exists.",
  MORE_THAN_ONE_PROJECT: `Free users are limited to 1 project.`,
  UPDATE_PROJECT: "Project failed to updated.",

  // PREDICTIONS
  MOVE_PREDICTION: "Failed to move prediction.",
  CREATE_PREDICTION: "Prediction couldn't be created.",

  // SHARE
  SHARE_PROJECT: "Something went wrong.",
  SHARE_PREDICTION: "Something went wrong.",
};

export const SUCCESS_TOAST = {
  // AUTH
  RESET_PASSWORD: "Your password has been changed successfully!",
  FORGOT_PASSWORD: "Check your email to find your reset password link.",
  COUPON: "Your coupon was valid!",

  // PROFILE
  UPDATE_USER: "Your information was updated successfully!",
  GENERATE_KEY: "Your new key is ready!",
  UPDATE_PASSWORD: "Your password was updated successfully!",
  UPDATE_AVATAR: "Your new avatar has been saved!",

  // UPGRADE
  RENEW_SUBSCRIPTION: "Great news! Your subscription was renewed successfully.",

  // PROJECTS
  CREATE_PROJECT: "Project created successfully.",
  DELETE_PROJECT: "Project deleted successfully",
  UPDATE_PROJECT: "Project updated successfully.",

  // PREDICTION
  MOVE_PREDICTION: "Prediction moved successfully.",
  CREATE_PREDICTION: "Prediction created successfully.",
  DELETE_PREDICTION: "Prediction deleted successfully.",
  REMOVE_PREDICTION: "Prediction removed from project successfully.",
  UPDATE_PREDICTION: "Prediction name updated successfully.",

  // SHARE
  SHARE_PROJECT: "Your project is now shareable.",
  NOT_SHARE_PROJECT: "Your project is not shareable anymore.",
  SHARE_PREDICTION: "Your prediction is now shareable.",
  NOT_SHARE_PREDICTION: "Your prediction is not shareable anymore.",
};
