import React, { useEffect, useState, Fragment } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Info } from "desisystem";

import VerifySuccessModal from "../../../modals/VerifySuccessModal";
import DashboardPredictions from "./DashboardPredictions";
import FirstExamples from "./FirstExamples";
import DashboardProjects from "./DashboardProjects";
import GlobalCreationButton from "../../../ui/buttons/GlobalCreationButton";
import FullScreenModal from "../../../modals/FullScreenModal";
import FeatureForAll from "../../../ui/globals/FeatureForAll";

import {
  resendVerificationEmail,
  verifiedSuccessOver,
} from "../../../../redux/slices/user";

import { DELETE_FULLSCREEN_CONTENT } from "../../../../constants/GenericContent";
import {
  globalCreationMenu,
  globalActionsSelector,
} from "../../../../redux/slices/globalActions";
import { config } from "../../../../constants/endpoints";
import AppsumoIntroModal from "../../../modals/AppsumoIntroModal";
import { clearShowAppsumo } from "../../../../redux/ducks/auth";
import NeuronsBanner from "../../../ui/banners/NeuronsBanners";

const { PREDICT_CONTENT } = DELETE_FULLSCREEN_CONTENT;

const DashGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: relative;
  z-index: 1;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }
`;

const DashboardMainContainer = styled.div`
  padding: ${(props) => (props.isVerified ? "64px" : "6rem 48px 32px 48px")};
`;

const Verified = styled.div`
  ${"" /* background: rgb(213, 60, 60); */}
  background: #3E22DE;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
  padding: 0 48px;
  position: fixed;
  left: 0;
  right: 0;
  color: #fff;
  z-index: 2;

  span {
    display: flex;
    align-items: center;

    & > div {
      margin-left: 30px;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  p {
    display: inline-block;
    margin-left: 4px;
  }

  p::first-letter {
    text-transform: uppercase;
  }
`;

const SecondRow = styled.div`
  margin-top: 72px;
`;

const Dashboard = ({
  main,
  verifySuccessModal,
  isVerified = true,
  latestProjects,
  latestPredictions,
  exampleProjects,
  user,
  dashboardState,
  pathOrigin,
  tier,
  custom,
  showAppsumo,
}) => {
  const dispatch = useDispatch();
  const [modalSuccess, setModalSuccess] = useState(false);
  const [appsumoModalSuccess, setAppsumoModalSuccess] = useState(false);

  const { projectIdForGlobal, deleteModalOpen } = useSelector(
    globalActionsSelector
  );
  const { seenUpgradeMessage, seenAoiOnBoard, seenFeatureForAll } = useSelector(
    (state) => state.storage
  );
  const { first_name } = user;

  useEffect(() => {
    dispatch(globalCreationMenu(null));
  }, [dispatch]);

  useEffect(() => {
    if (verifySuccessModal) {
      openSuccessModal();
    }
  }, [verifySuccessModal]);

  useEffect(() => {
    if (showAppsumo) {
      openAppsumoModal();
    }
  }, [showAppsumo]);

  const openSuccessModal = () => {
    setModalSuccess(true);
  };
  const openAppsumoModal = () => {
    setAppsumoModalSuccess(true);
  };

  const closeModalSuccess = () => {
    setModalSuccess(false);
    dispatch(verifiedSuccessOver());
  };

  const closeAppsumoModal = () => {
    setAppsumoModalSuccess(false);
    dispatch(clearShowAppsumo());
  };

  return (
    <Fragment>
      {!isVerified && (
        <Verified>
          <span>
            <Info
              fill="#fff"
              width={20}
              height={20}
              style={{ marginRight: "8px" }}
            />
            Hey, <p>{user.first_name}</p>! Don't forget to verify your e-mail to
            unlock the rest of your credits.{" "}
            <div onClick={() => dispatch(resendVerificationEmail())}>
              {" "}
              Can’t find the e-mail? Resend.
            </div>
          </span>
        </Verified>
      )}
      <DashGrid>
        {main}
        <DashboardMainContainer isVerified={isVerified}>
          {!seenFeatureForAll && <FeatureForAll />}

          <FirstExamples firstExamples={exampleProjects} />

          <DashboardProjects
            latestProjects={latestProjects}
            dashboardState={dashboardState}
            pathOrigin={pathOrigin}
          />
          <SecondRow>
            <DashboardPredictions
              latestPredictions={latestPredictions}
              dashboardState={dashboardState}
              pathOrigin={pathOrigin}
              uid={user.uid}
            />
          </SecondRow>
        </DashboardMainContainer>
      </DashGrid>

      {deleteModalOpen && (
        <FullScreenModal
          isOpen={deleteModalOpen}
          content={PREDICT_CONTENT}
          isLatest={true}
        />
      )}

      {!deleteModalOpen && (
        <GlobalCreationButton projectIdForGlobal={projectIdForGlobal} />
      )}

      {verifySuccessModal && (
        <VerifySuccessModal
          verifySuccess={modalSuccess}
          closeVerifySuccess={closeModalSuccess}
          name={first_name}
        />
      )}

      {appsumoModalSuccess && (
        <AppsumoIntroModal
          visible={appsumoModalSuccess}
          close={closeAppsumoModal}
          firstname={first_name}
        />
      )}
    </Fragment>
  );
};

export default Dashboard;
