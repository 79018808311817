import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ArrowLeft } from "zoozoo";

// assets
import dots from "../../../../../assets/dark-dots.svg";
import watermarkDark from "../../../../../assets/image.png";
import watermarkLight from "../../../../../assets/watermark-light.svg";

// constants
import { PREDICTION_TITLES } from "../../../../../constants/GenericContent";

// ui
import { NonSelectable } from "../../../../layouts/Global";
import ZoomInOut from "../../../../ui/globals/ZoomInOut";
import GoTop from "../../../../ui/globals/ScrollToTop";
import GradientBox from "../../../../ui/globals/GradientBox";
import CircleScore from "../../../../ui/globals/CircleScore";
import ReportTemplate from "../../../../ui/templates/reportTemplate/ReportTemplate";
import PopUpBox from "../../../../ui/globals/PopUpBox";

// utils
import {
  debounceAction,
  drawAoiCoords,
} from "../../../../../utils/helperFunctions";
import useDrawInCanvas from "../../../../custom-hooks/useDrawInCanvas";

const OuterContainer = styled.div`
  background-image: url(${(props) => props.url});
  background-attachment: fixed;
  background-size: cover;
  min-height: calc(100vh - 210px);
`;

const Grid = styled.div`
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
`;

const GoToPreviousPage = styled(Link)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]};
  cursor: pointer;

  svg {
    margin-right: 6px;
    transition: all 0.3s;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};

    svg {
      margin-right: 6px;
      transform: translateX(-2px);
      transition: all 0.3s;
    }
  }
`;

const PredictBody = styled.div`
  position: relative;
  padding: 40px 48px 48px 48px;
`;

const ImageResult = styled.div`
  box-shadow: 0px 20px 30px rgba(0, 0, 0, 0.2);
  height: 100%;
  position: relative;
  width: ${(props) => props.width}%;
  margin: 0 auto;
  margin-top: 40px;
  ${NonSelectable}

  & > div {
    display: flex;
  }

  img {
    width: 100%;
    height: 100%;
  }
`;

const WatermarkImage = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(${(props) => props.watermark});
  background-size: 25% auto;
`;

const SingleImageResult = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const ZoomButton = styled.div`
  position: fixed;
  left: 150px;
  bottom: 56px;
  z-index: 7;
`;

const VerticalBox = styled.div`
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 7;
`;

const Dimensions = styled.div`
  position: absolute;
  top: 20px;
  background: #fff;
  display: inline-block;
  width: 90px;
  right: 19px;
  border-radius: 50px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
`;

const PredictionReport = ({
  predictionReport,
  inProjectReport,
  seenReportPop,
  isAuthenticated,
}) => {
  const {
    model,
    original_serve_path,
    attention_serve_path,
    clarity_serve_path,
    name,
    avatar,
    user_name,
    areas,
    project_id,
    clarity_score,
    use_percentage: percentageAoi,
    uid,
    tier,
  } = predictionReport;

  const { ORIGINAL, ATTENTION, CLARITY, AOI } = PREDICTION_TITLES;
  const [checkType, setCheckType] = useState("attention");
  const [type, setType] = useState(ATTENTION);
  const [scale, setScale] = useState(model === "mobile" ? 30 : 70);
  const [dimensions, setDimensions] = useState({ w: 0, h: 0 });

  const dimensionsRef = useRef();
  const canvasRef = useRef();

  useDrawInCanvas(
    dimensions.h,
    dimensions.w,
    areas,
    type,
    scale,
    canvasRef,
    percentageAoi
  );

  const getScaleWidth = (scale) => {
    setScale(scale);
  };

  const handleChange = (name) => {
    if (name === "original") {
      setCheckType(name);
      setType(ORIGINAL);
    } else if (name === "attention") {
      setCheckType(name);
      setType(ATTENTION);
    } else if (name === "aoi") {
      setCheckType(name);
      setType(AOI);
    } else if (name === "clarity") {
      setCheckType(name);
      setType(CLARITY);
    }
  };

  // was base64 now is urls
  function getMetricImage() {
    switch (type) {
      case ORIGINAL:
        return original_serve_path;
      case ATTENTION:
        return attention_serve_path;
      case CLARITY:
        return clarity_serve_path;
      default:
        return null;
    }
  }
  const imageBase64 = getMetricImage();

  useEffect(() => {
    const debounceHandleResize = debounceAction(function handleResize() {
      if (type === AOI) {
        drawAoiCoords(
          dimensionsRef.current.clientHeight,
          dimensionsRef.current.clientWidth,
          areas,
          canvasRef,
          percentageAoi
        );
      }
    }, 800);

    window.addEventListener("resize", debounceHandleResize);

    return (_) => {
      window.removeEventListener("resize", debounceHandleResize);
    };
  });

  const getDimensions = () => {
    setDimensions({
      w: dimensionsRef.current.clientWidth,
      h: dimensionsRef.current.clientHeight,
    });
  };

  useEffect(() => {
    setDimensions({
      w: dimensionsRef.current.clientWidth,
      h: dimensionsRef.current.clientHeight,
    });
  }, [scale]);

  const BackToProjectLink = () => {
    return (
      inProjectReport !== undefined && (
        <React.Fragment>
          {inProjectReport.belongsToProject && (
            <GoToPreviousPage
              to={{
                pathname: `/reporting/project/${project_id}`,
                state: {
                  belongsToProject: true,
                },
              }}
            >
              <ArrowLeft width={20} height={20} stroke="#3E21DE" />
              Go Back
            </GoToPreviousPage>
          )}
        </React.Fragment>
      )
    );
  };

  return (
    <ReportTemplate
      name={name}
      avatar={avatar}
      userName={user_name}
      backToProject={<BackToProjectLink />}
      handleChange={handleChange}
      checkType={checkType}
      type={type}
      uid={uid}
    >
      <OuterContainer url={dots}>
        <Grid>
          <PredictBody>
            <div>
              <ImageResult model={model} width={scale}>
                <React.Fragment>
                  <div>
                    <img
                      src={original_serve_path}
                      alt="original-result"
                      onLoad={() => getDimensions()}
                      ref={dimensionsRef}
                    />
                  </div>

                  {type === ATTENTION && (
                    <SingleImageResult>
                      <img src={imageBase64} alt="attention-result" />

                      {tier === "free" && (
                        <WatermarkImage
                          watermark={watermarkDark}
                        ></WatermarkImage>
                      )}
                    </SingleImageResult>
                  )}

                  {type === CLARITY && (
                    <SingleImageResult>
                      <img src={imageBase64} alt="clarity-result" />

                      {tier === "free" && (
                        <WatermarkImage
                          watermark={watermarkLight}
                        ></WatermarkImage>
                      )}

                      <Dimensions>
                        <CircleScore score={clarity_score} />
                      </Dimensions>
                    </SingleImageResult>
                  )}

                  {type === AOI && (
                    <SingleImageResult>
                      <canvas ref={canvasRef}></canvas>
                    </SingleImageResult>
                  )}
                </React.Fragment>
              </ImageResult>

              <GoTop scrollStepInPx="50" delayInMs="30" />
              <ZoomButton>
                <ZoomInOut width={scale} getScaleWidth={getScaleWidth} />
              </ZoomButton>

              {type === ATTENTION || type === CLARITY ? (
                <VerticalBox>
                  <GradientBox type={type} />
                </VerticalBox>
              ) : null}
            </div>

            {!seenReportPop && !isAuthenticated && <PopUpBox />}
          </PredictBody>
        </Grid>
      </OuterContainer>
    </ReportTemplate>
  );
};

export default PredictionReport;
