import React from "react";
import Modal from "react-awesome-modal";
import { Body, H2, Button } from "desisystem";
import limited from "../../assets/limited.svg";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hideLimitedModal } from "../../redux/slices/projects";

const ModalBody = styled.div`
  text-align: center;
  padding: 40px;
  border-radius: 10px;
  min-width: 400px;
  max-width: 480px;

  h2 {
    color: #212121;
    font-weight: bold;
    font-size: 26px;
    margin: 0 0 16px 0;
    text-align: left;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #212121;
    text-align: left;
    margin-bottom: 24px;
  }

  img {
    width: 100%;
  }
`;

const ModalButtons = styled.div`
  margin-top: 32px;

  button {
    height: 40px;
    font-size: 14px;
    padding: 0 1.5rem;
    min-width: 220px;
  }

  p {
    font-weight: normal;
    font-size: 14px;
    color: #3e21de;
    margin-top: 16px;
  }
`;

const UpgradeLink = styled(Link)`
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #fff;
  outline: 0;
  background: rgb(62, 33, 222);
  border-radius: ${(props) => props.theme.radius.soft};
  height: 40px;
  max-width: 200px;
  min-width: 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;

  &:hover {
    transition: all 0.3s;
    box-shadow: ${(props) => props.theme.shadows.darkShadowHover};
  }
`;

const ModalLink = styled(Link)`
  font-weight: normal;
  font-size: 14px;
  color: #3e21de;
  margin-top: 16px;
  display: inline-block;
`;

const LimitedProjectsModal = ({
  modal,
  closeModal,
  tier,
  custom,
  pathname,
}) => {
  const dispatch = useDispatch();

  return (
    <Modal
      visible={modal}
      effect="fadeInUp"
      onClickAway={() => dispatch(hideLimitedModal())}
    >
      <ModalBody>
        <H2>
          Your current plan is <br /> limited to{" "}
          {tier === "free" ? "1 project" : "5 projects"}
        </H2>
        <Body size="14px">
          File organization matters. <strong> A lot</strong>.
        </Body>
        <img src={limited} alt="leave" />
        <ModalButtons>
          {tier === "basic" && custom ? (
            <Button onClick={() => closeModal()}>Upgrade to Pro</Button>
          ) : (
            <UpgradeLink
              to={{
                pathname: "/upgrade",
                state: {
                  plan: "pro",
                  pathname,
                },
              }}
              onClick={() => dispatch(hideLimitedModal())}
            >
              Upgrade to Pro
            </UpgradeLink>
          )}
          <ModalLink
            to="/projects"
            onClick={() => dispatch(hideLimitedModal())}
          >
            No, thanks.
          </ModalLink>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
export default LimitedProjectsModal;
