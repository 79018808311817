import React from "react";
import styled from "styled-components";
import { Button } from "desisystem";
import zombe from "../../../assets/zombe.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { userSelector } from "../../../redux/slices/user";
// import zombe from "../../../assets/illustrations/learn-more.svg";

const BREAKPOINT = "1200px";

const FirstOpeningBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  background: ${(props) => props.theme.colors.white};

  & > div {
    justify-self: center;
    width: 100%;
    padding: 22px;
    box-sizing: border-box;
  }

  @media (max-width: ${BREAKPOINT}) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const Illustration = styled.div`
  img {
    width: 200px;
  }
  @media (max-width: ${BREAKPOINT}) {
    grid-row: 1 / 3;
  }
`;

const Content = styled.div`
  h2 {
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
    margin: 0;
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.softGrey};
    margin: 8px 0 0 0;
  }
`;

const Actions = styled.div`
  @media (max-width: ${BREAKPOINT}) {
    grid-column: 2;
    justify-self: start;
    margin-bottom: 20px;
  }
`;

const StyledLink = styled(Link)`
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSize[14]};
  color: #fff;
  outline: 0;
  background: rgb(62, 33, 222);
  border-radius: ${(props) => props.theme.radius.soft};
  height: 40px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  margin: 0 auto;

  &:hover {
    transition: all 0.3s;
    box-shadow: ${(props) => props.theme.shadows.darkShadowHover};
  }

  @media (max-width: ${BREAKPOINT}) {
    margin: 0 auto 0 0;
  }
`;

const FirstOpening = ({ openingContent }) => {
  const { HEADER_TITLE, HEADER_PARAGRAPH, BUTTON, LINK, SRC } = openingContent;
  const {
    user: { credits_left: creditsLeft },
  } = useSelector(userSelector);

  return (
    <FirstOpeningBox>
      <Illustration>
        <img src={SRC} alt="al" />
      </Illustration>
      <Content>
        <h2>{HEADER_TITLE}</h2>
        <p>{HEADER_PARAGRAPH}</p>
      </Content>
      <Actions>
        {creditsLeft === 0 && BUTTON === "Make Prediction" ? (
          <Button disabled={true}>{BUTTON}</Button>
        ) : (
          <StyledLink
            to={{
              pathname: LINK,
              state: {
                pathname: "/",
              },
            }}
          >
            {BUTTON}
          </StyledLink>
        )}
      </Actions>
    </FirstOpeningBox>
  );
};

export default FirstOpening;
