import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ArrowBack } from "desisystem";

// components
import GlobalCreationButton from "../../../ui/buttons/GlobalCreationButton";
import EmptyHistory from "../../openings/EmptyHistory";

// constants
import { EMPTY_HISTORY_PREDICT } from "../../../../constants/GenericContent";

import FullScreenModal from "../../../modals/FullScreenModal";
import { DELETE_FULLSCREEN_CONTENT } from "../../../../constants/GenericContent";
// import GlobalLoader from "../../../ui/loadings/GlobalLoader";

const { PREDICT_CONTENT } = DELETE_FULLSCREEN_CONTENT;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 24px;
`;

const PredictionsSidebar = styled.div`
  height: calc(100vh - 10px);
  position: sticky;
  top: 80px;
  background: #fff;
  padding: 0 0 50px 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  &::before {
    content: "";
    position: absolute;
    background: #fff;
    height: 100%;
    height: 100%;
    width: calc(50vh);
    left: -50vh;
  }

  h1 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[34]};
    margin: 0 0 8px 0;
  }

  span {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #9e9e9e;
  }
`;

const PredictionsGrid = styled.div`
  padding: 94px 40px 48px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;

  @media (max-width: 1300px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const GoBack = styled.div`
  position: relative;
  margin: 72px 0 16px 0;
`;

const PredictionNum = styled.div`
  h4 {
    font-weight: 500;
    font-size: ${(props) => props.theme.fontSize[34]};
    margin: 0;
  }

  span {
    font-size: ${(props) => props.theme.fontSize[12]};
    color: #9e9e9e;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.brandPurple};
  font-size: ${(props) => props.theme.fontSize[14]}
  display: flex;
  align-items: center;

  > svg {
    margin-right: 6px;
  }

  &:hover {
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const SidebarContent = styled.div`
  padding-right: 24px;

  p {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
  }
`;

const AllPredictions = ({
  allPredictions,
  projectIdForGlobal,
  deleteModalOpen,
  children,
  loadingPredictions,
  totalPredictions,
}) => {
  return (
    <React.Fragment>
      <Grid>
        <PredictionsSidebar>
          <GoBack>
            <StyledLink to="/">
              <ArrowBack width={20} height={20} fill="#3E21DE" />
              Go Back
            </StyledLink>
          </GoBack>
          <h1>All Predictions</h1>
          <SidebarContent>
            <p>
              This is a collection of all your generated predictions, sorted
              chronologically.
            </p>
            <PredictionNum>
              {children && <h4>{allPredictions.length}</h4>}
              <span>Predictions</span>
            </PredictionNum>
          </SidebarContent>
        </PredictionsSidebar>
        {allPredictions.length > 0 ? (
          <div>
            <PredictionsGrid>{children}</PredictionsGrid>
          </div>
        ) : (
          <React.Fragment>
            <EmptyHistory openingContent={EMPTY_HISTORY_PREDICT} />
          </React.Fragment>
        )}

        {!deleteModalOpen && (
          <GlobalCreationButton projectIdForGlobal={projectIdForGlobal} />
        )}
      </Grid>

      {deleteModalOpen && (
        <FullScreenModal isOpen={deleteModalOpen} content={PREDICT_CONTENT} />
      )}
    </React.Fragment>
  );
};

export default AllPredictions;
