import React from "react";
import styled from "styled-components";

import SocialProofLayout from "../../../layouts/SocialProofLayout";
import ResetHelp from "../../../icons/ResetHelp";

const AuthInnerContainer = styled.div`
  max-width: 432px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.subHeading};
  }
`;

const PromptUser = styled.div`
  margin-top: 40px;

  p {
    font-weight: normal;
    font-size: 14px;
    color: #9e9e9e;
  }

  span {
    font-weight: bold;
    color: #212121;
  }
`;

const ForgotPassword = (props) => {
  const { placeholderEmail = "" } = props.location.state || {
    placeholderEmail: "",
  };

  return (
    <SocialProofLayout>
      <AuthInnerContainer>
        <Heading>
          <h1>Help is coming!</h1>
          <p>Reset instructions in your inbox.</p>
        </Heading>

        <PromptUser>
          <p>
            Check your email <span> {placeholderEmail} </span> and follow the
            instructions
          </p>
          <ResetHelp />
        </PromptUser>
      </AuthInnerContainer>
    </SocialProofLayout>
  );
};

export default ForgotPassword;
