import React from "react";
import styled from "styled-components";
import { Button } from "desisystem";
// import Dialog from "@material-ui/core/Dialog";
import FullPageModal from "../global/FullPageModal";

// const FullPage = styled.div`
//   background: #fff;

//   && {
//     .MuiDialog-paperFullScreen {
//       border-radius: 0 !important;
//     }
//   }
// `;

// const FullPageBody = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
// `;

const ContentBox = styled.div`
  text-align: center;

  h2 {
    font-weight: bold;
    font-size: ${(props) => props.theme.fontSize[22]};
    color: ${(props) => props.theme.colors.black};
    margin: 0 0 16px 0;

    p:first-letter {
      text-transform: uppercase;
    }

    p {
      font-weight: bold;
      font-size: ${(props) => props.theme.fontSize[22]};
      color: ${(props) => props.theme.colors.black};
      display: inline-block;
    }
  }

  p {
    font-weight: normal;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};
    ${"" /* max-width: 80%; */}
    margin: 0 auto;
  }

  span {
    font-weight: bold;
    color: ${(props) => props.theme.colors.brandPurple};
  }
`;

const ActionButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;

  button {
    min-width: 160px;
    height: 40px;
    padding: 0;
    margin: 0 12px
    font-size: ${(props) => props.theme.fontSize[14]};
    transition: all .3s;
  }
`;

const SubscriptionModal = ({
  open,
  closeModal,
  content,
  action,
  modalType,
  isDowngrading = false,
  tierToGo = "free",
}) => {
  return (
    <FullPageModal open={open}>
      <ContentBox>{content}</ContentBox>
      <ActionButtonBox>
        {modalType === "hybrid" && isDowngrading && (
          <Button onClick={() => action()}>Yes, downgrade</Button>
        )}
        {modalType === "hybrid" && !isDowngrading && (
          <Button onClick={() => action()}>Yes, renew</Button>
        )}
        {modalType !== "hybrid" && (
          <Button onClick={() => action(tierToGo)}>Yes, {modalType}</Button>
        )}
        <Button appearance="secondary" onClick={() => closeModal()}>
          Go Back
        </Button>
      </ActionButtonBox>
    </FullPageModal>
  );
};

export default SubscriptionModal;
