import React, { useEffect, useState } from "react";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { CardMenu } from "desisystem";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  openDeleteModal,
  globalActionsSelector,
} from "../../../../redux/slices/globalActions";
import { subscriptionSelector } from "../../../../redux/slices/subs";

const ITEM_HEIGHT = 48;

const OptionButton = styled.span`
  padding: 14px 16px;
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? "none" : "initial")};
`;

const MenuOption = styled(MenuItem)`
  && {
    color: ${(props) =>
      props.disabled ? "#d0d0d0" : props.theme.colors.black};
    font-size: ${(props) => props.theme.fontSize[12]};
    padding: 0;

    &:hover {
      background: #f5f5f5;
    }
  }
`;

const ProjectOptions = ({ projectId, pathOrigin = "/", ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const { deleteModalOpen } = useSelector(globalActionsSelector);
  const {
    subs: { tier },
  } = useSelector(subscriptionSelector);

  let onlyPro = tier === "basic" || tier === "free";

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (deleteModalOpen) {
      setAnchorEl(null);
    }
  }, [deleteModalOpen]);

  const handleClickOpen = (data) => {
    // second parameter is for conditional rendering the delete function in FullScreenModal
    dispatch(openDeleteModal(data, "project", pathOrigin));
  };

  const shareProject = () => {
    props.history.push(`/projects/${projectId}`, {
      directShare: true,
    });
  };

  return (
    <div>
      <CardMenu
        aria-label="mo"
        aria-controls="long-m"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
        fill="#C9C9C9"
      >
        <MoreVertIcon />
      </CardMenu>
      <Menu
        id="long-m"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 140,
            background: "#FFFFFF",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.15)",
            borderRadius: "4px",
          },
        }}
      >
        <MenuOption>
          <OptionButton onClick={() => shareProject()}>Share</OptionButton>
        </MenuOption>
        <MenuOption onClick={() => handleClickOpen(projectId)}>
          <OptionButton style={{ color: "#D53C3C" }}>Delete</OptionButton>
        </MenuOption>
      </Menu>
    </div>
  );
};

export default withRouter(ProjectOptions);
