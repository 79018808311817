import React, { useEffect } from "react";
import getAllUrlParams from "../../utils/GetAllUrlParams";
import { useDispatch, useSelector } from "react-redux";
import SvgLogo from "../icons/SvgLogo";
import styled from "styled-components";
import { H1, Body } from "desisystem";
import Global from "../layouts/Global";
import { Link } from "react-router-dom";
import { unsubscribeUser } from "../../redux/slices/account";
import { fetchUser, userSelector } from "../../redux/slices/user";

const UnsubscribePage = styled.div`
  padding: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 40px 0;
    color: #212121;
    font-size: 34px;
    font-weight: 500;
    text-align: center;

    p {
      font-size: 34px;
      font-weight: 500;
      display: inline-block;
      color: #3e21de;
    }

    p::first-letter {
      text-transform: uppercase;
    }
  }

  p {
    text-align: center;
    font-size: 22px;
    margin: 0;
  }

  button {
    margin-top: 50px;
  }
`;

const StyleLink = styled(Link)`
  margin-top: 50px;
  text-decoration: none;
  cursor: pointer;
  font-family: "Roboto";
  font-style: normal;
  font-weight: bold;
  line-height: 1;
  outline: 0;
  border: none;
  font-size: 16px;
  background: #3e21de;
  border-radius: 4px;
  color: #fff;
  padding: 1rem 2rem;
`;

const Unsubscribe = () => {
  const dispatch = useDispatch();

  let baseUrl = window.location.href;
  let uid = getAllUrlParams(baseUrl).uid;

  const { user } = useSelector(userSelector);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(unsubscribeUser(uid));
  }, [dispatch, uid]);

  return (
    <UnsubscribePage>
      <Global />
      <div>
        <SvgLogo width={70} height={70} />
      </div>
      <div>
        <H1>
          It seems you're leaving, <Body>{user.first_name}</Body>
        </H1>
        <p>We are sad to see that you're unsubscribed from our newsletter...</p>
        <p className="bold-text">
          It's okay though, we are still glad to have you here with us!
        </p>
        <p
          style={{
            color: "#3E21DE",
            fontWeight: "italic",
            fontSize: "18px",
            marginTop: "24px",
          }}
        >
          - VisualEyes Team
        </p>
      </div>
      <StyleLink to="/">Back to Dashboard</StyleLink>
    </UnsubscribePage>
  );
};

export default Unsubscribe;
