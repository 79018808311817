import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import Select from "react-select";

// components
import Manager from "../../icons/Manager";
import Designer from "../../icons/Designer";
import Marketer from "../../icons/Marketer";
import OtherTitle from "../../icons/OtherTitle";
import SocialProofLayout from "../../layouts/SocialProofLayout";
import ButtonWithLoading from "../../ui/buttons/ButtonWithLoading";

// actions
import { onBoardingUser, userSelector } from "../../../redux/slices/user";

// constants
import { JOB_TITLE, TEAM, GOAL } from "../../../constants/onboardingContent";
import {
  shuffleArray,
  getArrayExceptLastItem,
  getLastArrayItem,
} from "../../../utils/helperFunctions";

const Container = styled.div`
  max-width: 510px;
`;

const Heading = styled.div`
  h1 {
    margin: 9vh 0 0 0;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    margin: 8px 0 0 0;
    font-size: ${(props) => props.theme.fontSize[14]};
    color: #9e9e9e;
  }
`;

const FormBody = styled.form`
  margin-top: 40px;

  span {
    font-size: ${(props) => props.theme.fontSize[14]};
    color: ${(props) => props.theme.colors.black};

    & > div > span {
      display: none !important;
    }
  }

  button {
    margin-top: 40px;
    min-width: 200px;
  }
`;

const Role = styled.div`
  display: flex;
  min-height: 168px;
  align-items: center;
  margin-top: 24px;
`;

const EachRole = styled.div`
  position: relative;
  display: inline-block;
  width: 80%;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(249, 249, 249, 0.7);
    transition: all 0.3s;
  }

  svg {
    width: 70%;
    height: 80%;
    transition: all 0.3s;
    display: block;
    margin: 0 auto;
  }

  ${(props) =>
    props.active &&
    `
    &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(249, 249, 249, 0);
    transition: all 0.3s;
  }
  
  svg {
      width: 100%
      transition: all 0.3s;
      display: block;
      margin: 0 auto;
  }
  `}
`;

const jobTitleStyles = {
  container: (provided) => ({
    ...provided,
    margin: "0 5px 8px 5px",
    textAlign: "left",
    display: "inline-block",
  }),
  control: (provided) => ({
    ...provided,
    minWidth: "130px",
    border: "none",
    borderBottom: "1px solid #E2E0EC",
    borderRadius: 0,
    minHeight: "40px",
    background: "#fafafa",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#3E21DE",
    position: "static",
    transform: "translateY(0%)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
};

const teamStyles = {
  container: (provided) => ({
    ...provided,
    margin: "0 5px 8px 5px",
    textAlign: "left",
    display: "inline-block",
  }),
  control: (provided) => ({
    ...provided,
    minWidth: "130px",
    border: "none",
    borderBottom: "1px solid #E2E0EC",
    borderRadius: 0,
    minHeight: "40px",
    background: "#fafafa",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#3E21DE",
    position: "static",
    transform: "translateY(0%)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
};

const goalStyles = {
  container: (provided) => ({
    ...provided,
    margin: "0 5px 8px 5px",
    textAlign: "left",
    display: "inline-block",
  }),
  control: (provided) => ({
    ...provided,
    minWidth: "130px",
    border: "none",
    borderBottom: "1px solid #E2E0EC",
    borderRadius: 0,
    minHeight: "40px",
    background: "#fafafa",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#3E21DE",
    position: "static",
    transform: "translateY(0%)",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: "transparent",
  }),
};

const AboutYou = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [team, setTeam] = useState("");
  const [goal, setGoal] = useState("");

  const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.loadings);
  const {
    loading,
    // user: { first_name: firstName },
  } = useSelector(userSelector);

  const firstName = "";

  const isDisabled = jobTitle === "" || team === "" || goal === "";

  const handleSave = (e) => {
    e.preventDefault();
    const userData = new FormData();
    userData.set("job_title", jobTitle);
    userData.set("origin", team);
    userData.set("reason", goal);

    dispatch(onBoardingUser(userData));
  };

  const getShuffledList = (array) => {
    let lastItem = getLastArrayItem(array);
    let newArrayNoLast = getArrayExceptLastItem(array);
    let mixedArray = shuffleArray(newArrayNoLast);
    return mixedArray.concat(lastItem);
  };

  const onChangeJob = (e) => {
    setJobTitle(e.value);
  };

  const onChangeTeam = (e) => {
    setTeam(e.value);
  };

  const onChangeGoal = (e) => {
    setGoal(e.value);
  };

  return (
    <SocialProofLayout>
      <Container>
        <Heading>
          <h1>Tell us about yourself, {firstName}</h1>
          <p>Help us tailor VisualEyes just for you.</p>
        </Heading>

        <Role>
          <EachRole
            active={
              jobTitle === JOB_TITLE[0].label || jobTitle === JOB_TITLE[1].label
                ? 1
                : 0
            }
          >
            <Designer />
          </EachRole>
          <EachRole active={jobTitle === JOB_TITLE[2].label ? 1 : 0}>
            <Manager />
          </EachRole>
          <EachRole active={jobTitle === JOB_TITLE[3].label ? 1 : 0}>
            <Marketer />
          </EachRole>
          <EachRole active={jobTitle === JOB_TITLE[4].label ? 1 : 0}>
            <OtherTitle />
          </EachRole>
        </Role>

        <FormBody onSubmit={handleSave}>
          <span>
            Hey, I'm a
            <Select
              options={getShuffledList(JOB_TITLE)}
              styles={jobTitleStyles}
              // onChange={(e) => setJobTitle(e.label)}
              onChange={(e) => onChangeJob(e)}
              placeholder=""
            />
            in
            <Select
              options={getShuffledList(TEAM)}
              styles={teamStyles}
              // onChange={(e) => setTeam(e.label)}
              onChange={(e) => onChangeTeam(e)}
              placeholder=""
            />
            and I'm looking to use VisualEyes for
            <Select
              options={GOAL}
              styles={goalStyles}
              // onChange={(e) => setGoal(e.label)}
              onChange={(e) => onChangeGoal(e)}
              placeholder=""
            />
          </span>

          <ButtonWithLoading
            loading={loading}
            title="Continue"
            type="submit"
            disabled={isDisabled}
          />
        </FormBody>
      </Container>
    </SocialProofLayout>
  );
};

export default AboutYou;
