import React from "react";
import styled from "styled-components";
import Switch from "../../globals/Switch";

const AttentionMergeOption = styled.div`
  display: inline-flex;
  align-items: center;
  border: 1px solid #f0f0f0;
  border-radius: 50px;
  height: 38px;
  padding: 0 10px;
  margin-left: 14px;

  p {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
    color: #1d0f68;
  }

  .MuiSwitch-root {
    height: 42px;
  }
  .MuiSwitch-switchBase {
    top: 5px;
    left: 5px;
  }

  .MuiSwitch-thumb {
    width: 14px;
    height: 14px;
    color: #fff;
    box-shadow: none;
  }

  .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(17px);
  }

  .Mui-checked .MuiSwitch-thumb {
    color: #fff;
  }

  .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #3e21de;
    opacity: 1;
    border-radius: 50px;
  }

  .MuiSwitch-colorPrimary + .MuiSwitch-track {
    background-color: #e0e0e0;
    opacity: 1;
    border-radius: 50px;
  }
`;

const AttentionSwitch = ({ checked, onChangeSwitch }) => {
  return (
    <AttentionMergeOption>
      <p>Show Attention Map</p>
      <Switch checked={checked} onChange={() => onChangeSwitch()} />
    </AttentionMergeOption>
  );
};

export default AttentionSwitch;
