import React from "react";
import styled from "styled-components";
import ContentLoader from "react-content-loader";

const StyledLoadingCard = styled(ContentLoader)`
  background: #fff;
  height: 80px;
  width: 100%;
  border-radius: ${props => props.theme.radius.soft};
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
`;

const ProjectLoading = ({ customWidth, customViewport }) => (
  <StyledLoadingCard
    speed={2}
    width={customWidth}
    height={80}
    viewBox={customViewport}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <circle x="5" cx="30" cy="38" r="15" />
    <rect x="55" y="26" rx="2" ry="2" width="140" height="10" />
    <rect x="55" y="42" rx="2" ry="2" width="90" height="10" />
  </StyledLoadingCard>
);

export default ProjectLoading;
