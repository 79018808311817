import React, { useRef, useState } from "react";
// import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import LearnMore from "./LearnMore";
import useOnClickOutside from "../../../custom-hooks/useOnClickOutside";
import { Download } from "zoozoo";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactTooltip from "react-tooltip";

const DownloadImage = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #3e21de;
  font-size: ${(props) => props.theme.fontSize[12]};
  transition: all 0.3s;
  font-weight: bold;
  height: 36px;
  position: relative;
  pointer-events: ${(props) => props.loading && "none"};
  transition: all 0.3s;

  &:hover {
    transition: all 0.3s;
    background: rgba(255, 255, 255, 0.3);
  }

  ${(props) =>
    props.disabled &&
    `
    cursor: initial;
  
    svg {
      stroke: #dcdcdc;
    }
  `}
`;

const DoubleAction = styled.div`
  background: "#fff";
  border: 1px solid #3e21de;
  border-radius: 100px;
  display: flex;
  width: 100px;
  justify-content: space-evenly;

  & > div {
    width: 50%;
    display: flex;
    justify-content: center;

    &:hover {
      transition: all 0.3s;
      background: rgba(255, 255, 255, 0.3);
    }
  }
`;

const Divider = styled.span`
  width: 1px;
  height: 24px;
  background: #3e21de;
  align-self: center;
`;

const DoubleButton = ({
  tier,
  dropdownContent,
  type,
  loadingDownload,
  downloadImage,
}) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => setOpenDropdown(false));

  const openInfo = () => {
    setOpenDropdown(!openDropdown);
  };

  return (
    <React.Fragment>
      <ReactTooltip id="downloadImage" effect="solid" place={"top"}>
        {tier === "free" ? (
          <span>Upgrade to Basic to download results</span>
        ) : (
          <span>Download</span>
        )}
      </ReactTooltip>
      <DoubleAction>
        <div>
          <span ref={ref}>
            <LearnMore
              onClick={openInfo}
              openDropdown={openDropdown}
              dropdownContent={dropdownContent}
              type={type}
            />
          </span>
        </div>

        <Divider />

        <DownloadImage
          loading={loadingDownload ? 1 : 0}
          className="btn"
          onClick={tier === "free" ? null : () => downloadImage()}
          data-tip
          data-for="downloadImage"
          disabled={tier === "free"}
        >
          {loadingDownload ? (
            <CircularProgress
              style={{
                width: "18px",
                height: "18px",
                color: "#3E21DE",
              }}
            />
          ) : (
            <Download stroke="#3E21DE" width={21} height={21} />
          )}
        </DownloadImage>
      </DoubleAction>
    </React.Fragment>
  );
};

export default DoubleButton;
