import React from "react";
import Modal from "react-awesome-modal";
import { Body, H2, Check, Button } from "desisystem";
import styled from "styled-components";
import { HiXCircle } from "react-icons/hi";

const ModalBody = styled.div`
  padding: 40px;
  border-radius: 10px;
  min-width: 400px;
  max-width: 400px;
  box-sizing: border-box;
  text-align: center;

  h2 {
    font-size: 22px;
    margin: 32px 0 24px 0;
    font-weight: bold;
    color: #212121;

    p {
      font-size: 22px;
      color: #3e21de;
      font-weight: bold;
    }
    p::first-letter {
      text-transform: uppercase;
    }
  }
`;

const ModalButtons = styled.div`
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    color: #3e21de;
    font-size: 16px;
    padding: 0 0 0 26px;
    cursor: pointer;
    font-weight: bold;
  }
`;

const DeleteTeamMemberModal = ({ member, visible, close, removeMember }) => {
  return (
    <Modal visible={visible} effect="fadeInUp" onClickAway={close}>
      <ModalBody>
        <HiXCircle color="red" size={40} />
        <H2>Confirm your action</H2>
        <Body>
          You're about to <b>delete {member ? member.email : "a member"}</b>{" "}
          from your team. The member will be notified and your billing will be
          adjusted.
        </Body>
        <ModalButtons>
          <Button onClick={removeMember}>
            Yes, I want to remove the member
          </Button>
          <Body onClick={close} style={{ marginTop: "1rem", padding: 0 }}>
            Close
          </Body>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
};
export default DeleteTeamMemberModal;
