import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Global from "../../../layouts/Global";
import { Link } from "react-router-dom";
import { Input, Button } from "desisystem";
import { useSelector, useDispatch } from "react-redux";
import {
  storePredictData,
  closeFeatureForAll,
} from "../../../../redux/ducks/storage";
import { Delete } from "zoozoo";
import DeviceType from "./screens/DeviceType";
import { globalActionsSelector } from "../../../../redux/slices/globalActions";
// import LinkButton from "../../../ui/buttons/LinkButton";

const Create = styled.div`
  background: ${(props) => props.theme.colors.globalBg}
  width: 100%;
  height: 100%;
  display: flex;
  padding-top: 140px;
  justify-content: center;

  h2 {
    text-align: center;
    font-weight: ${(props) => props.theme.weight.bold};
    font-size: ${(props) => props.theme.fontSize[26]};
  }

  input {
    border: 1px solid #c3baf5;
    box-sizing: border-box;
    border-radius: ${(props) => props.theme.radius.soft};
    min-width: 600px;
    height: 40px;
  }

  input:focus {
    border: 1px solid #3e21de;
  }
`;

const LinkBox = styled.div`
  margin: 40px auto 0 auto;
  text-align: center;
`;

const CloseLink = styled(Link)`
  & > svg {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: 40px;
  }
`;

const MakePrediction = (props) => {
  const [device, setDevice] = useState("desktop");
  const [predictionName, setPredictionName] = useState("");

  const { projectIdForGlobal } = useSelector(globalActionsSelector);
  const { pathname } = props.location.state;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(closeFeatureForAll());
  }, [dispatch]);

  const handlePredictionInfo = (e) => {
    e.preventDefault();

    const data = {
      model: device,
      name: predictionName,
      projectId: projectIdForGlobal ? projectIdForGlobal : null,
      pathname,
    };

    props.history.push("/predict/create/upload", {
      state: {
        projectIdForGlobal,
        pathname,
      },
    });
    dispatch(storePredictData(data));
  };

  const setCurrentDevice = (currentDevice) => {
    setDevice(currentDevice);
  };

  return (
    <React.Fragment>
      <Global />
      <CloseLink to={pathname}>
        <Delete width={40} height={40} />
      </CloseLink>
      <Create>
        <div>
          <h2>Create your Prediction</h2>
          <form onSubmit={handlePredictionInfo}>
            <Input
              type="text"
              size="10px"
              value={predictionName}
              placeholder="Type in your prediction name (optional)"
              onChange={(e) => setPredictionName(e.target.value)}
            />

            <DeviceType device={device} setCurrentDevice={setCurrentDevice} />

            <LinkBox>
              <Button onClick={handlePredictionInfo}>
                Next - Choose Image
              </Button>
            </LinkBox>
          </form>
        </div>
      </Create>
    </React.Fragment>
  );
};

export default MakePrediction;
