import { createSlice } from "@reduxjs/toolkit";
import { config } from "../../constants/endpoints";
import axios from "axios";
import { push } from "connected-react-router";
import { refreshToken, authorizedToken, getRefreshToken } from "./auth";
// import { emptyPrediction } from "./prediction";
import { closeDeleteModal } from "./globalActions";

// base url
let url = config.URL;

export const initialState = {
  loading: false,
  hasErrors: false,
  predictions: [],
  latestPredictions: [],
  hasMore: true,
};

const predictionsSlice = createSlice({
  name: "predictions",
  initialState,
  reducers: {
    loadPredictions: (state) => {
      state.loading = true;
    },

    getPredictions: (state) => {
      state.loading = true;
      state.predictions = [];
    },

    getPredictionsSuccess: (state, { payload }) => {
      state.predictions = payload;
      state.loading = false;
      state.hasErrors = false;
    },

    getPaginatedPredictionsSuccess: (state, { payload }) => {
      // state.predictions = payload;
      state.predictions = [...state.predictions, ...payload];
      state.loading = false;
      state.hasErrors = false;
      state.hasMore = payload.length === 12;
    },

    getPredictionsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },

    deleteSuccess: (state, { payload }) => {
      // state.predictions.splice(payload, 1);
      state.predictions = state.predictions.filter((p) => p.id !== payload);
      state.loading = false;
    },

    getLatestSuccess: (state, { payload }) => {
      state.latestPredictions = payload;
      state.loading = false;
      state.hasErrors = false;
    },

    emptyPredictions: (state) => {
      state.predictions = [];
      state.latestPredictions = [];
    },
  },
});

export const {
  loadPredictions,
  getPredictions,
  getPredictionsSuccess,
  getPaginatedPredictionsSuccess,
  deleteSuccess,
  getLatestSuccess,
  getPredictionsFailure,
  emptyPredictions,
} = predictionsSlice.actions;
export const predictionsSelector = (state) => state.predictions;
export default predictionsSlice.reducer;

export const fetchPredictions = (filter) => async (dispatch) => {
  dispatch(loadPredictions());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = filter ? `${url}/predictions${filter}` : `${url}/predictions`;

  try {
    const res = await axios.get(apiUrl, config);
    dispatch(getPredictionsSuccess(res.data));
    // dispatch(emptyPrediction());
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchPredictions, filter));
      } else {
        dispatch(getPredictionsFailure());
      }
    }
  }
};

export const fetchPaginatedPredictions = (page) => async (dispatch) => {
  // dispatch(loadPredictions());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions?page=${page}&page_size=12`;
  try {
    const res = await axios.get(apiUrl, config);

    dispatch(getPaginatedPredictionsSuccess(res.data));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(refreshToken(userData, fetchPredictions));
      } else {
        dispatch(getPredictionsFailure());
      }
    }
  }
};

export const deletePrediction = (id, pathOrigin, isLatest) => async (
  dispatch
) => {
  dispatch(loadPredictions());

  const userData = await dispatch(getRefreshToken());
  const config = await dispatch(authorizedToken());

  let apiUrl = `${url}/predictions/${id}`;

  try {
    await axios.delete(apiUrl, config);
    if (isLatest) {
      dispatch(fetchLatestPredictions());
    }
    dispatch(deleteSuccess(id));
    dispatch(closeDeleteModal());
    dispatch(push(pathOrigin));
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        dispatch(
          refreshToken(userData, deletePrediction, id, pathOrigin, isLatest)
        );
      }
    } else {
      dispatch(getPredictionsFailure());
    }
  }
};

export function fetchLatestPredictions() {
  return async (dispatch) => {
    dispatch(getPredictions());

    const userData = await dispatch(getRefreshToken());
    const config = await dispatch(authorizedToken());

    let apiUrl = `${url}/predictions?last=true`;

    try {
      const res = await axios.get(apiUrl, config);
      dispatch(getLatestSuccess(res.data));
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(refreshToken(userData, fetchLatestPredictions));
        }
      } else {
        dispatch(getPredictionsFailure());
      }
    }
  };
}

export const predictionsLogout = () => (dispatch) => {
  dispatch(emptyPredictions());
};
