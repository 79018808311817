import React, { Fragment, useState } from "react";
import styled from "styled-components";
import { Button, H2, KeyNew } from "desisystem";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "@material-ui/core/Tooltip";

// redux
import { generateApiKey, userSelector } from "../../../../redux/slices/user";

const LockBox = styled.div`
  display: block;
  margin: 40px auto 0 auto;
  text-align: center;
`;

const Circle = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${(props) => props.theme.primary[0]};
`;

const ApiKey = styled.div`
  background: #f5f5f5;
  border-radius: 4px;
  padding: 1rem 2rem;
  border-radius: 4px;
  margin: 32px 0;
  color: #212121;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
`;

const GenerateKey = () => {
  const dispatch = useDispatch();
  const { user } = useSelector(userSelector);

  const generateNewKey = () => {
    dispatch(generateApiKey());
  };

  const [open, setOpen] = useState(false);

  const copyToClipboard = (key) => {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 4000);
    const div = document.createElement("div");
    div.innerHTML = `<div tabindex="-1" style="position: fixed; top: 0px; left: 0px" id="clipboard" contentEditable>${key}</div>`;
    document.querySelector("body").appendChild(div.firstChild);
    const clipboard = document.getElementById("clipboard");
    clipboard.focus();

    document.execCommand("SelectAll");
    document.execCommand("Copy", false, null);

    clipboard.remove();
  };

  const toolTitle = !open ? "Copy to Clipboard" : "Copied!!!";
  return (
    <Fragment>
      <H2>Your API Key</H2>
      <LockBox>
        <Circle>
          <KeyNew fill="#3E21DE" width={64} height={64} />
        </Circle>
      </LockBox>
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={toolTitle}
        placement="top"
      >
        <ApiKey onClick={() => copyToClipboard(user.api_key)}>
          {user.api_key}
        </ApiKey>
      </Tooltip>
      <Button appearance="primary" width="100" onClick={generateNewKey}>
        Regenerate Key
      </Button>
    </Fragment>
  );
};

export default GenerateKey;
